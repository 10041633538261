import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import axios from "axios";
import {
  URLProductImage,
  URLServiceDetails,
  URLGetApplianceType,
  URLFaqList,
  URLReview,
} from "../util/UrlConst";
import CartAction, { AddProbleInCart } from "../action/CartAction";
import EssentialServiceComponent from "../component/EssentialServiceComponent";
import ServiceProblemComponent from "../component/ServiceProblemComponent";
import { useContext } from "react";
import { UrlHistoryContext } from "../context/UrlHistoryProvider";
import { toast } from "react-toastify";
import CategoryDetails from "./CategoryDetails";
import "./service.css";
import LoginModals from "./components/loginSignUp/login";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
// import { Container, Radio, Rating } from "./start.js";

import ReactGA from 'react-ga';
// import ''
export default function ServiceDetails() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  const urlcontext = useContext(UrlHistoryContext);
  // const {urlcontext ,cartQuantityTotal, setCartQuantityTotal,totalPrice, setTotalPrice,totalMRP, setTotalMRP} =
  //   useContext(UrlHistoryContext);
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();

  const [service, setService] = useState([]);

  const [relatedService, setRelatedService] = useState([]);
  const [faq, setFaq] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [problem, setProblem] = useState([]);
  const [cardData, setCardData] = useState();
  const [cartQuantity, setCartQuantity] = useState(0);
  const [desiredId1, setDesiredId] = useState();
  const [token, setToken] = useState();
  const [hideBooking, setHideBooking] = useState();

  const {
    changeLocalState,
    setChangeLocalState,
    cartQuantityTotal,
    totalPrice,
    totalMRP,
    setmodalLogin,
    modalLogin,
  } = useContext(UrlHistoryContext);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const url = window.location.href;
  const [expanded, setExpanded] = useState(false);

  const toggleReadMore = () => {
    setExpanded(!expanded);
  };
  // console.log(url, "urllllll");
  // useEffect(() => {
  //   const getCardData = JSON.parse(sessionStorage.getItem("cart"));
  //   console.log(getCardData, "getCardData");
  //   setCardData(getCardData);
  //   setCartQuantity(getCardData[0]?.quantity_data);
  // }, [changeLocalState]);
  useEffect(() => {
    const getCardData = JSON.parse(sessionStorage.getItem("cart"));

    // Replace 'yourDesiredId' with the actual id you're looking for
    // const desiredId = desiredId1;

    // Filter the getCardData array based on the id
    const desiredItem = getCardData?.find((item) => item.id === Number(id));

    if (desiredItem) {
      setCardData([desiredItem]); // Assuming you want to update cardData with only the item matching the id
      setCartQuantity(desiredItem.quantity_data);
    } else {
      setCardData([]);
      setCartQuantity(0); // or any default value if the item with the desired id is not found
    }
  }, [changeLocalState, id]);

  useEffect(() => {
    let token = sessionStorage.getItem("token");

    setToken(token);
  }, [sessionStorage.getItem("token")]);

  useEffect(() => {
    let tokens = sessionStorage.getItem("token");
    setToken(tokens);
    window.scrollTo(0, 0);
    getdata();

    async function getdata() {
      axios.defaults.headers.common["Authorization"] = `Bearer+ ${token}`;
      setCartButtonText(false);
      await axios.post(URLGetApplianceType, { id: id }).then((response) => {
        setProblem(response.data.data.problems);
        setService(response.data.data);
        setRelatedService(response?.data?.relatedservice?.items);
        setDesiredId(response.data.data.id);

        ReactGA.event({
          category: "Service",
          action: "Home Page",
          label: response.data.data.name,
          value: response.data.data.sale_price,
        });
      });
    }

    urlcontext.setUrlHistory(location.pathname);

    const gototcart = (e) => {
      navigate("/cart");
    };

    urlcontext.setUrlHistory(location.pathname);
  }, [id]);

  const getFqlQuestion = async () => {
    let token = sessionStorage.getItem("token");

    axios.defaults.headers.common["Authorization"] = `Bearer+ ${token}`;
    setCartButtonText(false);
    await axios.post(URLFaqList + id).then((response) => {
      setFaq(response?.data?.data);
    });
  };
  const getReview = async () => {
    let token = sessionStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = `Bearer+ ${token}`;
    await axios.post(URLReview + 31).then((response) => {
      setReviews(response.data.data);
    });
  };
  const createMarkup = (content) => {
    return { __html: content };
  };

  useEffect(() => {
    getFqlQuestion();
    getReview();
  }, [id]);

  const storeTokenInLocalStorage = (token) => {
    sessionStorage.setItem("userToken", token);
  };

  const gototcart = (e) => {
    navigate("/cart");
  };

  const [cartbuttontext, setCartButtonText] = useState(false);

  const addproblemtocart = (productid, quantity) => (e) => {
    setCartButtonText(true);
    AddProbleInCart(productid, quantity);
  };

  // const addtocart = (productid, quantity) => (e) => {
  //   // if (user === null) {
  //   //     toast("Login first.", { type: "error", theme: "dark" });
  //   //     navigate('/login');

  //   // } else {
  //   setCartButtonText(true);
  //   CartAction(productid, quantity);
  //   // }
  // };

  const buttons = document.querySelectorAll(".faq-toggle");

  buttons.forEach((button) => {
    button.addEventListener("click", () =>
      button.parentElement.classList.toggle("active")
    );
  });

  const addToCart = (product) => {
    const existingCart = JSON.parse(sessionStorage.getItem("cart")) || [];
    const existingProduct = existingCart?.find(
      (item) => item.id === product.id
    );

    if (existingProduct !== undefined) {
      existingProduct.quantity_data = (existingProduct.quantity_data || 0) + 1;
    } else {
      existingCart.push({ ...product, quantity_data: 1 });
    }

    sessionStorage.setItem("cart", JSON.stringify(existingCart));
    setCartQuantity(existingProduct?.quantity_data || 1);
  };

  const removeFromCart = (product) => {
    const existingCart = JSON.parse(sessionStorage.getItem("cart")) || [];
    const existingProductIndex = existingCart.findIndex(
      (item) => item.id === product.id
    );

    if (
      existingProductIndex !== -1 &&
      existingCart[existingProductIndex].quantity_data > 1
    ) {
      existingCart[existingProductIndex].quantity_data -= 1;
      setCartQuantity(existingCart[existingProductIndex].quantity_data);
    } else {
      // Remove the product from the cart
      existingCart.splice(existingProductIndex, 1);
      setCartQuantity(0);
    }

    sessionStorage.setItem("cart", JSON.stringify(existingCart));

    // setCartQuantity(existingCart[existingProductIndex].quantity_data);
  };

  const checkLog = () => {
    if (token == null || token == undefined) {
      setHideBooking(false);
    } else {
      setHideBooking(true);
    }
  };

  useEffect(() => {
    checkLog();
  }, [token]);

  const generateStars = (rating) => {
    return Array.from({ length: 5 }, (_, index) => (
      <FontAwesomeIcon
        key={index}
        icon={faStar}
        className={index < rating ? "filled" : ""}
      />
    ));
  };
  const initialContent =
    "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.";
  return (
    <>
      {/* {{-- ===========================================
                                BREADCRUMB
            =========================================== --}} */}

      {/* <Helmet>
        <title> {`The Classy | ${service?.name}`}</title>
        <meta name="description" content={`${service?.short_description}`} />
        <meta name="keywords" content={`${service?.tegs}`} />
        <meta property="og:image" content={`${service?.image}`} />
      </Helmet> */}
      {/* <Helmet>
        <title>{`The Classy | ${service?.name}`}</title>
        <meta name="description" content={service?.short_description || ""} />
        <meta name="keywords" content={service?.tegs || ""} />
        <meta property="og:title" content={service?.name} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:image" content={service?.image || ""} />
      </Helmet> */}
      {service ? (
        <Helmet>
          <title>{`The Classy | ${service?.name}`}</title>
          <meta property="og:title" content={`The Classy | ${service?.name}`} />
          <meta
            property="og:description"
            content={service?.short_description}
          />
          <meta property="og:image" content={service?.image} />
          <meta itemprop="image" content={service?.image} />
          <meta name="description" content={service?.short_description} />
          <meta name="keywords" content={service?.tegs} />
          <meta property="og:url" content={url} />
          <meta property="og:type" content="website" />
        </Helmet>
      ) : (
        ""
      )}
      <LoginModals props={modalLogin} />
      <div className="banner-inner-area section-bg-3 padding-top-100 padding-bottom-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner-inner-contents">
                <h2
                  className="banner-inner-title mb-0 text-black maxWidthFont"
                  style={{ fontFamily: "Poppins" }}
                >
                  {" "}
                  {service.name}{" "}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {{-- ===========================================
                            CATEGORY DETAILS
            =========================================== --}} */}

      {service && service.name ? (
        <section
          className="service-details-area padding-top-40 padding-bottom-40"
          style={{ overflow: "visible" }}
        >
          <div className="container">
            <div className="row">
              {/* When Entering From Repair */}
              <div className="col-12">
                <div className="row">
                  <div className="col-lg-7 margin-top-30">
                    <div className="service-details-wrapper">
                      <div className="service-details-inner">
                        <div className="details-thumb">
                          <div className="main-img-box ">
                            <div className="borderImg rounded-3 gallery-images single-featured service-details-background-image">
                              <img
                                src={
                                  service.image
                                    ? service.image
                                    : "/assets/frontend/img/static/imagenotfound.jpg"
                                }
                                alt="..."
                                className="w-100 h-100 rounded-3"
                              ></img>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 margin-top-30">
                    <div className="service-details-package">
                      <div className="single-packages">
                        <ul className="package-price p-0 m-0 listLine">
                          <li className="maxWidthFont serviceDetailHeaderWidth">
                            {" "}
                            {service.name}{" "}
                          </li>
                          <li className="" style={{ color: "gray" }}>
                            {/* {" "}
                            ₹.{" "}
                            <span className="prices ">
                              {service?.sale_price}{" "}
                            </span> */}
                            <p
                              className="p-0 m-0 fontDescription fs-5"
                              style={{ lineHeight: "1.5" }}
                            >
                              {" "}
                              {/* Start at{" "} */}
                              <span
                                className="text-success"
                                style={{ fontWeight: "600" }}
                              >
                                ₹ {service?.sale_price}
                              </span>
                            </p>
                          </li>
                        </ul>
                        <div className="d-flex gap-2">
                          <p
                            style={{ fontSize: "14px" }}
                            className="fw-bolder p-0 m-0 fontDescription "
                          >
                            Appliance name :
                          </p>
                          {"  "}
                          <span
                            className=" fontDescription pt-1 fw-bolder"
                          // style={{ fontFamily: "Poppins", fontWeight: "600" }}
                          >
                            {" "}
                            {service?.appliance_name}
                          </span>
                        </div>
                        <div className="d-flex gap-2">
                          <p
                            style={{ fontSize: "14px" }}
                            className="fw-bolder p-0 m-0 fontDescription"
                          >
                            Service type :
                          </p>
                          {"  "}
                          <span
                            className="text-black pt-1 fontDescription"
                            style={{ fontWeight: "600" }}
                          >
                            {" "}
                            {service?.serviceType_name}
                          </span>
                        </div>
                        <div className="d-flex gap-2">
                          <p
                            className="p-0 m-0 fw-bolder  fontDescription"
                            style={{ lineHeight: "1.5" }}
                          >
                            {" "}
                            Start at :
                            <span
                              className="text-success ms-1"
                              style={{ fontWeight: "600" }}
                            >
                              ₹ {service?.sale_price}
                            </span>
                          </p>
                        </div>
                        <div className="mt-2">
                          <p
                            className="p-0 m-0  fontDescription"
                            style={{ lineHeight: "1.5" }}
                          >
                            {service?.short_description}
                          </p>
                        </div>

                        <div className="details-available-price margin-top-20">
                          <span className="summery-title">
                            <ul className="available-list ">
                              <li className="fontDescription"> 45 Mins </li>
                              <li className="fontDescription">
                                {" "}
                                Engineer will visit your home and determine the
                                exact problem in your product.{" "}
                              </li>
                            </ul>
                          </span>
                        </div>
                        <div>
                          <h1 className="fontDescription">
                            {expanded
                              ? service?.content
                              : service?.content.slice(0, 20) + "..."}
                          </h1>
                          <button
                            className="p-0 m-0"
                            style={{ color: "blue" }}
                            onClick={toggleReadMore}
                          >
                            {expanded ? "Read Less" : "Read More"}
                          </button>
                        </div>

                        {problem == false && (
                          <div className="btn-wrapper text-center margin-top-30">
                            {cartQuantity ? (
                              <div className="right-static">
                                <div className="mid-info text-center">
                                  <div className="mid-ico">
                                    <button
                                      className="mid-ico backgroundColor rounded-2 p-0"
                                      style={{
                                        cursor: "pointer",
                                        height: "27px",
                                        margin: "0px",
                                        width: "23px",
                                      }}
                                      onClick={() => {
                                        // removeFromCart(props.product);
                                        removeFromCart(cardData[0]);

                                        setChangeLocalState(!changeLocalState);
                                      }}
                                    >
                                      -
                                    </button>
                                  </div>

                                  <div className="mid-ico ">{cartQuantity}</div>

                                  <div className="mid-ico">
                                    <button
                                      className="mid-ico backgroundColor rounded-2 p-0"
                                      style={{
                                        cursor: "pointer",
                                        height: "27px",
                                        margin: "0px",
                                        width: "23px",
                                      }}
                                      onClick={() => {
                                        addToCart(cardData[0]);
                                        setChangeLocalState(!changeLocalState);
                                      }}
                                    >
                                      +
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div
                                className="addButtonHeader py-1  d-flex justify-content-center"
                                style={{ cursor: "pointer", width: "17%" }}
                              >
                                <span
                                  className="addButton"
                                  // onClick={addToCart(service.id, 1)}
                                  onClick={() => {
                                    // addToCart(props.product);
                                    // addToCart(cardData[0]);
                                    addToCart(service);

                                    setChangeLocalState(!changeLocalState);
                                  }}
                                >
                                  {" "}
                                  Add{" "}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="order-pagkages">
                        <div className="single-order">
                          <div className="warranty-section maxWidthFont">
                            <div className="title">
                              Services and Parts Warranty
                            </div>
                            <div className="list">
                              <div className="d-flex align-items-baseline">
                                <div>
                                  <i className="las la-check-double"></i>
                                </div>
                                <div>20+ Services Covered</div>
                              </div>
                              <div className="d-flex align-items-baseline">
                                <div>
                                  <i className="las la-check-double"></i>
                                </div>
                                <div>Revisit - Free</div>
                              </div>
                              <div className="d-flex align-items-baseline">
                                <div>
                                  <i className="las la-check-double"></i>
                                </div>
                                <div>Service Warranty - 1 Month</div>
                              </div>
                              <div className="d-flex align-items-baseline">
                                <div>
                                  <i className="las la-check-double"></i>
                                </div>
                                <div>Parts Warranty - 60 Days</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* //phone */}

                      {(service?.problems.length && cartQuantityTotal != 0) ||
                        (service?.problems == false && cartQuantity != 0) ? (
                        <div
                          className="mb-2 mt-2 rounded-3 py-2 d-lg-block d-none d-md-block w-100"
                          style={{
                            boxShadow:
                              "0 -8px 16px 0 rgba(207, 205, 205, 0.8), 0 4px 8px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          <div className=" text-center p-1 px-3 d-flex">
                            <div className="rupes d-flex pt-2">
                              <p
                                style={{
                                  backgroundColor: "gray",
                                  borderRadius: "50%",
                                  width: "25px", // Set the width to the desired size
                                  height: "25px", // Set the height to the desired size
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: "white",
                                }}
                                className="rupes"
                              >
                                {service?.problems == false
                                  ? cartQuantity
                                  : cartQuantityTotal}
                              </p>
                              <span className="px-2">
                                {"   "}{" "}
                                {service?.problems == false
                                  ? cartQuantity * service?.sale_price
                                  : totalMRP}
                              </span>{" "}
                            </div>
                            {hideBooking ? (
                              <div className=" p-2 px-4 all-info ml-auto bottom-cart-info">
                                <Link
                                  to="/cart"
                                  className="fw-bold mt-1"
                                  style={{
                                    color: "white",
                                    textDecoration: "none",
                                  }}
                                >
                                  View Cart
                                </Link>
                              </div>
                            ) : (
                              <div
                                className=" p-2 px-4 all-info ml-auto bottom-cart-info cursor-pointer"
                                onClick={() => setmodalLogin(true)}
                              >
                                View Cart
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* When Entering From Other Category */}
              <div className="col-12 margin-top-30 problem-list ">
                <div className="row">
                  {problem &&
                    problem.map((data, index) => (
                      // <div  key={index}>
                      <ServiceProblemComponent item={data} problems={service} />
                      // </div>
                    ))}
                </div>
              </div>
              {/* {{-- ==================================================
                            SPARE PART PRICES
        ================================================================= --}} */}
              <div className="spare-part-section margin-top-30 ">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 p-0 pt-3">
                      <div className="card-spare w-100">
                        <Link
                          style={{ textDecoration: "none" }}
                          to={`/rateCard/${service?.id}`}
                          className="services-thumb location_relatives services-bg-thumb-format w-100"
                        >
                          <div className="spare-icon">
                            <i class="fa-regular fa-rectangle-list"></i>
                          </div>

                          <div className="spare-tilte w-100 maxWidthFont pt-1 pt-lg-0 pt-md-0 ">
                            Spare part prices
                          </div>
                          {/* <div className="spare-right-icon">
                            <i class="fa-solid fa-chevron-right"></i>
                          </div> */}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ====================================================4 grid layout card==================================== */}
              <section
                id="advertisers"
                className="advertisers-service-sec pt-4"
              >
                <div className="">
                  <div className="row">
                    <div className="section-header">
                      <h3 className="fw-bold fs-1 maxWidthFont  ">
                        {" "}
                        <span> The Classy</span> Cover
                      </h3>
                    </div>

                    <div className="row mt-5 mt-md-4 justify-content-center p-0 m-0 globle_ZIndex">
                      <div className="col-lg-3 col-md-3 col-6  m-0   mb-4  mb-sm-1 mb-lg-0">
                        <div className="service-card">
                          <div className="mt-4 d-flex justify-content-center maxWidthFont">
                            <h3>30 days</h3>
                          </div>
                          <br></br>
                          <h5
                            className="mx-4 text-center"
                            style={{
                              fontSize: "smaller",
                              fontWeight: "600",
                              fontFamily: "Serif, Sans-serif",
                            }}
                          >
                            Unconditional The classy Warranty
                          </h5>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-6  m-0  mb-4 mb-sm-1 mb-lg-0">
                        <div className="service-card">
                          <div className="mt-4 d-flex justify-content-center">
                            <i className="fs-1 fa-solid fa-person-circle-question"></i>
                          </div>
                          <br></br>
                          <h5
                            style={{
                              fontSize: "smaller",
                              fontWeight: "600",
                              fontFamily: "Serif, Sans-serif",
                            }}
                            className="mx-4 text-center"
                          >
                            No qustions asked claim
                          </h5>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-6  m-0  mb-4  mb-sm-1 mb-lg-0">
                        <div className="service-card">
                          <div className="mt-4 d-flex justify-content-center">
                            <i className="fs-1 fa-solid fa-headphones-simple"></i>
                          </div>
                          <br></br>
                          <h5
                            className="mx-4 text-center"
                            style={{
                              fontSize: "smaller",
                              fontWeight: "600",
                              fontFamily: "Serif, Sans-serif",
                            }}
                          >
                            Talk to The classy expert for free
                          </h5>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-6  m-0   mb-4  mb-sm-1 mb-lg-0">
                        <div className="service-card">
                          <div className="mt-4 d-flex justify-content-center">
                            <i className="fs-1 fa-solid fa-file-signature"></i>
                          </div>
                          <br></br>
                          <h5
                            className="mx-4 text-center"
                            style={{
                              fontSize: "smaller",
                              fontWeight: "600",
                              fontFamily: "Serif, Sans-serif",
                            }}
                          >
                            The classy verified repair quotes
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div className="row mt-3 py-2 text-black">
              <div className="col-lg-12 col-md-12 col-12 text-black">
                {/* <div
                  dangerouslySetInnerHTML={{
                    __html: service?.description,
                  }}
                ></div> */}
                <div
                  dangerouslySetInnerHTML={createMarkup(service?.description)}
                />
              </div>
            </div>
            <div className="container">
              {faq.length ? (
                <>
                  <div className="AccordionContainer">
                    <div className="title-head">
                      <h2
                        style={{ fontWeight: "bold" }}
                        className="maxWidthFont fs-3"
                      >
                        Frequently asked qustion
                      </h2>
                    </div>{" "}
                    <Accordion
                      className="accordionBorder"
                      border={false}
                      style={{
                        fontFamily: "Serif, Sans-serif",
                        // border: "none",
                      }}
                      defaultActiveKey="0"
                    >
                      {faq?.map((data) => (
                        <Accordion.Item
                          // className="accordionBorder"
                          eventKey={data.id}
                        >
                          <Accordion.Header>
                            {data?.faq_question}
                          </Accordion.Header>
                          <Accordion.Body>{data?.faq_answer}</Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </div>
                </>
              ) : null}
            </div>
            <div className="row">
              {reviews.length ? (
                <div className="col-lg-12 margin-top-30 globle_ZIndex">
                  <div className="reviews">
                    <div className="title-head">
                      <div className="maxWidthFont"> Reviews</div>
                    </div>

                    {reviews?.map((itemData) => (
                      <div class=" mt-2 verified_customer_section ">
                        <div class="image_review">
                          <div class="customer_image">
                            <img
                              src="https://cdn.shopify.com/s/files/1/0664/2191/5900/t/8/assets/screenshot-20221121-alle-191353-1669054447602.png?v=1669054450"
                              alt="customer image"
                            />
                          </div>

                          <div class="customer_name_review_status">
                            <div class="customer_name maxWidthFont">
                              {itemData?.user_name}
                            </div>
                            <div class="customer_review maxWidthFont">
                              {generateStars(itemData.rating)}
                            </div>

                            <div class="customer_status">
                              <div class="customer_bage d-flex p-0 m-0 pe-2">
                                {" "}
                                <img
                                  src="https://cdn.shopify.com/s/files/1/0566/8241/4246/t/11/assets/icon-verified-1662629893290.png?v=1662629894"
                                  alt=""
                                />{" "}
                              </div>
                              <div class="customer_status_content maxWidthFont">
                                Verified Customer
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="customer_comment maxWidthFont">
                          {itemData?.feedback}
                        </div>
                      </div>
                    ))}
                    {/* === */}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            {(service?.problems.length && cartQuantityTotal != 0) ||
              (service?.problems == false && cartQuantity != 0) ? (
              <div className="bottom-cart_phone py-2 d-lg-none d-block d-md-none w-100">
                <div
                  className=" text-center p-1 px-3 d-flex rounded-3"
                  style={{ background: "whitesmoke" }}
                >
                  <div className="rupes d-flex pt-2">
                    <p
                      style={{
                        backgroundColor: "gray",
                        borderRadius: "50%",
                        width: "25px", // Set the width to the desired size
                        height: "25px", // Set the height to the desired size
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "white",
                      }}
                      className="rupes m-2"
                    >
                      {service?.problems == false
                        ? cartQuantity
                        : cartQuantityTotal}
                    </p>
                    <h5 className="rupes text-black px-2 m-2">
                      {" "}
                      {/* {totalMRP} */}
                      {service?.problems == false
                        ? cartQuantity * service?.sale_price
                        : totalMRP}
                    </h5>
                    {/* <span className="rupe">₹{totalMRP}</span>{" "} */}
                  </div>

                  <div
                    className="all-info ml-auto p-2 px-3 rounded-3"
                    style={{ color: "black", background: "#0D6EFD" }}
                  >
                    {hideBooking ? (
                      <Link
                        to="/cart"
                        className="fw-bold mt-1 d-flex ms-3 cursor-pointer"
                        style={{
                          color: "white",
                          textDecoration: "none",
                        }}
                      >
                        View Cart
                      </Link>
                    ) : (
                      <span
                        // to="/cart"
                        className="fw-bold mt-1 cursor-pointer d-flex ms-3"
                        onClick={() => setmodalLogin(true)}
                        style={{
                          color: "white",
                          textDecoration: "none",
                        }}
                      >
                        View Cart
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </section>
      ) : (
        <center>
          <h4 className="mt-5">Loading....</h4>
        </center>
      )}
    </>
  );
}
