import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import {
  publicUrl,
  URLCancelOrder,
  URLOrderData,
  URLOrderDetails,
  URLProductImage,
} from "../util/UrlConst";

export default function ServiceBooked() {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (data) => async (e) => {
    setOrderDetails(data);
    // await axios.post(URLOrderDetails, { orderid: data.order_id, valetuserid: user.id, product_id: data.prodid.id }).then(res => {
    //   if (!res.data.error) {
    //     setOrderDetails(res.data.orderDetail)
    //   } else {
    //     toast("Data not found.", { type: "error", theme: "dark" });
    //   }

    // }).catch(error => {
    //   toast("Something went wrong.", { type: "error", theme: "dark" });
    // })

    setShow(true);
  };
  const [isloadin, setLoading] = useState(true);

  const [orders, setOrders] = useState([]);
  const [orderDetails, setOrderDetails] = useState();

  async function getdata() {
    await axios
      .post(URLOrderData, { valetuserid: user.id, type: "service" })
      .then((res) => {
        setOrders(res.data.orderData);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
  }

  useEffect(() => {
    getdata();
  }, []);

  const cancelorder = (id, product_id) => async (e) => {
    await axios
      .post(URLCancelOrder, {
        orderid: id,
        valetuserid: user.id,
        productid: product_id,
      })
      .then((res) => {
        getdata();
        toast(res.data.Message, { type: "success", theme: "dark" });
        handleClose();
      });
  };

  return (
    <>
      {/* {{-- ===========================================
                          BREADCRUMB
      =========================================== --}} */}
      <div className="banner-inner-area section-bg-3 padding-top-100 padding-bottom-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner-inner-contents">
                <h2 className="banner-inner-title mb-0"> Services Booked </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {{-- ===========================================
                        SERVICES LIST
      =========================================== --}} */}
      <section className="booked-services-list padding-top-40 padding-bottom-40">
        <div className="container">
          {orders.length > 0 ? (
            <div className="row">
              {orders &&
                orders.map((order) => (
                  <>
                    {order && (
                      <div className="col-lg-6">
                        <div
                          className="book-service-box"
                          onClick={handleShow(order)}
                        >
                          <div className="image">
                            <img
                              src={
                                order.orderid[0].prodid
                                  ? URLProductImage +
                                    order.orderid[0].prodid.image
                                  : "/assets/frontend/img/static/imagenotfound.jpg"
                              }
                              alt="..."
                              className="w-100 h-100"
                            ></img>
                          </div>
                          <div className="details">
                            <div className="name">
                              Order Id:{" "}
                              {order.orderid[0] && order.orderid[0].order_id}
                            </div>
                            <div className="price">
                              <span>Amount</span> : Rs. {order.total}
                            </div>
                            {/* <div className="status">
                          <span>Status : </span>
                          {order.status}
                        </div> */}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))}
            </div>
          ) : (
            <>
              {isloadin ? (
                <center>
                  <h4 className="mt-5">Loading....</h4>
                </center>
              ) : (
                <center>
                  <h5> No orders found</h5>
                </center>
              )}
            </>
          )}
        </div>
      </section>

      {/* {{-- ===========================================
                          SERVICE MODAL
      =========================================== --}} */}
      <Modal size="md" id="service-modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Service Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {orderDetails && (
            <>
              <div className="row">
                {orderDetails.orderid &&
                  orderDetails.orderid.map((data) => (
                    <div className="col-md-12">
                      <div className="service-top">
                        <div className="image">
                          <img
                            src={
                              data.prodid
                                ? URLProductImage + data.prodid.image
                                : "/assets/frontend/img/static/imagenotfound.jpg"
                            }
                            alt="..."
                            className="w-100 h-100"
                          ></img>
                        </div>
                        <div className="details">
                          <div className="serice-id">{data.order_id}</div>
                          <div className="name">{data.productname}</div>
                          <div className="price">
                            Rs. {data.discounted_price}
                          </div>
                          <b>
                            <div className="price">
                              Staus : {data.order_status}
                            </div>
                          </b>
                          <div className="status">
                            Service Booked :{" "}
                            {moment(data.created_at).format(
                              "DD MMMM, YYYY HH:MM:SS"
                            )}
                          </div>
                        </div>

                        <div className="service-detail ml-2">
                          <div className="head">Service Details</div>
                          <div className="info-bar">
                            <span>Schedule Time : </span> {orderDetails.date} ,{" "}
                            {orderDetails.time}
                          </div>
                          <div className="info-bar">
                            <span>Order Status : </span>{" "}
                            {orderDetails.order_status}
                          </div>
                          {data.order_status !== "Cancel" ? (
                            <>
                              {" "}
                              {data.order_status !== "Task-Complete" ? (
                                <>
                                  <button
                                    className="cancel"
                                    style={{ cursor: "pointer" }}
                                    onClick={cancelorder(
                                      data.order_id,
                                      data.prodid && data.prodid.id
                                    )}
                                  >
                                    Cancel Order
                                  </button>
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            data.order_status
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

              <div className="service-detail">
                <div className="head">Service Location</div>
                <div className="content">
                  <div className="name">
                    {orderDetails.address && orderDetails.address.name} ({" "}
                    {orderDetails.address && orderDetails.address.mobile})
                  </div>
                  <div className="address">
                    {orderDetails.address &&
                      orderDetails.address.building +
                        ", " +
                        orderDetails.address.landmark +
                        ", " +
                        orderDetails.address.district +
                        ", " +
                        orderDetails.addressid.state +
                        ", " +
                        orderDetails.address.zipcode}
                  </div>
                </div>
              </div>

              <div className="payment-detail">
                <div className="head">Payment Details</div>
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <td>Service</td>
                      <td>
                        {orderDetails.orderid && orderDetails.orderid.length}
                      </td>
                    </tr>

                    <tr>
                      <td>Convenience Fee</td>
                      <td>Rs. 0</td>
                    </tr>
                    {/* <tr>
                      <td>Offer Price</td>
                      <td>Rs. {orderDetails.discounted_price}</td>
                    </tr> */}
                    <tr>
                      <td>Tip Price</td>
                      <td>Rs. {orderDetails.tip}</td>
                    </tr>
                    <tr>
                      <td>Total Price</td>
                      <td>Rs. {orderDetails.total}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Cancel</Button>
          {/* {
            orderDetails && orderDetails.order_status === 'Task-Complete' && <a target="_open" href={`${publicUrl}public/admininvoice/${orderDetails.orderid && orderDetails.orderid.id}`}>Invoice</a>
          } */}

          {/* <Button>Payment</Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}
