import React from 'react'

export default function OTP() {
    return (
        <>

            <div className="signup-area padding-top-40 padding-bottom-40">
                <div className="container">
                    <div className="signup-wrapper">
                        <div className="signup-contents">
                            <h3 className="signup-title"> Verify OTP. </h3>
                            <form className="signup-forms" action="" method="post">
                                <div className="single-signup margin-top-30">
                                    <label className="signup-label"> Enter OTP </label>
                                    <input className="form--control" type="email" name="email" placeholder="OTP"></input>
                                </div>
                                <button type="submit">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
