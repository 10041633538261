import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
// eslint-disable-next-line no-unused-vars
import { ButtonGroup, Spinner } from "react-bootstrap";
// import { toast } from "react-toastify";
import CartServiceComponent from "../component/CartServiceItemComponent";
import {
  URLCartTimeSlot,
  URLCouponId,
  URLDeleteaddress,
  URLGetAddress,
  URLGetCart,
  URLOfferList,
  URLPayment,
  URLPaymentdata,
  URLSubmit,
  URLSubmitOrder,
  URLaddress,
} from "../util/UrlConst";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Link, redirect, useNavigate } from "react-router-dom";
import _ from "lodash";
import { v4 as uuid } from "uuid";
import moment from "moment";
import AddAddressComponent from "../component/AddAddressComponent";
import { UrlHistoryContext } from "../context/UrlHistoryProvider";
import Problems_Table from "../component/problem_table";
import { error } from "jquery";
import Timeschedule from "./components/Timeschedule";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function Cart() {
  // const history = useHistory();
  const [iframe, setIframe] = useState(null);

  // const { changeLocalState, setChangeLocalState, salePrice, mrp } =
  //   useContext(UrlHistoryContext);

  const {
    changeLocalState,
    setmodalLocation,
    mapData,
    setMapData,
    postPinCode,
    setPostPinCode,
    updateData,
    setLocationModalOpen,
    setCurrentPathCart,
  } = useContext(UrlHistoryContext);
  useEffect(() => {
    // Get the pathname from window.location
    const currentPathname = window.location.pathname;
    setCurrentPathCart(currentPathname);
    return () => {
      setCurrentPathCart("");

      // Perform cleanup or destroy the state here
    };
  }, []);
  const [model, setModal] = useState(false);
  const [handleID, setHandleID] = useState();
  const [close, setClose] = useState(false);
  const [itemProblem, setItems] = useState();

  const [selectedDateTime, setSelectedDateTime] = useState({
    date: null,
    time: null,
  });
  // const [paymentMethod, setPaymentMethod] = useState("online");

  const [paymentMethod, setPaymentMethod] = useState("cash");

  const handleCloseOffer = () => {
    setModal(false);
    // setShow(false);
  };

  const user = JSON.parse(sessionStorage.getItem("user"));
  const UserNumber = sessionStorage.getItem("UserNumber");
  const [totalPrice, setTotalPrice] = useState(0);

  //   console.log(totalPrice,'totalPricetotalPrice')

  let token = sessionStorage.getItem("token");
  const [cartdata, setCartData] = useState([]);
  const [cartdataLocal, setCartDataLocal] = useState([]);
  const [areaId, setAreaId] = useState();
  const [offer, setOfferList] = useState();

  const groupedServices = cartdataLocal?.reduce((acc, service) => {
    const applianceId = service?.appliance_id;
    if (!acc[applianceId]) {
      acc[applianceId] = [];
    }
    acc[applianceId].push(service);
    return acc;
  }, {});

  // Convert object to array4
  let resultArray;
  if (groupedServices) {
    resultArray = Object?.values(groupedServices);
  }

  const [alladdress, setaddaddress] = useState([]);

  async function getdata() {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    // if (user === null) {
    //     navigate('/login', { replace: true });
    //     toast("Login First.", { type: "error", theme: "dark" });
    // }

    await axios.post(URLGetCart, {}).then((res) => {
      if (res.data.error) {
        navigate("/", { replace: true });
        toast(res.data.message, { type: "error", theme: "dark" });
      } else {
        setCartData(res.data.data);
      }

      // addressdataload();
    });
  }

  async function addressdataload() {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    await axios.post(URLGetAddress, { valetuserid: user?.id }).then((res) => {
      setaddaddress(res.data.address);
    });
  }

  const tipamountset = (tipa) => async (e) => {
    setTipAmount(tipa);
    await axios
      .post(URLGetCart, { valetuserid: user.id, type: "service", tip: tipa })
      .then((res) => {
        if (res.data.error) {
          toast(res.data.message, { type: "error", theme: "dark" });
        } else {
          setCartData(res.data);
        }
      });
  };

  // const setCurrentLocation = () => {

  //     navigator.geolocation.getCurrentPosition(pos => {

  //         const { latitude, longitude } = pos.coords;
  //         console.log(latitude, longitude)
  //         findlocation(latitude, longitude);

  //     },
  //         err => {
  //             alert("Please give us location permission to add your address");
  //         }
  //     )

  // }

  const [gpslocation, setGpsLocation] = useState();

  async function findlocation(latitude, longitude) {
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
    await axios.get(url).then((data) => {
      setGpsLocation(data.data.address);

      setAddLandmark(gpslocation.suburb);
      setaddDistrict(gpslocation.city);
      setAddState(gpslocation.state);
      setaddZipcode(gpslocation.postcode);

      setUsinggpslocation(true);
      showeditaddress(true);
    });
  }

  const [isusinggpslocation, setUsinggpslocation] = useState(false);

  // useEffect(() => {

  //     getdata();

  //     navigator.geolocation.getCurrentPosition(pos => {

  //         const { latitude, longitude } = pos.coords;
  //         console.log(latitude, longitude)
  //         findlocation(latitude, longitude);

  //     },
  //         err => {
  //             alert("Please give us location permission to add your address");
  //         }
  //     )

  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const [tipamount, setTipAmount] = useState(0);
  const [date, setDate] = useState();

  const [time, setTime] = useState();
  const [timeSlot, setTimeSlot] = useState([]);

  const [accesscode, setaccesscode] = useState();
  const [Encryptdata, setEncrypt] = useState();
  const [marchant_id, setMarchant_id] = useState();

  const [cartaddress, setCartAddress] = useState();
  const [finaladdress, setFinaaddress] = useState();
  const paymentform = useRef(null);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  const [addressformtoggle, setaddressformtoggle] = useState(false);
  const showAddressForm = () => setaddressformtoggle(true);
  const closeAddressForm = () => setaddressformtoggle(false);

  const addressdatarefresh = () => addressdataload();

  function addDays(numDays) {
    return moment(new Date()).add(numDays, "days").format("MMM-DD,dddd");
  }

  const dateslotclick = (date) => async (e) => {
    setDate(date);

    await axios
      .post(URLCartTimeSlot, {
        date: moment(date, "MMM-DD,dddd").format("YYYY-MM-DD"),
      })
      .then((res) => {
        setTimeSlot(res.data.data);
      });
  };

  const addresselectevent = (id) => (e) => {
    e.preventDefault();
    handleClose();

    const add = alladdress.filter((obj) => obj.id === id)[0];

    setCartAddress(add);

    setFinaaddress(
      add.landmark +
        "," +
        add.district +
        "," +
        add.building +
        "," +
        add.state +
        "," +
        add.zipcode
    );

    setUsinggpslocation(false);
  };

  const [editaddressstate, setEditAddressState] = useState(false);

  const showeditaddress = (e) => setEditAddressState(true);
  const closeeditaddress = (e) => setEditAddressState(false);

  const saveeditaddress = (e) => {
    e.preventDefault();

    if (addLandMark && addDistrict && addbuilding && addState && addZipcode) {
      setCartAddress({
        name: user?.valetuser,
        mobile: user.mobile,
        zipcode: addZipcode,
        city: addDistrict,
        state: addState,
      });
      setFinaaddress(
        addLandMark +
          "," +
          addDistrict +
          "," +
          addbuilding +
          "," +
          addState +
          "," +
          addZipcode
      );
      setEditAddressState(false);
    } else {
      toast("Please enter add all address field", {
        type: "error",
        theme: "dark",
      });
    }
  };

  const [addbuilding, setAddBuilding] = useState();
  const [addLandMark, setAddLandmark] = useState();
  const [addState, setAddState] = useState();
  const [addDistrict, setaddDistrict] = useState();
  const [addZipcode, setaddZipcode] = useState();

  const deleteaddress = (id) => async (e) => {
    e.preventDefault();
    await axios
      .post(URLDeleteaddress, { valetuserid: user.id, id: id })
      .then((res) => {
        toast(res.data.address, { type: "success", theme: "dark" });
        addressdataload();
      });
  };

  const submitOrder = async (e) => {
    if (finaladdress === undefined) {
      toast("Please select address slot.", { type: "error", theme: "dark" });
      return;
    }

    if (date === undefined) {
      toast("Please select date.", { type: "error", theme: "dark" });
      return;
    }

    if (time === undefined) {
      toast("Please select time slot.", { type: "error", theme: "dark" });
      return;
    }

    if (paymentMethod === undefined) {
      toast("Please Payment method.", { type: "error", theme: "dark" });
      return;
    }

    if (paymentMethod === "cash") {
      e.preventDefault();
      await axios
        .post(URLSubmitOrder, {
          valetuserid: user.id,
          addressid: finaladdress,
          total: Number(cartdata.totalprice),
          payment_id: null,
          time: time,
          date: date,
        })
        .then((res) => {
          if (res.data.error) {
            toast(res.data.message, { type: "error", theme: "dark" });
          } else {
            toast(res.data.message, { type: "success", theme: "dark" });
            navigate("/service-booked", { replace: true });
          }
        });
    } else {
      await axios
        .post(URLPaymentdata, {
          user_id: user.id,
          amount: Number(cartdata.totalprice),
          name: cartaddress.name,
          address: finaladdress,
          zipcode: cartaddress.zipcode,
          city: cartaddress.district,
          state: cartaddress.state,
          mobile: cartaddress.mobile,
          time: time,
          date: date,
        })
        .then((res) => {
          if (!res.data.error) {
            setaccesscode(res.data.data.access_code);
            setEncrypt(res.data.data.enc_val);
            setMarchant_id(res.data.data.merchant_id);
          }

          setTimeout(function () {
            paymentform.current.submit();
          }, 500);
        })
        .catch((error) => {
          toast("Something went wrong", { type: "error", theme: "dark" });
        });
    }
  };

  const handleRedirect = async (e) => {
    try {
      const redirectUrl = e;
      // `https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction&encRequest=${encodeURIComponent(
      //   paymentData.encRequest
      // )}&access_code=${encodeURIComponent(paymentData.access_code)}`;

      const newIframe = document.createElement("iframe");
      newIframe.src = redirectUrl;
      newIframe.style.width = "100%";
      newIframe.style.height = "500px";
      newIframe.style.border = "none";

      // Remove the existing iframe (if any)
      if (iframe) {
        document.body.removeChild(iframe);
      }

      // Append the new iframe to the document body
      document.body.appendChild(newIframe);

      // Set the new iframe in the state
      setIframe(newIframe);
    } catch (error) {
      console.error("Error during redirection:", error);
    }
  };

  const submitOrderPayment = async () => {
    // ==
    const localData = sessionStorage.getItem("cart");
    // const objectsWithoutDateAndTime = JSON.parse(localData).filter(obj => !('date' in obj) && !('time' in obj));
    // console.log(objectsWithoutDateAndTime,'localDatalocalData')
    // Function to check if an object has date and time keys
    // Function to check for the presence of date and time keys

    const hasDateAndTime = (obj) => "date" in obj && "time" in obj;

    // Recursively check for the presence of date and time keys in nested objects
    const recursivelyCheckDateAndTime = (obj) => {
      if (Array.isArray(obj)) {
        return obj.some(recursivelyCheckDateAndTime);
      } else if (typeof obj === "object" && obj !== null) {
        return (
          Object.values(obj).some(recursivelyCheckDateAndTime) ||
          hasDateAndTime(obj)
        );
      } else {
        return false;
      }
    };

    // Filter objects without date and time at both top-level and within problems array
    // const objectsWithoutDateAndTime = JSON.parse(localData).filter(
    //   (obj) => !recursivelyCheckDateAndTime(obj)
    // );
    const objectsWithoutDateAndTime = JSON.parse(localData).filter(
      (obj) => !recursivelyCheckDateAndTime(obj)
    );

    // ==
    // if (user === null) {
    //   toast("Login first.", { type: "error", theme: "dark" });
    //   window.location.href = "/login";
    // } else {

    if (!objectsWithoutDateAndTime.length && areaId) {
      const urlPaymentPost = `${URLPayment}amount=${totalPrice}&address_id=${areaId}`;

      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios.post(urlPaymentPost).then(async (res) => {
        if (res.data.error) {
          toast(res.data.message, { type: "error", theme: "dark" });
        } else {
          // toast(res.data.message, { type: "success", theme: "dark" });
          var paymentData = {
            encRequest: res?.data?.data?.enc_val,

            access_code: res?.data?.data?.access_code,
          };

          if (
            paymentData &&
            paymentData.encRequest &&
            paymentData.access_code
          ) {
            try {
              // Construct the redirect URL
              const redirectUrl = `https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction&encRequest=${encodeURIComponent(
                paymentData.encRequest
              )}&access_code=${encodeURIComponent(paymentData.access_code)}`;

              // Open the URL in the current tab
              // await handleRedirect(redirectUrl);
              window.location.replace(redirectUrl);
            } catch (error) {
              console.error("Error during redirection:", error);
            }
          } else {
            console.error("Invalid or incomplete paymentData");
          }
        }
      });
    } else {
      // toast({
      //   message: areaId
      //     ? "Please select Date and time"
      //     : "Please select correct Location",
      //   type: "error",
      //   theme: "dark",
      // });
      toast.error(
        areaId
          ? "Please Select Date and time"
          : "Please select correct Location",
        { theme: "dark" }
      );

      // toast({areaId ? "Plese select Date and time" : "Plese select correct Location"}, { type: "error", theme: "dark" });
    }
    // }
  };
  useEffect(() => {
    // Check if the URL contains the cancel parameter
    // const urlParams = new URLSearchParams(indow.location.search);
    const url = new URL("https://admin.theclassy.in/api/cancel");
    const urlParams = new URLSearchParams(url.search);
    const isCancel = urlParams.get("cancel");

    if (isCancel) {
      // Redirect to your cancel path
      navigate.push("/cancel");
    }
  }, [navigate]);

  const submitOrderCash = async () => {
    debugger;
    const localData = sessionStorage.getItem("cart");
    // const objectsWithoutDateAndTime = JSON.parse(localData).filter(obj => !('date' in obj) && !('time' in obj));
    // console.log(objectsWithoutDateAndTime,'localDatalocalData')
    // Function to check if an object has date and time keys
    // Function to check for the presence of date and time keys

    const hasDateAndTime = (obj) => "date" in obj && "time" in obj;

    // Recursively check for the presence of date and time keys in nested objects
    const recursivelyCheckDateAndTime = (obj) => {
      if (Array.isArray(obj)) {
        return obj.some(recursivelyCheckDateAndTime);
      } else if (typeof obj === "object" && obj !== null) {
        return (
          Object.values(obj).some(recursivelyCheckDateAndTime) ||
          hasDateAndTime(obj)
        );
      } else {
        return false;
      }
    };

    // Filter objects without date and time at both top-level and within problems array
    const objectsWithoutDateAndTime = JSON.parse(localData).filter(
      (obj) => !recursivelyCheckDateAndTime(obj)
    );

    if (objectsWithoutDateAndTime?.length) {
      toast("Plese Select date and time", { type: "error", theme: "dark" });
    }

    if (mapData) {
    } else {
      toast("Plese Select Location", { type: "error", theme: "dark" });
    }

    const newArray = [];

    JSON.parse(localData).forEach((originalObj) => {
      // Check if the object has a "problems" array
      if (
        originalObj.problems &&
        Array.isArray(originalObj.problems) &&
        originalObj.problems.length > 0
      ) {
        originalObj.problems.forEach((problem) => {
          // Create a new object with the required structure
          const newObject = {
            id: originalObj.id,
            applianceId: originalObj.appliance_id,
            applianceName: originalObj.name,
            problemName: problem.name || "",
            image: problem.image || "",
            pId: problem.id || null,
            quantity: problem.quantity_data || 0,
            date: problem.date || "",
            time: problem.time || "",
            salePrice: parseFloat(problem.sale_price) || 0.0,
          };

          // Add the new object to the newArray
          newArray.push(newObject);
        });
      } else {
        // Create a new object for cases without problems
        const newObject = {
          id: originalObj.id,
          applianceId: originalObj.appliance_id,
          applianceName: originalObj.name,
          problemName: "",
          image: originalObj.image || "",
          pId: null,
          quantity: originalObj.quantity_data || 0,
          date: originalObj.date || "",
          time: originalObj.time || "",
          salePrice: parseFloat(originalObj.sale_price) || 0.0,
        };

        // Add the new object to the newArray
        newArray.push(newObject);
      }
    });

    // console.log(newArray, "newArraynewArray");

    if (
      !objectsWithoutDateAndTime?.length &&
      mapData &&
      areaId
      // postPinCode.Name
    ) {
      // console.log(newArray, "newArraynewArray");
      // &user_name=${
      //   postPinCode.Name
      // }

      const urlpost = `${URLSubmit}address_id=${areaId}&cart_data=${JSON.stringify(
        newArray
      )}`;

      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        const res = await axios.post(urlpost);
        if (res.data.error) {
          toast("Order not submit successfully.", {
            type: "error",
            theme: "dark",
          });
        } else {
          toast("Order submit successfully.", {
            type: "success",
            theme: "dark",
          });
          navigate("/booking", { replace: true });
        }
      } catch (error) {
        // Handle errors
        console.error("Error:", error);
      }
    }
  };
  useEffect(() => {
    const getCardData = JSON.parse(sessionStorage.getItem("cart"));

    // var dt = getCardData?.filter((item) => item?.appliance_id == id)
    // console.log(dt,'dtdtdtdt')
    // if(getCardData?.appliance_id == id){

    setCartDataLocal(getCardData);
    // }
  }, [changeLocalState]);

  const updateTotals = () => {
    const result = cartdataLocal?.map((item) => {
      const totalFromProblems = item?.problems
        ? item?.problems?.reduce((acc, problem) => {
            const price = parseInt(problem?.sale_price);
            const quant_data = parseInt(problem?.quantity_data);
            return acc + price * quant_data;
          }, 0)
        : 0;

      const totalFromProperties =
        item.sale_price && item.quantity_data
          ? parseInt(item?.sale_price) * parseInt(item.quantity_data)
          : 0;

      return {
        total: totalFromProblems + totalFromProperties,
      };
    });

    const totalSum = result?.reduce((acc, item) => acc + item.total, 0);

    setTotalPrice(totalSum);
  };

  useEffect(() => {
    updateTotals();
  }, [cartdataLocal]);

  const findtheService = async () => {
    let token = sessionStorage.getItem("token");
    var data = postPinCode;
    const urlpost = `${URLaddress}address_name=${
      data.HouseNo + " , " + data?.address_name
    }&landmark=${data?.landmark}&latituate=${data?.latitude}&longitutite=${
      data?.longitude
    }&pincode=${data?.pinCode}&user_name=${data?.Name}`;

    try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      // Make a POST request using Axios
      const res = await axios.post(urlpost);
      setAreaId(res?.data?.data?.id);
      // (,'response')
      // Handle the response
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    findtheService();
  }, [mapData]);

  const offerList = async () => {
    let token = sessionStorage.getItem("token");

    try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      // Make a POST request using Axios
      const res = await axios.post(URLOfferList);
      setOfferList(res?.data?.data);

      // setAreaId(res?.data?.data?.id)
      // (,'response')
      // Handle the response
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    offerList();
  }, [model]);

  // const handleRadioChange = (event) => {
  //   setSelectedOption(event.target.value);
  // };

  const handleOfferId = async (id, code) => {
    let token = sessionStorage.getItem("token");

    // var data = postPinCode;

    // const urlpost = `${URLaddress}address_name=${data?.address_name}&landmark=${data?.mapData}&latituate=${data?.latitude}&longitutite=${data?.longitude}&pincode=${data?.pinCode}`;

    try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      cartdataLocal.forEach(function (item) {
        // Remove the "description" and "image" keys from each object
        delete item.description;
        delete item.image;
      });

      var urlcode = `${URLCouponId}${code}&cart_data=${JSON.stringify(
        cartdataLocal
      )}`;
      const res = await axios.post(urlcode);
      // setOfferList(res?.data?.data)
      if (res?.data?.error == true) {
        toast("Coupon Code Invalid", { type: "error", theme: "dark" });
      }

      // addressdataload();

      // setAreaId(res?.data?.data?.id)
      // (,'response')
      // Handle the response
    } catch (error) {
      // Handle errors
      toast(error?.message, { type: "danger", theme: "dark" });
      // addressdataload();
      console.error("Error:", error);
    }
  };

  const handleDateTime = (date, time) => {
    setSelectedDateTime({ date, time });
  };
  // const handleID = (e) => {
  //   console.log(e[0]?.id, "hadnleID");
  // };
  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const submitOrderData = () => {
    if (paymentMethod == "cash") {
      submitOrderCash();
    } else {
      submitOrderPayment();
    }
  };
  return (
    <>
      {/* {{-- ===========================================
                                BREADCRUMB
            =========================================== --}} */}
      <ToastContainer />

      <Timeschedule
        props={close}
        id={handleID}
        item={itemProblem}
        setClose={setClose}
        selectedDateTime={selectedDateTime}
      />
      <div className="banner-inner-area section-bg-3 p-3 d-none">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner-inner-contents">
                <h2 className="banner-inner-title mb-0 text-black"> Cart </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="cart-area" data-padding-top="40" data-padding-bottom="40">
        <div className="mx-lg-5 px-lg-5">
          <div className="d-block d-lg-block d-md-block cart-table margin-bottom-30">
            <div className="row">
              <div className="col-1"></div>
              <div className="col-12 col-md-5 col-lg-5">
                <div
                  className="border p-3 mb-2 rounded"
                  // style={{ marginLeft: "17%" }}
                >
                  <div
                    className="d-flex m-0 p-0 pb-2 gap-3"
                    style={{ borderBottom: "2px solid #E3E3E3" }}
                  >
                    <div className="mt-2">
                      <img
                        src="https://res.cloudinary.com/urbanclap/image/upload/t_high_res_category/w_36,dpr_1,fl_progressive:steep,q_auto:low,f_auto,c_limit/images/growth/home-screen/1690278194843-5037f4.jpeg"
                        alt="Location"
                      />
                    </div>
                    <div className="location-bar col-10 p-0 ps-3">
                      <div
                        className="head maxWidthFont"
                        style={{ fontFamily: "Poppins", color: "#OFOFOF" }}
                      >
                        Send booking details to
                        <p className="p-1 m-0">{UserNumber}</p>
                      </div>
                    </div>
                  </div>
                  {/* == */}
                  <div
                    className="d-flex gap-3 m-0 p-0 pt-2 pb-2 "
                    style={{ borderBottom: "2px solid #E3E3E3" }}
                  >
                    <div className="mt-2">
                      <img
                        src="https://res.cloudinary.com/urbanclap/image/upload/t_high_res_category/w_36,dpr_1,fl_progressive:steep,q_auto:low,f_auto,c_limit/images/growth/home-screen/1690278194843-5037f4.jpeg"
                        alt="Location"
                      />
                    </div>
                    <div className="location-bar col-10 p-0 ps-3">
                      <div
                        className="head maxWidthFont"
                        style={{ color: "#OFOFOF" }}
                      >
                        Address
                      </div>
                      {!postPinCode?.HouseNo && !postPinCode?.Name ? (
                        <div className="mt-1">
                          <button
                            style={{ background: "rgb(42, 140, 243)" }}
                            className=" text-white py-2 rounded-3 w-100"
                            onClick={() => {
                              setmodalLocation(true);
                              setLocationModalOpen(true);
                            }}
                          >
                            Select Location
                          </button>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-between m-0 p-0">
                          <p
                            className="p-0 m-0"
                            style={{
                              maxWidth: "300px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              color: "#545454",
                            }}
                          >
                            {mapData}
                          </p>
                          <div
                            className="border rounded bg-white p-1"
                            style={{ marginTop: "-10px" }}
                          >
                            <button
                              className="fw-normal bg-white"
                              style={{ color: "#OFOFOF" }}
                              onClick={() => setmodalLocation(true)}
                            >
                              Edit
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className=""></div>
                </div>

                <div
                  className="table-responsive d-none d-lg-block d-md-block"
                  style={{
                    border: "1px solid #dcdcdc7d",
                    borderRadius: "8px",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    paddingTop: "10px",
                    paddingBottom: "30px",
                    boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                  }}
                >
                  <div className="payment-details maxWidthFont">
                    Payment Details
                  </div>
                  <br></br>
                  <div
                    className="product-bil"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="total-amount maxWidthFont">
                      Total Amount
                    </div>
                    <div
                      className="total-price "
                      style={{
                        color: "green",
                        fontWeight: "600",
                      }}
                    >
                      ₹ {totalPrice}
                    </div>
                  </div>
                  <div
                    className="product-bil"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="coupon-discount maxWidthFont">
                      Coupon Discount
                    </div>
                    <div
                      className="total-price "
                      style={{
                        color: "green",
                        fontWeight: "600",
                      }}
                    >
                      ₹ -{cartdata.totaldes}
                    </div>
                  </div>
                  <div
                    className="product-bil"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="convenience maxWidthFont">
                      Convenience Fee
                    </div>
                    <div
                      className="total-price  "
                      style={{
                        color: "green",
                        fontWeight: "600",
                      }}
                    >
                      ₹ 00
                    </div>
                  </div>
                </div>

                <div className="mt-2 ms-3 d-none d-lg-block d-md-block">
                  <div className="row d-flex justify-content-around ms-2">
                    <div className="form-check col-6 ">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="radioOptions"
                        id="cash"
                        value="cash"
                        checked={paymentMethod === "cash"}
                        onChange={handlePaymentMethodChange}
                      />
                      <label
                        className="form-check-label maxWidthFont"
                        htmlFor="cash"
                      >
                        Pay after service
                      </label>
                    </div>

                    <div className="form-check col-6">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="radioOptions"
                        id="online"
                        value="online"
                        checked={paymentMethod === "online"}
                        onChange={handlePaymentMethodChange}
                      />
                      <label
                        className="form-check-label maxWidthFont"
                        htmlFor="online"
                      >
                        Pay Online
                      </label>
                    </div>
                  </div>
                </div>
                {/* == */}
                <div className=" mt-3 d-none d-md-block d-lg-block ">
                  <div className="book-slot d-flex justify-content-end">
                    <input
                      type="submit"
                      value="Proceed"
                      className="rounded py-1 px-4 text-white"
                      style={{
                        border: "1px solid gray",
                        backgroundColor: "green",
                      }}
                      onClick={() => submitOrderData()}
                    ></input>
                  </div>
                </div>

                {/* == */}
              </div>

              <div className="col-12 col-sm-12 col-md-5 ">
                <div className="">
                  <div className="d-block d-lg-block d-md-block cardProblem w-100  pb-3 pt-3 px-3">
                    {resultArray.length ? (
                      resultArray?.map((cartdataLocal) => (
                        <>
                          {cartdataLocal ? (
                            cartdataLocal?.map((data) => (
                              <>
                                {data?.problem_exist == false ? (
                                  <>
                                    <CartServiceComponent
                                      data={data}
                                      cartfunction={getdata}
                                    />
                                    <hr></hr>
                                  </>
                                ) : (
                                  <>
                                    <div className="w-100 d-none d-lg-block d-md-block ">
                                      {data?.problems?.map((problemItem) => (
                                        <Problems_Table
                                          item={problemItem}
                                          problems={data}
                                          cartfunction={getdata}
                                        />
                                      ))}

                                      {data?.problems?.[0]?.time &&
                                      data?.problems?.[0]?.date ? (
                                        <div className="p-1 ms-4">
                                          <button
                                            style={{
                                              background: "#ffff",
                                              border: "1px solid blue",
                                              color: "#1479e5",
                                            }}
                                            className="py-2 rounded-3"
                                            onClick={() => {
                                              setClose(true);
                                              handleDateTime(
                                                data.problems[0].date,
                                                data.problems[0].time
                                              );
                                              setHandleID(data?.id);
                                              setItems(data?.problems[0]);
                                            }}
                                          >
                                            {`${data.problems[0].date} ${data.problems[0].time}`}
                                            <i className="fas fa-edit ms-5"></i>
                                          </button>
                                        </div>
                                      ) : (
                                        <div className="px-4">
                                          <button
                                            style={{
                                              background: "#ffff",
                                              border: "1px solid blue",
                                              color: "#1479e5",
                                            }}
                                            className="px-lg-5 px-md-1 py-2 rounded-3"
                                            onClick={() => {
                                              setClose(true);
                                              setHandleID(data?.id);
                                              setItems(data?.problems[0]);
                                            }}
                                          >
                                            Select time and date
                                          </button>
                                        </div>
                                      )}
                                      <hr></hr>

                                      {/* == */}
                                    </div>
                                    <div className="w-100 d-block d-lg-none d-md-none ">
                                      {data?.problems?.map((problemItem) => (
                                        <Problems_Table
                                          item={problemItem}
                                          problems={data}
                                          cartfunction={getdata}
                                        />
                                      ))}
                                      {/* == */}

                                      {data?.problems?.[0]?.time &&
                                      data?.problems?.[0]?.date ? (
                                        <div
                                          style={{
                                            border: "2px solid #F3F3F3",
                                            color: "#1479e5",
                                            background: "white",
                                          }}
                                          className="  rounded-3"
                                        >
                                          <div className="d-flex justify-content-center p-1 ms-4 ">
                                            {`${data.problems[0].date} ${data.problems[0].time}`}

                                            <button
                                              style={{
                                                // border: "2px solid #F3F3F3",
                                                color: "#1479e5",
                                                background: "white",
                                              }}
                                              className=" ms-2  rounded-3"
                                              onClick={() => {
                                                setClose(true);
                                                handleDateTime(
                                                  data.problems[0].date,
                                                  data.problems[0].time
                                                );
                                                setHandleID(data?.id);
                                                setItems(data?.problems[0]);
                                              }}
                                            >
                                              {" "}
                                              Edit{" "}
                                            </button>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="p-1 ">
                                          <button
                                            style={{
                                              border: "2px solid #F3F3F3",
                                              color: "#1479e5",
                                              background: "white",
                                            }}
                                            className="w-100 rounded-3 py-2"
                                            onClick={() => {
                                              setClose(true);
                                              setHandleID(data?.id);
                                              setItems(data?.problems[0]);
                                            }}
                                          >
                                            Select Date and Time
                                          </button>
                                        </div>
                                      )}

                                      <hr></hr>

                                      {/* == */}
                                    </div>
                                  </>
                                )}
                              </>
                            ))
                          ) : (
                            <center>
                              {" "}
                              <h4 className="mt-5"> Loading...</h4>
                            </center>
                          )}
                        </>
                        // </div>
                      ))
                    ) : (
                      <>
                        <div
                          className="css-1dbjc4n r-1awozwy r-bztko3 r-13qz1uu px-5 py-3 d-lg-block d-sm-none d-none"
                          style={{
                            border: "1px solid ainsboro",
                            // width: "400px",
                            // boxShadow: "0 0 5px #3336",
                            borderRadius: "8px",
                          }}
                        >
                          <div className="css-1dbjc4n r-1awozwy r-bztko3 r-13qz1uu">
                            <div
                              aria-disabled="true"
                              role="img"
                              tabIndex="-1"
                              className="css-1dbjc4n r-1xc7w19 r-1xfd6ze r-1phboty r-1yadl64 r-wy61xf r-1nflyc r-1udh08x r-1i6wzkk r-lrvibr r-7bouqp"
                              style={{
                                marginRight: "0px",
                                transitionDuration: "0s",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src="./assets/frontend/fonts/cart_item.svg"
                                style={{ width: "20%", height: "20%" }}
                              />
                            </div>
                          </div>
                          <div className="css-1dbjc4n r-1awozwy r-bztko3 r-13qz1uu">
                            <div className="css-1dbjc4n r-3da1kt r-1jg9483"></div>
                            <div className="css-1dbjc4n r-1awozwy r-13awgt0 r-eqz5dr r-1777fci r-13qz1uu">
                              <p
                                dir="auto"
                                role="paragraph"
                                className="css-901oao r-1ej5qbt r-1b43r93 r-16dba41 r-rjixqe r-13wfysu r-3twk1y"
                                style={{
                                  color: "rgb(117, 117, 117)",
                                  textAlign: "center",
                                }}
                              >
                                No items in your cart
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="d-none d-md-block d-lg-block">
                    <div
                      className="border  d-lg-flex d-flex p-3 mt-3  rounded-3"
                      onClick={() => setModal(true)}
                    >
                      <div className=" ">
                        <svg
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          fill="#07794C"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.75 8.5a.75.75 0 111.5 0 .75.75 0 01-1.5 0zM14.75 15.5a.75.75 0 111.5 0 .75.75 0 01-1.5 0z"
                            fill="#07794C"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12zm6.5-5.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zm7 7a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zm-8.93 3.12l9.9-9.9 1.06 1.06-9.9 9.9-1.06-1.06z"
                            fill="#07794C"
                          ></path>
                        </svg>
                      </div>

                      <div className="ps-3 cursor-pointer">
                        <p
                          className="p-0 m-0 maxWidthFont"
                          style={{
                            // fontFamily: "Poppins",
                            fontSize: "16px",
                            fontWeight: "normal",
                          }}
                        >
                          Apply coupon code
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="total-bar mt-2 w-75 "></div>
                </div>
              </div>
            </div>
            {/* </tbody>
            </table> */}
          </div>

          {/* <div className="d-block d-md-none d-lg-none">
            {cartdataLocal ? (
              cartdataLocal?.map((data) => (
                <>
                  {data?.problem == false ? (
                    <CartServiceComponent data={data} cartfunction={getdata} />
                  ) : (
                    <>
                      {data?.problems?.map((problemItem) => (
                        <Problems_Table
                          item={problemItem}
                          problems={data}
                          cartfunction={getdata}
                        />
                      ))}
                    </>
                  )}
                </>
              ))
            ) : (
              <center>
                {" "}
                <h4 className="mt-5"> Loading...</h4>
              </center>
            )}
          </div> */}

          <div className="bill-area">
            <div className="row">
              <div className="col-xl-7 col-lg-7 margin-bottom-30 d-block d-md-none d-lg-none ">
                <div
                  className="border d-flex p-3 w-auto w-lg-50 w-sm-50 rounded-3 "
                  onClick={() => setModal(true)}
                >
                  <div className=" ">
                    <svg
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="#07794C"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.75 8.5a.75.75 0 111.5 0 .75.75 0 01-1.5 0zM14.75 15.5a.75.75 0 111.5 0 .75.75 0 01-1.5 0z"
                        fill="#07794C"
                      ></path>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12zm6.5-5.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zm7 7a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zm-8.93 3.12l9.9-9.9 1.06 1.06-9.9 9.9-1.06-1.06z"
                        fill="#07794C"
                      ></path>
                    </svg>
                  </div>

                  <div className="ps-3">
                    <p
                      className="p-0 m-0 maxWidthFont"
                      style={{
                        // fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: "normal",
                      }}
                    >
                      Apply coupon code
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 margin-bottom-30 d-none">
                <div className="location-bar">
                  <div className="head">Location for Pickup</div>
                  <div className="location-chooser">
                    <button
                      onClick={() => setmodalLocation(true)}
                      style={{
                        maxWidth: "300px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <i className="las la-map-pin"></i>&nbsp;
                      {mapData ? mapData : "Search for Location"}
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-lg-6  margin-bottom-10">
                <div className="total-bar d-block d-md-none d-lg-none">
                  <div
                    className="table-responsive"
                    style={{
                      border: "1px solid #dcdcdc7d",
                      borderRadius: "8px",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      paddingTop: "10px",
                      paddingBottom: "30px",
                      boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    }}
                  >
                    <div className="payment-details maxWidthFont">
                      Payment Details
                    </div>
                    <br></br>
                    <div
                      className="product-bil"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="total-amount maxWidthFont">
                        Total Amount
                      </div>
                      <div
                        className="total-price "
                        style={{
                          color: "green",
                          fontWeight: "500",
                        }}
                      >
                        ₹ {totalPrice}
                      </div>
                    </div>
                    <div
                      className="product-bil"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="coupon-discount maxWidthFont">
                        Coupon Discount
                      </div>
                      <div
                        className="total-price "
                        style={{
                          color: "green",
                          fontWeight: "500",
                        }}
                      >
                        ₹ -{cartdata.totaldes}
                      </div>
                    </div>
                    <div
                      className="product-bil"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="convenience maxWidthFont">
                        Convenience Fee
                      </div>
                      <div
                        className="total-price "
                        style={{
                          color: "green",
                          fontWeight: "500",
                        }}
                      >
                        ₹ 00
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
              // className="col-xl-6 col-lg-5 mb-3"
              // style={{
              //   marginLeft: "130px",
              // }}
              >
                <div className=" row d-block d-md-none d-lg-none d-flex ms-1">
                  {/* <div className="col-6 col-lg-4 col-md-4"> */}
                  <div className="form-check col-5">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="online"
                      name="optradio"
                      value="online"
                      onChange={(e) => setPaymentMethod(e.target.value)}
                      checked={paymentMethod == "online"}
                    />
                    <label
                      className="form-check-label maxWidthFont"
                      htmlFor="online"
                    >
                      Pay Online
                    </label>
                  </div>
                  {/* </div> */}
                  {/* <div className="col-6 col-lg-4 col-md-4"> */}
                  <div className="form-check col-5">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="cash"
                      name="optradio"
                      value="cash"
                      onChange={(e) => setPaymentMethod(e.target.value)}
                      checked={paymentMethod == "cash"}
                    />
                    <label
                      className="form-check-label maxWidthFont"
                      htmlFor="cash"
                    >
                      Pay after service
                    </label>
                  </div>
                  {/* </div> */}
                </div>
              </div>
              <div className="col-lg-6 mb-4 d-block d-md-none d-lg-none mx-3 mt-2">
                <div className="book-slot">
                  <input
                    type="submit"
                    value="Proceed"
                    className="mx-5 mt-2"
                    // onClick={submitOrder1}
                    onClick={() => submitOrderData()}
                  ></input>
                  {/* <button>Procced</button> */}
                </div>
              </div>

              <form
                ref={paymentform}
                method="post"
                name="redirect"
                action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
              >
                <input
                  type="hidden"
                  name="merchant_id"
                  id="merchantId"
                  value={marchant_id}
                />
                <input
                  type="hidden"
                  name="tid"
                  id="tid"
                  value={uuid()}
                  readonly
                />
                <input type="hidden" name="encRequest" value={Encryptdata} />
                <input type="hidden" name="access_code" value={accesscode} />
                <script language="javascript">
                  document.redirect.submit ();
                </script>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="md"
        id="address-modal"
        show={editaddressstate}
        onHide={closeeditaddress}
      >
        <form onSubmit={saveeditaddress}>
          <Modal.Header closeButton>
            <Modal.Title> Edit Address</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="address-form">
              <div className="group-input">
                <label htmlFor="building">Building Name/House No.</label>
                <input
                  type="text"
                  name="building"
                  value={addbuilding}
                  onChange={(e) => setAddBuilding(e.target.value)}
                  required
                ></input>
              </div>
              <div className="group-input">
                <label htmlFor="landmark">Area and Landmark*</label>
                <input
                  type="text"
                  name="landmark"
                  value={addLandMark}
                  onChange={(e) => setAddLandmark(e.target.value)}
                  required
                ></input>
              </div>
              <div className="group-input">
                <label htmlFor="district">District*</label>
                <input
                  type="text"
                  name="district"
                  value={addDistrict}
                  onChange={(e) => setaddDistrict(e.target.value)}
                  required
                ></input>
              </div>
              <div className="group-input">
                <label htmlFor="state">State*</label>
                <input
                  type="text"
                  name="state"
                  value={addState}
                  onChange={(e) => setAddState(e.target.value)}
                  required
                ></input>
              </div>

              <div className="group-input">
                <label htmlFor="zip">Zip Code*</label>
                <input
                  type="number"
                  name="zip"
                  value={addZipcode}
                  onChange={(e) => setaddZipcode(e.target.value)}
                  required
                ></input>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={closeeditaddress}>Cancel</Button>
            <Button type="submit">Save</Button>
          </Modal.Footer>
        </form>
      </Modal>

      {/* ===== offer modal*/}
      <Modal
        Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="category-modal"
        show={model}
        onHide={handleCloseOffer}
        className="main_model modeld"
        dialogClassName="main_model"
      >
        <Modal.Header
          className="modelHeder"
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            padding: "15px",
            border: "none",
            bottom: "50px",
          }}
        >
          <div className="modalCloseButton cursor-pointer">
            <span
              // className="ms-4"
              style={{ margin: "3px 8px" }}
              onClick={() => handleCloseOffer()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-x"
                style={{ margin: "3px -3px", color: "gray" }}
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </span>
          </div>
        </Modal.Header>

        {/* <Modal.Title></Modal.Title> */}
        <Modal.Body className="m-0 p-0 model_body">
          {/* <p>All Coupon</p> */}
          <div className="px-2 pb-2 px-lg-5 px-sm-5">
            {offer?.length ? (
              <>
                {offer?.map((offer) => (
                  <div
                    className="border  bg-white rounded-3 mb-2"
                    style={{
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    }}
                  >
                    <div className="ps-4">
                      <div className="mt-2 d-flex  ">
                        <svg
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          fill="#07794C"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.75 8.5a.75.75 0 111.5 0 .75.75 0 01-1.5 0zM14.75 15.5a.75.75 0 111.5 0 .75.75 0 01-1.5 0z"
                            fill="#07794C"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12zm6.5-5.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zm7 7a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zm-8.93 3.12l9.9-9.9 1.06 1.06-9.9 9.9-1.06-1.06z"
                            fill="#07794C"
                          ></path>
                        </svg>
                        <p
                          className="p-0 m-0 ms-5 "
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          {offer?.title}
                        </p>
                      </div>
                      <span
                        className="ms-5 ps-3"
                        style={{ fontFamily: "Poppins", fontSize: "12px" }}
                      >
                        {offer?.description}
                      </span>
                    </div>
                    <div className="ms-auto mt-2 ">
                      <buton className="fw-normal border rounded-3 px-3 ms-5 p-1">
                        {/* OFFER   */}
                        {offer?.code}
                      </buton>
                    </div>
                    <div
                      className="w-100 mt-3 p-1 d-flex justify-content-around rounded-bottom-2"
                      style={{ background: "#2196F3" }}
                    >
                      <button
                        className=""
                        style={{ background: "none", color: "white" }}
                        onClick={() => handleOfferId(offer?.id, offer?.code)}
                      >
                        TAP TO APPLY
                      </button>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className="mx-5">
                <p className="fw-bold"> Currently Offer is not Available</p>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
      {/* {{-- ===========================================
                            ADDRESS MODAL
            =========================================== --}} */}
      <Modal size="md" id="address-box-modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="address-list">
            {alladdress.length > 0 ? (
              alladdress.map((data) => (
                <div className="address-box">
                  {/* <div className="top-bar d-flex flex-row justify-content-around">
                                    <div className="name">{data.name}  ( {data.mobile} {', ' + data.alter_number})</div>

                                    <span className='btn btn-primary' onClick={deleteaddress(data.id)}><i className="las la-trash"></i></span>

                                </div> */}
                  <div className="inner" onClick={addresselectevent(data.id)}>
                    <table className="table table-borderless mb-0">
                      <tbody>
                        <tr>
                          <td>Zip Code</td>
                          <td className="text-end">{data.zipcode}</td>
                        </tr>
                        <tr>
                          <td>State</td>
                          <td className="text-end">{data.state}</td>
                        </tr>
                        <tr>
                          <td>District</td>
                          <td className="text-end">{data.district}</td>
                        </tr>
                        {/* <tr>
                                                    <td>Town</td>
                                                        <td className="text-end">{}</td>
                                                </tr> */}
                        <tr>
                          <td>Landmarks</td>
                          <td className="text-end">{data.landmark}</td>
                        </tr>
                        <tr>
                          <td>Building Name/House No.</td>
                          <td className="text-end">{data.building}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ))
            ) : (
              <h5>No address found! please add your address.</h5>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={showAddressForm}>Add Address</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </Modal.Footer>
      </Modal>
      <AddAddressComponent
        show={addressformtoggle}
        handleShow={showAddressForm}
        handleClose={closeAddressForm}
        getData={addressdatarefresh}
      />
    </>
  );
}
