import React, { useEffect, useState } from "react";
import "./viewAll.css";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import { URLhome } from "../../util/UrlConst";

const ViewPage = () => {
  let { id } = useParams();
  const [viewData, setViewData] = useState();
  const headers = {
    Authorization: "Bearer YourAccessToken",
    "Content-Type": "application/json",
  };

  const getviewData = async () => {
    const token = sessionStorage.getItem("token");

    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    await axios
      .post(URLhome, {
        headers: headers,
        fcm_token: 123456,
      })
      .then((data) => {
        const dataFilter = data?.data?.data?.datas.filter(
          (item) => item?.id == id
        );
        setViewData(dataFilter[0]);
      });
  };

  useEffect(() => {
    getviewData();
  }, [id]);
  return (
    <>
      <section className="explore-all  mt-5 viewNone">
        <div className="container mt-5 pt-5">
          <span className="fw-bolder mt-5 pt-5 main-head lineHeightView maxWidthFont">
            {" "}
            {viewData?.name}
          </span>

          <div className="row">
            <div className="col-lg-5 col-12">
              {viewData?.items?.map((item) => (
                <div className="product-list m-0">
                  <div key={item?.id} className="product-item m-0">
                    <div className="top-part p-0 m-0">
                      <div className="content">
                        <div
                          className="title lineHeightView maxWidthFont"
                          style={{ color: "black" }}
                        >
                          {item?.name}
                        </div>
                        <div className="data lineHeightView fontDescription">
                          {item?.short_description}
                        </div>
                      </div>
                      <div className="image mt-3">
                        <Link
                          to={`/service-details/${item?.id}`}
                          className="section-btn"
                        >
                          <img
                            src={item?.image}
                            alt="..."
                            className="w-100 h-100"
                          ></img>
                        </Link>
                      </div>
                    </div>
                    <div className="bottom-part">
                      <div className="price">
                        {/* <div><del>Rs. {props.product.mrp}</del></div> */}
                        {/* <br/> */}
                        {item?.sale_price ? (
                          <div style={{ color: "green", fontWeight: "500" }}>
                            {/* <span style={{ color: "#E0E0E0" }}> Start at</span>{" "}
                            ₹ {item?.sale_price}{" "} */}
                            <p
                              className="p-0 m-0 maxWidthFont fontDescription"
                              style={{ lineHeight: "1.5" }}
                            >
                              {" "}
                              Start at{" "}
                              <span
                                className="text-success"
                                style={{ fontWeight: "600" }}
                              >
                                ₹ {item?.sale_price}
                              </span>
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        <br />
                      </div>
                      {/* <Link to={`/service-details/${props.product.id}`} className='view-detail btn btn-white border-success btn-sm '>Add </Link> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-lg-6 ms-5 viewNone">
              <div className="right-static">
                <div className="main-head">Our Expertise</div>
                <div className="list">
                  <div className="list-item">
                    <div className="icon">
                      <i className="las la-tools"></i>
                    </div>
                    <div className="content">
                      <div className="title">Experienced Professionals</div>
                      <div className="data">
                        Our team of technicians are highly experienced and have
                        been trained to provide efficient and effective repair,
                        installation and uninstallation services.
                      </div>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="icon">
                      <i className="las la-tools"></i>
                    </div>
                    <div className="content">
                      <div className="title">Timely Service</div>
                      <div className="data">
                        We understand the importance of time and offer quick and
                        prompt services to our customers. We ensure that our
                        technicians reach your doorstep within the given time
                        frame.
                      </div>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="icon">
                      <i className="las la-tools"></i>
                    </div>
                    <div className="content">
                      <div className="title">Affordable Pricing</div>
                      <div className="data">
                        Our services are competitively priced and we offer a
                        range of packages to suit your budget. We believe in
                        providing quality services at reasonable rates.
                      </div>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="icon">
                      <i className="las la-tools"></i>
                    </div>
                    <div className="content">
                      <div className="title">Quality Workmanship</div>
                      <div className="data">
                        We use only the best quality spare parts and tools for
                        repairs and installations. Our technicians take pride in
                        their work and ensure that the job is done right the
                        first time.
                      </div>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="icon">
                      <i className="las la-tools"></i>
                    </div>
                    <div className="content">
                      <div className="title">Customer Satisfaction</div>
                      <div className="data">
                        Our top priority is customer satisfaction. We strive to
                        provide an exceptional service experience to our
                        customers and ensure that all their queries and concerns
                        are addressed promptly.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="3 row p-3 d-lg-none inResponsiveMargin" style={{}}>
        <span className="fw-bolder lineHeightView"> {viewData?.name}</span>

        {viewData?.items?.map((data) => (
          <div className=" col-6 p-2 " key={data?.id}>
            <div className=" p-0 ">
              <Link to={`/service-details/${data?.id}`} className="section-btn">
                <img
                  // service-bg-thumb-format
                  className="service-thumb  responsiveIMg  location_relative  rounded-3"
                  src={data?.image}
                  style={{}}
                  alt={data?.name}
                />
              </Link>
              <div className="px-md-2 pt-1 px-sm-0">
                <h6 className="m-0 lineHeightView maxWidthFont">
                  {data?.name}
                </h6>
                <p className="p-0 m-0 lineHeightView maxWidthFont">
                  {data?.short_description}
                </p>
                {data?.sale_price ? (
                  <p
                    className="p-0 m-0 lineHeightView maxWidthFont"
                    style={{ color: "#E0E0E0" }}
                  >
                    {" "}
                    Start at{" "}
                    <span className="text-success">₹ {data?.sale_price}</span>
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ViewPage;
