import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { URLGetPriceList } from "../util/UrlConst";

export default function PriceList(props) {
  const [priceList, setPriceList] = useState([]);
  const [listNum, setIndex] = useState(-1);
  const { id } = useParams();
  useEffect(() => {
    let token = sessionStorage.getItem("token");
    getdata();
    async function getdata() {
      axios.defaults.headers.common["Authorization"] = `Bearer+ ${token}`;
      await axios
        .post(`${URLGetPriceList}${id}`, { appliance_type_id: id })
        .then((response) => {
          setPriceList(response?.data?.data);
        });
      //  { appliance_id: id }
    }
  }, [id]);

  const showData = (index) => {
    if (listNum === index) {
      setIndex(-1);
    } else {
      setIndex(index);
    }
  };

  return (
    <>
      <div className="navbar navbar-dark bg-dark p-4">
        <div className="navbar-brand font-weight-bold ">Rate Card</div>
      </div>
      <div className="bg-light underline-text p-4 ">
        <div className=" maxWidthFont fw-bolder text-dark fs-3">
          Rate Card
          {/* Labour Changes are capped at ₹499 per appliance */}
        </div>
      </div>
      <div className="container col my-5">
        {priceList && priceList.length > 0 ? (
          priceList.map((ele, index) => (
            <div className="container col my-2" key={index}>
              <div className="row" onClick={() => showData(index)}>
                <div className="col-11 bg-dark p-3 text-white fs-5 font-weight-bold maxWidthFont">
                  {ele.name}
                </div>
                <div
                  className="col-1 bg-dark p-3 text-white fs-5 font-weight-bold text-center"
                  onClick={() => showData(index)}
                >
                  {listNum === index ? (
                    <i className="fa-solid fa-angle-down"></i>
                  ) : (
                    <i className="fa-solid fa-angle-up"></i>
                  )}
                </div>
              </div>
              {listNum === index && (
                <React.Fragment>
                  <div className="row">
                    <div className="col-4 maxWidthFont  p-3 border text-dark  fs-5 font-weight-bold">
                      S no
                    </div>
                    <div className="col-4 maxWidthFont p-3 border text-dark fs-5 font-weight-bold">
                      Parts Name
                    </div>
                    <div className="col-4 maxWidthFont p-3 border text-dark  text-center fs-5 font-weight-bold">
                      Price
                    </div>
                  </div>
                  {ele.partsList.map((item, idx) => (
                    <div className="row" key={idx}>
                      <div className="col-4 maxWidthFont  p-3 border text-dark">
                        {idx + 1}
                      </div>
                      <div className="col-4 maxWidthFont p-3 border text-dark">
                        {item.name}
                      </div>
                      <div className="col-4 maxWidthFont  p-3 border text-dark  text-center">
                        ₹{item.sale_price}
                      </div>
                    </div>
                  ))}
                </React.Fragment>
              )}
            </div>
          ))
        ) : (
          <div className="d-flex justify-content-around fw-bold">
            No price list available
          </div>
        )}
      </div>
    </>
  );
}
