import React, { useContext, useEffect, useState } from "react";
// eslint-disable-next-line no-unused-vars
import CartAction, { AddProbleInCart } from "../action/CartAction";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { UrlHistoryContext } from "../context/UrlHistoryProvider";

export default function ServiceProblemComponent(props) {
  var { item, problems } = props;
  // console.log(props, "propsprops10101");
  // var {props.data}=

  const user = JSON.parse(sessionStorage.getItem("user"));

  const navigate = useNavigate();

  const gototcart = (e) => {
    // navigate("/cart");
  };

  const [cartbuttontext, setCartButtonText] = useState(false);
  const addtocart = (productid, quantity, problem) => (e) => {
    // if (user === null) {
    //     // toast("Login first.", { type: "error", theme: "dark" });
    //     // navigate('/login');
    //     setCartButtonText(true);

    // } else {
    setCartButtonText(true);
    AddProbleInCart(productid, quantity, problem);
    // }
  };

  const [cartQuantity, setCartQuantity] = useState(0);
  //   const [totalPrice, setTotalPrice] = useState(0);
  // const [totalMRP, setTotalMRP] = useState(0);
  // const [cartQuantityTotal, setCartQuantityTotal] = useState();
  const {
    changeLocalState,
    setChangeLocalState,
    cartQuantityTotal,
    setCartQuantityTotal,
    totalPrice,
    setTotalPrice,
    totalMRP,
    setTotalMRP,
  } = useContext(UrlHistoryContext);

  const [cardData, setCardData] = useState();

  const addToCartProblemPlus = (product) => {
    const existingCart = JSON.parse(sessionStorage.getItem("cart")) || [];
    const existingProductIndex = existingCart.findIndex(
      (item) => item?.id === problems?.id
    );

    if (existingProductIndex !== -1) {
      const existingProduct = existingCart[existingProductIndex].problems.find(
        (item) => item?.id === product?.id
      );

      if (existingProduct) {
        existingProduct.quantity_data =
          (existingProduct.quantity_data || 0) + 1;
      } else {
        // Problem not found in existingCart, add it
        const copyData = problems;
        const newData = copyData?.problems?.find(
          (dt) => dt?.id === product?.id
        );

        if (newData) {
          newData.quantity_data = 1;
          existingCart[existingProductIndex]?.problems.push(newData);
        }
      }
    } else {
      // Product not found in existingCart, add it along with the problem
      const copyData = problems;
      const newData = copyData?.problems?.find((dt) => dt?.id === product?.id);

      if (newData) {
        newData.quantity_data = 1;
        existingCart.push({ ...copyData, problems: [newData] });
      }
    }

    sessionStorage.setItem("cart", JSON.stringify(existingCart));
    // setCartQuantity(existingProduct?.quantity_data || 1);
  };

  const addToCartProblemMinus = (product) => {
    let existingCart = JSON.parse(sessionStorage.getItem("cart")) || [];
    const existingProductIndex = existingCart.findIndex(
      (item) => item?.id === problems?.id
    );

    if (existingProductIndex !== -1) {
      const existingProduct = existingCart[existingProductIndex].problems.find(
        (item) => item?.id === product?.id
      );

      if (existingProduct) {
        existingProduct.quantity_data =
          (existingProduct.quantity_data || 0) - 1;

        // Check if all quantity_data in problems array are zero, remove the entire item from existingCart
        if (
          existingCart[existingProductIndex].problems.every(
            (problem) => problem.quantity_data === 0
          )
        ) {
          existingCart.splice(existingProductIndex, 1);
        }
      }
    } else {
      // Product not found in existingCart, add it
      const copyData = problems;
      const newData = copyData.problems.find((dt) => dt?.id === product?.id);

      if (newData) {
        newData.quantity_data = 1;
        existingCart.push(copyData);
      }
    }

    sessionStorage.setItem("cart", JSON.stringify(existingCart));
  };

  function findAllProblems(data) {
    const allProblems = [];
    // console.log(data,'allProblemsallProblems')
    data?.forEach((person) => {
      if (person?.problems) {
        person?.problems?.forEach((problem) => {
          if (
            !allProblems?.some(
              (p) => JSON.stringify(p) === JSON.stringify(problem)
            )
          ) {
            allProblems?.push(problem);
          }
        });
      }
    });

    return allProblems;
  }
  useEffect(() => {
    const existingCart = JSON.parse(sessionStorage.getItem("cart"));
    setCardData(existingCart);
    if (existingCart != []) {
      var all = findAllProblems(existingCart);
      var findData = all?.find((dt) => dt?.id == item?.id);
      setCartQuantity(findData?.quantity_data);
    }
  }, [changeLocalState]);

  useEffect(() => {
    const existingCart = JSON.parse(sessionStorage.getItem("cart"));
    if (existingCart != []) {
      var problemData = existingCart?.filter((dt) => dt?.id == problems?.id);

      let hasProblemsKey = problemData?.some((obj) => "problems" in obj);

      if (hasProblemsKey) {
        var sum = problemData[0]?.problems.reduce(
          (acc, problem) => acc + parseInt(problem.quantity_data),
          0
        );
        var totalMrp = problemData[0]?.problems.reduce(
          (acc, problem) =>
            acc + parseInt(problem?.mrp * problem.quantity_data),
          0
        );
        var sale_price = problemData[0]?.problems.reduce(
          (acc, problem) =>
            acc + parseInt(problem?.sale_price * problem.quantity_data),
          0
        );

        setCartQuantityTotal(sum);
        setTotalMRP(totalMrp);
        setTotalPrice(sale_price);
      } else {
        setCartQuantityTotal(0);
        setTotalMRP(0);
        setTotalPrice(0);
      }
    }
  }, [changeLocalState]);

  return (
    <>
      <div className="col-lg-6 col-sm-12 pt-2">
        <div className="problem-item">
          <div className="name d-flex justify-content-between">
            <p className="p-0 m-0 maxWidthFont fs-6">{item?.name}</p>
            {/* <p className="price me-2" style={{ color: "gray" }}>
              ₹. <span className="text-success"> {item?.sale_price}</span>
            </p> */}
            <p
              className="p-0 m-0 me-2 fs-6 maxWidthFont fontDescription"
              style={{ lineHeight: "1.5" }}
            >
              {" "}
              {/* Start at{" "} */}
              <span className="text-success" style={{ fontWeight: "600" }}>
                ₹ {item?.sale_price}
              </span>
            </p>
          </div>

          <div className="image d-flex justify-content-between ">
            <span style={{ color: "gray" }} className="pt-1 time">
              <i className="las la-history"></i>&nbsp;45 m
            </span>
            <span>
              {cartQuantity ? (
                <div className="right-static">
                  <div className="mid-info text-center">
                    <div className="mid-ico">
                      <button
                        className="mid-ico backgroundColor"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          addToCartProblemMinus(item);
                          setChangeLocalState(!changeLocalState);
                        }}
                      >
                        -
                      </button>
                    </div>

                    <div className="mid-ico ">{cartQuantity}</div>

                    <div className="mid-ico">
                      <button
                        className="mid-ico backgroundColor"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          addToCartProblemPlus(item);
                          setChangeLocalState(!changeLocalState);
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="addButtonHeader px-3 py-1">
                  <button
                    className="addButton "
                    onClick={() => {
                      addToCartProblemPlus(item);
                      setChangeLocalState(!changeLocalState);
                    }}
                  >
                    {" "}
                    Add{" "}
                  </button>
                </div>
              )}
            </span>
          </div>

          <div className="bottom-bar maxWidthFontmaxWidthFont">
            <span>{item?.short_description}</span>
            {/* <div className="price " style={{ color: "gray" }}>
              ₹. <span className="text-success"> {item?.sale_price}</span>
            </div> */}
            {/* <div className="time">
            <i className="las la-history"></i>&nbsp;45 mins
          </div> */}
            {/* <div className="rating">
            <i className="las la-star"></i>&nbsp;({item?.rating})
          </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
