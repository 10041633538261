import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { URLresigter, URLSendOtp, URLSignUp } from "../util/UrlConst";

export default function Register() {
  const [name, setName] = useState();
  const [number, setNumber] = useState();
  const [isError, setError] = useState(false);
  const [errormessage, setErrormessage] = useState();
  const [loginform, setLoginForm] = useState(true);
  const [otp, setOtp] = useState();
  const [userOtp, setUserotp] = useState();
  const [otperror, setOtpError] = useState(false);
  const navigate = useNavigate();

  //create an var to store token in local storage.
  const storeTokenInLocalStorage = (token) => {
    sessionStorage.setItem("userToken", token);
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    // setError(false);
    await axios.post(URLSignUp, { name: name, phone: number }).then((res) => {
      if (!res.data.error) {
        const token = res.data.data.auth_token;
        storeTokenInLocalStorage(token);
        navigate("/home");
        toast("User found.", { type: "error", theme: "dark" });
      } else {
        toast("User Not found.", { type: "error", theme: "dark" });
      }
    });
  };

  const otpcheckform = async (e) => {
    e.preventDefault();

    await axios
      .post(URLresigter, { valetuser: name, mobile: number, password: number })
      .then((res) => {
        if (res.data.error) {
          toast(res.data.message, { type: "error", theme: "dark" });
        } else {
          toast("Successfully Rgistered.", { type: "success", theme: "dark" });
          navigate("/login", { replace: true });
        }
      });
  };

  return (
    <>
      {/* {{-- ===========================================
                                BREADCRUMB
            =========================================== --}} */}
      <div className="banner-inner-area section-bg-3 padding-top-100 padding-bottom-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner-inner-contents">
                <h2 className="banner-inner-title mb-0"> Register </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="signup-area padding-top-100 padding-bottom-40">
        <div className="container">
          <div className="signup-wrapper">
            {loginform ? (
              <div className="signup-contents">
                <h3 className="signup-title">Register</h3>
                <div className="error-message"></div>
                <form className="signup-forms" onSubmit={formSubmit}>
                  <div className="single-signup margin-top-30">
                    <label className="signup-label"> Name * </label>
                    <input
                      className="form--control"
                      type="text"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      id="name"
                      placeholder="Name"
                    ></input>
                  </div>
                  <div className="single-signup margin-top-30">
                    <label className="signup-label"> Phone Number * </label>
                    <input
                      className="form-control"
                      type="text"
                      name="number"
                      maxLength={10}
                      minLength={10}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      value={number}
                      onChange={(e) => setNumber(e.target.value)}
                      id="number"
                      placeholder="Phone Number"
                    ></input>
                  </div>
                  {isError && (
                    <div>
                      {" "}
                      <span className="text-danger"> {errormessage} </span>{" "}
                    </div>
                  )}

                  <button id="signin_form" type="submit">
                    Send OTP
                  </button>
                  <span className="bottom-register">
                    Already have an account?
                    <Link className="resgister-link" to="/login">
                      {" "}
                      Login{" "}
                    </Link>
                  </span>
                </form>
              </div>
            ) : (
              <div className="signup-contents">
                <h3 className="signup-title"> Verify OTP. </h3>
                <form className="signup-forms" onSubmit={otpcheckform}>
                  <center>
                    {" "}
                    <span>We have send otp to {number}</span>
                  </center>
                  <div className="single-signup margin-top-30">
                    <label className="signup-label"> Enter OTP </label>
                    <input
                      className="form-control"
                      type="text"
                      minLength={4}
                      maxLength={4}
                      value={userOtp}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        setUserotp(e.target.value);
                        setOtpError(false);
                      }}
                      placeholder="OTP"
                    />
                  </div>
                  {otperror && (
                    <div>
                      {" "}
                      <span className="text-danger">
                        {" "}
                        Please enter correct otp.{" "}
                      </span>{" "}
                    </div>
                  )}

                  <button type="submit">Submit</button>
                  <span className="bottom-register">
                    Wrong Number ?
                    <span
                      className="resgister-link btn"
                      onClick={(e) => setLoginForm(true)}
                    >
                      {" "}
                      Click here{" "}
                    </span>
                  </span>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
