import { Parser } from "html-to-react";
import React, { useContext, useEffect, useState } from "react";
import { UrlHistoryContext } from "../context/UrlHistoryProvider";
import Timeschedule from "../pages/components/Timeschedule";

export default function Problems_Table(props) {
  var { item, problems, addToCartProblem } = props;
  const [cartQuantity, setCartQuantity] = useState(0);
  const { changeLocalState, setChangeLocalState, setTimeDate, timeDate } =
    useContext(UrlHistoryContext);

  const addToCartProblemPlus = (product) => {
    // debugger;
    const existingCart = JSON.parse(sessionStorage.getItem("cart")) || [];
    const existingProductIndex = existingCart.findIndex(
      (item) => item?.id === problems?.id
    );

    if (existingProductIndex !== -1) {
      const existingProduct = existingCart[existingProductIndex].problems.find(
        (item) => item?.id === product?.id
      );

      if (existingProduct) {
        existingProduct.quantity_data =
          (existingProduct.quantity_data || 0) + 1;
      } else {
        // Problem not found in existingCart, add it
        const copyData = problems;
        const newData = copyData?.problems?.find(
          (dt) => dt?.id === product?.id
        );

        if (newData) {
          newData.quantity_data = 1;
          existingCart[existingProductIndex].problems.push(newData);
        }
      }
    } else {
      // Product not found in existingCart, add it along with the problem
      const copyData = problems;
      const newData = copyData?.problems?.find((dt) => dt?.id === product?.id);

      if (newData) {
        newData.quantity_data = 1;
        existingCart.push({ ...copyData, problems: [newData] });
      }
    }

    sessionStorage.setItem("cart", JSON.stringify(existingCart));
    // setCartQuantity(existingProduct?.quantity_data || 1);
  };

  const addToCartProblemMinus = (product) => {
    // debugger;
    let existingCart = JSON.parse(sessionStorage.getItem("cart")) || [];
    const existingProductIndex = existingCart.findIndex(
      (item) => item?.id === problems?.id
    );

    if (existingProductIndex !== -1) {
      const existingProduct = existingCart[existingProductIndex].problems.find(
        (item) => item?.id === product?.id
      );

      if (existingProduct) {
        existingProduct.quantity_data =
          (existingProduct.quantity_data || 0) - 1;
        // Check if all quantity_data in problems array are zero, remove the entire item from existingCart
        if (
          existingCart[existingProductIndex].problems.find(
            (problem) => problem.quantity_data == 0
          )
        ) {
          const existingProblemIndex = existingCart[
            existingProductIndex
          ].problems.findIndex((item) => item.quantity_data == 0);
          existingCart[existingProductIndex].problems.splice(
            existingProblemIndex,
            1
          );
          if (existingCart[existingProductIndex].problems.length == 0) {
            existingCart.splice(existingProductIndex, 1);
          }
        }
      }
    } else {
      // Product not found in existingCart, add it
      const copyData = problems;
      const newData = copyData.problems.find(
        (item) => item?.id === product?.id
      );
      if (newData) {
        newData.quantity_data = 1;
        existingCart.push(copyData);
      }
    }

    sessionStorage.setItem("cart", JSON.stringify(existingCart));
  };

  function findAllProblems(data) {
    const allProblems = [];

    data?.forEach((person) => {
      if (person?.problems) {
        person?.problems?.forEach((problem) => {
          // Check if the problem is not already in the array
          if (
            !allProblems?.some(
              (p) => JSON.stringify(p) === JSON.stringify(problem)
            )
          ) {
            allProblems.push(problem);
          }
        });
      }
    });

    return allProblems;
  }

  useEffect(() => {
    const existingCart = JSON.parse(sessionStorage.getItem("cart"));
    if (existingCart != []) {
      var all = findAllProblems(existingCart);
      var findData = all.find((dt) => dt?.id == item?.id);
      setCartQuantity(findData?.quantity_data);
    }
  }, [changeLocalState]);

  const [salePrice, setSalePrice] = useState(0);
  const [mrp, setMrp] = useState(0);

  const calculatedSalePrice =
    parseFloat(item.sale_price) * parseInt(item.quantity_data);
  const calculatedMrp = parseFloat(item.mrp) * parseInt(item.quantity_data);

  // Set the values in the state
  // Using useEffect to update the state when the component mounts or when the item changes
  useEffect(() => {
    setSalePrice(calculatedSalePrice);
    setMrp(calculatedMrp);
  }, [item]);

  const [close, setClose] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState({
    date: null,
    time: null,
  });

  const handleDateTime = (date, time) => {
    setSelectedDateTime({ date, time });
  };

  return (
    <>
      <Timeschedule
        props={close}
        id={problems?.id}
        item={item}
        setClose={setClose}
        selectedDateTime={selectedDateTime}
      />

      <div className="right-card d-none  d-lg-block d-md-block">
        <div className="pt-1 pb-2">
          <>
            <div className="d-flex pb-1 p-2 justify-content-between">
              <div
                className="left-info mx-3 pt-1"
                style={{
                  width: "56%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <span className="fw-bold maxWidthFont"> {item?.name}</span>
                <div
                  className="name maxWidthFont"
                  style={{ color: "gray", fontSize: "12px" }}
                >
                  {" "}
                  {problems?.name}
                </div>
              </div>

              <div className="mid-info text-center ms-auto d-flex">
                <div className="mid-ico">
                  <button
                    className="mid-ico backgroundColor fw-bolder"
                    onClick={() => {
                      addToCartProblemMinus(item);
                      setChangeLocalState(!changeLocalState);
                    }}
                  >
                    -
                  </button>
                </div>
                <div className="mid-ico px-1">{item?.quantity_data}</div>
                <div className="mid-ico">
                  <button
                    className="mid-ico backgroundColor fw-bolder"
                    onClick={() => {
                      addToCartProblemPlus(item);
                      setChangeLocalState(!changeLocalState);
                    }}
                  >
                    +
                  </button>
                </div>
              </div>

              <div
                className="right-info"
                style={{ marginLeft: "7px", color: "#4D9C67" }}
              >
                <div className="fw-semibold">
                  ₹ {parseFloat(item?.sale_price)}
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
      {/* ==== */}
      <div
        className="d-block d-md-none  d-lg-none mb-2"
        // style={{ borderBottom: "3px solid rgb(221, 221, 219)" }}
      >
        <div className="d-flex justify-content-between pb-2 maxWidthFont">
          <div
            style={{ fontWeight: "bold", color: "black" }}
            className=" fw-bold "
          >
            {item?.name}
            <div className="name" style={{ color: "gray", fontSize: "12px" }}>
              {" "}
              {problems?.name}
            </div>
          </div>
          <div className="justify-content-end">
            <p
              style={{ fontWeight: "bold", color: "#4D9C67" }}
              className=" price p-0 m-0 fs-6 ms-4"
            >
              ₹ <span className="prices ">{parseFloat(item?.sale_price)}</span>
            </p>
            <div className="mid-info text-center mt-2 d-flex p-0 m-0 ms-3">
              <div className="mid-ico">
                <button
                  className="mid-ico backgroundColor fw-bolder"
                  onClick={() => {
                    addToCartProblemMinus(item);
                    setChangeLocalState(!changeLocalState);
                  }}
                >
                  -
                </button>
              </div>
              <div className="mid-ico ps-1">{item.quantity_data}</div>
              <div className="mid-ico ps-1">
                <button
                  className="mid-ico backgroundColor fw-bolder "
                  onClick={() => {
                    addToCartProblemPlus(item);
                    setChangeLocalState(!changeLocalState);
                  }}
                >
                  +
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
