import React from "react";

function PopularCategories() {
  return (
    <>
      <div className="padding-top-120">
        <div>
          <h3 className="d-flex justify-content-center">Services in Indore</h3>
          <div className="container">
            <div className="row ">
              <div className="col-lg-7 col-md-7 col-12 ">
                <div>
                  <img
                    className="rounded"
                    src="https://www.valetgroups.com/service_images/13serone.jpg"
                    alt=""
                    srcset=""
                  />
                </div>
                <div className="pt-3 border-bottom">
                  <h2 className="font-weight-bold">Cleaning Services</h2>
                  <h5>Description</h5>
                  <p className="fs-6 ">
                    Valet Groups brings the cleaning services to your doorstep.
                    Home Cleaning, Bathroom Cleaning, Sofa Cleaning, Kitchen
                    Deep Cleaning. All our Cleaning Professionals use
                    eco-friendly and chemical-free products.
                  </p>
                </div>
                <div className="pt-3 border-bottom">
                  <h2 className="font-weight-bold">Cleaning Services</h2>
                  <h5>Description</h5>
                  <p className="fs-6 ">
                    Valet Groups brings the cleaning services to your doorstep.
                    Home Cleaning, Bathroom Cleaning, Sofa Cleaning, Kitchen
                    Deep Cleaning. All our Cleaning Professionals use
                    eco-friendly and chemical-free products.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-4">
                <div
                  className=""
                  style={{ backgroundColor: "aliceblue", padding: "20px" }}
                >
                  <div
                    className="justify-content-around d-flex m-0"
                    style={{ backgroundColor: "white", padding: "20px" }}
                  >
                    <img
                      src="https://www.valetgroups.com/images/qs.jpg"
                      alt=""
                      srcset=""
                      className="rounded"
                    />
                  </div>
                </div>
                <div
                  className="mt-5"
                  style={{ backgroundColor: "aliceblue", padding: "20px" }}
                >
                  <div
                    className="justify-content-around d-flex m-0"
                    style={{ backgroundColor: "white", padding: "20px" }}
                  >
                    <img
                      src="https://www.valetgroups.com/images/qs.jpg"
                      alt=""
                      srcset=""
                      className="rounded"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PopularCategories;
