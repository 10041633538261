import React from "react";
import ExploreAll from "./../ExploreAll";
import { Link } from "react-router-dom";

function Banner(props) {
  const handleSession = (e) => {
    sessionStorage.setItem("categoryImg", e?.image);
    sessionStorage.setItem("categoryName", e?.name);
  };
  return (
    <>
      <section key={props.key}
        className="banner-section mt-2 mt-lg-5 container-lg container-md"
        // data-padding-top="40"
        // data-padding-bottom="40"
      >
        <div className="">
          <h2 className="title main_title"> </h2>
          <div className="">
            <div className="">
              <div className=" rounded-2 banner-data">
                {props?.props?.items?.map((data, index) => (
                  <div key={index} className="banner-info row">
                    <div className="banner-title col-md-5 py-0">
                      <div className="banner-subtitle ps-5 maxWidthFont">
                        {data?.name}
                      </div>
                      <div className="banner-pera mt-lg-2 ps-5 fontDescription">
                        {data?.short_description}
                      </div>
                      <div
                        className="botton-list ps-5 "
                        style={{ position: "relative", top: "4rem" }}
                      >
                        <Link
                          to={`/category-details/${
                            data.id
                          }/${encodeURIComponent(data.name)}/${"Banner"}`}
                        >
                          <button
                            className="button-btn "
                            style={{ background: "white" }}
                          >
                            <span
                              className="text-black "
                              onClick={() => handleSession(data)}
                            >
                              Explore now
                            </span>
                          </button>
                        </Link>
                      </div>
                    </div>
                    <div className="banner-img col-md-7 col-7">
                      <img
                        className="w-100"
                        src={data?.website_image}
                        alt="banner Image"
                        style={{
                          borderRadius: "35px",
                          width: "100%",
                          padding: "18px",
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="small-screen" className="px-3 borderONHome py-4">
        <div className="container p-0 rounded-3">
          <div className="row">
            <div className="col-lg-6">
              <div className="rounded">
                <div style={{ position: "relative" }}>
                  <img
                    src={props?.props?.items[0]?.image}
                    className="rounded w-100"
                    alt=""
                  />
                  <div className="banner-title-name mt-4 ml-4">
                    <h5
                      className="main_titles text-white fw-normal"
                      style={{ fontFamily: "Line Awesome Free" }}
                    >
                      {/* {props?.props?.items[0]?.name} */}
                    </h5>
                  </div>
                  <div className="explore-button">
                    <Link
                      to={`/category-details/${
                        props?.props?.items[0]?.id
                      }/${encodeURIComponent(
                        props?.props?.items[0]?.id
                      )}/${"Banner"}`}
                    >
                      <button
                        className="button-btn w-auto h-auto py-1"
                        style={{
                          background: "white",
                          position: "absolute",
                          bottom: "37px",
                          left: "22%",
                          transform: "translateX(-50%)",
                        }}
                      >
                        <span
                          className="text-black"
                          onClick={() =>
                            sessionStorage.setItem(
                              "categoryName",
                              props?.props?.items[0]?.name
                            )
                          }
                        >
                          Explore now
                        </span>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Banner;
