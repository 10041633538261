import React, { useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
// var __html = require("../../public/product.html");
// var template = { __html: __html };

const CategoryDetailsAC = () => {
  useEffect(() => {
    // Set document title
    document.title = "AC service indore";

    // Create and append meta tag for description
    const metaDescription = document.createElement("meta");
    metaDescription.name = "description";
    metaDescription.content =
      "This is the description for Category Details AC.";
    document.head.appendChild(metaDescription);

    // Optionally, you can add more meta tags similarly if needed
    // const metaKeywords = document.createElement("meta");
    // metaKeywords.name = "keywords";
    // metaKeywords.content = "category, details, AC";
    // document.head.appendChild(metaKeywords);

    // Clean up function to remove added meta tags when component unmounts
    return () => {
      document.head.removeChild(metaDescription);
      // Remove other meta tags if added
      // document.head.removeChild(metaKeywords);
    };
  }, []);

  return (
    <>
      {/* <span dangerouslySetInnerHTML={template} /> */}
      <Helmet>
        <title>The Classy | split AC</title>
        <title>The Classy |AC service indore</title>

        <meta
          name="description"
          content="Ensure your AC system runs smoothly with our expert AC service. From maintenance to repairs, we've got you covered for a cool and comfortable space."
        />
        {/* Add more meta tags if needed */}
      </Helmet>
      <section
        className="category-area section-bg-1"
        data-padding-top=""
        data-padding-bottom="10"
      >
        <div className="container">
          <div className="row mt-5">
            <div className="col-lg-5 col-sm-12 col-md-4 order-2 order-lg-1 p-4 ">
              <div className="row rounded-3 mt-2 borderlg">
                <span className="p-1 ps-2 ms-2 commonfontFamily fw-bold">
                  Air Conditioner
                </span>
                {/* Static service items */}
                <div className="col-lg-4 col-md-6 col-4">
                  <a href="#repair" style={{ textDecoration: "none" }}>
                    <div className="single-category">
                      <div className="image borderlg img_Card_Slider px-4 py-3">
                        <img
                          src="https://admin.theclassy.in/uploads/SubAppliance/20240102150647.png"
                          alt="Service 1"
                          className="imgSmallSize rounded-3"
                        />
                      </div>
                      <div className="w-100">
                        <p className="p-0 m-0  text-black commonfontFamily">
                          Repair
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-4">
                  <a href="#Installation" style={{ textDecoration: "none" }}>
                    <div className="single-category">
                      <div className="image borderlg img_Card_Slider px-4 py-3">
                        <img
                          src="https://admin.theclassy.in/uploads/SubAppliance/20240102150633.png"
                          alt="Service 1"
                          className="imgSmallSize rounded-3"
                        />
                      </div>
                      <div className="w-100">
                        <p className="p-0 m-0  text-black commonfontFamily">
                          Installation & UnInstallation
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-lg-4 col-md-6 col-4">
                  <a href="#Service" style={{ textDecoration: "none" }}>
                    <div className="single-category">
                      <div className="image borderlg img_Card_Slider px-4 py-3">
                        <img
                          src="https://admin.theclassy.in/uploads/SubAppliance/20240102150456.png"
                          alt="Service 1"
                          className="imgSmallSize rounded-3"
                        />
                      </div>
                      <div className="w-100">
                        <p className="p-0 m-0  text-black commonfontFamily">
                          Service
                        </p>
                      </div>
                    </div>
                  </a>
                </div>

                {/* Add more static service items here */}
              </div>
            </div>
            <div className="col-lg-7 col-sm-12 col-md-8 order-sm-1 order-1 order-lg-2 inResponsiveMargin">
              <div
                id="demo"
                className="slider carousel slide pt-2"
                data-bs-ride="carousel"
                data-bs-interval="10000"
              >
                <div className="carousel-inner primary">
                  {/* First Slide */}
                  <div className="images-slider carousel-item active">
                    <div className=" rounded-3">
                      <img
                        src="https:\/\/admin.theclassy.in\/uploads\/Service\/20231216130334.png"
                        alt="Los Angeles"
                        className="d-block cover rounded-3 responveImg"
                      />
                    </div>
                  </div>

                  {/* Third Slide */}
                  <div className="images-slider carousel-item">
                    <div className=" rounded-3">
                      <img
                        src="https:\/\/admin.theclassy.in\/uploads\/SubAppliance\/20240102150647.png"
                        alt="Los Angeles"
                        className="d-block cover rounded-3 responveImg"
                      />
                    </div>
                  </div>

                  {/* Fourth Slide */}
                  <div className="images-slider carousel-item">
                    <div className="">
                      <video
                        style={{ objectFit: "cover" }}
                        className="rounded-2 responveVideo "
                        autoPlay
                        muted
                      >
                        <source
                          src="https://admin.theclassy.in/uploads/Appliance/videos/1704012740_VID-20230323-WA0005.mp4"
                          type="video/mp4"
                        />
                      </video>
                    </div>
                  </div>
                </div>

                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#demo"
                  data-bs-slide="prev"
                >
                  <span className="carousel-control-prev-icon"></span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#demo"
                  data-bs-slide="next"
                >
                  <span className="carousel-control-next-icon"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="explore-all padding-top-20 padding-bottom-40">
        <div className="container">
          <div
            className="row "
            style={{ borderBottom: "3px solid rgba(237,237,237,1.00)" }}
          >
            <div
              className="col-lg-6 overflow-x-scroll scrollHeighInLg"
              // style={{ height: "600px" }}
            >
              <a id="repair" style={{ textDecoration: "none" }}></a>
              <h1 className="categoryDesFont fs-6">Repair</h1>
              <div className="product-item">
                <div className="top-part">
                  <div className="content">
                    <div
                      className="title maxWidthFont "
                      style={{
                        color: "black",
                      }}
                    >
                      Split AC [Repair]
                    </div>
                    <div className="data">
                      <p
                        className="categoryDesFont p-0 m-0 "
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        Our Technician Check Your Units Properly [ No power,
                        Insufficient Cooling ,Strange Noise Etc] And Get Same
                        Day Solution
                      </p>
                    </div>
                  </div>
                  <div className="image borderImg rounded-3">
                    <img
                      src="https://admin.theclassy.in/uploads/ApplianceType/202402181629146048.png"
                      alt="..."
                      className="w-100 h-100 rounded-3"
                    ></img>
                  </div>
                </div>
                <div className="bottom-part">
                  <div className="price">
                    <div>
                      <p
                        className="p-0 m-0 maxWidthFont fontDescription"
                        style={{ lineHeight: "1.5" }}
                      >
                        {" "}
                        Start at{" "}
                        <span
                          className="text-success"
                          style={{ fontWeight: "600" }}
                        >
                          ₹ 350.00
                        </span>
                      </p>
                    </div>
                    <Link
                      to={`/service-details/35`}
                      className="view-detail listLine"
                    >
                      MORE INFO
                    </Link>
                  </div>
                  <div className="px-3 p-1 addButtonHeader">
                    <Link to="/category-details/4/Air%20Conditioner">
                      <button
                        className="addButton"
                        // style={{color:'#3485da',background:'none'}}
                      >
                        {" "}
                        Add{" "}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="product-item">
                <div className="top-part">
                  <div className="content">
                    <div
                      className="title maxWidthFont "
                      style={{
                        color: "black",
                      }}
                    >
                      Window AC [Repair]
                    </div>
                    <div className="data">
                      <p
                        className="categoryDesFont p-0 m-0 "
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        Our Technician Check Your Units Properly [ No power,
                        Insufficient Cooling ,Strange Noise Etc] And Get Same
                        Day Solution
                      </p>
                    </div>
                  </div>
                  <div className="image borderImg rounded-3">
                    <img
                      src="https://admin.theclassy.in/uploads/ApplianceType/202402181628474826.png"
                      alt="..."
                      className="w-100 h-100 rounded-3"
                    ></img>
                  </div>
                </div>
                <div className="bottom-part">
                  <div className="price">
                    <div>
                      <p
                        className="p-0 m-0 maxWidthFont fontDescription"
                        style={{ lineHeight: "1.5" }}
                      >
                        {" "}
                        Start at{" "}
                        <span
                          className="text-success"
                          style={{ fontWeight: "600" }}
                        >
                          ₹ 350.00
                        </span>
                      </p>
                    </div>
                    <Link
                      to={`/service-details/36`}
                      className="view-detail listLine"
                    >
                      MORE INFO
                    </Link>
                  </div>
                  <div className="px-3 p-1 addButtonHeader">
                    <Link to="/category-details/4/Air%20Conditioner">
                      <button
                        className="addButton"
                        // style={{color:'#3485da',background:'none'}}
                      >
                        {" "}
                        Add{" "}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <a id="Installation" style={{ textDecoration: "none" }}></a>

              <h1 className="categoryDesFont fs-6">
                Installation & UnInstallation
              </h1>
              <div className="product-item">
                <div className="top-part">
                  <div className="content">
                    <div
                      className="title maxWidthFont "
                      style={{
                        color: "black",
                      }}
                    >
                      Split Air Conditioner
                    </div>
                    <div className="data">
                      <p
                        className="categoryDesFont p-0 m-0 "
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        Complete Installation And dismantle Of Both Units Indoor
                        & Outdoor Properly By Experts
                      </p>
                    </div>
                  </div>
                  <div className="image borderImg rounded-3">
                    <img
                      src="https://admin.theclassy.in/uploads/ApplianceType/202402181628318690.png"
                      alt="..."
                      className="w-100 h-100 rounded-3"
                    ></img>
                  </div>
                </div>
                <div className="bottom-part">
                  <div className="price">
                    <div>
                      <p
                        className="p-0 m-0 maxWidthFont fontDescription"
                        style={{ lineHeight: "1.5" }}
                      >
                        {" "}
                        Start at{" "}
                        <span
                          className="text-success"
                          style={{ fontWeight: "600" }}
                        >
                          ₹ 1359.00
                        </span>
                      </p>
                    </div>
                    <Link
                      to={`/service-details/259`}
                      className="view-detail listLine"
                    >
                      MORE INFO
                    </Link>
                  </div>
                  <div className="px-3 p-1 addButtonHeader">
                    <Link to="/category-details/4/Air%20Conditioner">
                      <button
                        className="addButton"
                        // style={{color:'#3485da',background:'none'}}
                      >
                        {" "}
                        Add{" "}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="product-item">
                <div className="top-part">
                  <div className="content">
                    <div
                      className="title maxWidthFont "
                      style={{
                        color: "black",
                      }}
                    >
                      Window Air Conditioner
                    </div>
                    <div className="data">
                      <p
                        className="categoryDesFont p-0 m-0 "
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        Complete Installation And dismantle Of Both Units Indoor
                        & Outdoor Properly By Experts
                      </p>
                    </div>
                  </div>
                  <div className="image borderImg rounded-3">
                    <img
                      src="https://admin.theclassy.in/uploads/ApplianceType/20240218162815399.png"
                      alt="..."
                      className="w-100 h-100 rounded-3"
                    ></img>
                  </div>
                </div>
                <div className="bottom-part">
                  <div className="price">
                    <div>
                      <p
                        className="p-0 m-0 maxWidthFont fontDescription"
                        style={{ lineHeight: "1.5" }}
                      >
                        {" "}
                        Start at{" "}
                        <span
                          className="text-success"
                          style={{ fontWeight: "600" }}
                        >
                          ₹ 900.00
                        </span>
                      </p>
                    </div>
                    <Link
                      to={`/service-details/260`}
                      className="view-detail listLine"
                    >
                      MORE INFO
                    </Link>
                  </div>
                  <div className="px-3 p-1 addButtonHeader">
                    <Link to="/category-details/4/Air%20Conditioner">
                      <button
                        className="addButton"
                        // style={{color:'#3485da',background:'none'}}
                      >
                        {" "}
                        Add{" "}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <a id="Service" style={{ textDecoration: "none" }}></a>

              <h1 className="categoryDesFont fs-6">SERVICE</h1>
              <div className="product-item">
                <div className="top-part">
                  <div className="content">
                    <div
                      className="title maxWidthFont "
                      style={{
                        color: "black",
                      }}
                    >
                      Split AC Service [ Lite]
                    </div>
                    <div className="data">
                      <p
                        className="categoryDesFont p-0 m-0 "
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        Regular Servicing Maintain Your AC Efficiency And
                        ensuring Good Indoor Air Quality
                      </p>
                    </div>
                  </div>
                  <div className="image borderImg rounded-3">
                    <img
                      src="https://admin.theclassy.in/uploads/ApplianceType/202402181628017887.png"
                      alt="..."
                      className="w-100 h-100 rounded-3"
                    ></img>
                  </div>
                </div>
                <div className="bottom-part">
                  <div className="price">
                    <div>
                      <p
                        className="p-0 m-0 maxWidthFont fontDescription"
                        style={{ lineHeight: "1.5" }}
                      >
                        {" "}
                        Start at{" "}
                        <span
                          className="text-success"
                          style={{ fontWeight: "600" }}
                        >
                          ₹ 350.00
                        </span>
                      </p>
                    </div>
                    <Link
                      to={`/service-details/31`}
                      className="view-detail listLine"
                    >
                      MORE INFO
                    </Link>
                  </div>
                  <div className="px-3 p-1 addButtonHeader">
                    <Link to="/category-details/4/Air%20Conditioner">
                      <button
                        className="addButton"
                        // style={{color:'#3485da',background:'none'}}
                      >
                        {" "}
                        Add{" "}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="product-item">
                <div className="top-part">
                  <div className="content">
                    <div
                      className="title maxWidthFont "
                      style={{
                        color: "black",
                      }}
                    >
                      Foam & Jet Service ( Split Ac )
                    </div>
                    <div className="data">
                      <p
                        className="categoryDesFont p-0 m-0 "
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        Complete Cleaning Of Both Units Indoor Out door with
                        Foam & Water Jet High Pressure Pump
                      </p>
                    </div>
                  </div>
                  <div className="image borderImg rounded-3">
                    <img
                      src="https://admin.theclassy.in/uploads/ApplianceType/202402181627457962.png"
                      alt="..."
                      className="w-100 h-100 rounded-3"
                    ></img>
                  </div>
                </div>
                <div className="bottom-part">
                  <div className="price">
                    <div>
                      <p
                        className="p-0 m-0 maxWidthFont fontDescription"
                        style={{ lineHeight: "1.5" }}
                      >
                        {" "}
                        Start at{" "}
                        <span
                          className="text-success"
                          style={{ fontWeight: "600" }}
                        >
                          ₹ 649.00
                        </span>
                      </p>
                    </div>
                    <Link
                      to={`/service-details/33`}
                      className="view-detail listLine"
                    >
                      MORE INFO
                    </Link>
                  </div>
                  <div className="px-3 p-1 addButtonHeader">
                    <Link to="/category-details/4/Air%20Conditioner">
                      <button
                        className="addButton"
                        // style={{color:'#3485da',background:'none'}}
                      >
                        {" "}
                        Add{" "}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="product-item">
                <div className="top-part">
                  <div className="content">
                    <div
                      className="title maxWidthFont "
                      style={{
                        color: "black",
                      }}
                    >
                      Power Jet Service ( Split Ac )
                    </div>
                    <div className="data">
                      <p
                        className="categoryDesFont p-0 m-0 "
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        Complete Cleaning Of Both Units Indoor Out door with
                        Foam & Water Jet High Pressure Pump
                      </p>
                    </div>
                  </div>
                  <div className="image borderImg rounded-3">
                    <img
                      src="https://admin.theclassy.in/uploads/ApplianceType/202402181627457962.png"
                      alt="..."
                      className="w-100 h-100 rounded-3"
                    ></img>
                  </div>
                </div>
                <div className="bottom-part">
                  <div className="price">
                    <div>
                      <p
                        className="p-0 m-0 maxWidthFont fontDescription"
                        style={{ lineHeight: "1.5" }}
                      >
                        {" "}
                        Start at{" "}
                        <span
                          className="text-success"
                          style={{ fontWeight: "600" }}
                        >
                          ₹ 350.00
                        </span>
                      </p>
                    </div>
                    <Link
                      to={`/service-details/31`}
                      className="view-detail listLine"
                    >
                      MORE INFO
                    </Link>
                  </div>
                  <div className="px-3 p-1 addButtonHeader">
                    <Link to="/category-details/4/Air%20Conditioner">
                      <button
                        className="addButton"
                        // style={{color:'#3485da',background:'none'}}
                      >
                        {" "}
                        Add{" "}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="product-item">
                <div className="top-part">
                  <div className="content">
                    <div
                      className="title maxWidthFont "
                      style={{
                        color: "black",
                      }}
                    >
                      Window Ac Service ( Lite )
                    </div>
                    <div className="data">
                      <p
                        className="categoryDesFont p-0 m-0 "
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        {/* Complete Cleaning Of Both Units Indoor Out door with
                        Foam & Water Jet High Pressure Pump */}
                      </p>
                    </div>
                  </div>
                  <div className="image borderImg rounded-3">
                    <img
                      src="https://admin.theclassy.in/uploads/ApplianceType/202403121850024325.png"
                      alt="..."
                      className="w-100 h-100 rounded-3"
                    ></img>
                  </div>
                </div>
                <div className="bottom-part">
                  <div className="price">
                    <div>
                      <p
                        className="p-0 m-0 maxWidthFont fontDescription"
                        style={{ lineHeight: "1.5" }}
                      >
                        {" "}
                        Start at{" "}
                        <span
                          className="text-success"
                          style={{ fontWeight: "600" }}
                        >
                          ₹ 350.00
                        </span>
                      </p>
                    </div>
                    <Link
                      to={`/service-details/286`}
                      className="view-detail listLine"
                    >
                      MORE INFO
                    </Link>
                  </div>
                  <div className="px-3 p-1 addButtonHeader">
                    <Link to="/category-details/4/Air%20Conditioner">
                      <button
                        className="addButton"
                        // style={{color:'#3485da',background:'none'}}
                      >
                        {" "}
                        Add{" "}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="product-item">
                <div className="top-part">
                  <div className="content">
                    <div
                      className="title maxWidthFont "
                      style={{
                        color: "black",
                      }}
                    >
                      Foam & Jet Service ( Window Ac )
                    </div>
                    <div className="data">
                      <p
                        className="categoryDesFont p-0 m-0 "
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        {/* Complete Cleaning Of Both Units Indoor Out door with
                        Foam & Water Jet High Pressure Pump */}
                      </p>
                    </div>
                  </div>
                  <div className="image borderImg rounded-3">
                    <img
                      src="https://admin.theclassy.in/uploads/ApplianceType/202402172108342477.png"
                      alt="..."
                      className="w-100 h-100 rounded-3"
                    ></img>
                  </div>
                </div>
                <div className="bottom-part">
                  <div className="price">
                    <div>
                      <p
                        className="p-0 m-0 maxWidthFont fontDescription"
                        style={{ lineHeight: "1.5" }}
                      >
                        {" "}
                        Start at{" "}
                        <span
                          className="text-success"
                          style={{ fontWeight: "600" }}
                        >
                          ₹ 499.00
                        </span>
                      </p>
                    </div>
                    <Link
                      to={`/service-details/287`}
                      className="view-detail listLine"
                    >
                      MORE INFO
                    </Link>
                  </div>
                  <div className="px-3 p-1 addButtonHeader">
                    <Link to="/category-details/4/Air%20Conditioner">
                      <button
                        className="addButton"
                        // style={{color:'#3485da',background:'none'}}
                      >
                        {" "}
                        Add{" "}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="product-item">
                <div className="top-part">
                  <div className="content">
                    <div
                      className="title maxWidthFont "
                      style={{
                        color: "black",
                      }}
                    >
                      Power Jet Service ( Window Ac )
                    </div>
                    <div className="data">
                      <p
                        className="categoryDesFont p-0 m-0 "
                        style={{
                          lineHeight: "1.5",
                        }}
                      >
                        {/* Complete Cleaning Of Both Units Indoor Out door with
                        Foam & Water Jet High Pressure Pump */}
                      </p>
                    </div>
                  </div>
                  <div className="image borderImg rounded-3">
                    <img
                      src="https://admin.theclassy.in/uploads/ApplianceType/202402181627118646.png"
                      alt="..."
                      className="w-100 h-100 rounded-3"
                    ></img>
                  </div>
                </div>
                <div className="bottom-part">
                  <div className="price">
                    <div>
                      <p
                        className="p-0 m-0 maxWidthFont fontDescription"
                        style={{ lineHeight: "1.5" }}
                      >
                        {" "}
                        Start at{" "}
                        <span
                          className="text-success"
                          style={{ fontWeight: "600" }}
                        >
                          ₹ 499.00
                        </span>
                      </p>
                    </div>
                    <Link
                      to={`/service-details/288`}
                      className="view-detail listLine"
                    >
                      MORE INFO
                    </Link>
                  </div>
                  <div className="px-3 p-1 addButtonHeader">
                    <Link to="/category-details/4/Air%20Conditioner">
                      <button
                        className="addButton"
                        // style={{color:'#3485da',background:'none'}}
                      >
                        {" "}
                        Add{" "}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="right-static ms-5">
                <div className="our-expertise">
                  <div className="main-head">Our Expertise</div>
                  <div className="list">
                    <div className="list-item">
                      <div className="icon">
                        <i className="las la-tools"></i>
                      </div>
                      <div className="content">
                        <div className="title">Timely Service</div>
                        <div className="data ">
                          We understand the importance of time and offer quick
                          and prompt services to our customers. We ensure that
                          our technicians reach your doorstep within the given
                          time frame.
                        </div>
                      </div>
                    </div>

                    <div className="list-item">
                      <div className="icon">
                        <i className="las la-tools"></i>
                      </div>
                      <div className="content">
                        <div className="title">Quality Workmanship</div>
                        <div className="data">
                          We use only the best quality spare parts and tools for
                          repairs and installations. Our technicians take pride
                          in their work and ensure that the job is done right
                          the first time.
                        </div>
                      </div>
                    </div>
                    <div className="list-item">
                      <div className="icon">
                        <i className="las la-tools"></i>
                      </div>
                      <div className="content">
                        <div className="title">Customer Satisfaction</div>
                        <div className="data">
                          Our top priority is customer satisfaction. We strive
                          to provide an exceptional service experience to our
                          customers and ensure that all their queries and
                          concerns are addressed promptly.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="row pb-3 mt-3"
            style={{ borderBottom: "3px solid rgba(237,237,237,1.00)" }}
          >
            <div className="AccordionContainer col-12 col-md-7 col-lg-8 border-right">
              <div className="title-head pt-2">
                <h2
                  style={{ fontWeight: "bold" }}
                  className="maxWidthFont fs-3"
                >
                  Frequently asked question
                </h2>
              </div>
              <Accordion
                className="accordionBorder"
                border={false}
                style={{ fontFamily: "Serif, Sans-serif" }}
                defaultActiveKey="0"
              >
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    How does the pricing of AC services work?
                  </Accordion.Header>
                  <Accordion.Body>
                    The pricing of our AC services depends solely on the type of
                    service you require. We offer transparent pricing, ensuring
                    you know exactly what to expect without any hidden costs.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    How can I book AC Service and AC Repair near me with
                    TheClassy Company
                  </Accordion.Header>
                  <Accordion.Body>
                    Booking your AC Service and Repair with TheClassy Company is
                    simple and hassle-free. You can do it through our app or
                    website. Just select the service you need, choose a
                    convenient time slot, and we'll assign a skilled
                    professional to take care of your AC needs.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    Can I request the same professional for future services?
                  </Accordion.Header>
                  <Accordion.Body>
                    Yes, TheClassy allows you to request the same professional
                    for future services if you're satisfied with their work. We
                    understand the importance of building trust and rapport with
                    our customers.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    What safety measures does TheClassy Company follow,
                    especially during the COVID-19 pandemic?
                  </Accordion.Header>
                  <Accordion.Body>
                    TheClassy Company prioritizes the safety of both our
                    customers and professionals. We follow strict COVID-19
                    guidelines, including mandatory mask-wearing, sanitisation,
                    and social distancing. Our professionals are equipped with
                    necessary protective gear to ensure a safe service
                    experience.
                  </Accordion.Body>
                </Accordion.Item>
                {/* Add more Accordion.Item components as needed for additional FAQ items */}
              </Accordion>
            </div>
            <div className=" col-12 col-md-5 col-lg-4">
              <div>
                <div className="title-head px-0 px-md-2 px-lg-4">
                  <h2
                    style={{ fontWeight: "bold" }}
                    className=" fs-3 commonfontFamily"
                  >
                    Ac Service And Repair in Indore, India
                  </h2>
                  <p className="pt-3 commonfontFamily">
                    Get your window or split AC service in Indore, India done by
                    TheClassy Company's Power Jet AC Service technicians. We
                    offer you a 90 Day service guarantee with INR 10k insurance
                    against damage for every AC serviced. Also get a no
                    questions asked 90 day revisit policy. Only genuine spare
                    parts are supplied with fixed pricing. TheClassy Company
                    will help you connect with the best AC services to cater
                    your needs, right at your doorstep.
                  </p>
                </div>
                <div className="title-head px-0 px-md-2 px-lg-4">
                  <h2
                    style={{ fontWeight: "bold" }}
                    className=" fs-3 commonfontFamily"
                  >
                    You are here
                  </h2>
                  <p className="pt-3 d-flex">
                    <Link
                      to={`/`}
                      className="view-detail listLine commonfontFamily"
                      style={{ color: "black" }}
                    >
                      Home
                    </Link>
                    /{" "}
                    <Link
                      to="/category-details/4/Air%20Conditioner"
                      className="view-detail listLine commonfontFamily"
                      style={{ color: "black" }}
                    >
                      Ac Service And Repair in Indore
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-4">
            <h1
              style={{ fontWeight: "bold" }}
              className="commonfontFamily fs-2"
            >
              #1 AC cleaning services by TheClassy
            </h1>
            <p className="commonfontFamily">
              Explore our comprehensive range of AC cleaning services! If you're
              searching for 'split ac service near me' or 'window ac service
              near me' or any related query, you've landed in the right place.
              Our mission is to ensure your AC operates at its peak efficiency,
              providing you with cool comfort throughout the year. Explore our
              range of AC service options:
            </p>
          </div>
          <div className="row pt-4">
            <div className="col-lg-6 col-12 col-md-6">
              <h2 className="fs-3 commonfontFamily fw-bold">
                Foam & power jet AC service in Indore, India
              </h2>
              <p className="commonfontFamily">
                Your AC unit's performance is affected by dust and debris from
                surroundings. Our foam & power jet AC service protects your AC
                from the clutches of dirt and grime, by ensuring the best AC
                filter cleaning. This service includes:
              </p>
              <ol class="list-group list-group-numbered commonfontFamily">
                <li class="list-group-item">
                  2X deeper dust removal: With our innovative foam + power jet
                  technology, we ensure a thorough cleaning, leaving no particle
                  behind.
                </li>
                <li class="list-group-item">
                  Pre-service checks: Our technicians conduct detailed
                  inspections, including AC gas checks, to pinpoint any
                  potential repairs.
                </li>
                <li class="list-group-item">
                  Mess-free experience: Say goodbye to spills with our unique AC
                  jacket, ensuring a tidy workspace post-service.
                </li>
                <li class="list-group-item">
                  Mess-free experience: Say goodbye to spills with our unique AC
                  jacket, ensuring a tidy workspace post-service.
                </li>

                <li class="list-group-item">
                  Mess-free experience: Say goodbye to spills with our unique AC
                  jacket, ensuring a tidy workspace post-service.
                </li>
              </ol>
            </div>
            {/* <div className="col-lg-6 col-12 col-md-6 ">
              <div className="d-flex">
                <img
                  src="https://admin.theclassy.in/uploads/ApplianceType/20240218162815399.png"
                  alt="AC Service"
                />
                <img
                  src="https://admin.theclassy.in/uploads/ApplianceType/20240218162815399.png"
                  alt="AC Service"
                />
              </div>
            </div> */}
          </div>

          <div className="row mt-5">
            <div className="col-12 col-md-6 col-lg-6 commonfontFamily">
              <h2 className="fw-bold">Power jet AC service</h2>
              <p className="commonfontFamily">
                When it comes to enhancing cooling performance, our power jet AC
                service stands out in the market. Bid farewell to AC cooling
                issues with our specialized power jet AC servicing.
              </p>
              <ol class="list-group list-group-numbered commonfontFamily">
                <li class="list-group-item">
                  Enhanced cooling performance: Say hello to a refreshing breeze
                  as we work our magic with power jet AC servicing.
                </li>
                <li class="list-group-item">
                  Indoor & basic outdoor cleaning: While we focus on intensively
                  cleaning the indoor unit, we ensure a basic cleanup for the
                  outdoor counterpart.
                </li>
                <li class="list-group-item">
                  Pre & post service checks: Our technicians leave no stone
                  unturned, conducting thorough inspections before and after the
                  service to ensure optimal functionality.
                </li>
              </ol>
            </div>
            <div className="col-12 col-md-6 col-lg-6 commonfontFamily">
              <h2 className="fw-bold">Anti-rust deep clean AC service</h2>
              <p className="commonfontFamily">
                Our anti-rust deep clean AC service safeguards your AC's
                longevity and performance by eliminating rust-induced gas
                leakages.
              </p>
              <ol class="list-group list-group-numbered commonfontFamily">
                <li class="list-group-item">
                  Applicable on split & window ACs: Whether you own a split or
                  window AC, our anti-rust treatment has got you covered.
                </li>
                <li class="list-group-item">
                  Prevents gas leakages: Our unique anti-rust formula shields
                  your AC from frequent gas leakages, ensuring long-lasting
                  performance.
                </li>
              </ol>
            </div>
          </div>

          <div className="mt-5">
            <h1 className="commonfontFamily fs-4 fw-bold">
              Here are 4 ways in which TheClassy Company provides the best AC
              service & repair near you
            </h1>
            <ol class="list-group list-group-numbered commonfontFamily">
              <li class="list-group-item">
                Advanced cleaning tools: Say goodbye to manual cleaning
                techniques, we harness the power of foam cleaning & power jet
                technology for unmatched results.
              </li>
              <li class="list-group-item">
                Comprehensive internal cleaning: Unlike competitors, we ensure
                thorough cleaning of all internal parts including ac filter
                cleaning, leaving no corner untouched.
              </li>
              <li class="list-group-item">
                Pre & post service checks: From start to finish, we prioritize
                meticulous inspections to guarantee a job well done
              </li>
              <li class="list-group-item">
                Affordable rates: Ran out of your free AC services? Urban
                Company provides affordable at home AC service eliminating the
                need of contacting AC service centres in Indore, India.
              </li>
            </ol>
            <h2 className="commonfontFamily fs-6 fw-bold">
              Looking for the best ac service centre near you? Book your next AC
              service request with TheClassy today and experience reliability,
              efficiency, and cool comfort like never before!
            </h2>
          </div>
          <div className="mt-5">
            <h1 className="commonfontFamily fs-5 fw-bold">
              Your comprehensive guide for AC repairs in Indore, India
            </h1>
            <p className="commonfontFamily">
              We offer a range of AC repair services in Indore, India, which are
              aimed to keep your AC in top-notch condition. Whether you are
              looking for a "split ac repair near me" or a "window ac repair
              near me", we've got you covered with our meticulous AC repair
              service.
            </p>
            <div className="row">
              <div className="col-12  col-md-6 col-lg-6">
                <img
                  src="https://admin.theclassy.in/uploads/ApplianceType/202402181628318690.png"
                  alt=""
                  className="rounded"
                />
              </div>
              <div className="col-12  col-md-6 col-lg-6">
                <h1 className="commonfontFamily fs-4 fw-bold">
                  Understanding the scope
                </h1>
                <p className="commonfontFamily">
                  When it comes to AC repair, precision is important. Our
                  skilled technicians delve deep into the heart of your air
                  conditioner to diagnose and resolve issues promptly, ensuring
                  optimal performance. From minor adjustments to complex fixes,
                  we handle it all with finesse.
                </p>

                <h1 className="commonfontFamily fs-4 fw-bold">
                  Inclusions and exclusions
                </h1>
                <p className="commonfontFamily">
                  Our AC repair service leaves no stone unturned. We
                  meticulously inspect every component, from the coils to the
                  filters, ensuring thorough cleaning and maintenance. However,
                  please note that certain parts, such as spare components
                  beyond our standard toolkit may require additional charges,
                  for example, AC pcb repairs, etc.
                </p>
                <h1 className="commonfontFamily fs-4 fw-bold">
                  Benefits unveiled
                </h1>
                <p className="commonfontFamily">
                  Experience the difference with our AC repair services near
                  you. Enjoy uninterrupted cooling and enhanced energy
                  efficiency as our experts fine-tune your unit to perfection.
                  Say goodbye to unwanted noise, erratic cooling, and pesky
                  leaks with our comprehensive repair solutions
                </p>
                <h1 className="commonfontFamily fs-4 fw-bold">
                  The procedure unveiled
                </h1>
                <p className="commonfontFamily">
                  Our technicians kick off the process with a detailed
                  inspection, identifying any underlying issues. With precision
                  and expertise, we address each concern methodically, utilizing
                  high-quality spare parts and cutting-edge tools for lasting
                  results.
                </p>
                <h1 className="commonfontFamily fs-4 fw-bold">
                  Details matter
                </h1>
                <p className="commonfontFamily">
                  At TheClassy, we understand the importance of thoroughness.
                  That's why our repair service encompasses pre and post-service
                  checks, ensuring that every aspect of your AC is functioning
                  flawlessly. Bid farewell to worries as we provide a 30-day
                  warranty, offering peace of mind long after the service is
                  completed.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <h2 className="commonfontFamily fs-4 fw-bold">
              Discover top-notch AC repair services in Indore, India
            </h2>
            <p className="commonfontFamily">
              When it comes to AC repair, trust only the best. Urban Company's
              dedicated professionals are trained to deliver excellence, backed
              by years of expertise and a commitment to customer satisfaction.
              With us, your comfort is our priority, and we go above and beyond
              to ensure a hassle-free experience.
            </p>
            <p className="commonfontFamily">
              In conclusion, AC repair doesn't have to be daunting. With Urban
              Company's meticulous service, you can be rest assured that your
              cooling companion is in capable hands.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default CategoryDetailsAC;
