import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import Popup from "./Popup";
import "owl.carousel/dist/assets/owl.carousel.css";
import { Modal } from "react-bootstrap";
import axios from "axios";
import "../pages/model.css";
import { URLhome, URLApplianceDetails } from "../util/UrlConst";
import Category from "./components/categorys";
import OwlCarousels from "./components/owlCarousel";
import Appliance from "./components/Appliance";
import Banner from "./components/banner";
import { UrlHistoryContext } from "../context/UrlHistoryProvider";
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';

export default function Home() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });
  const [homeSlider, setHomeSlider] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);

  const [mostbooks, setMostBooks] = useState([]);
  const [appliance, setAppliance] = useState([]);
  const [applianceOnly, setApplianceOnly] = useState([]);
  const [banners, setBanner] = useState([]);
  const [mostbook, setMostBook] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [blogs, setBlogs] = useState([]);
  const [allservice, setAllservice] = useState([]);
  const [toppics, setTopPics] = useState([]);
  const [productcategory, setProductCategory] = useState([]);
  const [offerslider, setOfferslider] = useState([]);
  const [subCategoryTitle, setsubcategoryTitle] = useState();
  const [modelsubacategory, setmodelSubCategory] = useState([]);
  const [allData, setAllData] = useState([]);
  const headers = {
    Authorization: "Bearer YourAccessToken",
    "Content-Type": "application/json",
  };

  const token = sessionStorage.getItem("token");

  async function getData() {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    await axios
      .post(URLhome, {
        headers: headers,
        fcm_token: 123456,
      })
      .then((data) => {
        // console.log(data);
        setAllData(data?.data?.data?.datas);

        setMostBooks(data.data.data.datas);
        // var applianceonly = data.data.data.datas.filter((item) => item?.key == "appliance")
        // setApplianceOnly(applianceonly);

        // var slider = data.data.data.datas.filter((item) => item?.key == "appliancetype" || item?.key == "appliance")
        setAllservice(data?.data?.data?.slider);
        // setHomeSlider(data.data.data.slider);
        setCategories(data?.data?.data?.categorys);
        // setBrands(data.data.allbrands);
        setMostBooks(data?.data?.data?.datas);
        var appliance = data?.data?.data?.datas.filter(
          (item) => item?.key == "appliancetype" || item?.key == "appliance"
        );
        setAppliance(appliance);
        var banner = data?.data?.data?.datas.filter(
          (item) => item?.key == "banner"
        );

        setBanner(banner);
        setMostBook(data?.data?.data?.datas[4]?.items);
        // setBlogs(data.data.blogdata);
        // setTopPics(data.data.toppicks);
        // setOfferslider(data.data.offerslider);
        // setProductCategory(data.data.productcategory);
      });
  }
  // console.log(allservice);

  const [searchkey, setSearchkey] = useState();

  const { model, setModal, category } = useContext(UrlHistoryContext);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setModal(false);
    setShow(false);
  };

  const handleCategoryName = (e) => {
    sessionStorage.setItem("categoryName", e);
  };


  const formostbooked = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    navText: [
      '<i class="las la-angle-left"></i>',
      '<i class="las la-angle-right"></i>',
    ],
    smartSpeed: 500,
    responsive: {
      0: {
        items: 1,
        nav: false,
      },
      400: {
        items: 1,
        nav: false,
      },
      576: {
        items: 2,
        nav: false,
      },
      768: {
        items: 2,
        nav: false,
      },
      991: {
        items: 3,
      },
      1200: {
        items: 4,
      },
    },
  };

  useEffect(() => {
    getData();

    // setTimeout(function () {
    //   getData();
    // }, 500);
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  


  const [showPopup, setShowPopup] = useState(false);



  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 5000);

    // Clear the timer when the component unmounts to avoid memory leaks
    return () => {
      clearTimeout(timer);
    };
  }, []);
  const url = window.location.href;

  return (
    <>
      <Helmet>
        <title> {`The Classy `}</title>
        <meta property="og:url" content={url} />
        <meta
          name="description"
          content="The Classy  is your ultimate shop for professional local services in your area. Find a good amount of reliable professionals in your location to manage all of your household and beauty needs"
        />
        <meta
          property="og:description"
          content="The Classy  is your ultimate shop for professional local services in your area. Find a good amount of reliable professionals in your location to manage all of your household and beauty needs"
        />

      </Helmet>
      <section
        className="category-area"
        data-padding-top="60"
        data-padding-bottom="60"
      >
        <div className="container-fluid ">
          <Category props={categories} />
        </div>
      </section>
      <div className="" style={{ marginBottom: "50px" }}>
        {allData?.map((item, index) => (
          <>
            {item?.key === "appliancetype" || item?.key === "appliance" ? (
              <Appliance key={index} props={item} formostbooked={formostbooked} />
            ) : item?.key === "slider" ? (
              <OwlCarousels key={index} props={item} formostbooked={formostbooked} />
            ) : (
              <Banner key={index} props={item} />
            )}
          </>
        ))}
      </div>
      {/* {{-- ===========================================
                             Appliance 
            =========================================== --}} */}

      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="category-modal"
        show={model}
        onHide={handleClose}
        className="main_model rounded-top categoryModel"
        dialogClassName="main_model"
      >
        <Modal.Header
          className="modelHeder"
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            // padding: "15px",
            border: "none",
            // bottom: "50px",
          }}
        >
          <div className="modalCloseButton cursor-pointer d-none d-md-block d-lg-block">
            <span style={{ margin: "3px 8px" }} onClick={() => handleClose()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-x"
                style={{ margin: "3px -3px", color: "gray" }}
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </span>
          </div>
        </Modal.Header>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
            top: "-45px",
          }}
        >
          <h4
            className="m-0 py-2 model_heading pt-5 pt-lg-0 pt-md-0"
            style={{ fontWeight: "600" }}
            //  marginLeft: "10px", padding: "12px ",
          >
            {category}
          </h4>
          <Modal.Body className="m-0 p-0 pt-2 model_body">
            <div
              className=" modalGrid "
              // style={{
              // service-grid
              //   display: "flex",
              //   flexWrap: "wrap",
              //   justifyContent: "center",
              // }}
            >
              {modelsubacategory.length > 0 ? (
                modelsubacategory.map((data) => (
                  <div
                    key={data.id}
                    onClick={() => handleCategoryName(data.name)}
                    className=""
                  >
                    <Link
                      onClick={() => handleCategoryName(data.name)}
                      to={`/category-details/${data.id}/${encodeURIComponent(
                        data.name
                      )}`}
                      style={{
                        textDecoration: "none",
                        display: "block",
                        // margin: "10px",
                      }}
                    >
                      <div className="service-item p-2 p-md-0 p-lg-0">
                        <div className="icon">
                          <img
                            src={data.image}
                            alt="..."
                            className="w-100 h-100"
                          ></img>
                        </div>
                        <div className="name ">
                          <p className="p-0 m-0 whiteSpaceCantegory">
                            {" "}
                            {data.name}{" "}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))
              ) : (
                <div className="d-flex justify-content-center">
                  {modelsubacategory ? " No Category Found" : "Loading..."}
                </div>
              )}
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}
