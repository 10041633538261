import { Parser } from "html-to-react";
import React, { useContext, useEffect, useState } from "react";
import { UrlHistoryContext } from "../context/UrlHistoryProvider";

export default function Problems_card_show(props) {
  var { item, problems, addToCartProblem } = props;
  const [cartQuantity, setCartQuantity] = useState(0);
  const { changeLocalState, setChangeLocalState } =
    useContext(UrlHistoryContext);

  const addToCartProblemPlus = (product) => {
    const existingCart = JSON.parse(sessionStorage.getItem("cart")) || [];
    const existingProductIndex = existingCart.findIndex(
      (item) => item?.id === problems?.id
    );

    if (existingProductIndex !== -1) {
      const existingProduct = existingCart[existingProductIndex].problems.find(
        (item) => item?.id === product?.id
      );

      if (existingProduct) {
        existingProduct.quantity_data =
          (existingProduct.quantity_data || 0) + 1;
      } else {
        // Problem not found in existingCart, add it
        const copyData = problems;
        const newData = copyData?.problems?.find(
          (dt) => dt?.id === product?.id
        );

        if (newData) {
          newData.quantity_data = 1;
          existingCart[existingProductIndex].problems.push(newData);
        }
      }
    } else {
      // Product not found in existingCart, add it along with the problem
      const copyData = problems;
      const newData = copyData?.problems?.find((dt) => dt?.id === product?.id);

      if (newData) {
        newData.quantity_data = 1;
        existingCart.push({ ...copyData, problems: [newData] });
      }
    }

    sessionStorage.setItem("cart", JSON.stringify(existingCart));
    // setCartQuantity(existingProduct?.quantity_data || 1);
  };

  const addToCartProblemMinus = (product) => {
    let existingCart = JSON.parse(sessionStorage.getItem("cart")) || [];
    const existingProductIndex = existingCart.findIndex(
      (item) => item?.id === problems?.id
    );

    if (existingProductIndex !== -1) {
      const existingProduct = existingCart[existingProductIndex].problems.find(
        (item) => item?.id === product?.id
      );

      if (existingProduct) {
        existingProduct.quantity_data =
          (existingProduct.quantity_data || 0) - 1;

        // Check if all quantity_data in problems array are zero, remove the entire item from existingCart
        if (
          existingCart[existingProductIndex].problems.every(
            (problem) => problem.quantity_data === 0
          )
        ) {
          existingCart.splice(existingProductIndex, 1);
        }
      }
    } else {
      // Product not found in existingCart, add it
      const copyData = problems;
      const newData = copyData.problems.find((dt) => dt?.id === product?.id);

      if (newData) {
        newData.quantity_data = 1;
        existingCart.push(copyData);
      }
    }

    sessionStorage.setItem("cart", JSON.stringify(existingCart));
  };

  function findAllProblems(data) {
    const allProblems = [];

    data?.forEach((person) => {
      if (person?.problems) {
        person?.problems?.forEach((problem) => {
          // Check if the problem is not already in the array
          if (
            !allProblems?.some(
              (p) => JSON.stringify(p) === JSON.stringify(problem)
            )
          ) {
            allProblems.push(problem);
          }
        });
      }
    });

    return allProblems;
  }

  useEffect(() => {
    const existingCart = JSON.parse(sessionStorage.getItem("cart"));
    if (existingCart != []) {
      var all = findAllProblems(existingCart);
      var findData = all.find((dt) => dt?.id == item?.id);
      setCartQuantity(findData?.quantity_data);
    }
  }, [changeLocalState]);

  const [salePrice, setSalePrice] = useState(0);
  const [mrp, setMrp] = useState(0);

  const calculatedSalePrice =
    parseFloat(item.sale_price) * parseInt(item.quantity_data);
  const calculatedMrp = parseFloat(item.mrp) * parseInt(item.quantity_data);

  // Set the values in the state
  // Using useEffect to update the state when the component mounts or when the item changes
  useEffect(() => {
    setSalePrice(calculatedSalePrice);
    setMrp(calculatedMrp);
  }, [item]);
  return (
    <>
      {/* quantity_data */}
      {item?.quantity_data > 0 ? (
        // <div className="d-flex  pb-2 justify-content-around">
        //   <div className="left-info mx-3 m-0" style={{ width: "56%" }}>
        //     <p className="p-0 m-0">{item?.name}</p>
        //     <p className="left-info p-0">{problems?.name}</p>
        //   </div>

        //   <div className="mid-info text-center mx-3 mt-2">
        //     <div className="d-flex">
        //       <div className="mid-ico">
        //         <button
        //           className="mid-ico backgroundColor"
        //           onClick={() => {
        //             addToCartProblemMinus(item);
        //             setChangeLocalState(!changeLocalState);
        //           }}
        //         >
        //           -
        //         </button>
        //       </div>
        //       <div className="mid-ico px-1">{item.quantity_data}</div>
        //       <div className="mid-ico">
        //         <button
        //           className="mid-ico backgroundColor"
        //           onClick={() => {
        //             addToCartProblemPlus(item);
        //             setChangeLocalState(!changeLocalState);
        //           }}
        //         >
        //           +
        //         </button>
        //       </div>
        //     </div>
        //     <div className="right-info pt-3">
        //       <div className="d-flex">
        //         <p className="p-0 p-1" style={{ color: "rgb(224, 224, 224)" }}>
        //           ₹{" "}
        //         </p>
        //         <p className="p-0 pt-1 text-success fw-bolder">
        //           {parseFloat(item.sale_price) * parseInt(item.quantity_data)}
        //         </p>
        //       </div>
        //     </div>
        //   </div>
        // </div>
        <div className="d-flex  pb-2 justify-content-around">
          <div className="left-info mx-3 m-0" style={{ width: "56%" }}>
            <p
              className="p-0 ms-4 m-0 mx-4 maxWidthFont"
              style={{ fontSize: "16px" }}
            >
              {item?.name}
            </p>
            <p className="left-info p-0 ms-4 fontDescription">
              {problems?.name}
            </p>

            {/* <p className="left-info p-0">{problems?.name}</p> */}
          </div>

          <div className="mid-info text-center  mx-auto">
            <div className="d-flex">
              <div className="mid-ico">
                <button
                  className="mid-ico backgroundColor"
                  onClick={() => {
                    addToCartProblemMinus(item);
                    setChangeLocalState(!changeLocalState);
                  }}
                >
                  -
                </button>
              </div>
              <div className="mid-ico px-2">{item.quantity_data}</div>

              <div className="mid-ico">
                <button
                  className="mid-ico backgroundColor"
                  onClick={() => {
                    addToCartProblemPlus(item);
                    setChangeLocalState(!changeLocalState);
                  }}
                >
                  +
                </button>
              </div>
            </div>
            <div className="right-info ">
              <div className="d-flex">
                <p className="p-0 p-1" style={{ color: "rgb(224, 224, 224)" }}>
                  ₹{" "}
                </p>
                <p className="p-0 pt-1 text-success fw-bolder">
                  {parseFloat(item.sale_price) * parseInt(item.quantity_data)}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
