import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./booking.css";
import { URLCancel, URLFaqUser, URLOrderDetails } from "../util/UrlConst";
import axios from "axios";
import { Alert, Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Form from "react-bootstrap/Form";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";

export default function Booking() {
  const [active, setActive] = useState();
  const [view, setView] = useState("Active");
  const [faq, setFaq] = useState();
  const [selectedValue, setSelectedValue] = useState();
  const [problem, setProblems] = useState();
  const [canceld, setCanceldId] = useState();
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);

  const getComplaintList = async () => {
    let token = sessionStorage.getItem("token");

    try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      // Make a POST request using Axios
      const res = await axios.post(URLOrderDetails);
      let viewKey;
      if (view == "Active") {
        viewKey = res?.data?.data?.map((complaint) =>
          complaint?.complaintData?.filter(
            (item) => item?.status == 1 || item?.status == 0
          )
        );
      }
      if (view == "Success") {
        viewKey = res?.data?.data?.map((complaint) =>
          complaint?.complaintData?.filter((item) => item?.status == 3)
        );

        // res?.data?.data?.filter((item) => item?.status == 2);
      }
      if (view == "Canceled") {
        viewKey = res?.data?.data?.map((complaint) =>
          complaint?.complaintData?.filter((item) => item?.status == 5)
        );
        // viewKey = res?.data?.data?.filter((item) => item?.status == 5);
      }
      // var activeData = res?.data?.data?.filter((item) => item?.status == viewKey || 1==1)
      // console.log(activeData,'activeDataactiveData')
      var finalData = viewKey?.flat();
      // console.log(finalData, "finalData");
      setActive(finalData);

      // console.log(data,'hellll')
      // var data =
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
    }
  };
  const getFaqList = async () => {
    let token = sessionStorage.getItem("token");

    try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      // Make a POST request using Axios
      const res = await axios.post(URLFaqUser);
      setFaq(res?.data?.data);

      // console.log(data,'hellll')
      // var data =
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
    }
  };
  const handleTextareaChange = (e) => {
    const inputValue = e.target.value;
    setProblems(inputValue);
  };

  useEffect(() => {
    if (show) {
      getFaqList();
    }
  }, [show]);

  useEffect(() => {
    getComplaintList();
  }, [view]);
  const handleClose = () => {
    // setModal(false);
    setShow(false);
    setProblems("");
    setSelectedValue("");
  };

  const handleSelectChange = (e) => {
    const selectedId = e.target.value;
    setSelectedValue(selectedId);

    // You can perform additional actions based on the selected value if needed
  };
  const handleCancleBooking = async () => {
    let token = sessionStorage.getItem("token");

    try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      const urlmain = `${URLCancel}${canceld}&reason=${problem}&faq_id=${selectedValue}`;
      // Make a POST request using Axios
      const res = await axios.post(urlmain);

      if (res.data.error) {
        toast("Booking Cancel not  successfully.", {
          type: "error",
          theme: "dark",
        });
      } else {
        toast("Booking Cancel successfully.", {
          type: "success",
          theme: "dark",
        });
        getComplaintList();
      }
      //  setFaq(res?.data?.data)

      // console.log(data,'hellll')
      // var data =
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>{`The Classy | ${
          view == "Active"
            ? "Active Booking"
            : view == "Success"
            ? "Success Booking"
            : view == "Canceled"
            ? "cancel Booking"
            : "Booking"
        } `}</title>
        <meta
          property="og:description"
          content={`View your${
            view == "Active"
              ? "Active "
              : view == "Success"
              ? "Success "
              : view == "Canceled"
              ? "cancel "
              : "Booking"
          }  booking now on The Classy.`}
        />
      </Helmet>
      <div className="booking-section ">
        <ToastContainer />

        <div className="container">
          <div className="row mt-5 pt-5 mt-lg-0 pt-lg-0 mt-sm-2 pt-sm-2">
            <div className="col-lg-6 mt-3 mt-lg-0 mt-sm-0">
              <div class="active-card">
                <button
                  onClick={() => setView("Active")}
                  className={
                    view == "Active"
                      ? "listLine text-black actives px-3"
                      : "listLine text-black non_actives px-3"
                  }
                >
                  {" "}
                  <span>Active</span>
                </button>
                <button
                  onClick={() => setView("Success")}
                  className={
                    view == "Success"
                      ? "listLine text-black actives px-3"
                      : "listLine text-black non_actives px-3"
                  }
                >
                  {" "}
                  <span>Success</span>
                </button>
                <button
                  onClick={() => setView("Canceled")}
                  className={
                    view == "Canceled"
                      ? "listLine text-black actives px-3"
                      : "listLine text-black non_actives px-3"
                  }
                >
                  {" "}
                  <span>Canceled</span>
                </button>
              </div>
              <br></br>
              {active && active.length > 0 ? (
                active.map((data) => (
                  <div key={data.id}>
                    {data.status === 0 ? (
                      <>
                        <div className="active-view">
                          <Link
                            to={`/bookingDetails/${data?.id}`}
                            style={{ textDecoration: "none" }}
                            onClick={() => {
                              sessionStorage.setItem(
                                "bookingDetailsStatus",
                                data?.status
                              );
                            }}
                          >
                            <div className="button-info">
                              <div className="tok-name maxWidthFont">
                                {data?.status_name}
                              </div>
                              <button className="confirm">
                                {data?.status_name}
                              </button>
                            </div>
                            <div className="button-infor">
                              <div className="product-details  ">
                                <span className=" maxWidthFont">
                                  {" "}
                                  {data?.appliance_type_name}
                                </span>
                                <div className="date-time  ">
                                  {data?.visit_date}, {data?.visit_time}
                                </div>
                              </div>
                              <div
                                style={{ fontWeight: "bold", color: "gray" }}
                                className="price d-block mt-4"
                              >
                                <p
                                  className="p-0 m-0 maxWidthFont fontDescription"
                                  style={{ lineHeight: "1.5" }}
                                >
                                  {" "}
                                  {/* Start at{" "} */}
                                  <span
                                    className="text-success fs-5"
                                    style={{ fontWeight: "600" }}
                                  >
                                    ₹ {parseFloat(data?.total)}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </Link>
                          <div className="canceld-button">
                            <button
                              className="cancel-button"
                              onClick={() => {
                                setShow(true);
                                setCanceldId(data?.id);
                              }}
                            >
                              Cancel Booking
                            </button>
                          </div>
                        </div>
                        <br />
                      </>
                    ) : (
                      <>
                        <Link
                          to={`/bookingDetails/${data?.id}`}
                          style={{ textDecoration: "none" }}
                          onClick={() => {
                            sessionStorage.setItem(
                              "bookingDetailsStatus",
                              data?.status
                            );
                          }}
                        >
                          <div className="active-view">
                            <div className="button-info">
                              <div className="tok-name">{data?.order_id}</div>
                              <button className="confirm">
                                {data?.status_name}
                              </button>
                            </div>
                            <div className="button-infor">
                              <div className="product-details">
                                <spna className="maxWidthFont">
                                  {data?.appliance_type_name}
                                </spna>
                                <div className="date-time">
                                  {data?.visit_date}, {data?.visit_time}
                                </div>
                              </div>
                              <div
                                style={{ fontWeight: "bold", color: "gray" }}
                                className="price d-block mt-4"
                              >
                                <p
                                  className="p-0 m-0 maxWidthFont fontDescription"
                                  style={{ lineHeight: "1.5" }}
                                >
                                  <span
                                    className="text-success fs-5"
                                    style={{ fontWeight: "600" }}
                                  >
                                    ₹{parseFloat(data?.sale_price)}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="border mx-4"></div>
                            <div className="product-details px-4">
                              {data?.technicianDetails?.techName}
                              <div className="date-time">
                                {/* <img
                                  style={{ width: "20px" }}
                                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEJ0lEQVR4nO2ZvW8bdRzGf1Q09r357MT3YikTZSgIwdAWCZA6MYBQ/oIKykCHDlUZujcUlQ2pVMAYJFQQUgckhKCxHb/cxU7sOunZvoiKdkCZUFGGVlS0gd/vQXcXv9upae4SG/mRnsny3ef5+vHd92xCJppoosAEW3sVdf0EGUfB0lTY2kPXlqaScRPq2jxsHag71ubJOAm/Ph+Crf+OuubAAzX1HoqzHBkXwU6cacHvuKp+QMZBAHkGNXWjCV5THXjAUm87r5FRF6r6221Tb8ADluL4LTLqQk1L9YW/pQDr8UUyyoKlvQRLZX3hvQDAuvIKGVXBUr/qqkwXfByozCyQkb1xWcpfPVNvh19zAkw/QlWeIKMmWMrH/SvTBr8243wCji+RURKKsxys+B994RvgLXjg5vQWLE04uOu8reioxY/B0uZgKWdhKdcGVqYd3IP3XJ6+hlLsLMrROazKx1BSdF/uE6grR7Chn4SdOIW6fgE1/Qpq6nVUtQKq6iaq6vbuV5kB8A1wDx4ox4CSZ7YadU1Xotu0KG/SFblAi5HrtBi5goJ0AcuRUyiIJ2HKz+0Ov5H4yF3AWktY9zrQfWMatjKd4H3g2UrDMlhxx4WI5+WGJcAQLw4OYOunUddZE757Heg39eEr0wHeCy+3wHvgJTBTAjUkBlN67wkV0t9FXdvumXoAlWH94Bvg3fCm9A8M8cxw34Oa+iaq2oNRqAxzJy8+RF56Zyj4Zoh1/QSqyr2DrAzz4LdgiG/8J/hWnZQjsOJ3DqIyzIHPi78hKx59KvhmCOf6vB6/5U9l5KEqwzx4G1ludk/wzRC2ImItntyPyjAXXsghFZN9ge94zq3MfBdkZZghOvDfo0i44NaIysynQVSGOfCG+DlADgUC3xGkHLvqZ2WYO3nxauDgzQCl2Cd+VYbtmOaFy/sXoBxb8qMyzHHeM83x6f2BBzmEUvT+XivTghfAcgJoln+Ai/vR/9XpF/dcmXwnPHOc5YGc+ELwAVbk9/2oTBM858G7ATL86cAD0FX5Sz8q44ELTXiW4UGXwl8EH6AYrfhVGbYD7pkDTYdvBgqPLAnTovx44NQ7dhlhC4ZwDlnhHM0JW/0qwxrwS5xrusQ9ds4RXICC9PoT19+8RGle/BoFofmnBkw5RrP8ZzQj/N0JzzXhWdpxGEiFXwsuQFH+cNf11xAzMISXB74/Kx6lGe6nXviw51QYSE6dDywAXZa+7QtuSpsw+d2fVduDpLk5mubudsM7pouhb4ILUJDudj6nin/SvDT/NL1FhRxGeuo8TYfve/AhsGQINDl1JxB4t8dmhDnw1JAoNcUF5Pk9/8aJn/kETU0t0GSIugFuhBh+JDF/qNtPtKgJ1BBvU1PKYTly3PfjJ589Tm9M5eji4V/wA+H9Pv5EE/1f9S9M4cKBWKiMUAAAAABJRU5ErkJggg=="
                                ></img> */}
                                <i
                                  class="fa-solid fa-star"
                                  style={{ color: "gold" }}
                                ></i>
                                <span
                                  className="ms-2"
                                  style={{ color: "gold" }}
                                >
                                  {" "}
                                  {data?.technicianDetails?.rating}{" "}
                                </span>
                                <span className="mx-5">
                                  {data?.technicianDetails?.total_rating_count}{" "}
                                  Ratings
                                </span>
                              </div>
                            </div>
                          </div>
                        </Link>
                        <br />
                      </>
                    )}
                  </div>
                ))
              ) : (
                <div>No bookings available.</div>
              )}
            </div>

            <div className="col-lg-6">
              <div className="right-imagess">
                <div className="right-img1">
                  <img
                    src="https://img.freepik.com/free-vector/professional-cleaning-service-isometric-banners_1284-21788.jpg?w=740&t=st=1701498690~exp=1701499290~hmac=ec02c852d7126c2e2351640cc405f55f6d1c3f15da04b6c2e1ff2e67b5824822"
                    alt="..."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Modal
        Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="category-modal"
        show={show}
        onHide={handleClose}
        className="main_model p-0 px-3"
        dialogClassName="main_model"
      >
        <Modal.Header
          className="modelHeder"
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            padding: "15px",
            border: "none",
            bottom: "50px",
          }}
        >
          <div className="modalCloseButton cursor-pointer">
            <span
              // className="ms-4"
              style={{ margin: "3px 8px" }}
              onClick={() => handleClose()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-x"
                style={{ margin: "3px -3px", color: "gray" }}
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </span>
          </div>
        </Modal.Header>
        <div
          style={{
            position: "absolute",
            width: "100%",
            // height: "100%",
            borderBottom: "2px solid #E3E3E3" /* Adjust border properties */,
            boxSizing:
              "border-box" /* Ensures the border width is included in the div's total width */,
          }}
        >
          <h3
            className="m-0 model_heading"
            style={{ marginLeft: "10px", padding: "12px ", fontWeight: "bold" }}
          >
            {/* {subCategoryTitle} */}
          </h3>
        </div>
        {/* <Modal.Title>{problemData?.name}</Modal.Title> */}
        <Modal.Body className="m-0 model_body">
          <Form.Select
            style={{ fontFamily: "Serif, Sans-serif" }}
            aria-label="Default select example"
            onChange={handleSelectChange}
            value={selectedValue}
          >
            <option>Why do you want to cancel this booking?</option>
            {faq?.map((option, index) => (
              <option
                key={index}
                style={{ fontFamily: "Serif, Sans-serif" }}
                value={option?.id}
              >
                {option?.description}
              </option>
            ))}
          </Form.Select>
          <Form className="p-0 mt-2">
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Control
                onChange={handleTextareaChange}
                style={{ fontFamily: "Poppines" }}
                as="textarea"
                placeholder="What's Problem"
                rows={3}
                value={problem}
              />
              {/* {error && (
                <Alert className="p-1 mt-1" variant="danger">
                  {error}
                </Alert>
              )} */}
            </Form.Group>
          </Form>
          <div className="d-flex justify-content-end">
            <Button
              disabled={selectedValue ? false : true}
              style={{ fontFamily: "Poppines" }}
              className="w-auto"
              onClick={(e) => {
                handleCancleBooking();
                handleClose();
              }}
              variant="primary"
            >
              Cancel Booking
            </Button>{" "}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
