import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { URLDeleteaddress, URLGetAddress } from "../util/UrlConst";
import AddAddressComponent from "../component/AddAddressComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Profile() {
  const [userId, setUserId] = useState();
  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: "100vh",
  };
  useEffect(() => {
    setUserId(sessionStorage.getItem("UserId"));
  });
  const contentStyle = {
    textAlign: "center",
  };

  const navigate = useNavigate();

  const handleLogOut = () => {
    sessionStorage.removeItem("token");
    navigate("/");
  };

  return (
    <>
      <div className="inResponsiveMargin mb-5 pb-5">
        {/* <Button variant="primary" onClick={() => setShow(true)}> */}
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div style={containerStyle}>
              <div style={contentStyle}>
                {/* <p className="p-0 m-0 fw-bolder">Rio mic</p> */}
                <p className="p-0 pt-2 m-0">
                  <span style={{ fontWeight: "bold" }}>UserId</span> :- {userId}
                </p>

                {/* <button type="button" class="btn btn-outline-primary px-3">
                  {" "}
                  Edit Profile
                </button> */}
              </div>
            </div>
            <div className="mt-1 mx-4">
              <p className="fw-bolder fs-6 ">profile</p>
            </div>
            <div className="mx-4">
              <a
                href="https://play.google.com/store/apps/details?id=com.theclassy.in"
                className="listLine text-black"
              >
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <i class="fa-solid fa-handshake-angle"></i>

                    <span class="ml-2">Register as a partner</span>
                  </div>
                  <i class="fa-solid fa-chevron-right"></i>
                </div>
              </a>
              <hr className="" style={{ color: "gray" }}></hr>
              <Link
                to="/Booking"
                className="textDecoration"
                style={{ color: "#212529" }}
              >
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <img
                      width="20"
                      height="20"
                      // class="ms-2"
                      src="https://img.icons8.com/material-rounded/24/000000/event-accepted.png"
                      alt="event-accepted"
                    />

                    <span class="ml-2 ">My Booking</span>
                  </div>
                  <i class="fa-solid fa-chevron-right"></i>
                </div>
              </Link>
              <hr style={{ color: "gray" }}></hr>

              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <i class="fa-solid fa-cart-shopping"></i>
                  <span class="ml-2">Check out</span>
                </div>
                <i class="fa-solid fa-chevron-right"></i>
              </div>
              <hr style={{ color: "gray" }}></hr>

              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <i class="fa-solid fa-share-nodes"></i>
                  <span class="ml-2">Share</span>
                </div>
                <i class="fa-solid fa-chevron-right"></i>
              </div>
              <hr style={{ color: "gray" }}></hr>
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <i class="fa-regular fa-star"></i>
                  <span class="ml-2">Rate us</span>
                </div>
                <i class="fa-solid fa-chevron-right"></i>
              </div>
              <hr style={{ color: "gray" }}></hr>

              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <i class="fa-solid fa-person-circle-question"></i>
                  <span class="ml-2">Help center</span>
                </div>
                <i class="fa-solid fa-chevron-right"></i>
              </div>
              <hr style={{ color: "gray" }}></hr>
              <Link
                to="/privacy"
                className="textDecoration"
                style={{ color: "#212529" }}
              >
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <i class="fa-solid fa-user-lock"></i>
                    <span class="ml-2">Privacy Policy</span>
                  </div>
                  <i class="fa-solid fa-chevron-right"></i>
                </div>
              </Link>
              <hr style={{ color: "gray" }}></hr>
            </div>
            <div className="mx-5">
              <button
                type="button"
                onClick={() => handleLogOut()}
                class="btn btn-outline-primary w-100 "
              >
                Logout
              </button>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </>
  );
}
