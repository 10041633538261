import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  URLCartQunatityUpdate,
  URLDeleteCartitem,
  URLProductImage,
} from "../util/UrlConst";
import { UrlHistoryContext } from "../context/UrlHistoryProvider";
import Timeschedule from "../pages/components/Timeschedule";

export default function CartServiceComponent(props) {
  const [cartQuantity, setCartQuantity] = useState("00");
  const [cartQuantitys, setCartQuantitys] = useState("");

  const { changeLocalState, setChangeLocalState, salePrice, mrp } =
    useContext(UrlHistoryContext);

  const [isprogress, setProgress] = useState(false);

  const user = JSON.parse(sessionStorage.getItem("user"));

  const addToCart = (product) => {
    const existingCart = JSON.parse(sessionStorage.getItem("cart")) || [];
    const existingProduct = existingCart?.find(
      (item) => item.id === product.id
    );

    if (existingProduct !== undefined) {
      existingProduct.quantity_data = (existingProduct.quantity_data || 0) + 1;
    } else {
      existingCart.push({ ...product, quantity_data: 1 });
    }

    sessionStorage.setItem("cart", JSON.stringify(existingCart));

    setCartQuantity(existingProduct?.quantity_data);
    setCartQuantitys(existingProduct?.quantity_data);
  };

  const removeFromCart = (product) => {
    const existingCart = JSON.parse(sessionStorage.getItem("cart")) || [];
    const existingProductIndex = existingCart.findIndex(
      (item) => item.id === product.id
    );

    if (
      existingProductIndex !== -1 &&
      existingCart[existingProductIndex].quantity_data > 1
    ) {
      existingCart[existingProductIndex].quantity_data -= 1;
      setCartQuantity(existingCart[existingProductIndex].quantity_data);
      setCartQuantitys(existingCart[existingProductIndex].quantity_data);
    } else {
      existingCart.splice(existingProductIndex, 1);
      setCartQuantity(0);
    }
    sessionStorage.setItem("cart", JSON.stringify(existingCart));
  };

  useEffect(() => {
    // debugger;
    const existingCart = JSON.parse(sessionStorage.getItem("cart"));
    if (existingCart != []) {
      var problemData = existingCart?.filter((dt) => dt?.id == props?.data?.id);
      // problemData
      let hasProblemsKey = problemData?.some(
        (obj) => "problems" in obj && obj.problems.length > 0
      );

      if (hasProblemsKey) {
        var sum = problemData[0]?.problems.reduce(
          (acc, problem) => acc + parseInt(problem.quantity_data),
          0
        );
        setCartQuantity(sum);
      } else {
        const getProduct = existingCart?.find(
          (item) => item.id === props.data.id
        );
        setCartQuantity(getProduct?.quantity_data);
      }
    }
  }, [changeLocalState]);

  // let incNum = (productid,qty) => async (event) => {
  //     setProgress(true);
  //     await axios.post(URLCartQunatityUpdate, { id: productid, quantity : qty }).then(res => {
  //         console.log(productid);
  //         props.cartfunction();
  //         setTimeout(() => {
  //             setProgress(false);
  //         }, 1000);

  //     });;

  // };
  // let decNum = (productid,qty) => async (event) => {
  //     setProgress(true);
  //     await axios.post(URLCartQunatityUpdate, { id: productid, quantity : qty }).then(res => {
  //         console.log(res);
  //         props.cartfunction();
  //         setTimeout(() => {
  //             setProgress(false);
  //         }, 1000);

  //     });
  // }

  // const deleteitem = (productid) => async (e) => {

  //     await axios.post(URLDeleteCartitem, { id: productid}).then(res => {
  //         props.cartfunction();

  //         toast(res.data.message, { type: "success", theme: "dark" });
  //     });
  // }
  const [close, setClose] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState({
    date: null,
    time: null,
  });

  const handleDateTime = (date, time) => {
    setSelectedDateTime({ date, time });
  };

  return (
    <>
      <Timeschedule
        props={close}
        id={props?.data?.id}
        setClose={setClose}
        selectedDateTime={selectedDateTime}
      />

      <div className="right-card d-none d-lg-block d-md-block pb-2">
        <div className="pt-1 pb-2">
          <>
            <div className="d-flex pb-1 p-2 justify-content-between">
              <div
                className="left-info mx-3 pt-1"
                style={{
                  width: "56%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <span className="fw-bold maxWidthFont">
                  {" "}
                  {props?.data?.name}
                </span>
              </div>

              <div className="mid-info text-center  d-flex ms-auto">
                <div className="mid-ico">
                  <button
                    className="mid-ico backgroundColor fw-bolder"
                    onClick={() => {
                      removeFromCart(props?.data);
                      setChangeLocalState(!changeLocalState);
                    }}
                  >
                    -
                  </button>
                </div>
                <div className="mid-ico px-1">{cartQuantity}</div>
                {/* <div className="mid-ico px-1">{cartQuantitys}</div> */}
                <div className="mid-ico">
                  <button
                    className="mid-ico backgroundColor fw-bolder"
                    onClick={() => {
                      addToCart(props?.data);
                      setChangeLocalState(!changeLocalState);
                    }}
                  >
                    +
                  </button>
                </div>
              </div>

              <div className="right-info" style={{ marginLeft: "7px" }}>
                <div className="fw-semibold " style={{ color: "#4D9C67" }}>
                  ₹ {parseFloat(props?.data?.sale_price)}
                </div>
              </div>
            </div>
          </>
        </div>
        {/* <button>hello</button> */}

        {/* == */}
        {props?.data?.time && props?.data?.date ? (
          <div className="p-1 ms-4">
            <button
              onClick={() => {
                setClose(true);
                handleDateTime(props?.data?.date, props?.data?.time);
              }}
              style={{
                background: "#ffff",
                border: "1px solid blue",
                color: "#1479e5",
              }}
              className=" py-2 rounded-3 "
            >
              {props?.data?.date} {props?.data?.time}
              <i class="fas fa-edit ms-5"></i>
            </button>
          </div>
        ) : (
          <div className=" px-4">
            <button
              style={{
                background: "#ffff",
                border: "1px solid blue",
                color: "#1479e5",
              }}
              className="px-lg-5  px-md-1 py-2 rounded-3 "
              onClick={() => setClose(true)}
            >
              Select time and date
            </button>
          </div>
        )}
      </div>

      {/* </div> */}

      {/* === */}
      <div
        className="d-block d-md-none  d-lg-none mb-2"
        // style={{ borderBottom: "3px solid rgb(221, 221, 219)" }}
      >
        <div className="d-flex justify-content-between  pb-2">
          {/* <div
            style={{ fontWeight: "bold", color: "black" }}
            className=" fw-bold "
          >
            {props?.data?.name}
          </div> */}
          <div
            className="left-info  pt-1"
            style={{
              // width: "56%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <span className="fw-bold maxWidthFont"> {props?.data?.name}</span>
          </div>
          <div className="justify-content-end">
            <p
              style={{ fontWeight: "bold", color: "#4D9C67" }}
              className=" price p-0 m-0 fs-6 ms-4"
            >
              ₹{" "}
              <span className="prices ">
                {parseFloat(props?.data?.sale_price)}
              </span>
            </p>
            <div className="mid-info text-center mt-2 d-flex p-0 m-0 ms-3">
              <div className="mid-ico">
                <button
                  className="mid-ico backgroundColor fw-bolder"
                  onClick={() => {
                    removeFromCart(props?.data);
                    setChangeLocalState(!changeLocalState);
                  }}
                >
                  -
                </button>
              </div>
              <div className="mid-ico ps-1">{cartQuantity}</div>
              {/* <div className="mid-ico ps-1">{cartQuantitys}</div> */}
              <div className="mid-ico ps-1">
                <button
                  className="mid-ico backgroundColor fw-bolder "
                  onClick={() => {
                    addToCart(props?.data);
                    setChangeLocalState(!changeLocalState);
                  }}
                >
                  +
                </button>
              </div>
            </div>
          </div>
        </div>

        {props?.data?.time && props?.data?.date ? (
          <div
            style={{
              border: "2px solid #F3F3F3",
              color: "#1479e5",
              background: "white",
            }}
            className="  rounded-3"
          >
            <div className="d-flex justify-content-center p-1 ms-4 ">
              {props?.data?.date} {props?.data?.time}
              <button
                style={{
                  // border: "2px solid #F3F3F3",
                  color: "#1479e5",
                  background: "white",
                }}
                className=" ms-2  rounded-3"
                onClick={() => {
                  setClose(true);
                  handleDateTime(props?.data?.date, props?.data?.time);
                }}
              >
                {" "}
                Edit{" "}
              </button>
            </div>
          </div>
        ) : (
          <div className="p-1 ">
            <button
              style={{
                border: "2px solid #F3F3F3",
                color: "#1479e5",
                background: "white",
              }}
              className="w-100 rounded-3 py-2"
              onClick={() => setClose(true)}
            >
              Select Date and Time
            </button>
          </div>
        )}
        <div className=""></div>
      </div>
    </>
  );
}
