// import axios from "axios";
// // eslint-disable-next-line no-unused-vars
// import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import { URLAddToCart } from "../util/UrlConst";

// const user = JSON.parse(localStorage.getItem("user"));

// export default async function CartAction(productid, quantity) {

//   if (user === null) {
//     toast("Login first.", { type: "error", theme: "dark" });
//     window.location.href = "/login";
//   } else {
//     return await axios.post(URLAddToCart, { valetuserid: user.id, productid: productid, quantity: quantity, type: 'service' }).then(res => {

//       if (res.data.error) {
//         toast(res.data.message, { type: "error", theme: "dark" })

//       } else {
//         toast(res.data.message, { type: "success", theme: "dark" })
//       }

//     })
//   }

// }

// export async function AddProbleInCart(productid, quantity) {

//   if (user === null) {
//     toast("Login first.", { type: "error", theme: "dark" });
//     window.location.href = "/login";
//   } else {
//     return await axios.post(URLAddToCart, { valetuserid: user.id, productid: productid, quantity: quantity, type: 'problem' }).then(res => {

//       if (res.data.error) {
//         toast(res.data.message, { type: "error", theme: "dark" })

//       } else {
//         toast(res.data.message, { type: "success", theme: "dark" })
//       }

//     })
//   }

// }

import axios from "axios";
// eslint-disable-next-line no-unused-vars
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { URLAddToCart } from "../util/UrlConst";

const user = JSON.parse(sessionStorage.getItem("user"));

let token = sessionStorage.getItem("token");

export default async function CartAction(productid, quantity) {
  // if (user === null) {
  //   toast("Login first.", { type: "error", theme: "dark" });
  //   window.location.href = "/login";
  // } else {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return await axios
    .post(URLAddToCart, { appliance_type_id: productid, quantity: quantity })
    .then((res) => {
      if (res.data.error) {
        toast(res.data.message, { type: "error", theme: "dark" });
      } else {
        toast(res.data.message, { type: "success", theme: "dark" });
      }
    });
  // }
}

export async function AddProbleInCart(productid, quantity, problem) {
  // if (user === null) {
  //   toast("Login first.", { type: "error", theme: "dark" });
  //   window.location.href = "/login";
  // } else {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return await axios
    .post(URLAddToCart, {
      appliance_type_id: productid,
      problem_id: problem,
      quantity: quantity,
    })
    .then((res) => {
      if (res.data.error) {
        toast(res.data.message, { type: "error", theme: "dark" });
      } else {
        toast(res.data.message, { type: "success", theme: "dark" });
      }
    });
  // }
}
