// export const publicUrl = "https://valet2.venturingdigitally.com/";
export const publicUrl = "https://admin.theclassy.in/";
const ApiUrl = publicUrl + "api/user/v1/";
// const ApiUrl = publicUrl + "api/user/v1/";
const TestAPI = "https://test.theclassy.in/api/user/v1/";

export const URLLogin = ApiUrl + "login";
export const URLHomePage = ApiUrl + "blog/all";

export const URLOtpVerify = ApiUrl + "otpVarify";
export const URLSignUp = ApiUrl + "signup";
export const URLhome = ApiUrl + "home";
export const URLApplianceDetails = ApiUrl + "getApplianceByCategoryId";
// export const URLApplianceTypeDetails = ApiUrl + "getServiceTypesByApplianceId";
export const URLApplianceTypeDetails =
  ApiUrl + "getServiceTypesByApplianceId?appliance_id=";
export const URLApplianceTypeDetailsNew =
  ApiUrl +
  // TestAPI +
  "getApplinceTypesByApplianceId?appliance_id=";
export const URLSliderDetails =
  ApiUrl + "slider/getApplianceTypebySliderId?slider_id=";

// export const URLGetApplianceType = ApiUrl + "getApplianceTypeById";
export const URLGetApplianceType =
  // TestAPI +
  ApiUrl + "getApplianceTypeById";

export const URLGetPriceList =
  ApiUrl + "getPriceListPartsByApplianceId?appliance_type_id=";
export const URLSearchProduct = ApiUrl + "search";
export const URLDateTime =
  ApiUrl + "order/getTimeSlotsByApplianceTypeId?appliance_type_id=";
export const URLFaqList = ApiUrl + "helpFaqList?appliance_id=";
export const URLaddress = ApiUrl + "address/addAddress?";
export const URLSubmit = ApiUrl + "order/submitOrder?";
export const URLgetComlain = ApiUrl + "order/getComplaintList";
export const URLFaqUser = ApiUrl + "getUserFaq";
export const URLCancel =
  ApiUrl + "order/cancelComplaintItem?complaint_data_id=";
export const URLOfferList = ApiUrl + "getOfferList";
export const URLCouponId = ApiUrl + "applyCouponCode?code=";
// https://admin.theclassy.in/api/user/v1/applyCouponCode?code=OFFER20&cart_data
export const URLPayment = ApiUrl + "payment?";
export const URLCheckArea =
  ApiUrl + "address/checkServiceableAreaByPincode?pincode=";
export const URLRevisit =
  ApiUrl + "revisit/revsitBookingTechAttendence?complaint_data_id=";
export const URLRevisitBooking =
  ApiUrl + "revisit/revisitBooking?complaint_data_id=";

export const URLComplaintListDetailsDetails =
  ApiUrl + "order/getComplaintItemDataById?complaint_data_id=";
export const URLReScheduleBookingSlot =
  ApiUrl + "order/rescheduleComplaintItem?complaint_data_id=";

export const URLSuccessComplaintFeedbackOrder =
  ApiUrl + "order/complaintFeedback?complaint_data_id=";
export const URLAccountRemove = ApiUrl + "deleteUserAccount";
export const URLGetPrivacy = ApiUrl + "getPrivacy";
export const URLReview =
  ApiUrl + "feedback/getFeedbackByApplianceTypeId?appliance_type_id=";
//  https://admin.theclassy.in/api/user/v1/services
export const keywordsURL = ApiUrl + "services";
export const URLBlogDetails = ApiUrl + "blog/details";

// ==== old
export const URLLoginUser = ApiUrl + "login";
export const URLotpVarify = ApiUrl + "otpVarify";
export const URLCategoryDetails =
  ApiUrl + "getServiceTypesByApplianceId?appliance_id=";
export const URLServiceDetails = ApiUrl + "singleservice";
export const URLSendOtp = ApiUrl + "sendotpphone";
export const URLresigter = ApiUrl + "userregister";
export const URLUserDetails = ApiUrl + "valetuser";
export const URLAddToCart = ApiUrl + "cart/addToCart";
export const URLGetCart = ApiUrl + "cart/getCartItems";
export const URLCartQunatityUpdate = ApiUrl + "cart/updateQuantityToCart";
export const URLDeleteCartitem = ApiUrl + "cart/deleteCartItem";
export const URLPaymentdata = ApiUrl + "web/payment/encryptpaymentdata";
export const URLAddAddress = ApiUrl + "addaddress";
export const URLGetAddress = ApiUrl + "getaddress";
export const URLDeleteaddress = ApiUrl + "deleteaddress";
export const URLCartTimeSlot = ApiUrl + "timeschedule";
export const URLSubmitOrder = ApiUrl + "submitOrderData";
export const URLOrderData = ApiUrl + "getOrderData";
export const URLCancelOrder = ApiUrl + "productcancel";
export const URLOrderDetails = ApiUrl + "order/getComplaintList";

//!------------------ Assets URl ---------------------------

export const URLSliderImage = publicUrl + "public/uploads/offer/";
export const URLCategoryImage = publicUrl + "public/uploads/category/";
export const URLSubCategoryImage = publicUrl + "public/uploads/subcategory/";
export const URLBrandsImage = publicUrl + "public/uploads/brands/";
export const URLOffersImage = publicUrl + "public/uploads/offer/";
export const URLProductImage = publicUrl + "public/uploads/products/";
export const URLSubSubCategoryImage =
  publicUrl + "public/uploads/subsubcategory/";
