import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import ProductComponent from '../component/ProductComponent';
import { URLHomePage, URLSearchProduct } from '../util/UrlConst';

export default function ExploreAll() {

    const { type } = useParams();
    const { id } = useParams();

    const [products, setProudcts] = useState();

    const [isloadin, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0)
        getData();


        async function getData() {

            await axios.post(URLHomePage).then(data => {

                if (type === "repair") {
                    setProudcts(data.data.allservice);
                } else if (type === "most-booked") {
                    setProudcts(data.data.mostbook);
                } else if (type === "top-pics") {
                    setProudcts(data.data.toppicks);
                }

                setTimeout(() => {
                    setLoading(false);
                }, 1000);

            });

            if (type === "search") {
                await axios.post(URLSearchProduct + id).then(res => {

                    setProudcts(res.data.Search);
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);

                });
            }


        }

    }, [])

    return (
        <>

            {/* {{-- ===========================================
                                BREADCRUMB
            =========================================== --}} */}
            <div className="banner-inner-area section-bg-3 padding-top-100 padding-bottom-30">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="banner-inner-contents">
                                <h2 className="banner-inner-title mb-0"> Services </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* {{-- ===========================================
                                EXPLORE ALL
            =========================================== --}} */}
            <section className="explore-all padding-top-40 padding-bottom-40">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-6">

                            <div className="product-list">
                                {products ? products.map(data =>

                                    <ProductComponent product={data} />

                                ) :
                                    <>

                                        {isloadin ? <center><h4>Loading....</h4></center> : <h5> No product found</h5>}
                                    </>

                                }
                            </div>

                        </div>

                         <div className="col-lg-6">
                            <div className="right-static">
                                <div className="main-head">
                                    Our Expertise
                                </div>
                                <div className="list">
                                    <div className="list-item">
                                        <div className="icon">
                                            <i className="las la-tools"></i>
                                        </div>
                                        <div className="content">
                                            <div className="title">
                                                Experienced Professionals
                                            </div>
                                            <div className="data">
                                                Our team of technicians are highly experienced and have been trained to provide efficient and effective repair, installation and uninstallation services.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="list-item">
                                        <div className="icon">
                                            <i className="las la-tools"></i>
                                        </div>
                                        <div className="content">
                                            <div className="title">
                                                Timely Service
                                            </div>
                                            <div className="data">
                                                We understand the importance of time and offer quick and prompt services to our customers. We ensure that our technicians reach your doorstep within the given time frame.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="list-item">
                                        <div className="icon">
                                            <i className="las la-tools"></i>
                                        </div>
                                        <div className="content">
                                            <div className="title">
                                                Affordable Pricing
                                            </div>
                                            <div className="data">
                                                Our services are competitively priced and we offer a range of packages to suit your budget. We believe in providing quality services at reasonable rates.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="list-item">
                                        <div className="icon">
                                            <i className="las la-tools"></i>
                                        </div>
                                        <div className="content">
                                            <div className="title">
                                                Quality Workmanship
                                            </div>
                                            <div className="data">
                                                We use only the best quality spare parts and tools for repairs and installations. Our technicians take pride in their work and ensure that the job is done right the first time.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="list-item">
                                        <div className="icon">
                                            <i className="las la-tools"></i>
                                        </div>
                                        <div className="content">
                                            <div className="title">
                                                Customer Satisfaction
                                            </div>
                                            <div className="data">
                                                Our top priority is customer satisfaction. We strive to provide an exceptional service experience to our customers and ensure that all their queries and concerns are addressed promptly.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                         </div> 

                    </div>
                </div>
            </section> 

        </>
    )
}
