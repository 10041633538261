import React from 'react'

export default function Terms() {
    const listItemStyle = {
        listStyleType: 'disc',
        marginBottom: '10px',
    
        // Add more style properties as needed
      };
    return (
        <>

            <div className="banner-inner-area section-bg-3 padding-top-100 padding-bottom-30">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="banner-inner-contents">
                                <h2 className="banner-inner-title mb-0 text-black"> Terms and Conditions of Service for The Classy - Volet Home Services Pvt. Ltd. </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="policies" data-padding-top="40" data-padding-bottom="40">
                <div className="container">

                    <div className='data'>
                    Welcome to The Classy! We appreciate your trust in us for your home and office maintenance needs. To ensure a smooth and transparent service experience, please take a moment to read our terms and conditions:
                    </div>

                    {/* <div className='data'>
                        It also describes the choices available to you regarding our use of your
                        Personal Information and how you can access and update this information.
                        This Policy is incorporated into and subject to the the classy Terms of Use,
                        which can be accessed at www.theclassy.in/terms
                    </div> */}



                    <div className='head'>1. Scope of Services:</div>

                    <div className='data'>
                        <ol>
                            <li style={listItemStyle}>
                            The Classy provides home and office maintenance services, including but not limited to appliances, plumbing, carpentry, pest control, and cleaning services.
                            </li>
                            <li style={listItemStyle}>
                            Our services are available in 10+ cities in Madhya Pradesh.
                            </li>
                        </ol>
                    </div>



                    <div className='head'>2. Booking and Scheduling:</div>

                    <div className="list">
                    <ol>
                            <li style={listItemStyle} >
                            Bookings can be made through our website, mobile app, or customer service.
                            </li>
                            <li style={listItemStyle}>
                            We aim to provide efficient scheduling, and our skilled technicians will arrive promptly at the scheduled time.
                            </li>
                        </ol>
                    </div>



                    <div className='head'>3. Pricing and Payment:</div>

                    <div className="list">
                    <ol>
                            <li style={listItemStyle} >
                            Our pricing is transparent and is communicated upfront before service initiation.
                            </li>
                            <li style={listItemStyle}>
                            Payment can be made online or through other specified payment methods upon completion of the service.

                            </li>
                        </ol>
                    </div>



                    <div className='head'>4. Cancellation and Rescheduling:</div>

                    <div className="list">
                    <ol>
                            <li style={listItemStyle} >
                            Cancellations and rescheduling can be done through our website, mobile app, or by contacting our customer service.

                            </li>
                            <li style={listItemStyle}>
                            We kindly request cancellations or rescheduling to be made at least 24 hours before the scheduled service time.

                            </li>
                        </ol>
                    </div>



                    <div className='head'>5. Quality Assurance:</div>

                    <div className="list">
                    <ol>
                            <li style={listItemStyle} >
                            We are committed to delivering high-quality service using skilled technicians, quality tools, and industry-standard practices.

                            </li>
                            <li style={listItemStyle}>
                            If you are dissatisfied with the service, please contact our customer service within 48 hours, and we will work to address your concerns.

                            </li>
                        </ol>
                    </div>



                    <div className="head">6. Customer Responsibility:</div>

                    <div className="list">
                    <ol>
                            <li style={listItemStyle} >
                            Provide accurate information about the service requirements during booking.

                            </li>
                            <li style={listItemStyle}>
                            Ensure a safe and accessible working environment for our technicians.


                            </li>
                            <li style={listItemStyle}>
                            Report any damages or issues promptly after service completion.


                            </li>
                        </ol>
                    </div>



                    <div className="head">7. Liability:</div>

                    <div className="list">
                    <ol>
                            <li style={listItemStyle} >
                            The Classy is not liable for pre-existing issues or damages not reported during the booking process.
                            </li>
                            <li style={listItemStyle}>
                            We strive to handle your property with care; however, any accidental damage should be reported immediately.

                            </li>
                           
                        </ol>
                    </div>



                    <div className="head">8. Confidentiality:</div>

                    <ol>
                            <li style={listItemStyle} >
                            Any personal information provided during the booking process is handled with utmost confidentiality and is used solely for service-related purposes.
                            </li>
                           
                           
                        </ol>



                    <div className="head">9. Governing Law:</div>

                    <ol>
                            <li style={listItemStyle} >
                            These terms and conditions are governed by the laws of the jurisdiction in which the service is provided.
                            </li>
                           
                           
                        </ol>



                    <div className="head">10. Amendments:

</div>

                    <div className="data">
                    The Classy reserves the right to update and amend these terms and conditions. Any changes will be communicated through our website or mobile app.
                    </div>



                    <div className="head">11. Contact Information:</div>

                    <ol>
                            <li style={listItemStyle} >
                            For any inquiries, concerns, or feedback, please contact our customer service at  <a className="text-decoration-none text-primary"href="mailto:info@valetgroups.com">info@valetgroups.com.</a>
                            </li>
                           
                           
                           
                        </ol>

                         <span>   Thank you for choosing The Classy. We look forward to serving you and providing a classier maintenance experience for your home or office!
                         </span>
                </div>
            </section>

        </>
    )
}
