import React, { useContext, useEffect, useState } from "react";
import { UrlHistoryContext } from "../../context/UrlHistoryProvider";
import { Modal } from "bootstrap";

export default function Ordersummary() {
  const { setTimeDate, timeDate } = useContext(UrlHistoryContext);
  const modalInstance = new Modal();
  // console.log(props, "propspropsprops");

  const handleClose = () => {
    setTimeDate(false);
    // setShow(false);
  };
  return (
    <>
      {/* ===========================active============================== */}
      <Modal
        Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="category-modal"
        // show={model}
        onHide={handleClose}
        className="main_model"
        dialogClassName="main_model"
      >
        <Modal.Header
          className="modelHeder"
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            padding: "15px",
            border: "none",
            bottom: "50px",
          }}
        >
          <div className="modalCloseButton cursor-pointer">
            <span
              // className="ms-4"
              style={{ margin: "3px 8px" }}
              onClick={() => handleClose()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-x"
                style={{ margin: "3px -3px", color: "gray" }}
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </span>
          </div>
        </Modal.Header>
        <div
          style={{
            position: "absolute",
            width: "100%",
            // height: "100%",
            borderBottom: "2px solid #E3E3E3",
            boxSizing: "border-box",
          }}
        >
          <h3
            className="m-0 model_heading"
            style={{ marginLeft: "10px", padding: "12px ", fontWeight: "bold" }}
          ></h3>
        </div>

        <Modal.Body className="m-0 model_body">
          <div className="service-grid">
            <div className="row">
              <div className="col-sm-6">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="fw-bolder">#203025</div>
                  <div className="phone-icon">
                    <i class="fa-solid fa-phone"></i>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <div
                  className="time-schedule"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="text-dark ">Split AC [Repair]</div>

                  <div className="text-success fw-bold">₹ 350.00</div>
                </div>
                <div className="text-muted">
                  Suited for repair or replacement{" "}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <div
                  className="time-schedule"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="text-primary ">#20325</div>
                  <button type="button" class="btn btn-primary btn-sm ">
                    confirm{" "}
                  </button>
                </div>

                <div className="text-dark"> Shiv Kumar</div>
                <div className="text-body">The Classy Pvt. Ltd.</div>
                <div className="text-muted h6">
                  <i class="fa-solid fa-location-dot"></i>
                  <span className="text-muted h6">Sagar Madhya Pradesh</span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <div
                  className="time-schedule"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="text-primary ">#20325</div>
                  <button type="button" class="btn btn-primary btn-sm ">
                    ReSchedule
                  </button>
                </div>
                <div className="text-dark">Schedule Time </div>
                <div className="text-muted h6">
                  <i class="fa-regular fa-clock"></i>
                  <span>06:00 PM, 06-10-2023</span>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-sm-6">
                <div className="time-schedule">
                  <div className="user-name ">Shiv Kumar</div>
                  <div
                    className="product-img"
                    style={{
                      height: "30px",
                      width: "30px",
                      borderRadius: "8px",
                    }}
                  >
                    <img
                      src="https://img.freepik.com/premium-photo/woman-wearing-mask-gloves-holds-bucket-cleaning-products-blue-bucket-with-thumb-up_646443-2291.jpg"
                      alt="..."
                      style={{
                        height: "30px",
                        width: "30px",
                        borderRadius: "8px",
                      }}
                    />{" "}
                  </div>
                </div>
                <div
                  className="users-ratings"
                  style={{
                    display: "flex",
                    gap: "40px",
                  }}
                >
                  <div className="satrs-icon"></div>
                  <div className="border">4</div>
                  <div className="border">1 Ratings</div>
                </div>
                <div className="border"></div>
                <button type="button" class="btn btn-primary btn-lg w-100">
                  Location Tracking{" "}
                </button>
              </div>
            </div> */}
            {/* <div className="row">
              <div className="col-sm-6">
                <div
                  className="invoice"
                  style={{
                    display: "flex",
                    gap: "40px",
                  }}
                >
                  <div className="mobile-share ">ds</div>
                  <div className="text-dark">Invoice share</div>
                  <div className="text-muted ">
                    <i class="fa-regular fa-clock"></i>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="row">
              <div className="col-sm-6">
                <div className="order-details fw-bolder">Order Summary </div>
                <div
                  className="order-summary"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="text-muted">Service price</div>
                  <div className="text-muted ">₹ 0.00</div>
                </div>
                <div
                  className="order-summary"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="text-muted">Service price</div>
                  <div className="text-muted ">₹ 350.00</div>
                </div>

                <div className="border"></div>
                <div
                  className="order-summary"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="text-muted">Total</div>
                  <div className="text-dark fw-bold ">₹ 350.00</div>

                  <div
                    className="summary text-muted"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    we've sent a copy of this bill to your email id
                  </div>
                  <div
                    className="text-primary"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    support@theclassy.in
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <button type="button" class="btn btn-primary btn-sm ">
                Cancel Booking
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* =============================================success-button=============================== */}
    </>
  );
}
