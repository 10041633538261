import React, { useEffect, useState } from "react";
import { Modal, Alert, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import FileDownload from "js-file-download";

import {
  URLCancel,
  URLComplaintListDetailsDetails,
  URLFaqUser,
  URLOrderDetails,
  URLSuccessComplaintFeedbackOrder,
} from "../util/UrlConst";
import axios from "axios";
import { FaStar } from "react-icons/fa";
import { Container, Radio, Rating } from "./start.js";
import Form from "react-bootstrap/Form";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Timeschedule from "./components/Timeschedule.jsx";
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';

  
const BookingDetails = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });
  const navigate = useNavigate();

  const { id } = useParams();
  const [rate, setRate] = useState(2);
  const [show, setShow] = useState(false);
  const [showFeedBack, setShowFeedBack] = useState(false);
  const [reSchesule, setReSchedule] = useState();
  const [error, setError] = useState("");

  const [faq, setFaq] = useState();
  const [selectedValue, setSelectedValue] = useState();
  const [problem, setProblems] = useState();
  const [canceld, setCanceldId] = useState();

  //   const divStyle = {
  //     boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  //   };
  const [isVisible, setIsVisible] = useState(true);
  const [bookingDetails, setBookingDetail] = useState();
  const [bookingStatus, setStatus] = useState();
  const [feedback, setFeedBack] = useState();

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const handleClose = () => {
    setShow(false);
    setSelectedValue("");
    setProblems("");
  };
  const handleCloseFeedBack = () => {
    // setShow(false);
    // setSelectedValue("");
    // setProblems("");
    setShowFeedBack(false);
  };
  const getComplaintListDetails = async () => {
    let token = sessionStorage.getItem("token");

    try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      // Make a POST request using Axios
      const res = await axios.post(`${URLComplaintListDetailsDetails}${id}`);
      var data = res?.data?.data;
      // console.log(data, "dataaaaaaaaaaaaaaaa");
      setBookingDetail(data);
    } catch (error) {}
  };

  useEffect(() => {
    var status = sessionStorage.getItem("bookingDetailsStatus");
    setStatus(status);
    getComplaintListDetails();
  }, [1]);

  const handleCancleBooking = async () => {
    let token = sessionStorage.getItem("token");

    try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      const urlmain = `${URLCancel}${id}&reason=${problem}&faq_id=${selectedValue}`;
      const res = await axios.post(urlmain);

      if (res.data.error) {
        toast("Booking Cancel not  successfully.", {
          type: "error",
          theme: "dark",
        });
      } else {
        toast("Booking Cancel successfully.", {
          type: "success",
          theme: "dark",
        });
        navigate("/Booking");
      }
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
    }
  };
  const getFaqList = async () => {
    let token = sessionStorage.getItem("token");

    try {
      axios.defaults.headers.common["Authorization"] = token;

      // Make a POST request using Axios
      const res = await axios.post(URLFaqUser);
      setFaq(res?.data?.data);

      // console.log(data,'hellll')
      // var data =
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
    }
  };
  const handleTextareaChange = (e) => {
    const inputValue = e.target.value;

    if (inputValue.length < 25) {
      setError("Input should not exceed 25 characters");
      setProblems(inputValue.slice(0, 25));
    } else {
      setError("");
      setProblems(inputValue);
    }
  };

  useEffect(() => {
    if (show) {
      getFaqList();
    }
  }, [show]);
  const handleSelectChange = (e) => {
    const selectedId = e.target.value;
    setSelectedValue(selectedId);

    // You can perform additional actions based on the selected value if needed
  };
  const [close, setClose] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState({
    date: null,
    time: null,
  });

  const handleDateTime = (date, time) => {
    setSelectedDateTime({ date, time });
  };

  const handlePDFDownloader = (download) => {
    if (download) {
      window.open(download, "Invoice");
    }
  };

  const spanStyle = {
    color: "gray",
    width: "98%",
    fontFamily: "Serif, Sans-serif",
    fontSize: "12px",
    fontWeight: "lighter",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  };
  const PostSuccessComplaintFeedback = async () => {
    let token = sessionStorage.getItem("token");
    handleCloseFeedBack();

    try {
      axios.defaults.headers.common["Authorization"] = token;

      const res = await axios.post(
        `${URLSuccessComplaintFeedbackOrder}${bookingDetails?.id}&feedback=${feedback}&rating=${rate}`
      );
      // var data = res?.data?.data;

      // setBookingDetail(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>{`The Classy | ${bookingDetails?.complaintData?.appliance_type_name} Booking details page `}</title>
        <meta
          property="og:description"
          content={"View your booking details page now on The Classy."}
        />
      </Helmet>
      <Timeschedule
        props={close}
        id={bookingDetails?.complaintData?.appliance_type_id}
        // item={item}
        setClose={setClose}
        selectedDateTime={selectedDateTime}
        Booking={reSchesule}
        complaint_id={bookingDetails?.id}
        // getComplaintListDetails={getComplaintListDetails()}
      />
      <section
        className="category-area inResponsiveMargin mx-0"
        data-padding-top="25"
        data-padding-bottom="100"
      >
        <ToastContainer />

        <div className="row ms-1 ms-lg-5 ms-md-2">
          <div className="col-lg-2"></div>
          <div className="col-lg-4">
            <div
              className=" borderboxBookingDetails mt-2 
                  "
              // style={{
              //   border: "1px solid #dcdcdc7d",
              //   borderRadius: "8px",
              //   paddingLeft: "30px",
              //   paddingRight: "30px",
              //   paddingTop: "10px",
              //   paddingBottom: "30px",
              //   boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              // }}
            >
              <p className="fw-bolder">{bookingDetails?.order_id}</p>
              <div className="d-flex justify-content-between">
                <div className="lineHeight">
                  <p className="p-0 m-0 fs-6 maxWidthFont">
                    {bookingDetails?.complaintData?.appliance_type_name}
                  </p>
                  {/* <span
                    className="d-block"
                    style={{
                      color: "gray",
                      fontFamily: "normal",
                      fontSize: "14px",
                    }}
                  >
                    {bookingDetails?.complaintData?.problem_item[0]?.name}
                  </span> */}
                  <span
                    className=""
                    style={{
                      color: "gray",
                      fontFamily: "Serif, Sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    {/* Suited for repaor or replacment */}
                    Service type of Repair
                  </span>
                  <span
                    className="d-block"
                    style={{
                      color: "black",
                      fontFamily: "normal",
                      fontSize: "14px",
                    }}
                  >
                    <ol className="p-0">
                      {bookingDetails?.complaintData?.problem_item?.map(
                        (item) => (
                          <li className="p-0 m-0 p-1 pt-1 maxWidthFont">
                            {item?.name}
                          </li>
                        )
                      )}
                      {/* <li className="">irmm</li>
                      <li>irmm</li>
                      <li>irmm</li> */}
                    </ol>
                  </span>
                </div>
                <div
                  className="total-price "
                  style={{
                    color: "green",
                  }}
                >
                  ₹ {bookingDetails?.complaintData?.sale_price}
                </div>
              </div>
              <hr></hr>

              <div className="mt-2 bookingDetails">
                <div className="button-info ">
                  <div className="tok-name">{bookingDetails?.order_id}</div>
                  <button
                    className="confirm "
                    style={{ fontFamily: "sans-serif" }}
                  >
                    {bookingDetails?.status_name}
                  </button>
                </div>
                <div className="button-infor">
                  <div className="product-details">
                    {/* {item?.technicianDetails?.techName} */}
                    {bookingDetails?.address?.customer_name}
                    <div
                      className="fw-normal  maxWidthFont"
                      style={{ fontSize: "13px" }}
                    >
                      The Classy Pvt. Ltd.
                    </div>
                    <div style={{ lineHeight: "0.7" }} className="d-flex">
                      <img
                        src="/assets/frontend/img/banner/location.svg"
                        className=""
                        style={{ width: "13px" }}
                      />

                      <p className="p-0 m-0 addressWidth">
                        {bookingDetails?.address?.landmark}
                        {bookingDetails?.address?.address_name}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {bookingStatus !== 3 ? (
                <div className="mt-2 bookingDetails">
                  <div className="button-info ">
                    <div className="tok-name">{bookingDetails?.order_id}</div>
                    {bookingStatus == 0 ? (
                      <button
                        className="confirm"
                        onClick={() => {
                          setClose(true);
                          setReSchedule("ReSchedule");
                          handleDateTime(
                            bookingDetails?.complaintData?.visit_date,
                            bookingDetails?.complaintData?.visit_time
                          );
                        }}
                      >
                        ReSchedule
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="button-infor">
                    <div className="product-details">
                      Schedule Time
                      <div style={{ lineHeight: "0.7" }}>
                        <span
                          className=""
                          style={{
                            color: "gray",
                            fontFamily: "Serif, Sans-serif",
                            fontSize: "12px",
                            fontWeight: "lighter",
                          }}
                        >
                          <img
                            src="/assets/frontend/img/banner/timeslot.svg"
                            className="mb-1"
                            style={{ width: "13px" }}
                          />{" "}
                          {bookingDetails?.complaintData?.visit_time},{" "}
                          {bookingDetails?.complaintData?.visit_date}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* === */}
              {bookingDetails?.technicianDetails != null ? (
                <div className="mt-2 bookingDetails">
                  <div className="product-details px-4">
                    <span className="maxWidthFont">
                      {" "}
                      {bookingDetails?.technicianDetails?.techName}
                    </span>
                    <div className="date-time">
                      {/* <img
                                  style={{ width: "20px" }}
                                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEJ0lEQVR4nO2ZvW8bdRzGf1Q09r357MT3YikTZSgIwdAWCZA6MYBQ/oIKykCHDlUZujcUlQ2pVMAYJFQQUgckhKCxHb/cxU7sOunZvoiKdkCZUFGGVlS0gd/vQXcXv9upae4SG/mRnsny3ef5+vHd92xCJppoosAEW3sVdf0EGUfB0lTY2kPXlqaScRPq2jxsHag71ubJOAm/Ph+Crf+OuubAAzX1HoqzHBkXwU6cacHvuKp+QMZBAHkGNXWjCV5THXjAUm87r5FRF6r6221Tb8ADluL4LTLqQk1L9YW/pQDr8UUyyoKlvQRLZX3hvQDAuvIKGVXBUr/qqkwXfByozCyQkb1xWcpfPVNvh19zAkw/QlWeIKMmWMrH/SvTBr8243wCji+RURKKsxys+B994RvgLXjg5vQWLE04uOu8reioxY/B0uZgKWdhKdcGVqYd3IP3XJ6+hlLsLMrROazKx1BSdF/uE6grR7Chn4SdOIW6fgE1/Qpq6nVUtQKq6iaq6vbuV5kB8A1wDx4ox4CSZ7YadU1Xotu0KG/SFblAi5HrtBi5goJ0AcuRUyiIJ2HKz+0Ov5H4yF3AWktY9zrQfWMatjKd4H3g2UrDMlhxx4WI5+WGJcAQLw4OYOunUddZE757Heg39eEr0wHeCy+3wHvgJTBTAjUkBlN67wkV0t9FXdvumXoAlWH94Bvg3fCm9A8M8cxw34Oa+iaq2oNRqAxzJy8+RF56Zyj4Zoh1/QSqyr2DrAzz4LdgiG/8J/hWnZQjsOJ3DqIyzIHPi78hKx59KvhmCOf6vB6/5U9l5KEqwzx4G1ludk/wzRC2ImItntyPyjAXXsghFZN9ge94zq3MfBdkZZghOvDfo0i44NaIysynQVSGOfCG+DlADgUC3xGkHLvqZ2WYO3nxauDgzQCl2Cd+VYbtmOaFy/sXoBxb8qMyzHHeM83x6f2BBzmEUvT+XivTghfAcgJoln+Ai/vR/9XpF/dcmXwnPHOc5YGc+ELwAVbk9/2oTBM858G7ATL86cAD0FX5Sz8q44ELTXiW4UGXwl8EH6AYrfhVGbYD7pkDTYdvBgqPLAnTovx44NQ7dhlhC4ZwDlnhHM0JW/0qwxrwS5xrusQ9ds4RXICC9PoT19+8RGle/BoFofmnBkw5RrP8ZzQj/N0JzzXhWdpxGEiFXwsuQFH+cNf11xAzMISXB74/Kx6lGe6nXviw51QYSE6dDywAXZa+7QtuSpsw+d2fVduDpLk5mubudsM7pouhb4ILUJDudj6nin/SvDT/NL1FhRxGeuo8TYfve/AhsGQINDl1JxB4t8dmhDnw1JAoNcUF5Pk9/8aJn/kETU0t0GSIugFuhBh+JDF/qNtPtKgJ1BBvU1PKYTly3PfjJ589Tm9M5eji4V/wA+H9Pv5EE/1f9S9M4cKBWKiMUAAAAABJRU5ErkJggg=="
                                ></img> */}
                      <i class="fa-solid fa-star" style={{ color: "gold" }}></i>
                      <span className="ms-2" style={{ color: "gold" }}>
                        {" "}
                        {bookingDetails?.technicianDetails?.rating}{" "}
                      </span>
                      <span className="mx-5">
                        {bookingDetails?.technicianDetails?.total_rating_count}{" "}
                        Ratings
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* == */}
              {/* === Revisit */}
              {
                // bookingDetails?.revisit 3
                bookingDetails?.revisit == "true" ? (
                  <div className="mt-3">
                    <button
                      onClick={() => {
                        setClose(true);
                        // setCanceldId(id);
                        setReSchedule("Revisit");
                      }}
                      type="button"
                      class="btn btn-outline-primary w-100 bg-transparent text-primary rounded-3 py-2"
                    >
                      Revisit Complain
                    </button>
                  </div>
                ) : (
                  ""
                )
              }
              {/* === Revisit */}

              {/* === Invoice  */}
              {bookingStatus == 3 ? (
                <div className="mt-2 bookingDetails">
                  <a
                    // href={bookingDetails?.invoice_link}
                    style={{ textDecoration: "none", color: "black" }}
                    onClick={() =>
                      handlePDFDownloader(bookingDetails?.invoice_link)
                    }
                  >
                    <div className="button-info py-3">
                      <p className="p-0 m-0">
                        <i class="fa-regular fa-file-lines"></i>{" "}
                        <span
                          className="px-3 fs-6"
                          // onClick={() =>
                          //   handlePDFDownloader(bookingDetails?.invoice_link)
                          // }
                        >
                          Invoice View
                        </span>
                      </p>
                      <button className="text-black"></button>
                    </div>
                  </a>
                </div>
              ) : (
                ""
              )}
            </div>

            {/* == */}
            {bookingDetails?.complaintUpdateData != null ? (
              <div
                className="  mt-2 
                  "
                style={{
                  border: "1px solid #dcdcdc7d",
                  borderRadius: "8px",
                  paddingLeft: "30px",
                  paddingRight: "30px",
                  paddingTop: "10px",
                  paddingBottom: "30px",
                  boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                }}
              >
                <div>
                  {/* <p className="">Completed Service</p> */}
                  <p className="p-0 m-0">Technician update service</p>

                  <div className="p-0 m-0 d-flex justify-content-between">
                    <div className="" style={{ lineHeight: "0.3" }}>
                      <p className="p-0 m-0 text-black  maxWidthFont">
                        {" "}
                        {
                          bookingDetails?.complaintUpdateData
                            ?.appliance_type_name
                        }
                      </p>
                      <span
                        className="pt-1"
                        style={{
                          color: "gray",
                          fontFamily: "normal",
                          fontSize: "14px",
                        }}
                      >
                        {/* Suited for repaor or replacment */}
                        Service type of Repair
                      </span>
                    </div>
                    <span className="text-success">
                      ₹ {bookingDetails?.complaintUpdateData?.sale_price}
                    </span>
                  </div>

                  <div>
                    <span
                      className="d-block"
                      style={{
                        color: "black",
                        fontFamily: "normal",
                        fontSize: "14px",
                      }}
                    >
                      <ol className="p-0 pt-1">
                        {bookingDetails?.complaintUpdateData?.problem_item?.map(
                          (item) => (
                            <li className="p-0 m-0 p-1 pt-1">{item?.name}</li>
                          )
                        )}
                        {/* <li className="">irmm</li>
                      <li>irmm</li>
                      <li>irmm</li> */}
                      </ol>
                    </span>
                    {/* <hr></hr> */}

                    <div>
                      {bookingDetails?.complainParts?.map((parts) => (
                        <>
                          <h6 className="fw-bold">Parts Name</h6>
                          <p className="p-0 m-0 text-black">
                            {" "}
                            <div className="p-0 m-0 d-flex justify-content-between">
                              <div className="" style={{ lineHeight: "0.3" }}>
                                <p className="p-0 m-0 text-black">
                                  {parts?.parts_name}
                                </p>
                              </div>
                              <span className="text-success">
                                ₹ {parts?.parts_sale_price}
                              </span>
                            </div>
                          </p>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
                <hr></hr>
              </div>
            ) : (
              ""
            )}
            {/* == */}
            {bookingStatus == 3 ? (
              <div
                className="  mt-2 
                  "
                style={{
                  border: "1px solid #dcdcdc7d",
                  borderRadius: "8px",
                  paddingLeft: "30px",
                  paddingRight: "30px",
                  paddingTop: "10px",
                  paddingBottom: "1px",
                  boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                }}
              >
                <div>
                  {/* <p className="p-0 m-0">Completed Service</p> */}

                  {/* <div className="p-0 m-0 d-flex justify-content-between">
                    <p className="p-0 m-0">
                      {bookingDetails?.complaintUpdateData?.appliance_type_name}
                    </p>
                    <span>
                      ₹ {bookingDetails?.complaintUpdateData?.sale_price}
                    </span>
                  </div> */}
                  {/* <div>
                    <span
                      className="d-block"
                      style={{
                        color: "black",
                        fontFamily: "normal",
                        fontSize: "14px",
                      }}
                    >
                      <ol className="p-0 pt-1">
                        {bookingDetails?.complaintUpdateData?.problem_item?.map(
                          (item) => (
                            <li className="p-0 m-0 p-1 pt-1">{item?.name}</li>
                          )
                        )}
                       
                      </ol>
                    </span>
                  </div> */}
                </div>
                <hr></hr>
                {isVisible ? (
                  <>
                    {/* <div>
                      <p className="p-0 m-0">Use parts</p>

                      <div className="p-0 m-0 d-flex justify-content-between">
                        <span
                          className="d-block"
                          style={{
                            color: "black",
                            fontFamily: "normal",
                            fontSize: "14px",
                          }}
                        >
                          <ol className="p-0 pt-1">
                            {bookingDetails?.complaintUpdateData?.complaint_parts?.map(
                              (item) => (
                                <li className="p-0 m-0 p-1 pt-1">
                                  <span>{item?.parts_name}</span>
                                  <span> {item?.parts_sale_price}</span>
                                </li>
                              )
                            )}
                           
                          </ol>
                        </span>
                      </div>
                      <hr></hr>
                      <div className="p-0 m-0 d-flex justify-content-between">
                        <p className="p-0 m-0"> Total Amount</p>
                        <span>
                          ₹ {bookingDetails?.complaintUpdateData?.total}
                        </span>
                      </div>
                    </div> */}
                    <hr
                      style={{ height: "3px", backgroundColor: "black" }}
                    ></hr>

                    <div>
                      <p>Update Image</p>
                      <div className="d-flex gap-2 justify-content-between">
                        <div className="">
                          <span>After Image</span>
                          <img
                            className=""
                            src={
                              bookingDetails?.complaintUpdateData?.after_image
                            }
                          />
                        </div>
                        <div>
                          <span>Before Image</span>

                          {/* <img src="/assets/frontend/img/banner/location.svg" /> */}
                          <img
                            src={
                              bookingDetails?.complaintUpdateData?.before_image
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <p
                  onClick={toggleVisibility}
                  className="d-flex justify-content-around text-primary"
                >
                  {" "}
                  {isVisible ? "Less details" : "More than"}
                </p>
                {/* <div>
                  <span>Technician update service</span>
                  <div className="p-0 m-0 d-flex justify-content-between">
                    <p className="p-0 m-0"> Window Ac repair</p>
                    <span>₹ 350</span>
                  </div>
                </div> */}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="col-lg-1 d-none d-lg-block"></div>
          <div className="col-lg-4">
            <div
              className="  d-sm-none mt-2 
                  d-lg-block d-md-block"
              style={{
                border: "1px solid #dcdcdc7d",
                borderRadius: "8px",
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingTop: "10px",
                paddingBottom: "30px",
                boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              }}
            >
              <div className="payment-details fw-bold fontFamily">
                Order Summary
              </div>
              <br></br>
              <div
                className="product-bil"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  className="total-amount "
                  style={{ color: "gray", fontFamily: "Serif, Sans-serif" }}
                >
                  Service price
                </div>
                <div
                  className="total-price "
                  style={{
                    color: "black",
                  }}
                >
                  ₹ {bookingDetails?.totalCalculation?.totalSalePrice}
                </div>
              </div>
              <div
                className="product-bil"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  className="coupon-discount"
                  style={{ color: "gray", fontFamily: "normal" }}
                >
                  Parts price
                </div>
                <div
                  className="total-price "
                  style={{
                    color: "black",
                  }}
                >
                  ₹ {bookingDetails?.totalCalculation?.parts}
                  {/* {"00"} */}
                </div>
              </div>
              <hr></hr>
              <div
                className="product-bil"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className="convenience">Total</div>
                <div
                  className="total-price fontFamily"
                  style={{
                    color: "black",
                    fontWeight: "900",
                  }}
                >
                  ₹ {bookingDetails?.totalCalculation?.total}
                </div>
              </div>
              {bookingStatus == 3 ? (
                ""
              ) : (
                <>
                  {/* <div
                    className="product-bil"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="convenience">Pending amount</div>
                    <div
                      className="total-price fontFamily"
                      style={{
                        color: "black",
                        fontWeight: "900",
                      }}
                    >
                      ₹{bookingDetails?.totalCalculation?.pending_amount}
                    </div>
                  </div> */}
                  {bookingStatus == 5 || bookingStatus == 3 ? (
                    // =
                    ""
                  ) : (
                    <>
                      {bookingDetails?.totalCalculation?.payment_status ==
                      "pending" ? (
                        <div
                          className="product-bil gap-3 mt-3"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="convenience ms-auto p-0 m-0 pt-3">
                            <p
                              className="p-0 m-0 pt-2"
                              style={{ lineHeight: "0.3", fontSize: "11px" }}
                            >
                              {" "}
                              Pending amount
                            </p>
                            <span
                              className="total-price fontFamily"
                              style={{
                                color: "black",
                                fontWeight: "700",
                                fontSize: "11px",
                              }}
                            >
                              {" "}
                              ₹
                              {bookingDetails?.totalCalculation?.pending_amount}
                            </span>
                          </div>
                          {/* // = */}
                          <div
                            className="product-bil gap-3 mt-3 justify-content-end"
                            style={{
                              display: "flex",
                            }}
                          >
                            {}
                            <div className="">
                              <button
                                type="button"
                                class="btn btn-primary px-5 fw-normal"
                              >
                                Pay Now
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </>
              )}
              {bookingStatus == 0 ? (
                <div className="mt-3">
                  <button
                    onClick={() => {
                      setShow(true);
                      setCanceldId(id);
                    }}
                    type="button"
                    class="btn btn-outline-primary w-100 bg-transparent text-primary rounded-3 py-2"
                  >
                    cancel Booking
                  </button>
                </div>
              ) : (
                ""
              )}

              <div style={{ textAlign: "center" }}>
                <div className="p-2 pt-2">
                  <span className=" maxWidthFont">
                    we've sent a copy of this bill to your email id
                  </span>
                  <p className="p-0 m-0  maxWidthFont">
                    <a
                      style={{ textDecoration: "none" }}
                      href="mailto:support@theclassy.in"
                    >
                      support@theclassy.in
                    </a>
                  </p>
                </div>
                {bookingStatus == 3 ? (
                  // true
                  <button
                    style={{ textAlign: "center" }}
                    type="button"
                    class="btn btn-primary px-5 fw-normal"
                    onClick={(e) => setShowFeedBack(true)}
                  >
                    Give Feedback
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>

        <Modal
          Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          id="category-modal"
          show={showFeedBack}
          onHide={handleCloseFeedBack}
          className="main_model"
          dialogClassName="main_model"
        >
          <Modal.Header
            className="modelHeder"
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              padding: "15px",
              border: "none",
              bottom: "50px",
            }}
          >
            <div className="modalCloseButton cursor-pointer">
              <span
                // className="ms-4"
                style={{ margin: "3px 8px" }}
                onClick={() => handleCloseFeedBack()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-x"
                  style={{ margin: "3px -3px", color: "gray" }}
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </span>
            </div>
          </Modal.Header>

          {/* <Modal.Title>{problemData?.name}</Modal.Title> */}
          <Modal.Body className="m-0 model_body">
            <div className="service-grid">
              <p className="maxWidthFont d-flex justify-content-around fw-bolder fs-5">
                Service FeedBack
              </p>
              <Container>
                {[...Array(5)].map((item, index) => {
                  const givenRating = index + 1;
                  return (
                    <label>
                      <Radio
                        type="radio"
                        value={givenRating}
                        onClick={() => {
                          setRate(givenRating);
                        }}
                      />
                      <Rating>
                        <FaStar
                          style={{ width: "30px", height: "30px" }}
                          color={
                            givenRating < rate || givenRating === rate
                              ? "gold"
                              : "rgb(192,192,192)"
                          }
                        />
                      </Rating>
                    </label>
                  );
                })}
              </Container>
              <div>
                <textarea
                  class="form-control rounded-3"
                  id="exampleFormControlTextarea1"
                  placeholder="Enter cancelled reason"
                  rows="3"
                  onChange={(e) => setFeedBack(e.target.value)}
                ></textarea>
              </div>
              {/* <button>Submit</button> */}
              <div className="d-flex justify-content-end p-3">
                <button
                  type="button"
                  onClick={() => PostSuccessComplaintFeedback()}
                  class="btn btn-primary"
                >
                  Submit
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* == */}
        <Modal
          Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          id="category-modal"
          show={show}
          onHide={handleClose}
          className="main_model p-0 px-3"
          dialogClassName="main_model"
        >
          <Modal.Header
            className="modelHeder"
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              padding: "15px",
              border: "none",
              bottom: "50px",
            }}
          >
            <div className="modalCloseButton cursor-pointer">
              <span
                // className="ms-4"
                style={{ margin: "3px 8px" }}
                onClick={() => handleClose()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-x"
                  style={{ margin: "3px -3px", color: "gray" }}
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </span>
            </div>
          </Modal.Header>
          <div
            style={{
              position: "absolute",
              width: "100%",
              // height: "100%",
              borderBottom: "2px solid #E3E3E3" /* Adjust border properties */,
              boxSizing:
                "border-box" /* Ensures the border width is included in the div's total width */,
            }}
          >
            <h3
              className="m-0 model_heading"
              style={{
                marginLeft: "10px",
                padding: "12px ",
                fontWeight: "bold",
              }}
            >
              {/* {subCategoryTitle} */}
            </h3>
          </div>
          {/* <Modal.Title>{problemData?.name}</Modal.Title> */}
          <Modal.Body className="m-0 model_body">
            <Form.Select
              style={{ fontFamily: "Poppines" }}
              aria-label="Default select example"
              onChange={handleSelectChange}
              value={selectedValue}
            >
              <option>Select question</option>
              {faq?.map((option, index) => (
                <option
                  key={index}
                  style={{ fontFamily: "Poppines" }}
                  value={option?.id}
                >
                  {option?.description}
                </option>
              ))}
            </Form.Select>
            <Form className="p-0 mt-2">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Control
                  onChange={handleTextareaChange}
                  style={{ fontFamily: "Poppines" }}
                  as="textarea"
                  placeholder="What's Problem"
                  rows={3}
                  value={problem}
                />
                {error && (
                  <Alert className="p-1 mt-1" variant="danger">
                    {error}
                  </Alert>
                )}
              </Form.Group>
            </Form>
            <div className="d-flex justify-content-end">
              <Button
                disabled={problem && selectedValue && !error ? false : true}
                style={{ fontFamily: "Poppines" }}
                className="w-auto"
                onClick={(e) => {
                  handleCancleBooking();
                  handleClose();
                }}
                variant="primary"
              >
                Cancel Booking
              </Button>{" "}
            </div>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};
export default BookingDetails;
