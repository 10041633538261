import axios from 'axios';
import React, { useState } from 'react'
import { URLAddAddress } from '../util/UrlConst';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

export default function AddAddressComponent(props) {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const [addname, setAddName] = useState();
    const [addNumber, setAddNumber] = useState();
    const [alterNumber, setAlterNumber] = useState();
    const [addbuilding, setAddBuilding] = useState();
    const [addLandMark, setAddLandmark] = useState();
    const [addState, setAddState] = useState();
    const [addDistrict, setaddDistrict] = useState();
    const [addZipcode, setaddZipcode] = useState();


    const submitaddress = async (event) => {
        event.preventDefault();

        await axios.post(URLAddAddress, {
            name: addname,
            valetuserid: user?.id,
            mobile: addNumber,
            alter_number: alterNumber,
            zipcode: addZipcode,
            state: addState,
            district: addDistrict,
            building: addbuilding,
            landmark: addLandMark
        }).then(res => {

            if (!res.data.error) {
                toast(res.data.message, { type: "success", theme: "dark" });
                props.getData();
                props.handleClose();
            } else {
                toast(res.data.message, { type: "error", theme: "dark" });
            }


        });

    }


    return (
        <>

            {/* {{-- ===========================================
                                ADDRESS MODAL
            =========================================== --}} */}
            <Modal size="md" id="address-modal" show={props.show} onHide={props.handleClose}>
                <form onSubmit={submitaddress}>

                    <Modal.Header closeButton>
                        <Modal.Title>Address</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="address-form">
                            <div className="group-input">
                                <label htmlFor="name">Name*</label>
                                <input type="text" name="name" value={addname} onChange={e => setAddName(e.target.value)} required></input>
                            </div>
                            <div className="group-input">
                                <label htmlFor="number">Mobile number*</label>
                                <input type="text" name="number" minLength={10} maxLength={10} value={addNumber} onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} onChange={e => setAddNumber(e.target.value)} required></input>
                            </div>
                            <div className="group-input">
                                <label htmlFor="number">Alternate Mobile number</label>
                                <input type="text" name="number" minLength={10} maxLength={10} value={alterNumber} onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} onChange={e => setAlterNumber(e.target.value)} ></input>
                            </div>
                            <div className="group-input">
                                <label htmlFor="building">Building Name/House No.</label>
                                <input type="text" name="building" value={addbuilding} onChange={e => setAddBuilding(e.target.value)} ></input>
                            </div>
                            <div className="group-input">
                                <label htmlFor="landmark">Area and Landmark*</label>
                                <input type="text" name="landmark" value={addLandMark} onChange={e => setAddLandmark(e.target.value)} required></input>
                            </div>
                            <div className="group-input">
                                <label htmlFor="district">District*</label>
                                <input type="text" name="district" value={addDistrict} onChange={e => setaddDistrict(e.target.value)} required></input>
                            </div>
                            <div className="group-input">
                                <label htmlFor="state">State*</label>
                                <input type="text" name="state" value={addState} onChange={e => setAddState(e.target.value)} required></input>
                            </div>

                            <div className="group-input">
                                <label htmlFor="zip">Zip Code*</label>
                                <input type="number" name="zip" value={addZipcode} onChange={e => setaddZipcode(e.target.value)} required></input>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={props.handleClose}>Cancel</Button>
                        <Button type="submit">Save</Button>
                    </Modal.Footer>

                </form>
            </Modal>


        </>
    )
}
