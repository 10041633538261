import { Parser } from "html-to-react";
import React, { useContext, useEffect, useState } from "react";
import { UrlHistoryContext } from "../context/UrlHistoryProvider";

export default function Problems_card(props) {
  var { item, problems, addToCartProblem } = props;
  const [cartQuantity, setCartQuantity] = useState(0);
  const { changeLocalState, setChangeLocalState } =
    useContext(UrlHistoryContext);

  const addToCartProblemPlus = (product) => {
    const existingCart = JSON.parse(sessionStorage.getItem("cart")) || [];
    const existingProductIndex = existingCart.findIndex(
      (item) => item?.id === problems?.id
    );

    if (existingProductIndex !== -1) {
      const existingProduct = existingCart[existingProductIndex].problems.find(
        (item) => item?.id === product?.id
      );

      if (existingProduct) {
        existingProduct.quantity_data =
          (existingProduct.quantity_data || 0) + 1;
      } else {
        // Problem not found in existingCart, add it
        const copyData = problems;
        const newData = copyData?.problems?.find(
          (dt) => dt?.id === product?.id
        );

        if (newData) {
          newData.quantity_data = 1;
          existingCart[existingProductIndex].problems.push(newData);
        }
      }
    } else {
      // Product not found in existingCart, add it along with the problem
      const copyData = problems;
      const newData = copyData?.problems?.find((dt) => dt?.id === product?.id);

      if (newData) {
        newData.quantity_data = 1;
        existingCart.push({ ...copyData, problems: [newData] });
      }
    }

    sessionStorage.setItem("cart", JSON.stringify(existingCart));
    // setCartQuantity(existingProduct?.quantity_data || 1);
  };

  const addToCartProblemMinus = (product) => {
    let existingCart = JSON.parse(sessionStorage.getItem("cart")) || [];
    const existingProductIndex = existingCart.findIndex(
      (item) => item?.id === problems?.id
    );

    if (existingProductIndex !== -1) {
      const existingProduct = existingCart[existingProductIndex].problems.find(
        (item) => item?.id === product?.id
      );

      if (existingProduct) {
        existingProduct.quantity_data =
          (existingProduct.quantity_data || 0) - 1;

        // Check if all quantity_data in problems array are zero, remove the entire item from existingCart
        if (
          existingCart[existingProductIndex].problems.every(
            (problem) => problem.quantity_data === 0
          )
        ) {
          existingCart.splice(existingProductIndex, 1);
        }
      }
    } else {
      // Product not found in existingCart, add it
      const copyData = problems;
      const newData = copyData.problems.find((dt) => dt?.id === product?.id);

      if (newData) {
        newData.quantity_data = 1;
        existingCart.push(copyData);
      }
    }

    sessionStorage.setItem("cart", JSON.stringify(existingCart));
  };

  function findAllProblems(data) {
    const allProblems = [];

    data?.forEach((person) => {
      if (person?.problems) {
        person?.problems?.forEach((problem) => {
          // Check if the problem is not already in the array
          if (
            !allProblems?.some(
              (p) => JSON.stringify(p) === JSON.stringify(problem)
            )
          ) {
            allProblems.push(problem);
          }
        });
      }
    });

    return allProblems;
  }

  useEffect(() => {
    const existingCart = JSON.parse(sessionStorage.getItem("cart"));
    if (existingCart != []) {
      var all = findAllProblems(existingCart);
      var findData = all.find((dt) => dt?.id == item?.id);
      setCartQuantity(findData?.quantity_data);
    }
  }, [changeLocalState]);
  return (
    <>
      <div>
        <div style={{ borderBottom: "2px solid #E3E3E3", marginTop: "8px" }}>
          <div className="top-part">
            <div className=" d-flex">
              <div className="title">
                <h4
                  style={{ fontWeight: "", fontFamily: "Poppins" }}
                  className="titleheader fontDescription p-0 mb-0"
                >
                  {item?.name}
                </h4>
              </div>
              <div
                className="image"
                style={{ position: "absolute", right: "15px" }}
              >
                <div style={{ fontWeight: "", color: "gray" }}>
                  <p
                    className="p-0 ms-2 m-0 maxWidthFont fontDescription"
                    style={{ lineHeight: "1.5" }}
                  >
                    {" "}
                    {/* Start at{" "} */}
                    <span
                      className="text-success"
                      style={{ fontWeight: "600" }}
                    >
                      ₹ {item?.sale_price}
                    </span>
                  </p>
                </div>

                {cartQuantity ? (
                  <div className="right-static">
                    <div className="mid-info text-center">
                      <div className="mid-ico ">
                        <button
                          className="mid-ico backgroundColor"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            addToCartProblemMinus(item);
                            setChangeLocalState(!changeLocalState);
                          }}
                        >
                          -
                        </button>
                      </div>

                      <div className="mid-ico ">{cartQuantity}</div>

                      <div className="mid-ico">
                        <button
                          className="mid-ico backgroundColor"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            addToCartProblemPlus(item);
                            setChangeLocalState(!changeLocalState);
                          }}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="addButtonHeader">
                    <button
                      className="addButton  px-4 py-1 "
                      onClick={() => {
                        addToCartProblemPlus(item);
                        setChangeLocalState(!changeLocalState);
                      }}
                    >
                      {" "}
                      Add{" "}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="bottom-part">
            <ul
              className="single-packag d-flex p-0 m-0"
              style={{ listStyle: "none" }}
            >
              {/* <li className="mt-1"> </li> */}
              <i
                className="las la-history pt-1"
                style={{ fontSize: "small" }}
              ></i>
              <span className="ms-2 fontDescription">45 min</span>
            </ul>
            <div className="price ">
              {/* <div><del>Rs. {props.product.mrp}</del></div> */}
              {/* <br/> */}

              <div className="data">
                {/* {Parser().parse(item?.description)} */}
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
