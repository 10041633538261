import React, { useEffect, useState } from "react";

import { createContext } from "react";

export const UrlHistoryContext = createContext();

const UrlHistoryProvider = (props) => {
  const [urlHistory, setUrlHistory] = useState(null);
  const [modalLogin, setmodalLogin] = useState(false);
  const [modalRegister, setmodalRegister] = useState(false);
  const [changeLocalState, setChangeLocalState] = useState(false);
  const [cardQuantityForRepair, setCardQuantityForRepair] = useState(0);
  const [cartQuantityTotal, setCartQuantityTotal] = useState();
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalMRP, setTotalMRP] = useState(0);
  const [salePrice, setSalePrice] = useState(0);
  const [mrp, setMrp] = useState(0);
  const [model, setModal] = useState(false);
  const [timeDate, setTimeDate] = useState(false);
  const [modalLocation, setmodalLocation] = useState(false);
  const [mapData, setMapData] = useState("");
  const [postPinCode, setPostPinCode] = useState({});
  const [updateData, setUpdateData] = useState(false);
  const [locationModakOpen, setLocationModalOpen] = useState(false);
  const [currentPathname, setCurrentPathCart] = useState();
  const [accountRemoveModal, setAccountRemoveModal] = useState(false);
  const [updateProfilePage, setUpdateProfilePage] = useState(false);
  const [category, setCategoryName] = useState();

  useEffect(() => {});

  return (
    <UrlHistoryContext.Provider
      value={{
        urlHistory,
        setUrlHistory,
        model,
        setModal,
        modalLogin,
        setmodalLogin,
        modalRegister,
        setmodalRegister,
        changeLocalState,
        setChangeLocalState,
        cardQuantityForRepair,
        setCardQuantityForRepair,
        cartQuantityTotal,
        setCartQuantityTotal,
        totalPrice,
        setTotalPrice,
        totalMRP,
        setTotalMRP,
        salePrice,
        setSalePrice,
        mrp,
        setMrp,
        setTimeDate,
        timeDate,
        modalLocation,
        setmodalLocation,
        mapData,
        setMapData,
        postPinCode,
        setPostPinCode,
        updateData,
        setUpdateData,
        locationModakOpen,
        setLocationModalOpen,
        currentPathname,
        setCurrentPathCart,
        accountRemoveModal,
        setAccountRemoveModal,
        updateProfilePage,
        setUpdateProfilePage,
        category,
        setCategoryName,
      }}
    >
      {props.children}
    </UrlHistoryContext.Provider>
  );
};

export default UrlHistoryProvider;
