import axios from "axios";
import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";

import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { URLresigter, URLSendOtp, URLSignUp } from "../../util/UrlConst";
import { UrlHistoryContext } from "../../context/UrlHistoryProvider";

const ModalRegister = () => {
  const [name, setName] = useState();
  const [number, setNumber] = useState();
  const [isError, setError] = useState(false);
  const [errormessage, setErrormessage] = useState();
  const [loginform, setLoginForm] = useState(true);
  const [otp, setOtp] = useState();
  const [userOtp, setUserotp] = useState();
  const [otperror, setOtpError] = useState(false);
  const { modalRegister, setmodalRegister } = useContext(UrlHistoryContext);

  const navigate = useNavigate();

  //create an var to store token in local storage.
  const storeTokenInLocalStorage = (token) => {
    sessionStorage.setItem("userToken", token);
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    // setError(false);

    if (name != "" || number != "") {
      await axios.post(URLSignUp, { name: name, phone: number }).then((res) => {
        if (!res.data.error) {
          const token = res.data.data.auth_token;
          storeTokenInLocalStorage(token);
          navigate("/home");
          toast("SignUp Successfully.", { type: "success" });
          setmodalRegister(false);
          setName("");
          setNumber("");
          sessionStorage.setItem("token", res);
        } else {
          toast(res.data.message, { type: "error", theme: "dark" });
          setName("");
          setNumber("");
        }
      });
    } else {
      toast("Plese Enter name and number.", { type: "error", theme: "dark" });
    }
  };

  const otpcheckform = async (e) => {
    e.preventDefault();

    await axios
      .post(URLresigter, { valetuser: name, mobile: number, password: number })
      .then((res) => {
        if (res.data.error) {
          toast(res.data.message, { type: "error", theme: "dark" });
        } else {
          toast("Successfully Rgistered.", { type: "success", theme: "dark" });

          navigate("/login", { replace: true });
        }
      });
  };
  const handleClose = () => {
    // setShow(false)

    setmodalRegister(false);
  };

  return (
    <>
      {/* {{-- ===========================================
                                BREADCRUMB
            =========================================== --}} */}
      {/* <div className="banner-inner-area section-bg-3 padding-top-100 padding-bottom-30">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="banner-inner-contents">
                                <h2 className="banner-inner-title mb-0"> Register </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
      <Modal
        Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="category-modal"
        show={modalRegister}
        onHide={handleClose}
      >
        {/* <Modal.Header className="modelHeder">
                    <div className="modalCloseButton "
                        style={{ cursor: "pointer" }}
                    >
                        <p className="ms-4" onClick={() => handleClose()}>
                            X
                        </p>
                    </div>

                    <Modal.Title></Modal.Title>
                </Modal.Header> */}

        <Modal.Header
          className="modelHeder"
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            padding: "15px",
            border: "none",
            bottom: "50px",
          }}
        >
          <div className="modalCloseButton cursor-pointer">
            <span
              // className="ms-4"
              style={{ margin: "3px 8px" }}
              onClick={() => handleClose()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-x"
                style={{ margin: "3px -3px", color: "gray" }}
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </span>
          </div>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="signup-area ">
            <div className="container">
              <div className="">
                {loginform ? (
                  <div className="signup-contents">
                    <h3 className="signup-title">Register</h3>
                    <div className="error-message"></div>
                    <form className=" p-4 signup-forms" onSubmit={formSubmit}>
                      <div className="single-signup margin-top-30">
                        <label className="signup-label"> Name * </label>
                        <input
                          className="form--control"
                          type="text"
                          name="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          id="name"
                          placeholder="Name"
                        ></input>
                      </div>
                      <div className="single-signup margin-top-30">
                        <label className="signup-label"> Phone Number * </label>
                        <input
                          className="form-control"
                          type="text"
                          name="number"
                          maxLength={10}
                          minLength={10}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          value={number}
                          onChange={(e) => setNumber(e.target.value)}
                          id="number"
                          placeholder="Phone Number"
                        ></input>
                      </div>
                      {isError && (
                        <div>
                          {" "}
                          <span className="text-danger">
                            {" "}
                            {errormessage}{" "}
                          </span>{" "}
                        </div>
                      )}

                      <button id="signin_form" type="submit">
                        Submit
                      </button>
                      {/* <span className="bottom-register">
                                                Already have an account?
                                                <Link className="resgister-link" to="/login"> Login </Link>
                                            </span> */}
                    </form>
                  </div>
                ) : (
                  <div className="signup-contents">
                    <h3 className="signup-title"> Verify OTP. </h3>
                    <form className="signup-forms" onSubmit={otpcheckform}>
                      <center>
                        {" "}
                        <span>We have send otp to {number}</span>
                      </center>
                      <div className="single-signup margin-top-30">
                        <label className="signup-label"> Enter OTP </label>
                        <input
                          className="form-control"
                          type="text"
                          minLength={4}
                          maxLength={4}
                          value={userOtp}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            setUserotp(e.target.value);
                            setOtpError(false);
                          }}
                          placeholder="OTP"
                        />
                      </div>
                      {otperror && (
                        <div>
                          {" "}
                          <span className="text-danger">
                            {" "}
                            Please enter correct otp.{" "}
                          </span>{" "}
                        </div>
                      )}

                      <button type="submit">Submit</button>
                      {/* <span className="bottom-register">
                                                Wrong Number ?
                                                <span className="resgister-link btn" onClick={e => setLoginForm(true)}> Click here </span>
                                            </span> */}
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalRegister;
