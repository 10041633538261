import React from 'react'
import { Link, useParams } from 'react-router-dom'

export default function PaymentStatus() {

  let { type } = useParams();
  let { id } = useParams();

  if (type === "success") {
    return (
      <div>
        <div className='container my-5'>
          <center>
            <img src={`/assets/frontend/img/static/success.png`} height={200} alt='success' />
            <br></br>
            <h4>Your transaction id is {id} . </h4> <h5> Thank You for your order.</h5> <br />  <h5 style={{ color: 'blue' }}><Link to="/service-booked"> Click here to check your order.</Link> </h5>  </center>
        </div>
      </div>
    )
  } else {
    return (
      <div>
        <div className='container my-5 '>
          <center>
            <img src={`/assets/frontend/img/static/cancel.png`} height={200} alt='success' />
            <br></br> <br></br>
            <h4>Your transaction id is {id} . </h4> <br></br>
            <h5> Your order has been canceled due to some reason Thank You. </h5>

            <br></br>
            <h5 style={{ color: 'blue' }}><Link to="/home">  Click here to return home.</Link> </h5>
          </center>
        </div>
      </div>
    )
  }
}
