import React from "react";
import "./career.css";
export default function career() {
  return (
    <>
      <div className="career-page">
        <div className="career-section">
          <div className="image">
            <img
              src="https://img.freepik.com/premium-vector/career-achiedvement-concept-smart-businessman-planting-flag-success-growing-word-career_641940-105.jpg"
              alt="...."
            />
          </div>
        </div>
      </div>
      <div className="main-section-area">
        <div className="row">
          <div className="main-section">
            <div className="col-lg-6">
              <div className="heading">Our Mission</div>
              <div className="lorem">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Officia, iure nam aperiam unde neque totam voluptate culpa
                officiis ipsa praesentium magni eligendi adipisci eveniet
                cupiditate odio, perspiciatis provident ex quam?
              </div>
            </div>
            <div className="col-lg-6">
              <div className="main-img">
                <img
                  src="https://img.freepik.com/free-photo/business-team-teamwork-partnership-together-concept_1423-215.jpg"
                  alt=".."
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main-section-area">
        <div className="row">
          <div className="main-section">
            <div className="col-lg-6">
              <div className="main-img">
                <img
                  src="https://img.freepik.com/free-photo/business-team-teamwork-partnership-together-concept_1423-215.jpg"
                  alt=".."
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="heading_1">
                Beniefits of Being <small>The classy Services</small>{" "}
              </div>
              <div className="lorem">
                Our investment in strategic talent development initiatives and
                the linking of learning to career development have energized our
                workforce. Our empowering, performance-driven work culture is
                helping us attract local talent across all our key markets.
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="section-card">
        <div id="card_1">
          // eslint-disable-next-line jsx-a11y/img-redundant-alt
          <img
            src="https://airdoot.com/assets/website/images/ecom_products/ductable_ac_mid_static_non_inverter.jpg"
            alt="image"
          />

          <div className="card-content">
            <p>Lorem ipsum</p>
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptas
              minus inventore quas accusamus enim error corporis dolorem quidem
              mollitia aliquid. Atque iusto deleniti magni nulla illum. Vero
              aspernatur ab voluptate.
            </p>
          </div>
        </div>

        <div id="card_1">
          <img
            src="https://airdoot.com/assets/website/images/ecom_products/ductable_ac_mid_static_non_inverter.jpg"
            alt="image"
          />

          <div className="card-content">
            <p>Lorem ipsum</p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi
              voluptate suscipit iste, commodi provident similique veritatis
              corporis quas, odit cum quae illo exercitationem officia? Eius
              delectus aut dolor earum recusandae.
            </p>
          </div>
        </div>

        <div id="card_1">
          // eslint-disable-next-line jsx-a11y/img-redundant-alt, jsx-a11y/img-redundant-alt
          <img
            src="https://airdoot.com/assets/website/images/ecom_products/ductable_ac_mid_static_non_inverter.jpg"
            alt="image" />

          <div className="card-content">
            <p>Lorem ipsum</p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem
              tenetur culpa repudiandae corrupti aut neque non dolores
              perferendis aliquam dolorem voluptatem veritatis repellat,
              assumenda atque explicabo iste tempora delectus facere?
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
