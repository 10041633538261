import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Nabvar from "./Nabvar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UrlHistoryProvider from "../context/UrlHistoryProvider";
import PrivateComponent from "../util/private";
// import Cart from "../pages/Cart";

export default function Layout() {
  return (
    <>
      <UrlHistoryProvider>
        {/* <Nabvar /> */}
        <ToastContainer />
        <Outlet />
        <Footer />
        {/* <PrivateComponent /> */}
        {/* <Cart /> */}
      </UrlHistoryProvider>
    </>
  );
}
