import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProductComponent from "../component/ProductComponent";
import {
  URLApplianceTypeDetails,
  URLCategoryDetails,
  URLSliderDetails,
  URLSubSubCategoryImage,
} from "../util/UrlConst";

export default function Slider() {
  let { id } = useParams();
  let { name } = useParams();

  const [sliderName, setSliderName] = useState([]);
  const [sliderType, setSliderType] = useState([]);
  const [isloadin, setLoading] = useState(true);

  let token = sessionStorage.getItem("token");

  useEffect(() => {
    // debugger;
    window.scrollTo(0, 0);
    getdata();

    async function getdata() {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      //   URLCategoryDetails
      await axios.post(URLSliderDetails + id).then((response) => {
        setSliderName(response.data.data);
        setSliderType(response.data.data);

        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
    }
  }, []);

  return (
    <>
      {/* {{-- ===========================================
                                BREADCRUMB
            =========================================== --}} */}
      <div className="banner-inner-area section-bg-3 padding-top-100 padding-bottom-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner-inner-contents">
                <h2
                  className="banner-inner-title mb-0"
                  style={{ fontSize: "32px" }}
                >
                  {" "}
                  {name}{" "}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {{-- ===========================================
                                CATEGORIES
            =========================================== --}} */}
      <section
        className="category-area section-bg-1"
        data-padding-top="40"
        data-padding-bottom="40"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-7 col-md-10">
              <div className="section-title">
                <h2 className="title">
                  {" "}
                  Browse <span className="bg-main-one"> Categories </span>{" "}
                </h2>
                <span className="section-para">
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                </span>
              </div>
            </div>
          </div>
          <div className="row justify-content-center margin-top-50">
            <div className="col-lg-12">
              <div className="category-slider nav-style-one">
                <div className="row justify-content-center">
                  {sliderName?.map((data) => (
                    <div
                      className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp"
                      data-wow-delay=".2s"
                    >
                      <a href={`#${data?.name}`}>
                        <div className="single-category">
                          <div className="icon">
                            <img
                              src={data?.image}
                              alt="..."
                              className="w-100 h-100"
                            ></img>
                          </div>
                          <div className="category-contents">
                            <h4 className="category-title">{data?.name}</h4>
                          </div>
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* {{-- ===========================================
                            INNER CATEGORIES
            =========================================== --}} */}
      <section className="explore-all padding-top-40 padding-bottom-40">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              {sliderType.length > 0 ? (
                <div className="product-list">
                  {sliderType &&
                    sliderType?.map((data) => (
                      <>
                        {data.applianceTypes != 0 && (
                          <div id={data?.name}>
                            <div className="category-title">{data?.name}</div>
                            {data?.applianceTypes?.map((product) => (
                              <ProductComponent product={product} />
                            ))}
                          </div>
                        )}
                      </>
                    ))}
                </div>
              ) : (
                <>
                  {isloadin ? (
                    <center>
                      <h4>Loading....</h4>
                    </center>
                  ) : (
                    <h5> No product found</h5>
                  )}
                </>
              )}
            </div>

            <div className="col-lg-6">
              <div className="right-static">
                <div className="main-head">Our Expertise</div>
                <div className="list">
                  <div className="list-item">
                    <div className="icon">
                      <i className="las la-tools"></i>
                    </div>
                    <div className="content">
                      <div className="title">Experienced Professionals</div>
                      <div className="data">
                        Our team of technicians are highly experienced and have
                        been trained to provide efficient and effective repair,
                        installation and uninstallation services.
                      </div>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="icon">
                      <i className="las la-tools"></i>
                    </div>
                    <div className="content">
                      <div className="title">Timely Service</div>
                      <div className="data">
                        We understand the importance of time and offer quick and
                        prompt services to our customers. We ensure that our
                        technicians reach your doorstep within the given time
                        frame.
                      </div>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="icon">
                      <i className="las la-tools"></i>
                    </div>
                    <div className="content">
                      <div className="title">Affordable Pricing</div>
                      <div className="data">
                        Our services are competitively priced and we offer a
                        range of packages to suit your budget. We believe in
                        providing quality services at reasonable rates.
                      </div>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="icon">
                      <i className="las la-tools"></i>
                    </div>
                    <div className="content">
                      <div className="title">Quality Workmanship</div>
                      <div className="data">
                        We use only the best quality spare parts and tools for
                        repairs and installations. Our technicians take pride in
                        their work and ensure that the job is done right the
                        first time.
                      </div>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="icon">
                      <i className="las la-tools"></i>
                    </div>
                    <div className="content">
                      <div className="title">Customer Satisfaction</div>
                      <div className="data">
                        Our top priority is customer satisfaction. We strive to
                        provide an exceptional service experience to our
                        customers and ensure that all their queries and concerns
                        are addressed promptly.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
