import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { UrlHistoryContext } from "../../../context/UrlHistoryProvider";
import { URLLogin, URLOtpVerify } from "../../../util/UrlConst";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LoginModals = (props) => {
  // const [show, setShow] = useState(props);
  const [showPopupOtp, setShowPopupOtp] = useState(false);
  const [otp, setOtp] = useState("");
  // const [userPhoneNumber, setuserPhoneNumber] = useState();
  const [userPhoneNumber, setuserPhoneNumber] = useState("");
  const closePopupOtp = () => {
    setShowPopupOtp(false);
    setmodalLogin(false);
  };
  const { modalLogin, setmodalLogin, updateProfilePage, setUpdateProfilePage } =
    useContext(UrlHistoryContext);

  // console.log(props, '11111111111')

  // useEffect(() => {
  //     setShow(props)
  // }, [props])

  const handleClose = () => {
    // setShow(false)

    setmodalLogin(false);
    setShowPopupOtp(false);
    setuserPhoneNumber("");
  };
  const [seconds, setSeconds] = useState(); // Initial number of seconds

  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        // Timer reached zero, you can perform some action here
        clearInterval(timer);
      }
    }, 1000);

    // Clean up the timer when the component is unmounted
    return () => clearInterval(timer);
  }, [seconds, showPopupOtp]);

  const handlePhoneNumberChange = (e) => {
    const inputPhoneNumber = e.target.value;
    setuserPhoneNumber(inputPhoneNumber);

    // Remove any non-numeric characters from the input
    const cleanedPhoneNumber = inputPhoneNumber.replace(/\D/g, "");

    // Check if the cleaned phone number is exactly 10 digits
    if (cleanedPhoneNumber.length === 10) {
      setuserPhoneNumber(cleanedPhoneNumber);
    }
  };
  const handleSubmit = () => {
    // Add your logic to handle the submission
    // This can include showing the OTP popup or sending the phone number to a server

    axios
      .post(`${URLLogin}?phone=${userPhoneNumber}`)
      .then((response) => {
        // setuserPhoneNumber('')
        // Add your logic to handle the API response
      })
      .catch((error) => {
        console.error("API call failed:", error);

        // Add your logic to handle the error
      });
    setSeconds(59);
    setShowPopupOtp(true);
  };
  const handleOtp = () => {
    // setSeconds(10);

    // Add your logic to handle the submission
    // This can include showing the OTP popup or sending the phone number to a server

    axios
      .post(`${URLOtpVerify}?phone=${userPhoneNumber}&otp=${otp}`)
      .then((response) => {
        // setuserPhoneNumber("");
        // setOtp("");
        setmodalLogin(false);
        setShowPopupOtp(false);
        if (response.data.error) {
          // navigate("/", { replace: true });
          toast(response.data.message, { type: "error", theme: "dark" });
          setmodalLogin(false);
          setShowPopupOtp(false);
          setuserPhoneNumber("");
          setOtp("");
        } else {
          setuserPhoneNumber("");
          setOtp("");
          setmodalLogin(false);
          setShowPopupOtp(false);
          setUpdateProfilePage(!updateProfilePage);
          sessionStorage.setItem("token", response?.data?.data?.auth_token);
          sessionStorage.setItem("UserNumber", response?.data?.data?.phone);
          sessionStorage.setItem("UserId", response?.data?.data?.id);
        }

        // setuserPhoneNumber('')
        // Add your logic to handle the API response
      })
      .catch((error) => {
        console.error("API call failed:", error);

        // Add your logic to handle the error
      });
    // setShowPopupOtp(true);
  };

  const handleOtpChange = (e) => {
    const inputOtp = e.target.value;
    setOtp(inputOtp);
    // Remove any non-numeric characters from the input
    const cleanedOtp = inputOtp.replace(/\D/g, "");

    // Set the OTP state only if it is a 4-digit number
    if (/^\d{4}$/.test(cleanedOtp)) {
      setOtp(cleanedOtp);
    }
  };

  return (
    <>
      <ToastContainer />

      <Modal
        Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="category-modal"
        show={modalLogin}
        onHide={handleClose}
      >
        <Modal.Header
          className="modelHeder +++++"
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            padding: "15px",
            border: "none",
            bottom: "50px",
          }}
        >
          <div className="modalCloseButton cursor-pointer">
            <span
              // className="ms-4"
              style={{ margin: "3px 8px" }}
              onClick={() => handleClose()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-x"
                style={{ margin: "3px -3px", color: "gray" }}
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </span>
          </div>
        </Modal.Header>
        <Modal.Body>
          {showPopupOtp == false ? (
            <div id="">
              <div>
                <h4 className="d-flex justify-content-around fw-bold">
                  Please Login
                </h4>

                <form id="" className="p-0 p-lg-5 p-md-5" action="">
                  <div className="mb-3">
                    <label htmlFor="phone" className="form-label">
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      id="phone"
                      aria-describedby=""
                      placeholder="**********"
                      value={userPhoneNumber}
                      onChange={handlePhoneNumberChange}
                    />
                    <br />
                    <div className="d-flex justify-content-end ms-auto w-50">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleSubmit}
                        disabled={userPhoneNumber.length !== 10} // Disable the button if the phone number is not exactly 10 digits
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            <div id="">
              <div>
                {/* <legend>Verify OTP</legend> */}
                <h4 className="d-flex justify-content-around">Please Login</h4>
                {/* {seconds} */}

                <form className="p-0 p-lg-5 p-md-5" id="" action="">
                  <div className="mb-3">
                    <label htmlFor="otp" className="form-label">
                      Enter OTP
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      id="otp"
                      placeholder="******"
                      value={otp}
                      onChange={handleOtpChange}
                    />
                    <br />
                    {seconds > 0 ? (
                      <p>Did't get OPT ? Resend in {seconds} seconds</p>
                    ) : (
                      <p>
                        Did't get OPT ?{" "}
                        <strong
                          onClick={handleSubmit}
                          className="cursor-pointer"
                        >
                          {" "}
                          Resend OPT{" "}
                        </strong>
                      </p>
                    )}
                    <button
                      type="button"
                      className="btn btn-primary w-100"
                      onClick={handleOtp}
                      disabled={otp.length !== 6} // Disable the button if the OTP is not exactly 4 digits
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
export default LoginModals;
