/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import $ from "jquery";
import { UrlHistoryContext } from "../context/UrlHistoryProvider";
import AccountRemove from "../pages/modal/accountRemove";
import { Accordion } from "react-bootstrap";
import LoginModals from "../pages/components/loginSignUp/login";
import { keywordsURL } from "../util/UrlConst";
import axios from "axios";

export default function Footer() {
  const [hideBooking, setHideBooking] = useState();
  const [services, setServices] = useState([]);

  const {
    setmodalLogin,
    modalLogin,
  } = useContext(UrlHistoryContext);
  const [token, setToken] = useState();

  useEffect(() => {
    const tokens = sessionStorage.getItem("token");

    setToken(tokens);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios
      .get(keywordsURL)
      .then((response) => setServices(response?.data?.data))
      .catch((error) => console.error("Error fetching data:", error));
  };

  useEffect(() => {
    $(document).on("click", ".open-button", function () {
      $(this).toggleclassName("open-btn");
      $("#myForm").toggleclassName("popup-chat");
    });
  });

  const checkLog = () => {
    if (token == null || token === undefined) {
      setHideBooking(true);
    } else {
      setHideBooking(false);
    }
  };
  useEffect(() => {
    checkLog();
  });

  return (
    <>=
      <footer className="footer-area mt-4 ">
        <LoginModals props={modalLogin} />
        <div className="footer-top padding-top-50">
          <div className="container">
            <div className="d-lg-block d-md-block d-none">
              <p>Our Best Services</p>

              <div
                className=" d-flex flex-wrap overview-list  gap-2 pb-4 commonfontFamily"
                style={{ fontSize: "small" }}
              >
                {services?.map((item, i) => (
                  <Link
                    to={`${item?.url}`}
                    key={i}
                    className="text-dark text-decoration-none d-block "
                    style={{
                      borderRight: "2px solid gray",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    {item?.name}
                  </Link>
                ))}
              </div>
            </div>
            <div className="">
              <p>Our popular city</p>

              <div
                className=" d-flex flex-wrap overview-list  gap-2 pb-4 commonfontFamily"
                style={{ fontSize: "small" }}
              >
                <a
                  className="listLine"
                  href="/indore/product_category/ac_service/index.html"
                  style={{
                    borderRight: "2px solid gray",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                    color: "black",
                  }}
                >
                  Indore
                </a>
                <a
                  className="listLine"
                  href="/bhopal/product_category/ac_service/index.html"
                  style={{
                    borderRight: "2px solid gray",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                    color: "black",
                  }}
                >
                  Bhopal
                </a>
              </div>
            </div>
            <div className="d-lg-none d-md-none d-block">
              <Accordion className="" border={false}>
                {[1]?.map((data, index) => (
                  <Accordion.Item key={index} eventKey={index.toString()}>
                    <Accordion.Header>{"Our Best Services"}</Accordion.Header>
                    <Accordion.Body
                      className="py-3  p-0 "
                      style={{ fontSize: "small" }}
                    >
                      <div className=" overview-list ps-2 gap-2 ">
                        {services?.map((item,ii) => (
                          <Link
                            to={`${item?.url}`}
                            key={ii}
                            className="text-dark text-decoration-none d-block "
                            style={{
                              borderRight: "2px solid gray",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                            }}
                          >
                            {item?.name}
                          </Link>
                        ))}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
          <div className="container container-two">
            <div className="row">
              <div className="col-lg-5 col-md-12 col-sm-12">
                <div className="footer-widget widget ">
                  <div className="about_us_widget">
                    <Link to="/" className="footer-logo">
                      <img
                        src="/assets/uploads/media-uploader/logo.png"
                        alt="..."
                      ></img>
                    </Link>
                  </div>
                  <div className="footer-inner">
                    <p className="footer-para">
                      The Classy is your ultimate shop for professional local
                      services in your area. Find a good amount of reliable
                      professionals in your location to manage all of your
                      household and beauty needs.
                    </p>
                  </div>
                  <div className="download-btn">
                    <a href="https://play.google.com/store/apps/details?id=com.theclassy.in">
                      <img
                        src="/assets/uploads/media-uploader/google-play.png"
                        alt="..."
                        className="w-100 h-100"
                      ></img>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-6">
                <div className="footer-widget widget">
                  <h6 className="widget-title">Quick Links</h6>
                  <div className="footer-inner">
                    <ul className="footer-link-list">
                      <li className="list listLine">
                        <Link className="listLine" to="/home">
                          Home
                        </Link>
                      </li>
                      <li className="list listLine">
                        <Link className="listLine" to="/about">
                          About
                        </Link>
                      </li>
                      {/* <li className="list listLine">
                        <Link className="listLine" to="career">
                          Career
                        </Link>
                      </li> */}
                      <li className="list listLine">
                        <Link className="listLine" to="blog">
                          Blog{" "}
                        </Link>
                      </li>
                      <li className="list listLine">
                        <Link className="listLine" to="contact">
                          Contact
                        </Link>
                      </li>
                      <li className="list listLine">
                        <Link className="listLine" to="/terms">
                          Terms & Conditions
                        </Link>
                      </li>
                      <li className="list listLine">
                        <Link className="listLine" to="/refund">
                          Refund Policy
                        </Link>
                      </li>

                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-6 p-0">
                <div className="footer-widget widget">
                  <h6 className="widget-title">Contact Info</h6>
                  <div className="footer-inner">
                    <ul className="footer-link-address listLine">
                      <li className="list ">
                        <span className="address" style={{ color: "black" }}>
                          <i className="las la-mobile-alt "></i>&nbsp;
                          <a
                            className="listLine text-black"
                            href="tel:+919575077772"
                          >
                            {" "}
                            +919575077772{" "}
                          </a>
                        </span>
                      </li>
                      <li className="list">
                        <span className="address">
                          <i className="las la-envelope"></i>&nbsp;
                          <a
                            href="mailto:support@theclassy.in"
                            className="__cf_email__ listLine text-black"
                          >
                            support@theclassy.in
                          </a>
                        </span>
                      </li>
                    </ul>
                    <div className="footer-socials">
                      <ul className="footer-social-list listLine">
                        <li className="lists listLine">
                          <a
                            className="facebook"
                            href="https://www.facebook.com/profile.php?id=100090579593459"
                          >
                            {" "}
                            <i className="lab la-facebook-f"></i>{" "}
                          </a>
                        </li>
                        <li className="lists listLine">
                          <a
                            className="facebook"
                            href="https://twitter.com/theclassygroups"
                          >
                            {" "}
                            <i className="lab la-twitter"></i>{" "}
                          </a>
                        </li>
                        <li className="lists listLine">
                          <a
                            className="facebook"
                            href="https://www.instagram.com/theclassybyvaletgroups/"
                          >
                            {" "}
                            <i className="lab la-instagram"></i>{" "}
                          </a>
                        </li>
                        <li className="lists listLine">
                          <a
                            className="facebook"
                            href="https://www.youtube.com/channel/UCf5uVoMoFgTu6NXDKF4JTLA"
                          >
                            {" "}
                            <i className="lab la-youtube"></i>{" "}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-12">
                <div className="footer-widget widget">
                  <h6 className="widget-title ">For partners</h6>
                  <div className="footer-inner">
                    <ul className="footer-link-address listLine">
                      {/* <li className="list"><span className="address">
                                                <i className="las la-map-marker-alt"></i>&nbsp;
                                                41/1, Hilton Mall, NY City</span>
                                            </li> */}
                      <li className="list">
                        <span className="address" style={{ color: "black" }}>
                          <i className="las la-mobile-alt "></i>&nbsp;
                          <a
                            className="listLine text-black"
                            // href="tel:+919575077772"
                          >
                            {" "}
                            Register as a professional{" "}
                          </a>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="cities-area">
            <div className="container">
              <div className="head">India</div>
              <div className="city-links">
                <a
                  className="listLine text-black"
                  href="/indore/product_category/ac_service/index.html"
                >
                  Indore
                </a>
                <a
                  className="listLine text-black"
                  href="/bhopal/product_category/ac_service/index.html"
                >
                  Bhopal
                </a>
                <Link className="listLine text-black" to="#">
                  Ahemdabad
                </Link>
              </div>
            </div>
          </div>

          <div className="copyright-area copyright-border">
            <div className="container container-two">
              <div className="row align-items-center">
                <div className="col-lg-4 col-md-6">
                  <ul className="copyright-list">
                    <li className="list listLine">
                      <Link className="listLine text-black" to="/privacy">
                        Privacy Policy
                      </Link>
                    </li>
                    <li className="list listLine">
                      <Link className="listLine text-black" to="/terms">
                        Terms &amp; Conditions
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="copyright-contents">
                    <span>All copyright © 2023 Reserved</span>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="copyright-payment">
                    <ul className="payment-list listLine">
                      <li className="list">
                        <img
                          src="/assets/uploads/media-uploader/ccavenue.png"
                          alt=""
                          className="w-100 h-100"
                        ></img>
                      </li>
                      <li className="list">
                        <img
                          src="/assets/uploads/media-uploader/upi-icon.png"
                          alt=""
                          className="w-100 h-100"
                        ></img>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="mobile-overlay"></div>

      <div id="mobile-icons">
        <Link className="listLine" to="/">
          <i class="fa-solid fa-house"></i>
          <div>Home</div>
        </Link>
        <Link className="listLine" to="search">
          <i class="fa-solid fa-magnifying-glass"></i>
          <div>Search</div>
        </Link>

        {/* <Link className="listLine" to="Booking">
          <i className="fa-regular fa-calendar"></i>
          <div>Booking</div>
        </Link> */}
        {hideBooking ? (
          <Link className="listLine" onClick={() => setmodalLogin(true)}>
            <i className="fa-regular fa-calendar"></i>
            <div>Booking</div>
          </Link>
        ) : (
          <Link className="listLine" to="Booking">
            <i className="fa-regular fa-calendar"></i>
            <div>Booking</div>
          </Link>
        )}
        <Link className="listLine" to="/profile">
          <i class="fa-solid fa-user"></i>
          <div>Profile</div>
        </Link>
      </div>
    </>
  );
}
