import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { URLBrandsImage, URLHomePage } from "../util/UrlConst";
import { Parser } from "html-to-react";
import Moment from "moment";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import "./blogIndex.css";

export default function Blog() {
  const [blogs, setBlogs] = useState([]);
  // console.log(blogs, "blogs");

  async function getAllBlog() {
    try {
      const response = await axios.post(URLHomePage);
      // console.log(response?.data?.data, "response");
      // Handle response data here
      setBlogs(response?.data?.data);
    } catch (error) {
      // Handle errors here
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    getAllBlog();
  }, []);
  const images = [
    {
      image:
        "https://admin.theclassy.in/uploads/ApplianceType/20231105080733.png",
    },
    {
      image:
        "https://admin.theclassy.in/uploads/ApplianceType/20231105080733.png",
    },
    {
      image:
        "https://admin.theclassy.in/uploads/ApplianceType/20231105080733.png",
    },
    {
      image:
        "https://admin.theclassy.in/uploads/ApplianceType/20231105080733.png",
    },
    {
      image:
        "https://admin.theclassy.in/uploads/ApplianceType/20231105080733.png",
    },
    {
      image:
        "https://admin.theclassy.in/uploads/ApplianceType/20231105080733.png",
    },
    {
      image:
        "https://admin.theclassy.in/uploads/ApplianceType/20231105080733.png",
    },
    {
      image:
        "https://admin.theclassy.in/uploads/ApplianceType/20231105080733.png",
    },
    {
      image:
        "https://admin.theclassy.in/uploads/ApplianceType/20231105080733.png",
    },
    {
      image:
        "https://admin.theclassy.in/uploads/ApplianceType/20231105080733.png",
    },
    {
      image:
        "https://admin.theclassy.in/uploads/ApplianceType/20231105080733.png",
    },
  ];

  const [slidesToShow, setSlidesToShow] = useState(2); // Default to 2 slides

  useEffect(() => {
    const handleResize = () => {
      const newSlidesToShow = window.innerWidth >= 768 ? 1 : 1;
      if (newSlidesToShow !== slidesToShow) {
        setSlidesToShow(newSlidesToShow);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [slidesToShow]);
  const containerStyle = {
    width: "500px", // Set your desired width
    height: "300px", // Set your desired height
    margin: "auto", // Center the carousel
    overflow: "hidden", // Ensure that images don't overflow
  };

  const slideStyle = {
    // display: "flex",
    // justifyContent: "center",
  };
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  const imageStyle = {
    maxWidth: "100%", // Make the image fill the slide width without distortion
    maxHeight: "100%", // Make the image fill the slide height without distortion
    objectFit: "cover", // Ensure the image is contained within the slide without distortion
  };
  return (
    <>
      {/* {{-- ===========================================
                                BREADCRUMB
            =========================================== --}} */}
      <div className="banner-inner-area section-bg-3 padding-top-100 padding-bottom-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner-inner-contents">
                <h2 className="banner-inner-title mb-0"> Blogs </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {{-- ===========================================
                                BLOG GRID
            =========================================== --}} */}
      {/* =====blog */}
      <div className="mt-5  container">
        <div className="p-5 rounded-5 mainBackground">
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={60}
            totalSlides={blogs.length}
            visibleSlides={slidesToShow}
            isPlaying
            isInfinite={true}
            interval={5000}
            arrows={true}
          >
            <Slider>
              {blogs.map((img, ind) => (
                <Slide index={ind}>
                  <span>
                    <div className="our-services-box">
                      <div className="our-services-box-img ">
                        {/* <img
                          className=" rounded-5"
                          src={img.image}
                          alt="image"
                        /> */}
                        <div
                          className="photo "
                          // style={{ backgroundImage: `url(${img.image})` }}
                        >
                          <img
                            className="rounded-5 photo"
                            src={img.image}
                            alt="image"
                          />
                        </div>
                      </div>
                      <div className="post-meta">
                        <div
                          className="post-category light-green"
                          style={{ fontFamily: "Poppins" }}
                        >
                          {/* <a href="#" className="category-name"> */}
                          <h4 className="fw-bold p-0 m-0 commonfontFamily">
                            {" "}
                            Repairing
                          </h4>
                          {/* </a> */}
                        </div>
                        <p
                          class="author commonfontFamily"
                          style={{ fontFamily: "Poppins" }}
                        >
                          {/* November 30, 2023  */}
                          {Moment(img.created_at).format("d MMM YY")}

                          <span class="devider">|</span>
                          {/* <a href="#"></a> */}
                        </p>
                      </div>
                      <div class="post-title">
                        <h2
                          // style={{ fontFamily: "Poppins" }}
                          className="commonfontFamily"
                        >
                          {/* <a href="#"> */}
                          {img?.title}
                          {/* </a> */}
                        </h2>
                      </div>
                      <div class="post-description">
                        <p></p>
                        <p className="commonfontFamily">{img?.description}</p>
                        <p></p>
                      </div>
                    </div>
                  </span>
                </Slide>
              ))}
            </Slider>
          </CarouselProvider>
        </div>
      </div>
      {/* === */}
      <div className="px-3">
        <h1 className="commonfontFamily fs-1 fw-bolder d-block d-lg-none d-md-none">
          blog
        </h1>
      </div>
      <section className="blog-area padding-top-40 padding-bottom-40">
        <div className="container">
          <div className="row">
            {blogs?.map((data) => (
              <div className="col-xl-3 p-3 col-lg-4 col-md-6 margin-top-30">
                <a href={`/blog-details/${data.id}`} className="listLine">
                  <div
                    className="single-blog no-margin wow fadeInUp"
                    data-wow-delay=".2s"
                  >
                    <span
                      to={`/blog-details/${data.id}`}
                      className="blog-thumb service-bg-thumb-format"
                    >
                      <img
                        src={data?.image}
                        alt="..."
                        className="w-100 h-100"
                      ></img>
                    </span>
                    <div className="blog-contents">
                      <ul className="tags">
                        <li>
                          <i className="las la-clock text-black"></i>
                          <span className="commonfontFamily text-black">
                            {" "}
                            {Moment(data.created_at).format("d MMM YY")}
                          </span>
                          {/* {"12/06/2023"} */}
                        </li>
                        {/* <li>
                        <i className="las la-tag"></i>Electronics
                      </li> */}
                      </ul>
                      <h5 className="common-title">
                        {/* <Link to={`/blog-details/${data.id}`}>{data.title}</Link> */}
                        <span className="text-black commonfontFamily">
                          {data?.title}
                        </span>
                      </h5>
                      {/* <p className="common-para commonfontFamily">
                      {data?.description}
                    </p> */}
                      <p className="text-black common-para commonfontFamily">
                        {showFullDescription
                          ? data.description
                          : `${data.description.slice(0, 50)}...`}
                      </p>
                      {!showFullDescription && (
                        <button className="bg-transparent text-primary">
                          See More
                        </button>
                      )}
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>

        {/* === */}
        {/* <div class="blog-card">
          <div class="meta">
            <div
              class="photo"
            ></div>
            <ul class="details">
              <li class="author">
                <a href="#">John Doe</a>
              </li>
              <li class="date">Aug. 24, 2015</li>
              <li class="tags">
                <ul>
                  <li>
                    <a href="#">Learn</a>
                  </li>
                  <li>
                    <a href="#">Code</a>
                  </li>
                  <li>
                    <a href="#">HTML</a>
                  </li>
                  <li>
                    <a href="#">CSS</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="description">
            <h1>Learning to Code</h1>
            <h2>Opening a door to the future</h2>
            <p>
              {" "}
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad eum
              dolorum architecto obcaecati enim dicta praesentium, quam nobis!
              Neque ad aliquam facilis numquam. Veritatis, sit.
            </p>
           
          </div>
        </div>
        <div class="blog-card alt">
          <div class="meta">
            <div
              class="photo"
            ></div>
            <ul class="details">
              <li class="author">
                <a href="#">Jane Doe</a>
              </li>
              <li class="date">July. 15, 2015</li>
              <li class="tags">
                <ul>
                  <li>
                    <a href="#">Learn</a>
                  </li>
                  <li>
                    <a href="#">Code</a>
                  </li>
                  <li>
                    <a href="#">JavaScript</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="description">
            <h1>Mastering the Language</h1>
            <h2>Java is not the same as JavaScript</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad eum
              dolorum architecto obcaecati enim dicta praesentium, quam nobis!
              Neque ad aliquam facilis numquam. Veritatis, sit.
            </p>
           
          </div>
        </div> */}
        {/* == */}

        {/* == */}

        {/* == */}
      </section>
    </>
  );
}
