import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { UrlHistoryContext } from "../../context/UrlHistoryProvider";
import { Form, Button } from "react-bootstrap";
import { URLAccountRemove } from "../../util/UrlConst";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function AccountRemove() {
  const navigate = useNavigate();

  const { accountRemoveModal, setAccountRemoveModal } =
    useContext(UrlHistoryContext);
  const logout = (e) => {
    // toast("Succesfully Logout.", { type: "success", theme: "dark" });
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("cart");
    sessionStorage.clear();
    navigate("/", { replace: true });
  };
  const handleClose = () => {
    setAccountRemoveModal(false);
  };
  const [formData, setFormData] = useState({
    // username: "",
    // phoneNumber: "",
    reason: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let token = sessionStorage.getItem("token");
    const { username, phoneNumber, reason } = formData;

    try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      // var removeAccount = `${URLAccountRemove}${username}&number=${phoneNumber}&reason=${reason}`;
      var removeAccount = `${URLAccountRemove}`;

      const res = await axios.post(removeAccount);
      if (res?.data.error) {
        toast(res?.data?.message, {
          type: "error",
          theme: "dark",
        });
      } else {
        toast("Your Account Data will be remove with in 15 days", {
          type: "success",
          theme: "dark",
        });
        handleClose();
        setFormData({
          username: "",
          phoneNumber: "",
          reason: "",
        });
        navigate("/");
        logout();
      }

      // console.log(data,'hellll')
      // var data =
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
    }
  };

  return (
    <>
      {/* <Modal
        Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="category-modal"
        show={accountRemoveModal}
        onHide={handleClose}
        className="main_model"
        dialogClassName="main_model"
      >
        <Modal.Header
          className="modelHeder"
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            padding: "15px",
            border: "none",
            bottom: "50px",
          }}
        >
          <div className="modalCloseButton cursor-pointer">
            <span
              style={{ margin: "3px 8px" }}
              onClick={() => handleClose()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-x"
                style={{ margin: "3px -3px", color: "gray" }}
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </span>
          </div>
        </Modal.Header>
        <div
          style={{
            position: "absolute",
            width: "100%",
            borderBottom: "2px solid #E3E3E3" 
            boxSizing:
              "border-box" 
          }}
        >
          <h3
            className="m-0 model_heading"
            style={{ marginLeft: "10px", padding: "12px ", fontWeight: "bold" }}
          >
          </h3>
        </div>
        <Modal.Title className="p-0 m-0">Account Remove Details</Modal.Title>
        <Modal.Body className="m-0 p-0 model_body">
          <div className="">
            <Form className="p-0 mx-5" onSubmit={handleSubmit}>
              <Form.Group className="p-2" controlId="username">
                <Form.Label className="fw-medium">Username</Form.Label>
                <Form.Control
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="phoneNumber" className="p-2">
                <Form.Label className="fw-medium">Phone Number</Form.Label>
                <Form.Control
                  type="tel"
                  name="phoneNumber"
                  pattern="[0-9]{10}"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  required
                />
                
              </Form.Group>

              <Form.Group controlId="reason " className="p-2">
                <Form.Label className="fw-medium">
                  Reason for Account Remove
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  name="reason"
                  value={formData.reason}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <div className="text-right p-1">
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal> */}
      <div className="row pt-5 mt-5">
        <div className="col-6">
          <Form className="p-0 mx-5" onSubmit={handleSubmit}>
            <Form.Group controlId="reason " className="p-2">
              <Form.Label className="fw-medium">
                Reason for Account Remove
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                name="reason"
                value={formData.reason}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <div className="text-right p-1">
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </div>
          </Form>
        </div>
        {/* <div className="col-6"></div> */}
        <div className="col-lg-6">
          <div className="right-imagess">
            <div className="right-img1">
              <img
                src="https://img.freepik.com/free-vector/professional-cleaning-service-isometric-banners_1284-21788.jpg?w=740&t=st=1701498690~exp=1701499290~hmac=ec02c852d7126c2e2351640cc405f55f6d1c3f15da04b6c2e1ff2e67b5824822"
                alt="..."
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AccountRemove;
