import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

export default function ProductBooked() {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <>

            {/* {{-- ===========================================
                                BREADCRUMB
            =========================================== --}} */}
            <div className="banner-inner-area section-bg-3 padding-top-100 padding-bottom-30">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="banner-inner-contents">
                                <h2 className="banner-inner-title mb-0"> Products Booked </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* {{-- ===========================================
                                PRODUCTS LIST
            =========================================== --}} */}
            <section className="booked-services-list padding-top-40 padding-bottom-40">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="book-service-box" onClick={handleShow}>
                                <div className="image">
                                    <img src="/assets/uploads/media-uploader/young-beautiful-cleaner-woman-holding-bucket-with-products-pointing-camera-against-blue-backdrop-491643715397.png" alt="..." className='w-100 h-100'></img>
                                </div>
                                <div className="details">
                                    <div className="name">Lorem, ipsum dolor.</div>
                                    <div className="price">Rs. 500</div>
                                    <div className="status">
                                        <span>Status : </span>
                                        Pending
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="book-service-box" onClick={handleShow}>
                                <div className="image">
                                    <img src="/assets/uploads/media-uploader/young-beautiful-cleaner-woman-holding-bucket-with-products-pointing-camera-against-blue-backdrop-491643715397.png" alt="..." className='w-100 h-100'></img>
                                </div>
                                <div className="details">
                                    <div className="name">Lorem, ipsum dolor.</div>
                                    <div className="price">Rs. 500</div>
                                    <div className="status">
                                        <span>Status : </span>
                                        Pending
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="book-service-box" onClick={handleShow}>
                                <div className="image">
                                    <img src="/assets/uploads/media-uploader/young-beautiful-cleaner-woman-holding-bucket-with-products-pointing-camera-against-blue-backdrop-491643715397.png" alt="..." className='w-100 h-100'></img>
                                </div>
                                <div className="details">
                                    <div className="name">Lorem, ipsum dolor.</div>
                                    <div className="price">Rs. 500</div>
                                    <div className="status">
                                        <span>Status : </span>
                                        Pending
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="book-service-box" onClick={handleShow}>
                                <div className="image">
                                    <img src="/assets/uploads/media-uploader/young-beautiful-cleaner-woman-holding-bucket-with-products-pointing-camera-against-blue-backdrop-491643715397.png" alt="..." className='w-100 h-100'></img>
                                </div>
                                <div className="details">
                                    <div className="name">Lorem, ipsum dolor.</div>
                                    <div className="price">Rs. 500</div>
                                    <div className="status">
                                        <span>Status : </span>
                                        Pending
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* {{-- ===========================================
                                PRODUCT MODAL
            =========================================== --}} */}
            <Modal size="md" id="product-modal" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Product Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="service-top">
                        <div className="image">
                            <img src="/assets/uploads/media-uploader/young-beautiful-cleaner-woman-holding-bucket-with-products-pointing-camera-against-blue-backdrop-491643715397.png" alt="..." className='w-100 h-100'></img>
                        </div>
                        <div className="details">
                            <div className="serice-id">ORDR58987654</div>
                            <div className="name">Lorem, ipsum dolor.</div>
                            <div className="price">Rs. 500</div>
                            <div className="status">Product Booked : 17-02-2023 12:12 PM</div>
                        </div>
                    </div>
                    <div className="service-detail">
                        <div className="head">Product Location</div>
                        <div className="content">
                            <div className="name">Piyush Sahu</div>
                            <div className="address">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum veniam maxime odit!
                            </div>
                        </div>
                    </div>
                    <div className="payment-detail">
                        <div className="head">Payment Details</div>
                        <table className="table table-borderless">
                            <tbody>
                                <tr>
                                    <td>Product</td>
                                    <td>2</td>
                                </tr>
                                <tr>
                                    <td>Product Price</td>
                                    <td>Rs. 400</td>
                                </tr>
                                <tr>
                                    <td>Coupon Price</td>
                                    <td>Rs. 500</td>
                                </tr>
                                <tr>
                                    <td>Offer Price</td>
                                    <td>Rs. 200</td>
                                </tr>
                                <tr>
                                    <td>Total Price</td>
                                    <td>Rs. 1000</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="service-detail">
                        <div className="head">Payment Method</div>
                        <div className="content">
                            <div className="payment">
                                Online Payment : Rs. 1000
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button>Invoice</Button>
                    <Button>Payment</Button>
                </Modal.Footer>

            </Modal>

        </>
    )
}
