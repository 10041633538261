import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";

function OwlCarousels({ props, formostbooked }) {
  const formostbooked1 = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: true,
    autoplay: false,
    navText: [
      '<i class="las la-angle-left"></i>',
      '<i class="las la-angle-right"></i>',
    ],
    smartSpeed: 500,
    responsive: {
      0: {
        items: 1,
        nav: false,
        dots: true,
      },
      400: {
        items: 1,
        nav: false,
        dots: true,
      },
      576: {
        items: 2,
        nav: false,
        dots: true,
      },
      768: {
        items: 2,
        nav: false,
      },
      991: {
        items: 3,
      },
      1200: {
        items: 4,
      },
    },
  };

  return (
    <>
      <section key={props.key} className="services-area container pt-lg-3 pb-lg-3 borderONHome">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="section-title-two"></div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 p-0 m-0">
              <div key={props.key} className="services-slider nav-style-one dot-color-01">
                <OwlCarousel
                  loop
                  className="owl-theme"
                  margin={8}
                  navText={[
                    '<i class="fa fa-chevron-left"></i>',
                    '<i class="fa fa-chevron-right"></i>',
                  ]}
                  responsive={{
                    0: {
                      items: 1,
                      nav: false,
                      dots: false,
                      autoHeight: true,
                      autoplay: true,
                      autoplayTimeout: 3000,
                    },
                    576: {
                      items: 2,
                      nav: true,
                      dots: false,
                      autoHeight: false,
                      autoplay: true,
                      autoplayTimeout: 3000,
                    },
                    768: {
                      items: 3,
                      nav: true,
                      dots: false,
                      autoHeight: false,
                      autoplay: true,
                      autoplayTimeout: 3000,
                    },
                  }}
                >
                  {props?.items?.map((data, index) => (
                    <div className="sandhya-owl">
                      <Link
                      key={index}
                        // to="/explore-all/repair"
                        to={`/category-details/${data?.id}/${encodeURIComponent(
                          data?.name
                        )}/${"Banner"}`}
                        // service-bg-thumb-format
                        className="service-thumb location_relative "
                      >
                        <div class="owl-images">
                          <img
                            src={data?.image}
                            alt="..."
                            className="w-100 h-100 "
                          ></img>
                        </div>
                      </Link>
                    </div>
                  ))}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default OwlCarousels;
