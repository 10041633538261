// RedirectComponent.js

import React, { useEffect } from "react";

function RedirectComponent() {
  useEffect(() => {
    const currentHostname = window.location.hostname;
    if (currentHostname.startsWith("www.")) {
      window.location.href = `https://${currentHostname.substring(4)}${
        window.location.pathname
      }`;
    }
  }, []);

  return null;
}

export default RedirectComponent;
