import axios from "axios";
import { Parser } from "html-to-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { URLBlogDetails, URLBrandsImage } from "../util/UrlConst";

export default function BlogDetail() {
  let { id } = useParams();

  const [blog, setBlogs] = useState([]);

  async function getdata() {
    await axios.post(URLBlogDetails, { id: id }).then((response) => {
      setBlogs(response.data.data);
      // console.log(response, "respo");
    });
  }

  useEffect(() => {
    getdata();
  }, []);

  return (
    <>
      {/* {{-- ===========================================
                            BREADCRUMB
        =========================================== --}} */}
      <div className="banner-inner-area section-bg-3 padding-top-100 padding-bottom-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner-inner-contents">
                <h2 className="banner-inner-title mb-0"> Blog Details </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {{-- ===========================================
                            BLOG GRID
        =========================================== --}} */}

      <section className="blog-details-area padding-top-40 padding-bottom-40">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-details-wrapper">
                <div className="single-blog-details">
                  <div className="thumb">
                    <img src={blog.image} alt="" className="w-100 h-100"></img>
                  </div>
                  <ul className="tags">
                    <li className="list " style={{ listStyle: "none" }}>
                      <i className="las la-clock"></i>
                      {moment(blog.created_at).format("d MMM YY")}
                    </li>
                    {/* <li className="list">
                      <i className="las la-tag"></i> Electronics
                    </li> */}
                  </ul>
                  <h4 className="fs-5 common-title commonfontFamily">
                    {blog.title}
                  </h4>
                  <p className="details-para">
                    <div className="text-justify">
                      <div className="text-black">
                        <p className="commonfontFamily">{blog?.description}</p>
                      </div>
                    </div>
                  </p>
                  {/* <blockquote>
                                        <div className="content">
                                            <h3 className="blackquote-title">80 days around the world, we’ll find a pot of gold just
                                                sitting where the rainbow’s ending.</h3>
                                        </div>
                                    </blockquote> */}
                </div>

                {/* <div className="details-tag-area padding-top-10">
                  <div className="row align-items-center">
                    <div className="col-lg-6 margin-top-30">
                      <div className="social-share">
                        <h4 className="share-tiitle">Share: </h4>
                        <ul>
                          <li className="list-item">
                            <a className="facebook-bg" href="facebook">
                              <i className="lab la-facebook-f"></i>
                            </a>
                          </li>
                          <li className="list-item">
                            <a className="twitter-bg" href="twitter">
                              <i className="lab la-twitter"></i>
                            </a>
                          </li>
                          <li className="list-item">
                            <a className="pintarest-bg" href="pinterest">
                              <i className="lab la-pinterest-p"></i>
                            </a>
                          </li>
                          <li className="list-item">
                            <a className="youtube-bg" href="youtube">
                              <i className="lab la-youtube"></i>
                            </a>
                          </li>
                          <li className="list-item">
                            <a className="instagram-bg" href="instagram">
                              <i className="lab la-instagram"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
