import React, { useContext, useEffect, useState } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import axios from "axios";
import { URLCheckArea } from "../util/UrlConst";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UrlHistoryContext } from "../context/UrlHistoryProvider";

const MapContainer = () => {
  const {
    setPostPinCode,
    postPinCode,
    setmodalLocation,
    modalLocation,
    mapData,
    setMapData,
    openLocation,
    locationModakOpen,
  } = useContext(UrlHistoryContext);

  const [map, setMap] = useState(null);
  const [currentLocation, setCurrentLocation] = useState({ lat: 0, lng: 0 });
  const [destination, setDestination] = useState("");
  const [searchAddress, setSearchAddress] = useState("");
  const [destinationLocation, setDestinationLocation] = useState(null);
  const [currentActive, setCurrentActive] = useState("Choose");
  const [pinCodeNew, setPincodeNew] = useState();
  const [errors, setErrors] = useState({});

  let token = sessionStorage.getItem("token");

  const mapStyles = {
    height: "100%",
    width: "100%",
    marginLeft: "5px",
    // marginTop: "5px",
  };

  const defaultCenter = {
    lat: 0,
    lng: 0,
  };

  const onLoad = (map) => {
    setMap(map);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  // const handleLocation = async (latLng, address) => {
  //   const reverseGeocodeResponse = await fetch(
  //     // `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latLng.lat}&lon=${latLng.lng}`
  //     `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat},${latLng.lng}&key=AIzaSyC85uqpr_XiUY8O_Usjg3XV92w44vPpx4w`
  //   );
  //   console.log(reverseGeocodeResponse, "reverseGeocodeResponse");
  //   const reverseGeocodeData = await reverseGeocodeResponse.json();

  //   setPostPinCode({
  //     address_name: reverseGeocodeData?.name
  //       ? reverseGeocodeData?.name
  //       : reverseGeocodeData?.address?.county,
  //     pinCode: reverseGeocodeData?.address?.postcode,
  //     longitude: latLng.lat,
  //     latitude: latLng.lng,
  //     // mapData: address,
  //     mapData: reverseGeocodeData?.display_name,

  //     Name: "",
  //     HouseNo: "",
  //   });

  //   if (map) {
  //     map.panTo(latLng);
  //     map.setZoom(13);
  //   }
  // };
  const handleLocation = async (latLng, address) => {
    try {
      const googleResponse = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat},${latLng.lng}&key=AIzaSyC85uqpr_XiUY8O_Usjg3XV92w44vPpx4w`
      );
      const googleData = await googleResponse.json();

      if (googleData && googleData.results && googleData.results.length > 0) {
        const result = googleData.results[0];
        const postalCodeObj = result.address_components.find((component) =>
          component.types.includes("postal_code")
        );
        // console.log(postPinCode, result, "postalCodeObj");
        let requiredData = [
          result.address_components[0]["long_name"],
          result.address_components[1]["long_name"],
        ];
        let formattedData = requiredData.join(", ");

        let requiredData1 = [
          result.address_components[2]["long_name"],
          result.address_components[3]["long_name"],
          result.address_components[7]["long_name"],
          result.address_components[8]["long_name"],
        ];
        let formattedData1 = requiredData1.join(", ");

        if (postalCodeObj) {
          setPostPinCode({
            address_name: formattedData1,
            pinCode: postalCodeObj.long_name,
            longitude: latLng.lat,
            latitude: latLng.lng,
            mapData: result.formatted_address,
            Name: postPinCode.Name ? postPinCode.Name : "",
            HouseNo: postPinCode.HouseNo ? postPinCode.HouseNo : "",
            landmark: formattedData,
          });
          if (map) {
            map.panTo(latLng);
            map.setZoom(13);
          }
          return {
            address_name: formattedData1,
            pinCode: postalCodeObj.long_name,
            longitude: latLng.lat,
            latitude: latLng.lng,
            mapData: result.formatted_address,
            Name: postPinCode.Name ? postPinCode.Name : "",
            HouseNo: postPinCode.HouseNo ? postPinCode.HouseNo : "",
            landmark: formattedData,
          };
        } else {
          console.error(
            "Postal code not found in Google Maps Geocoding API response."
          );
          // Handle error appropriately
        }
      } else {
        console.error(
          "No results found in Google Maps Geocoding API response."
        );
        // Handle error appropriately
      }
    } catch (error) {
      console.error("Error occurred during fallback geocoding:", error);
      // Handle error appropriately
    }
  };

  const getCurrentLocation = async () => {
    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      const newPosition = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };

      setMap((prevMap) => {
        if (prevMap) {
          setCurrentLocation(newPosition);
          handleLocation(newPosition);
          prevMap.panTo(newPosition);
        }
        return prevMap;
      });
    } catch (error) {
      console.error("Error getting user location:", error);
    }
  };

  useEffect(() => {
    // Trigger the search whenever the destination changes
    handleSearch();
  }, [destination]);

  const handleSelect = async (address) => {
    // console.log(address, "addddddddddddd");
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      setCurrentLocation(latLng);
      setDestinationLocation(latLng);

      var data = await handleLocation(latLng, address);
      // .then((res) => {
      //   console.log("data==1", res);
      // })
      // .catch((err) => {});
      // console.log(data, "datadata");
      return data;
    } catch (error) {
      console.error("Error selecting location:", error);
    }
  };

  const handleSearch = () => {
    if (map && destination.trim() !== "") {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: destination }, (results, status) => {
        if (status === "OK" && results.length > 0) {
          const location = results[0].geometry.location;
          setDestinationLocation({
            lat: location.lat(),
            lng: location.lng(),
          });
          if (map) {
            // Animate to the new location
            map.panTo(location);
            map.setZoom(13);
          }
        } else {
        }
      });
    }
  };

  const handleCheckArea = async () => {
    try {
      const value = await handleSelect(postPinCode.mapData);

      // console.log(postPinCode, value, "postPinCode");

      const newErrors = {};
      if (!postPinCode?.HouseNo?.trim()) {
        newErrors.HouseNo = "HouseNo is required";
      }
      if (!postPinCode?.Name?.trim()) {
        newErrors.Name = "Name is required";
      }
      if (!postPinCode?.mapData?.trim()) {
        newErrors.mapData = "Your pick Address is required";
      }

      if (locationModakOpen && Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        throw new Error("Validation errors occurred");
      }

      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      const response = await axios.post(URLCheckArea + value?.pinCode);

      if (response.data.error) {
        toast(response.data.message, { type: "error", theme: "dark" });
      } else {
        toast("Congratulations you are in our serviceable area!", {
          type: "success",
          theme: "dark",
        });
        setmodalLocation(false);
        setMapData(postPinCode?.mapData);
      }

      return value;
    } catch (error) {
      console.error("Error in handleCheckArea:", error);
      throw error;
    }
  };

  const handleInputChange = (name, e) => {
    setPostPinCode((prevData) => ({ ...prevData, [name]: e.target.value }));
    setErrors({
      ...errors,
      [name]: "",
    });
  };
  // console.log(suggestions, "suggestionssuggestions");
  return (
    <div>
      {/* <ToastContainer /> */}
      <div className="row locationColHeight p-0 m-0">
        <div className=" col-lg-6 col-12 col-md-6 p-0 m-0 pe-2">
          {" "}
          <GoogleMap
            mapContainerStyle={mapStyles}
            // className="googleMap"
            zoom={7}
            center={currentLocation.lat !== 0 ? currentLocation : defaultCenter}
            onLoad={onLoad}
            // className="mt-3"
          >
            {currentLocation.lat !== 0 && <Marker position={currentLocation} />}
            {destinationLocation && (
              <Marker
                position={destinationLocation}
                icon={{
                  url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                }}
              />
            )}
          </GoogleMap>
        </div>
        <div className="col-12 col-lg-6 col-md-6 pt-2 pt-lg-0 pt-md-0">
          <PlacesAutocomplete
            value={searchAddress}
            onChange={(address) => setSearchAddress(address)}
            onSelect={handleSelect}
            style={{ width: "100px" }}
            className="pt-2"
          >
            {({ getInputProps, suggestions, getSuggestionItemProps }) => (
              <div>
                {/* <input
              {...getInputProps({
                placeholder: "Search Places...",
                className: "location-search-input",
              })}
            /> */}
                <div
                  className="signup-area  "
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <div className="px-2" style={{ width: "100%" }}>
                    <input
                      className="py-3"
                      style={{
                        height: "100%",
                        width: "100%",
                        borderRadius: "8px",
                        border: "1px solid #dcdcdc",
                        paddingLeft: "50px",
                        background: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.9 19.7" class="svg-icon search-icon" aria-labelledby="title desc" role="img"><title id="title">Search Icon</title><desc id="desc">A magnifying glass icon.</desc><g class="search-path" fill="none" stroke="%23848F91"><path stroke-linecap="square" d="M18.5 18.3l-5.4-5.4" /><circle cx="8" cy="8" r="7" /></g></svg>') no-repeat 10px center`,
                        backgroundSize: "auto 24px",
                      }}
                      {...getInputProps({
                        placeholder: "Search for your location",
                        className: "location-search-input py-3",
                      })}
                    />
                  </div>
                </div>
                <div className="mt-2 px-2 px-sm-3 px-lg-3">
                  <div
                    className="autocomplete-dropdown-container "
                    style={{
                      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                      borderRadius: "8px",
                      height: "100%",
                    }}
                  >
                    {suggestions.map((suggestion) => (
                      <>
                        <div {...getSuggestionItemProps(suggestion)}>
                          <div className="row p-1">
                            <div className="col-3">
                              <div
                                className=""
                                style={{
                                  position: "absolute",
                                  left: "52px",
                                  top: "20%",
                                  transform: "translateY(-50%)",
                                }}
                              >
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  fill="#757575"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.426 22.569L12 21.75l.573.82a1 1 0 01-1.147-.001z"
                                    fill="#757575"
                                  ></path>
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M12 5.75a4 4 0 100 8 4 4 0 000-8zm-2 4a2 2 0 114 0 2 2 0 01-4 0z"
                                    fill="#757575"
                                  ></path>
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M11.426 22.569L12 21.75c.573.82.575.818.575.818l.002-.001.006-.004.02-.015.07-.05.257-.192a25.395 25.395 0 003.575-3.368c1.932-2.223 3.995-5.453 3.995-9.188a8.5 8.5 0 10-17 0c0 3.735 2.063 6.965 3.995 9.187a25.4 25.4 0 003.575 3.369 14.361 14.361 0 00.327.242l.02.015.006.004.003.002zM7.404 5.154A6.5 6.5 0 0118.5 9.75c0 3.015-1.687 5.785-3.505 7.875A23.403 23.403 0 0112 20.495a23.4 23.4 0 01-2.995-2.869C7.187 15.534 5.5 12.764 5.5 9.75a6.5 6.5 0 011.904-4.596z"
                                    fill="#757575"
                                  ></path>
                                </svg>
                              </div>
                            </div>
                            <div className="col-9 mb-2 cursor-pointer">
                              {suggestion?.formattedSuggestion?.mainText}
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </PlacesAutocomplete>
          <div className="px-2">
            <div class="active-card_location px-1 mt-1">
              <div className="py-2">
                <button
                  onClick={() => setCurrentActive("Choose")}
                  className={
                    currentActive == "Choose"
                      ? "listLine text-black actives  py-1"
                      : "listLine text-black non_actives  py-1"
                  }
                >
                  <div className="d-flex gap-2 m-0">
                    {" "}
                    <img src="/assets/frontend/img/banner/location.svg" />
                    <span className="pt-1" style={{ fontSize: "11px" }}>
                      Choose Location
                    </span>
                  </div>
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    getCurrentLocation();
                    setCurrentActive("Current");
                  }}
                  // className="listLine text-black non_actives px-4 py-1"
                  className={
                    currentActive == "Current"
                      ? "listLine text-black actives   py-1"
                      : "listLine text-black non_actives  py-1"
                  }
                >
                  {" "}
                  <div className="d-flex gap-2 ">
                    <svg
                      width="20px"
                      height="20px"
                      viewBox="0 0 20 20"
                      fill="#6E42E5"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.063 3.504V5h1.874V3.504a6.567 6.567 0 015.559 5.559H15v1.874h1.496a6.567 6.567 0 01-5.558 5.559V15H9.062v1.496a6.567 6.567 0 01-5.558-5.558H5V9.062H3.504a6.567 6.567 0 015.559-5.558zm0-1.89a8.44 8.44 0 00-7.449 7.449H0v1.874h1.614a8.44 8.44 0 007.449 7.449V20h1.874v-1.614a8.44 8.44 0 007.449-7.448H20V9.062h-1.614a8.44 8.44 0 00-7.448-7.448V0H9.062v1.614zM10 8.438a1.563 1.563 0 100 3.125 1.563 1.563 0 000-3.126zM6.562 10a3.437 3.437 0 116.875 0 3.437 3.437 0 01-6.874 0z"
                        fill="gray"
                      ></path>
                    </svg>
                    <span className="pt-1" style={{ fontSize: "11px" }}>
                      {" "}
                      Current Location
                    </span>
                  </div>
                </button>
              </div>
            </div>
          </div>

          <div className="mt-2">
            <div className="d-flex gap-2 pb-2">
              <div className="mt-2"></div>
              <div className="w-100">
                <span
                  className="maxWidthFont  p-0 m-0"
                  // style={{ fontFamily: "Poppines" }}
                >
                  Your pick Address
                </span>
                <input
                  type="text"
                  className="form-control    placeholder-color py-lg-4"
                  placeholder="pick Address"
                  style={{
                    fontFamily: "Poppines",
                    color: "rgba(84, 84, 84, 1.00)",
                  }}
                  value={postPinCode?.mapData}
                  // onChange={(e) =>
                  //   setPostPinCode({ ...postPinCode, mapData: e.target.value })
                  // }
                  onChange={(e) => handleInputChange("mapData", e)}
                />
                {errors?.mapData && (
                  <div
                    style={{ color: "red", fontFamily: "Poppines" }}
                    className=" text-sm"
                  >
                    <i
                      style={{ color: "red" }}
                      class=" mx-2 fa-solid fa-triangle-exclamation"
                    ></i>
                    {errors?.mapData}
                  </div>
                )}
              </div>
            </div>
            {locationModakOpen ? (
              <>
                <div className="d-flex gap-2 pb-2">
                  <div className="mt-2"></div>
                  <div className="w-100">
                    <span
                      className="maxWidthFont "
                      style={{ fontFamily: "Poppines" }}
                    >
                      House/Flat Number
                    </span>
                    <input
                      type="text"
                      className="form-control placeholder-color py-lg-4"
                      placeholder=" House/Flat Number *"
                      style={{
                        fontFamily: "Poppines",
                        color: "rgba(84, 84, 84, 1.00)",
                      }}
                      value={postPinCode?.HouseNo}
                      // onChange={(e) =>
                      //   setPostPinCode({
                      //     ...postPinCode,
                      //     address_name: e.target.value,
                      //   })
                      // }
                      // onChange={(e) =>
                      //   setPostPinCode({
                      //     ...postPinCode,
                      //     HouseNo: e.target.value,
                      //   })
                      // }
                      onChange={(e) => handleInputChange("HouseNo", e)}
                    />
                    {errors?.HouseNo && (
                      <div
                        style={{ color: "red", fontFamily: "Poppines" }}
                        className=" text-sm"
                      >
                        <i
                          style={{ color: "red" }}
                          class=" mx-2 fa-solid fa-triangle-exclamation"
                        ></i>
                        {errors?.HouseNo}
                      </div>
                    )}
                  </div>
                </div>

                <div className="d-flex gap-2">
                  <div className="mt-2"></div>
                  <div className="w-100">
                    <span
                      className=" p-0 m-0 maxWidthFont"
                      // style={{ fontFamily: "Poppines" }}
                    >
                      Your Name
                    </span>

                    <input
                      type="text"
                      className="form-control placeholder-color py-lg-4"
                      placeholder="Plese Enter your *"
                      style={{
                        fontFamily: "Poppines",
                        color: "rgba(84, 84, 84, 1.00)",
                      }}
                      // onChange={(e) =>
                      //   setPostPinCode({ ...postPinCode, Name: e.target.value })
                      // }
                      value={postPinCode?.Name}
                      onChange={(e) => handleInputChange("Name", e)}
                    />
                    {errors?.Name && (
                      <div
                        style={{ color: "red", fontFamily: "Poppines" }}
                        className=" text-sm"
                      >
                        <i
                          style={{ color: "red" }}
                          class=" mx-2 fa-solid fa-triangle-exclamation"
                        ></i>
                        {errors?.Name}
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {/* {locationModakOpen ? (
              <div className="col-12 d-flex mt-2 bottom_Submit">
                <button
                  type="button"
                  onClick={() => {
                    handleCheckArea();
                  }}
                  disabled={
                    postPinCode?.pinCode &&
                    postPinCode?.Name &&
                    postPinCode?.HouseNo
                      ? false
                      : true
                  }
                  className={` mx-4 btn btn-lg btn-block w-100 rounded-4`}
                  style={{
                    backgroundColor: postPinCode?.pinCode
                      ? "#007AFF"
                      : "#343a40",
                    color: "#ffffff", // Set text color
                    fontFamily: "Poppines",
                  }}
                >
                  Submit
                </button>
              </div>
            ) : ( */}
            <div className="col-12 d-flex mt-2 bottom_Submit">
              <button
                type="button"
                onClick={() => {
                  handleCheckArea();
                }}
                // disabled={
                //   postPinCode?.pinCode && postPinCode?.Name ? false : true
                // }
                className={` mx-4 btn btn-lg btn-block w-100 rounded-4`}
                style={{
                  backgroundColor: "#007AFF",

                  color: "#ffffff",
                  fontFamily: "Poppines",
                }}
              >
                Submit
              </button>
            </div>
            {/* )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapContainer;
