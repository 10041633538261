import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ProductComponent from "../component/ProductComponent";
import {
  URLApplianceTypeDetails,
  URLApplianceTypeDetailsNew,
  URLCategoryDetails,
  URLSliderDetails,
  URLSubSubCategoryImage,
} from "../util/UrlConst";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { useNavigate } from "react-location";
import { UrlHistoryContext } from "../context/UrlHistoryProvider";
import ServiceProblemComponent from "../component/ServiceProblemComponent";
import Problems_card from "../component/Problems_card";
import Problems_card_show from "../component/product_card_show";
import LoginModals from "./components/loginSignUp/login";
import "bootstrap/dist/css/bootstrap.min.css"; // Make sure to include Bootstrap CSS
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";


import ReactGA from 'react-ga';


export default function CategoryDetails() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });
  let { id } = useParams();
  let { name } = useParams();
  let { Banner } = useParams();
  const url = window.location.href;

  const [serviceName, setServiceName] = useState([]);
  const [serviceType, setServiceType] = useState([]);
  const [cardData, setCardData] = useState();
  const [cartQuantity, setCartQuantity] = useState(0);
  const [hideBooking, setHideBooking] = useState();

  const [categoryName, setCategoryName] = useState();
  const [categoryImg, setCategoryImg] = useState();
  const [isloadin, setLoading] = useState(true);
  const [token, setToken] = useState();
  const [show1, setShow1] = useState(false);
  const [productId1, setProductId1] = useState();
  const {
    changeLocalState,
    setChangeLocalState,
    setmodalLogin,
    modalLogin,
    salePrice,
    mrp,
    setModal,
  } = useContext(UrlHistoryContext);

  useEffect(() => {
    let tokens = sessionStorage.getItem("token");
    let categoryName = sessionStorage.getItem("categoryName");
    let categoryImg = sessionStorage.getItem("categoryImg");

    setToken(tokens);
    setCategoryName(categoryName);
    setCategoryImg(categoryImg);

    setModal(false);
  });

  const getdata = async () => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    //   URLCategoryDetails
    if (Banner == "Banner") {
      await axios.post(URLSliderDetails + id).then((response) => {
        setServiceName(response?.data?.data);
        setServiceType(response?.data?.data);

        setTimeout(() => {
          setLoading(false);
          // nextFunction();
        }, 1000);
      });
    } else {
      // await axios.post(URLApplianceTypeDetails + id).then((response) =>
      await axios.post(URLApplianceTypeDetailsNew + id).then((response) => {
        setServiceName(response?.data?.data);
        setServiceType(response.data.data);

        // nextFunction();
        // setTimeout(() => {
        //   setLoading(false);
        // }, 1000);
      });
    }
  };
  const nextFunction = () => {
    // Your logic for the next function
    // useEffect(() => {
    //   console.log("hello 00001");
    // This code will run when the component mounts or updates
    var storedData = localStorage.getItem("problem");
    if (storedData) {
      var parsedData = JSON.parse(storedData);

      if (parsedData) {
        setShow1(parsedData?.problem);
        setProductId1(parsedData?.id);
      }
    }
    // }, [1]);
  };

  useEffect(() => {
    // debugger;
    // window.scrollTo(0, 0);
    if (id || name || Banner) {
      getdata();
    }
  }, []);

  const checkLog = () => {
    // debugger;
    if (token == null || token == undefined) {
      setHideBooking(false);
    } else {
      setHideBooking(true);
    }
  };
  useEffect(() => {
    checkLog();
  });

  const addToCart = (product) => {
    const existingCart = JSON.parse(sessionStorage.getItem("cart")) || [];
    const existingProduct = existingCart?.find(
      (item) => item.id === product.id
    );

    if (existingProduct) {
      var str = existingProduct.quantity_data;
      let num = Number(str);
      num += 1;
      existingProduct.quantity_data = num;
    } else {
      existingCart.push(product);
    }

    sessionStorage.setItem("cart", JSON.stringify(existingCart));
    setCartQuantity(existingProduct.quantity_data);
  };

  const removeFromCart = (product) => {
    const existingCart = JSON.parse(sessionStorage.getItem("cart")) || [];
    const existingProductIndex = existingCart.findIndex(
      (item) => item.id === product.id
    );

    if (
      existingProductIndex !== -1 &&
      existingCart[existingProductIndex].quantity_data > 1
    ) {
      existingCart[existingProductIndex].quantity_data -= 1;
      setCartQuantity(existingCart[existingProductIndex].quantity_data);
    } else {
      existingCart.splice(existingProductIndex, 1);
      setCartQuantity(0);
    }
    sessionStorage.setItem("cart", JSON.stringify(existingCart));
  };

  const [totalPrice, setTotalPrice] = useState(0);
  const [totalMRP, setTotalMRP] = useState(0);

  const updateTotals = () => {
    const result = cardData?.map((item) => {
      const totalFromProblems = item?.problems
        ? item?.problems?.reduce((acc, problem) => {
          const price = parseInt(problem?.sale_price);
          const quant_data = parseInt(problem?.quantity_data);
          return acc + price * quant_data;
        }, 0)
        : 0;

      const totalFromProperties =
        item.sale_price && item.quantity_data
          ? parseInt(item?.sale_price) * parseInt(item.quantity_data)
          : 0;

      return {
        total: totalFromProblems + totalFromProperties,
      };
    });

    const totalSum = result?.reduce((acc, item) => acc + item.total, 0);

    setTotalPrice(totalSum);
  };

  useEffect(() => {
    updateTotals();
  }, [cardData]);

  // useEffect(() => {
  //   getdata();
  // }, [id, name, Banner]);
  // useEffect(() => {
  //   const getCardData = JSON.parse(sessionStorage.getItem("cart"));
  //   console.log(getCardData, "getCardData");
  //   setCardData(getCardData);
  // }, [changeLocalState]);

  // ==4

  useEffect(() => {
    const getCardData = JSON.parse(sessionStorage.getItem("cart"));

    var dt = getCardData?.filter((item) => item?.appliance_id == id);
    // if(getCardData?.appliance_id == id){

    setCardData(getCardData);
    // }
  }, [changeLocalState]);
  // ==

  useEffect(() => {
    const existingCart = JSON.parse(sessionStorage.getItem("cart"));
    if (existingCart != []) {
      const getProduct = existingCart?.find((item) => item.id === id);
      setCartQuantity(getProduct?.quantity_data);
    }
  }, [changeLocalState]);

  const [slidesToShow, setSlidesToShow] = useState(2); // Default to 2 slides

  useEffect(() => {
    const handleResize = () => {
      const newSlidesToShow = window.innerWidth >= 768 ? 1 : 1;
      if (newSlidesToShow !== slidesToShow) {
        setSlidesToShow(newSlidesToShow);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [slidesToShow]);

  const [shouldInitializeCarousel, setShouldInitializeCarousel] =
    useState(false);

  useEffect(() => {
    // Check the condition when you want to start the carousel
    // For example, you can check if a certain element is present on the page
    const conditionToStartCarousel = true; // Change this condition based on your requirements

    if (conditionToStartCarousel) {
      setShouldInitializeCarousel(true);
    }
  }, []); // Empty dependency array ensures that this effect runs only once after the initial render

  useEffect(() => {
    if (shouldInitializeCarousel) {
      // Initialize the carousel
      const myCarousel = new window.bootstrap.Carousel(
        document.getElementById("demo"),
        {
          interval: 2000000, // Adjust the interval as needed (in milliseconds)
          wrap: true,
        }
      );
    }
  }, [shouldInitializeCarousel]);

  return (
    <>
      <Helmet>
        <title> {`The Classy | ${categoryName}`}</title>
        <meta property="og:url" content={url} />
        <meta property="og:title" content={categoryName} />
        <meta property="og:image" content={categoryImg || ""} />

        {/* <meta name="description" content={`${service?.short_description}`} />
        <meta name="keywords" content={`${service?.tegs}`} /> */}
      </Helmet>
      <LoginModals props={modalLogin} />
      <section
        className="category-area section-bg-1"
        data-padding-top=""
        data-padding-bottom="10"
      >
        <div className="container">
          <div className="row mt-5">
            <div className="col-lg-5  col-sm-12 col-md-4 order-2 order-lg-1 p-4 ">
              {serviceName?.allItems?.length > 0 ? (
                <div className="row rounded-3 mt-2 borderlg">
                  <span className="p-1 ps-2 ms-2 commonfontFamily fw-bold">
                    {categoryName}
                  </span>
                  {serviceName?.allItems?.map((data) => (
                    <>
                      <div className="col-lg-4 col-md-6 col-4">
                        <a
                          href={`#${data.name}`}
                          style={{ textDecoration: "none" }}
                        >
                          <div className="single-category ">
                            <div
                              className="image borderlg img_Card_Slider px-4 py-3"
                            // style={{ background: "#F6F5F5" }}
                            >
                              <img
                                // style={{ objectFit: "contain" }}
                                src={data?.image}
                                // src="https://www.shutterstock.com/shutterstock/photos/402961375/display_1500/stock-photo-photo-of-young-male-technician-repairing-air-conditioner-402961375.jpg"
                                // src="https://res.cloudinary.com/urbanclap/image/upload/t_high_res_template,q_auto:low,f_auto/w_64,dpr_1,fl_progressive:steep,q_auto:low,f_auto,c_limit/images/supply/customer-app-supply/1679580664633-06f041.jpeg"
                                alt="..."
                                className="imgSmallSize rounded-3"
                              ></img>
                            </div>

                            <div
                              className="w-100"
                            // style={{ whiteSpace: "pre-wrap" }}
                            >
                              <p className="p-0 m-0  text-black commonfontFamily ">
                                {data?.name}
                                {/* the classy .in Applince */}
                              </p>
                            </div>
                          </div>
                        </a>
                      </div>
                    </>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-lg-7 col-sm-12 col-md-8 order-sm-1 order-1 order-lg-2 inResponsiveMargin">
              <div
                id="demo"
                className="slider carousel slide pt-2"
                data-bs-ride="carousel"
                data-bs-interval="10000"
              >
                <div className="carousel-inner primary">
                  {serviceName?.slider?.map((data, index) => (
                    <div
                      key={index}
                      className={`images-slider carousel-item ${index === 0 ? "active" : ""
                        }`}
                    >
                      {data?.type === "image" ? (
                        <div className=" rounded-3">
                          <img
                            src={data?.link}
                            alt="Los Angeles"
                            className="d-block cover rounded-3 responveImg"
                          // style={{ width: "370px", height: "300px" }}
                          />
                        </div>
                      ) : (
                        // </div>
                        <div className="">
                          <video
                            style={{ objectFit: "cover" }}
                            className="rounded-2 responveVideo "
                            // style={{ width: "370px", height: "300px" }}
                            autoPlay
                            // controls
                            muted
                          >
                            <source
                              className=""
                              src={data?.link}
                              type="video/mp4"
                            />
                          </video>
                        </div>
                      )}
                    </div>
                  ))}
                </div>

                <button
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#demo"
                  data-bs-slide="prev"
                >
                  <span class="carousel-control-prev-icon"></span>
                </button>
                <button
                  class="carousel-control-next"
                  type="button"
                  data-bs-target="#demo"
                  data-bs-slide="next"
                >
                  <span class="carousel-control-next-icon"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* {{-- ===========================================
                            INNER CATEGORIES
            =========================================== --}} */}
      <section className="explore-all padding-top-20 padding-bottom-40">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 overflow-x-scroll scrollHeighInLg"
            // style={{ height: "600px" }}
            >
              {serviceType?.allItems?.length > 0 ? (
                <div className="product-list">
                  {serviceType.allItems.map((data) =>
                    data.products !== 0 ? (
                      <div key={data.name} id={data.name}>
                        <div
                          className="category-title "
                          style={{ fontFamily: "Poppins" }}
                        >
                          <h1 className="fs-6"> {data.name}</h1>
                        </div>
                        {data.serviceTypes.map((innerData) => (
                          <div key={innerData.name}>
                            <div
                              className="fw-semibold text-body-secondary "
                              style={{ fontFamily: "Poppins" }}
                            >
                              {/* {innerData.name} */}
                            </div>
                            {innerData.items.map((product) => (
                              <ProductComponent
                                key={product.id}
                                product={product}
                                show1={show1}
                                productId1={productId1}
                              />
                            ))}
                            {innerData.applianceTypes &&
                              innerData.applianceTypes.map((product) => (
                                <ProductComponent
                                  key={product.id}
                                  product={product}
                                  show1={show1}
                                  productId1={productId1}
                                />
                              ))}
                          </div>
                        ))}
                      </div>
                    ) : null
                  )}
                </div>
              ) : (
                <>
                  {serviceType?.length > 0 ? null : "product is not available"}
                </>
              )}

              {serviceType?.length > 0
                ? serviceType.map((item) => (
                  <div key={item.name}>
                    <p
                      className="category-title"
                      style={{ fontFamily: "Poppins" }}
                    >
                      {item?.name}
                    </p>
                    {item.applianceTypes?.map((product) => (
                      <ProductComponent
                        key={product.id}
                        product={product}
                        show1={show1}
                        productId1={productId1}
                      />
                    ))}
                  </div>
                ))
                : null}
            </div>

            <div className="col-lg-6">
              <div className="right-static ms-5" style={{ magrinLeft: "18%" }}>
                {cardData && cardData.length > 0 ? (
                  <>
                    <div className="right-card d-none d-lg-block">
                      <div className="cart-head ms-2 ">Cart</div>
                      {cardData?.map((item, index) => (
                        <div key={item.id}>
                          {item?.problem == false || item.problems == false ? (
                            <>
                              <div className="d-flex  pb-2 justify-content-around">
                                <div
                                  className="left-info mx-3 m-0"
                                  style={{ width: "56%" }}
                                >
                                  <p
                                    className="p-0 mx-4 m-0 pt-2 maxWidthFont"
                                    style={{ fontSize: "16px" }}
                                  >
                                    {item?.name}
                                  </p>
                                  {/* <p className="left-info p-0">{problems?.name}</p> */}
                                </div>

                                <div className="mid-info text-center  mx-auto">
                                  <div className="d-flex">
                                    <div className="mid-ico">
                                      <button
                                        className="mid-ico backgroundColor"
                                        onClick={() => {
                                          removeFromCart(item);
                                          setChangeLocalState(
                                            !changeLocalState
                                          );
                                        }}
                                      >
                                        -
                                      </button>
                                    </div>
                                    <div className="mid-ico px-2">
                                      {item.quantity_data}
                                    </div>

                                    <div className="mid-ico">
                                      <button
                                        className="mid-ico backgroundColor"
                                        onClick={() => {
                                          addToCart(item);
                                          setChangeLocalState(
                                            !changeLocalState
                                          );
                                        }}
                                      >
                                        +
                                      </button>
                                    </div>
                                  </div>
                                  <div className="right-info ">
                                    <div className="d-flex">
                                      <p
                                        className="p-0 p-1"
                                        style={{ color: "rgb(224, 224, 224)" }}
                                      >
                                        ₹{" "}
                                      </p>
                                      <p className="p-0 pt-1 text-success fw-bolder">
                                        {parseFloat(item.sale_price) *
                                          parseInt(item.quantity_data)}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {item?.problems?.map((problemItem) => (
                                <>
                                  <Problems_card_show
                                    item={problemItem}
                                    problems={item}
                                  />
                                </>
                              ))}
                            </>
                          )}
                        </div>
                      ))}

                      <div className="bottom-cart ">
                        <div className="bottom-cart-info text-center p-3">
                          <div className="rupes">₹ {totalPrice} </div>
                          {hideBooking ? (
                            <div className="all-info ml-auto">
                              <Link
                                to="/cart"
                                style={{
                                  color: "white",
                                  textDecoration: "none",
                                }}
                              >
                                View Cart
                              </Link>
                            </div>
                          ) : (
                            <div
                              className="all-info ml-auto cursor-pointer"
                              style={{}}
                            >
                              <span
                                style={{
                                  color: "white",
                                  textDecoration: "none",
                                }}
                                onClick={() => setmodalLogin(true)}
                              >
                                View Cart
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div
                      className="bottom-cart d-lg-none d-block w-100"
                      style={{
                        boxShadow:
                          "0 -8px 16px 0 rgba(207, 205, 205, 0.8), 0 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <div className=" text-center p-1 px-3 d-flex">
                        <div className="rupes">₹ {totalPrice}</div>

                        <div className=" p-2 px-4 all-info ml-auto bottom-cart-info">
                          {hideBooking ? (
                            <Link
                              to="/cart"
                              style={{ color: "white", textDecoration: "none" }}
                            >
                              View Cart
                            </Link>
                          ) : (
                            <span
                              // to="/cart"
                              style={{ color: "white", textDecoration: "none" }}
                              onClick={() => setmodalLogin(true)}
                            >
                              View Cart
                            </span>
                          )}

                          {/* View Cart */}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div className="our-expertise">
                  <div className="main-head">Our Expertise</div>
                  <div className="list">
                    <div className="list-item">
                      <div className="icon">
                        <i className="las la-tools"></i>
                      </div>
                      <div className="content">
                        <div className="title">Timely Service</div>
                        <div className="data ">
                          We understand the importance of time and offer quick
                          and prompt services to our customers. We ensure that
                          our technicians reach your doorstep within the given
                          time frame.
                        </div>
                      </div>
                    </div>

                    <div className="list-item">
                      <div className="icon">
                        <i className="las la-tools"></i>
                      </div>
                      <div className="content">
                        <div className="title">Quality Workmanship</div>
                        <div className="data">
                          We use only the best quality spare parts and tools for
                          repairs and installations. Our technicians take pride
                          in their work and ensure that the job is done right
                          the first time.
                        </div>
                      </div>
                    </div>
                    <div className="list-item">
                      <div className="icon">
                        <i className="las la-tools"></i>
                      </div>
                      <div className="content">
                        <div className="title">Customer Satisfaction</div>
                        <div className="data">
                          Our top priority is customer satisfaction. We strive
                          to provide an exceptional service experience to our
                          customers and ensure that all their queries and
                          concerns are addressed promptly.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
