import React, { useContext } from "react";
import { UrlHistoryContext } from "../../context/UrlHistoryProvider";

function Category(props) {
  const {  setModal, setCategoryName } = useContext(UrlHistoryContext);
  return (
    <>
      <div className="row p-0 m-0 ">
        <div className="col-sm-6 inResponsiveMargin p-0 px-2 p-sm-0 m-sm-0">
          <div className=" p-sm-0 m-sm-0">
            <h3 className="heading-title">Services </h3>
            <div className="card">
              <div className="card-body">
                <div className="category-slider1 gap-3">
                  {props?.props?.map((data) => (
                    <div
                      key={data.id}
                      onClick={() => setCategoryName(data.name)}
                      className="product"
                    >
                      <div
                        className="single-category-item wow fadeInUp"
                        data-wow-delay=".2s"
                        onClick={() => setModal(data.id)}
                      >
                        <div className="single-category ">
                          <div className="borderInPhoneView rounded-4">
                            <div className="icon ">
                              <img
                                src={data.image}
                                alt="..."
                                className="mt-3 mt-lg-0"
                              ></img>
                            </div>
                          </div>
                          <div className="category-contents mt-lg-0 mt-md-0 mt-1">
                            <h4 className="category-title">{data.name}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div
                  className="card  mt-4 d-none d-md-block d-lg-block"
                  style={{ backgroundColor: "#fcfcfd" }}
                >
                  <div className="row justify-content-center text-center">
                    <div className="col-4 ">
                      <p>
                        <i class="fa-brands fa-creative-commons-sampling-plus"></i>
                        <p
                          className="p-0 m-0 fw-bolder fs-5"
                          style={{ color: "#600E3E" }}
                        >
                          200000+
                        </p>
                        <p className="p-0 m-0 " style={{ color: "#600E3E" }}>
                          SATISFIED CUSTOMERS
                        </p>
                      </p>
                    </div>
                    <div className="col-4">
                      <p>
                        <i class="fa-brands fa-creative-commons-sampling-plus"></i>
                        <p
                          className="p-0 m-0 fw-bolder fs-5"
                          style={{ color: "#600E3E" }}
                        >
                          1500+
                        </p>
                        <p className="p-0 m-0 " style={{ color: "#600E3E" }}>
                          EXPERT TECHNICIANS
                        </p>
                      </p>
                    </div>
                    <div className="col-4">
                      <p>
                        <i class="fa-brands fa-creative-commons-sampling-plus"></i>
                        <p
                          className="p-0 m-0 fw-bolder fs-5"
                          style={{ color: "#600E3E" }}
                        >
                          1 COUNTRY
                        </p>
                        <p
                          className="p-0 m-0 fs-6"
                          style={{ color: "#600E3E" }}
                        >
                          20 CITIES
                        </p>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div className="images rounded-3 mt-3 pt-5">
            <img
              src="/assets/frontend/bannerMain2.png"
              alt=""
              className="rounded-3"
              style={{ width: "100%", height: "28rem" }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Category;
