import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CartAction from "../action/CartAction";
import { URLGetApplianceType, URLProductImage } from "../util/UrlConst";
import { Parser } from "html-to-react";
import { UrlHistoryContext } from "../context/UrlHistoryProvider";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Problems_card from "./Problems_card";

export default function ProductComponent(props) {
  // console.log(props, "propspropspropspropspropspropspropsprops");
  const [cartbuttontext, setCartButtonText] = useState(false);
  const [cartQuantity, setCartQuantity] = useState("00");

  const [totalPriceproblem, setTotalPrice] = useState("00");
  const [productId, setProductId] = useState();
  const [problemData, setProblem] = useState();
  const {
    changeLocalState,
    setChangeLocalState,
    totalPrice,
    cartQuantityTotal,
  } = useContext(UrlHistoryContext);

  useEffect(() => {
    const existingCart = JSON.parse(sessionStorage.getItem("cart"));
    if (existingCart != []) {
      var problemData = existingCart?.filter(
        (dt) => dt?.id == props?.product?.id
      );

      let hasProblemsKey = problemData?.some(
        (obj) => "problems" in obj && obj.problems.length > 0
      );

      if (hasProblemsKey) {
        var sum = problemData[0]?.problems.reduce(
          (acc, problem) => acc + parseInt(problem.quantity_data),
          0
        );
        var totalPrice = problemData[0]?.problems.reduce(
          (acc, problem) =>
            acc +
            parseInt(problem.quantity_data) * parseFloat(problem.sale_price),
          0
        );
        setTotalPrice(totalPrice);

        setCartQuantity(sum);
      } else {
        const getProduct = existingCart?.find(
          (item) => item.id === props.product.id
        );
        const totalPrice =
          getProduct?.quantity_data * parseFloat(props.product.sale_price);
        setTotalPrice(totalPrice);
        setCartQuantity(getProduct?.quantity_data);
      }
    }
  }, [changeLocalState]);

  const navigate = useNavigate();

  const addToCart = (product) => {
    const existingCart = JSON.parse(sessionStorage.getItem("cart")) || [];
    const existingProduct = existingCart?.find(
      (item) => item.id === product.id
    );

    if (existingProduct !== undefined) {
      existingProduct.quantity_data = (existingProduct.quantity_data || 0) + 1;
    } else {
      existingCart.push({ ...product, quantity_data: 1 });
    }

    sessionStorage.setItem("cart", JSON.stringify(existingCart));
    setCartQuantity(existingProduct?.quantity_data || 1);
  };

  const removeFromCart = (product) => {
    const existingCart = JSON.parse(sessionStorage.getItem("cart")) || [];
    const existingProductIndex = existingCart.findIndex(
      (item) => item.id === product.id
    );

    if (
      existingProductIndex !== -1 &&
      existingCart[existingProductIndex].quantity_data > 1
    ) {
      existingCart[existingProductIndex].quantity_data -= 1;
      setCartQuantity(existingCart[existingProductIndex].quantity_data);
    } else {
      existingCart.splice(existingProductIndex, 1);
      setCartQuantity(0);
    }
    sessionStorage.setItem("cart", JSON.stringify(existingCart));
  };
  const gototcart = (e) => {
    navigate("/cart");
  };
  // console.log(props, "props");

  const [show, setShow] = useState(false);
  // setShow(props.show1);
  // setProductId(props.setProductId1);

  const handleClose = () => {
    // setModal(false);
    setShow(false);
  };

  async function getdata() {
    let token = sessionStorage.getItem("token");

    axios.defaults.headers.common["Authorization"] = `Bearer+ ${token}`;
    setCartButtonText(false);
    await axios
      .post(URLGetApplianceType, { id: productId })
      .then((response) => {
        setProblem(response?.data?.data);
      });
  }

  useEffect(() => {
    // productId
    if (productId) {
      getdata();
    }
  }, [productId]);

  return (
    <>
      <div key={props.product.id} className="product-item">
        <div className="top-part">
          <div className="content">
            <div
              className="title maxWidthFont "
              style={{
                color: "black",
                // fontFamily: "Poppins",
                // fontSize: "18px",
              }}
            >
              {props.product.name}
            </div>
            <div className="data  ">
              <p
                className="categoryDesFont  p-0 m-0 "
                style={{
                  lineHeight: "1.5",
                }}
              >
                {Parser().parse(props.product.short_description)}
              </p>
            </div>
          </div>
          <div className="image borderImg rounded-3">
            <img
              src={
                props.product.image
                  ? props.product.image
                  : "/assets/frontend/img/static/imagenotfound.jpg"
              }
              alt="..."
              className="w-100 h-100 rounded-3"
            ></img>
          </div>
        </div>
        <div className="bottom-part">
          <div className="price">
            {/* <div><del>Rs. {props.product.mrp}</del></div> */}
            {/* <br/> */}
            <div style={{ color: "#E0E0E0" }}>
              {/* {" "}
              ₹{" "}
              <span className="text-success" style={{ fontWeight: "600" }}>
                {props.product.sale_price}{" "}
              </span> */}
              <p
                className="p-0 m-0 maxWidthFont fontDescription"
                style={{ lineHeight: "1.5" }}
              >
                {" "}
                Start at{" "}
                <span className="text-success" style={{ fontWeight: "600" }}>
                  ₹ {props.product.sale_price}
                </span>
              </p>
            </div>
            <Link
              to={`/service-details/${props.product.id}`}
              className="view-detail listLine"
            >
              MORE INFO
            </Link>
            <br />
          </div>
          {/* <Link to={`/service-details/${props.product.id}`} className='view-detail btn btn-white border-success btn-sm '>Add </Link> */}
          {props.product.problem == true ? (
            <button
              style={{ background: "none" }}
              // className="cart-btn cmn-btn btn-sm btn-primary btn "
              className={`${cartQuantity ? "" : ""}`}
              onClick={() => {
                setShow(true);
                setProductId(props?.product?.id);
                setChangeLocalState(!changeLocalState);
              }}
              // style={{ background: cartQuantity ? "none" : "blue" }}
            >
              {" "}
              {/* {cartQuantity} */}
              {cartQuantity ? (
                <div className="right-static">
                  <div className="mid-info text-center">
                    <div className="mid-ico">
                      <button
                        className="mid-ico  backgroundColor fw-bolder"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // removeFromCart(props?.product);
                          // setChangeLocalState(!changeLocalState);
                          setShow(true);
                        }}
                      >
                        -
                      </button>
                    </div>

                    <div className="mid-ico ">{cartQuantity}</div>

                    <div className="mid-ico">
                      <button
                        className="mid-ico backgroundColor fw-bolder"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // addToCart(props?.product);
                          // setChangeLocalState(!changeLocalState);
                          setShow(true);
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="px-3 p-1 addButtonHeader">
                  <button
                    className="addButton"
                    // style={{color:'#3485da',background:'none'}}
                    // onClick={() => {
                    //   addToCart(props?.product);
                    //   setChangeLocalState(!changeLocalState);
                    // }}
                  >
                    {" "}
                    Add{" "}
                  </button>
                </div>
              )}
              {/* Add to Cart */}
            </button>
          ) : (
            <>
              {cartQuantity ? (
                <div className="right-static">
                  <div className="mid-info text-center">
                    <div className="mid-ico">
                      <button
                        className="mid-ico backgroundColor fw-bolder"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          removeFromCart(props?.product);
                          setChangeLocalState(!changeLocalState);
                        }}
                      >
                        -
                      </button>
                    </div>

                    <div className="mid-ico ">{cartQuantity}</div>

                    <div className="mid-ico">
                      <button
                        className="backgroundColor fw-bolder"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          addToCart(props?.product);
                          setChangeLocalState(!changeLocalState);
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="px-3 p-1 addButtonHeader">
                    <button
                      className="addButton"
                      // style={{color:'#3485da',background:'none'}}
                      onClick={() => {
                        addToCart(props?.product);
                        setChangeLocalState(!changeLocalState);
                      }}
                    >
                      {" "}
                      Add{" "}
                    </button>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>

      <Modal
        Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="category-modal"
        show={show}
        onHide={handleClose}
        className="main_model"
        dialogClassName="main_model"
      >
        <Modal.Header
          className="modelHeder"
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            padding: "15px",
            border: "none",
            bottom: "50px",
          }}
        >
          <div className="modalCloseButton cursor-pointer">
            <span
              // className="ms-4"
              style={{ margin: "3px 8px" }}
              onClick={() => handleClose()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-x"
                style={{ margin: "3px -3px", color: "gray" }}
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </span>
          </div>
        </Modal.Header>
        <div
          style={{
            position: "absolute",
            width: "100%",
            // height: "100%",
            borderBottom: "2px solid #E3E3E3" /* Adjust border properties */,
            boxSizing:
              "border-box" /* Ensures the border width is included in the div's total width */,
          }}
        >
          <h3
            className="m-0 model_heading maxWidthFont d-flex justify-content-around"
            style={{
              marginLeft: "10px",
              padding: "12px ",
              fontWeight: "600",
              fontSize: "20px",
              // fontFamily: "Poppins",
            }}
          >
            {problemData?.name}
          </h3>
        </div>
        {/* <Modal.Title>{problemData?.name}</Modal.Title> */}
        <Modal.Body className="m-0 model_body">
          {problemData?.problems?.map((item) => (
            <>
              <Problems_card
                item={item}
                problems={problemData}
                // addToCartProblem={addToCartProblem}
              />
            </>
          ))}
        </Modal.Body>
        {cartQuantity ? (
          <div className="d-flex main_model_footer" style={{ padding: "9px" }}>
            <div className="total_counting d-flex fw-bold ">
              <p
                className="mt-1"
                style={{
                  fontWeight: "bold",
                  borderRadius: "5px",
                  backgroundColor: "#D7ECFF",
                  padding: "3px 12px",
                  color: "black",
                }}
                // className="backgroundColor"
              >
                {cartQuantity ? cartQuantity : "0"}
              </p>

              <p
                className="p-0 ps-1 mt-2 m-0 maxWidthFont fontDescription"
                style={{ lineHeight: "1.5" }}
              >
                <span className="text-success" style={{ fontWeight: "600" }}>
                  ₹ {totalPriceproblem ? totalPriceproblem : ""}
                </span>
              </p>
            </div>
            <div style={{ position: "absolute", right: "10px" }}>
              <button
                className="btn btn-success"
                onClick={() => handleClose()}
                style={{
                  width: "160px",
                  backgroundColor: "#007aff",
                  border: "none",
                }}
              >
                Proceed
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
      </Modal>
    </>
  );
}
