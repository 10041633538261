import axios from "axios";
import React, { useEffect, useState } from "react";
import { URLGetPrivacy } from "../util/UrlConst";

export default function Privacy() {
  const [privacy, setPrivacyData] = useState("");
  const listItemStyle = {
    // listStyleType: "disc",
    marginBottom: "10px",

    // Add more style properties as needed
  };

  const GetPrivacy = async () => {
    // let token = sessionStorage.getItem("token");

    try {
      // axios.defaults.headers.common["Authorization"] = token;

      // Make a POST request using Axios
      const res = await axios.get(URLGetPrivacy);
      setPrivacyData(res?.data?.data?.value);
      // setAreaId(res?.data?.data?.id)
      // (,'response')
      // Handle the response
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    GetPrivacy();
  });
  return (
    <>
      <div
        className="mx-2 mx-md-4 mx-lg-5 pt-4"
        dangerouslySetInnerHTML={{ __html: privacy }}
      />
      {/* <div className="banner-inner-area section-bg-3 padding-top-100 padding-bottom-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner-inner-contents">
                <h2 className="banner-inner-title mb-0 text-black">
                  {" "}
                  Privacy Policy for The Classy - Volet Home Services Pvt. Ltd.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section
        className="policies"
        data-padding-top="40"
        data-padding-bottom="40"
      >
        <div className="container">
          <div className="data">
            At The Classy, your privacy is of utmost importance to us. This
            Privacy Policy outlines how we collect, use, disclose, and protect
            your personal information. By using our services, you agree to the
            terms outlined below.
          </div>

          <div className="head">1. Information We Collect:</div>

          <div className="list">
            <ol>
              <li style={listItemStyle}>
                <strong> Personal Information:</strong> During the booking
                process, we may collect personal information such as your name,
                contact details, and address to facilitate our home and office
                maintenance services.
              </li>
              <li style={listItemStyle}>
                <strong> Payment Information:</strong> If you choose to make
                payments through our platform, we collect the necessary payment
                information to process transactions securely.
              </li>
              <li style={listItemStyle}>
                <strong>Live Location of user:</strong> We are using location in
                background to take exact location of user so that our best
                technician will visit their place and do the best service which
                the classy offers,also the background location can be used to
                check whether the service is available at that place or not
              </li>
              <li style={listItemStyle}>
                <strong> Address of user:</strong> For exact location finding
                for service we need address.
              </li>
              <li style={listItemStyle}>
                <strong> Data security of User:</strong> We are giving access to
                our user to delete their data from website and user application
                both also their data will be delete withing 15days
                as per their need.
              </li>
             
            </ol>
          </div>

          <div className="head">
            2. Information We Collect from Technician::
          </div>

          <div className="list">
            <ol>
              <li style={listItemStyle}>
                <strong> Personal Information:</strong> During the technician
                signup process, we may collect personal information such as your
                name, contact details, and address, technician qualification for
                proper verification of technician for our maintenance services
              </li>
              <li style={listItemStyle}>
                <strong> Payment Information:</strong>: If you choose to make
                payments through our platform, we collect the necessary payment
                information to process transactions securely
              </li>
              <li style={listItemStyle}>
                <strong>Live Location of Technician :</strong>– we are taking
                location for proper service at user location also tracking
                technician location for proper and safe service for our valuable
                customer also tracking attendance of our technician
              </li>
              <li style={listItemStyle}>
                <strong> Address of technician :</strong> – for exact location
                finding for service we need address also assigning of task
                according to their location and user can get proper service at
                their place we need our technician to be available there that
                why we need address
              </li>

             
            </ol>
          </div>

          <div className="head">3. How We Use Your Information:</div>

          <div className="list">
            <ol>
              <li style={listItemStyle}>
                We use your personal information to provide and improve our
                services, ensuring a seamless and personalized experience.
              </li>
              <li style={listItemStyle}>
                Your payment information is solely used for transactional
                purposes and is securely handled through our payment processing
                partners.
              </li>
            </ol>
          </div>

          <div className="head">4. Information Sharing:</div>
          <div className="list">
            <ol>
              <li style={listItemStyle}>
                We do not sell, trade, or otherwise transfer your personal
                information to third parties without your explicit consent.
              </li>
              <li style={listItemStyle}>
                We may share your information with our skilled technicians
                solely for the purpose of delivering the booked services.
              </li>
            </ol>
          </div>

          <div className="head">5. Data Security:</div>

          <div className="list">
            <ol>
              <li style={listItemStyle}>
                We implement a variety of security measures to protect your
                personal information. This includes encryption, secure socket
                layer technology, and regular system monitoring.
              </li>
              <li style={listItemStyle}>
                While we strive to protect your information, we cannot guarantee
                absolute security due to the inherent nature of online
                transactions.
              </li>
            </ol>
          </div>

          <div className="head">6. Cookies and Analytics:</div>

          <div className="list">
            <ol>
              <li style={listItemStyle}>
                We may utilize analytics tools to gather information about your
                usage patterns, helping us improve our services.
              </li>
              <li style={listItemStyle}>
                We may use cookies to enhance your user experience. You can
                choose to disable cookies through your browser settings.
              </li>
            </ol>
          </div>

          <div className="head">7. Access and Control:</div>

          <div className="list">
            <ol>
              <li style={listItemStyle}>
                You have the right to access, correct, or delete your personal
                information. You can do this by contacting our customer service
              </li>
              <li style={listItemStyle}>
                You can opt-out of receiving marketing communications from us at
                any time.
              </li>
            </ol>
          </div>

          <div className="head">8. Third-Party Links:</div>

          <div className="list">
            <ol>
              <li style={listItemStyle}>
                Our website or app may contain links to third-party websites. We
                are not responsible for the privacy practices or content of
                these third-party sites.
              </li>
            </ol>
          </div>

          <div className="head">9. Updates to Privacy Policy:</div>

          <div className="list">
            <ol>
              <li style={listItemStyle}>
                We reserve the right to update our Privacy Policy. Any changes
                will be communicated through our website or app.
              </li>
            </ol>
          </div>

          <div className="head">10. Governing Law:</div>

          <div className="list">
            <ol>
              <li style={listItemStyle}>
                This Privacy Policy is governed by the laws of the jurisdiction
                in which the service is provided.
              </li>
            </ol>
          </div>

          <div className="head">11. Contact Information:</div>

          <div className="list">
            <ol>
              <li style={listItemStyle}>
                For any privacy-related inquiries or concerns, please contact
                our privacy officer at{" "}
                <a
                  className="text-decoration-none text-primary"
                  href="mailto:info@valetgroups.com"
                >
                  info@valetgroups.com.
                </a>
              </li>
            </ol>
          </div>

          <span>
            {" "}
            Thank you for entrusting The Classy with your home and office
            maintenance needs. We are committed to safeguarding your privacy and
            providing a secure and enjoyable experience.
          </span>
        </div>
      </section> */}
    </>
  );
}
