import React from "react";

export default function Contact() {
  return (
    <>
      {/* {{-- ===========================================
                                BREADCRUMB
            =========================================== --}} */}
      <div className="banner-inner-area section-bg-3 padding-top-100 padding-bottom-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner-inner-contents">
                <h2 className="banner-inner-title mb-0"> Contact </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {{-- ===========================================
                                CONTACT AREA
            =========================================== --}} */}
      <section
        className="contact-promo-area"
        data-padding-top="40"
        data-padding-bottom="40"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-6 margin-top-30">
              <div
                className="single-contacts wow fadeInUp"
                data-wow-delay=".2s"
              >
                <div className="contact-icon">
                  <i className="las la-phone-volume"></i>
                </div>
                <div className="contacts-contents">
                  <h4 className="title"> Call Us </h4>
                  <div className="item-contents">
                    <span className="item">
                      <a
                        className="listLine text-black"
                        href="tel:+919575077772"
                      >
                        {" "}
                        +919575077772{" "}
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 margin-top-30">
              <div
                className="single-contacts wow fadeInUp"
                data-wow-delay=".2s"
              >
                <div className="contact-icon">
                  <i className="las la-envelope-open"></i>
                </div>
                <div className="contacts-contents">
                  <h4 className="title"> Mail Address </h4>
                  <div className="item-contents">
                    <span className="item">
                      <a
                        className="listLine text-black"
                        href="mailto:support@theclassy.in"
                      >
                        <span className="__cf_email__">
                          support@theclassy.in
                        </span>
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 margin-top-30">
              <div
                className="single-contacts wow fadeInUp"
                data-wow-delay=".2s"
              >
                <div className="contact-icon">
                  <i className="las la-clock"></i>
                </div>
                <div className="contacts-contents">
                  <h4 className="title"> Support Time </h4>
                  <div className="item-contents">
                    <span className="item"> 08.00am to 11.00pm</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="" style={{ textAlign: "center" }}>
        <h3>Contact Us</h3>
        <p className="fs-6 ">
          Our Mission is to empower millions of service professionals by
          delivering services at-home in a way that has never been experienced
          before.
        </p>
      </div>
      <section
        className="contact-area"
        data-padding-top="40"
        data-padding-bottom="40"
      >
        <div className="container">
          <div className="row">
            <div className="col-10 col-md-4 col-lg-4">
              <div
                className="single-contacts wow fadeInUp"
                data-wow-delay=".2s"
              >
                <div className="contacts-contents">
                  <h4 className="title">Indore </h4>
                  <div className="item-contents">
                    <span className="item"></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-10 col-md-4 col-lg-4">
              <div
                className="single-contacts wow fadeInUp"
                data-wow-delay=".2s"
              >
                <div className="contacts-contents">
                  <h4 className="title">Bhopal </h4>
                  <div className="item-contents">
                    <span className="item"> </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
