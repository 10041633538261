import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../component/index.css";
import ReactGA from 'react-ga';

function ApplianceGrid(dataProp) {
  return (
    <>
      <div className="gridViewFlex row-cols-lg-4">
        {dataProp?.dataProp?.map((data, index) => (
          <>
            <div
              key={data?.id}
              className="col-md-3 col-lg-3 col-6 SmallScreenPadding"
              style={{ display: "inline-block", whiteSpace: "normal" }}
            >
              <div className="p-0 ">
                <div className="borderImg rounded-3">
                  <Link
                    to={`/service-details/${data?.id}`}
                    className="section-btn"
                  >
                    <img
                      className="service-thumb location_relative service-bg-thumb-format rounded-3"
                      src={data?.image}
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                      alt={data?.name}
                    />
                  </Link>
                </div>
                <div className="px-md-2 px-sm-0">
                  <h6
                    className="m-0 pt-2 maxWidthFont"
                    style={{ color: "black" }}
                  >
                    {data?.name}
                  </h6>
                  <p
                    className="m-0 p-0 maxWidthFont fontDescription"
                    style={{ lineHeight: "1.5" }}
                  >
                    {data?.short_description}
                  </p>
                  <p
                    className="p-0 m-0 maxWidthFont fontDescription"
                    style={{ lineHeight: "1.5" }}
                  >
                    Start at{" "}
                    <span
                      className="text-success"
                      style={{ fontWeight: "600" }}
                    >
                      ₹ {data?.sale_price}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            {/* {dataProp?.dataProp?.length / 2 == index + 1 ? <br></br> : null} */}
            {/* {dataProp?.dataProp?.length / 2 == index + 1 ? <br></br> : null} */}
            {/* {dataProp?.dataProp?.length % 2 !== 0 ||
            index + 1 === Math.floor(dataProp.dataProp.length / 2) + 1 ? (
              <br />
            ) : null} */}

            {dataProp?.dataProp?.length % 2 === 0 &&
            index + 1 === dataProp.dataProp.length / 2 ? (
              <br />
            ) : null}
            {dataProp?.dataProp?.length % 2 !== 0 &&
            index + 1 === Math.ceil(dataProp.dataProp.length / 2) ? (
              <br />
            ) : null}
            {/* {index % 2 === 1 ? <br /> : null} */}
          </>
        ))}
      </div>
    </>
  );
}

function ApplianceListView(dataProp) {
  return (
    <>
      <div
        className="3 px-2 px-lg-0 px-md-0"
        style={{ display: "flex", overflowX: "scroll" }}
      >
        {dataProp?.dataProp?.map((data) => (
          <div className="col-md-3 col-lg-3 col-5 p-2 " key={data.id}>
            <div className=" p-0 ">
              <div className="borderImg rounded-3">
                <a href={`/service-details/${data.id}`} className="section-btn">
                  <img
                    className="service-thumb  responsiveIMg rounded-3 location_relative service-bg-thumb-format "
                    src={data?.image}
                    style={{}}
                    alt={data?.name}
                  />
                </a>
              </div>

              <div className="px-md-2 pt-1 px-sm-0">
                <h6
                  className="m-0 maxWidthFont pt-2"
                  style={{ color: "black" }}
                >
                  {data?.name}
                </h6>
                <p
                  className="p-0 m-0 maxWidthFont fontDescription"
                  style={{ lineHeight: "1.5" }}
                >
                  {data?.short_description}
                </p>
                <p
                  className="p-0 m-0 maxWidthFont fontDescription"
                  style={{ lineHeight: "1.5" }}
                >
                  {" "}
                  Start at{" "}
                  <span className="text-success" style={{ fontWeight: "600" }}>
                    ₹ {data?.sale_price}
                  </span>
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

function Appliance({ props }) {
  const [serviceDetailId, setServiceDetailId] = useState("");
  const handleSession = (e) => {
    sessionStorage.setItem("categoryImg", e.image);
    sessionStorage.setItem("categoryName", e?.name);
    ReactGA.event({
      category: "Category",
      action: "Click Category",
      label: e?.name,
      value: 1,
    });
  };
  return (
    <>
      <div key={props.key} className="container-md container-lg borderONHome pb-2 p-0">
        <div className="">
          <div className="row mb-2">
            <div className="col-lg-12">
              <div className="section-title-two p-2 mt-lg-4 mt-sm-0">
                <div className="">
                  <h2 className="title ps-2 bg-main-one"> {props?.name}</h2>
                </div>
                <a
                  href={`view/${props.id}`}
                  className="section-btn listLine mt-2 fw-normal"
                  style={{
                    color: "#007AFF",
                    fontFamily: "Serif, Sans-serif",
                    fontSize: "14px",
                  }}
                >
                  <span className="mt-2">View all</span>
                  <img
                    src="/assets/frontend/img/greaterthan.svg"
                    className="ps-2 pb-1"
                    alt="d"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="d-flex p-0 m-0">
            {props?.key === "appliancetype" ? (
              <>
                {props?.items?.map((data, index) => {
                  if (index === 0) {
                    return props?.view === "grid" ? (
                      // <Demo dataProp={props?.items?.slice(0, 4)} />
                      <ApplianceGrid key={index} dataProp={props?.items} />
                    ) : (
                      // <Demo_2 dataProp={props?.items?.slice(0, 4)} />
                      <ApplianceListView key={index} dataProp={props?.items} />
                    );
                  } else {
                    return ""; // or any other component or content you want to render for other indices
                  }
                })}
              </>
            ) : (
              <>
                <div
                  className="container-fluides "
                  style={{ display: "flex", overflowX: "scroll " }}
                >
                  <div
                    className=" pt-lg-5 border-3 border-black"
                    style={{ display: "flex", overflowX: "scroll" }}
                  >
                    {props?.items?.map((data) => (
                      <div
                        className="col-sm-2 col-md-3 p-1 ps-3"
                        onClick={() => handleSession(data)}
                        key={data.id}
                      >
                        <div
                          className="rounded-2 w-sm-75"
                          style={{ border: "" }}
                        >
                          <div className="rounded-3 borderImg">
                            <Link
                              to={`/category-details/${
                                data.id
                              }/${encodeURIComponent(data.name)}`}
                              className="section-btn listLine"
                            >
                              <img
                                className="service-thumb maxWidthFontImg location_relative service-bg-thumb-format rounded-3"
                                src={data?.image}
                                alt={data?.name}
                              />
                            </Link>
                          </div>

                          <div className="px-md-2 pt-1 px-sm-0 maxWidthSub">
                            <h6
                              className="m-0 maxWidthFont "
                              style={{
                                color: "black",
                                fontFamily: "sans-serif",
                              }}
                            >
                              {data?.name}
                            </h6>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* </section>  */}
    </>
  );
}

export default Appliance;
