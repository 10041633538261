import React, { useContext, useEffect, useState } from "react";
import Profile from "./Profile";
import ProfileBeforeLogin from "./profileBeforeLogin";
import { UrlHistoryContext } from "../context/UrlHistoryProvider";

function ProfileMain() {
  const [token, setItemToken] = useState();
  const { modalLogin, setmodalLogin, updateProfilePage } =
    useContext(UrlHistoryContext);

  useEffect(() => {
    var token = sessionStorage.getItem("token");
    setItemToken(token);
  }, [sessionStorage.getItem("token"), modalLogin, updateProfilePage]);
  // var items = sessionStorage.getItem("token");
  return <>{token ? <Profile /> : <ProfileBeforeLogin />}</>;
}

export default ProfileMain;
