import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
// import Home from "../pages/Home";
// import jwt from "jsonwebtoken";

const PrivateComponent = () => {
  const token = sessionStorage.getItem("token");
  // useEffect(() => {
  //   if (token) {
  //     try {
  //       const decodedToken = jwtDecode(token);

  //       console.log(decodedToken);

  //     } catch (error) {
  //       console.error("Error decoding JWT token:", error.message);
  //     }
  //   }
  // }, [token]);

  if (!token && token !== "undefined") {
    return <Navigate to="/" />;
  }
  return <Outlet />;
};
export default PrivateComponent;
