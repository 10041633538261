// eslint-disable-next-line no-unused-vars
import React, { Component, useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Singhup from "./pages/singhup";
// import {Map, GoogleApiWrapper} from "google-maps-react"
import Layout from "./component/Layout";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import About from "./pages/About";
import Blog from "./pages/Blog";
import Login from "./pages/Login";
import Cart from "./pages/Cart";
import OTP from "./pages/OTP";
import BlogDetail from "./pages/BlogDetail";
import ServiceBooked from "./pages/ServiceBooked";
import ProductBooked from "./pages/ProductBooked";
import Register from "./pages/Register";
import RateCard from "./pages/PriceList";
// import RateCard from "./pages/RateCard.js";
import ExploreAll from "./pages/ExploreAll";
import "./App.css";
import ServiceDetails from "./pages/ServiceDetails";
import CategoryDetails from "./pages/CategoryDetails";
import PaymentStatus from "./pages/PaymentStatus";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Refund from "./pages/Refund";
import SmoothScroll from "./component/SmoothScroll";
import SavedAddress from "./pages/SavedAddress";
import Career from "./pages/career";
import Slider from "./pages/Slider";
import Booking from "./pages/Booking";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
// import 'b'
import Ordersummary from "./pages/components/Ordersummary";
import Timeschedule from "./pages/components/Timeschedule";
import ViewPage from "./pages/components/viewAllPage";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import PrivateComponent from "./util/private";
import Nabvar from "./component/Nabvar";
import ProfileMain from "./pages/profileMain";
import BookingDetails from "./pages/BookingDetailPage";
import Loader from "./pages/loader/loader";
import AccountRemove from "./pages/modal/accountRemove";
import PopularCategories from "./pages/PopularCategories";
import { Helmet } from "react-helmet";
import RedirectComponent from "./pages/RedirectComponent";
import CategoryDetailsAC from "./Static/CategoriesAC";
import PriceList from "./pages/PriceList";

{/* <Route path="/rateCard/:id" element={<RateCard />} /> */}

// ReactGA.initialize('UA-C2J36RBJNS');
export default function App() {
  return (
    <>
      <Helmet>
        <link rel="icon" href="/favicon.ico" />
      </Helmet>


      <LoadScript
        googleMapsApiKey="AIzaSyC85uqpr_XiUY8O_Usjg3XV92w44vPpx4w"
        libraries={["places"]}
        loadingElement={<Loader />}
      >
        <BrowserRouter>
          <RedirectComponent />
          <Nabvar />

          <SmoothScroll>
            <Routes>
              <Route element={<PrivateComponent />}>
                <Route path="cart" element={<Cart />} />
                <Route path="Booking" element={<Booking />} />
              </Route>
              <Route path="singup" element={<Singhup />} />
              <Route path="/rateCard/:id" element={<PriceList />} />
              <Route path="singhup" element={<Singhup />} />
              
              <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="about" element={<About />} />
                <Route path="contact" element={<Contact />} />
                <Route path="bookingDetails/:id" element={<BookingDetails />} />
                <Route
                  path="category-details/:id/:name/:Banner"
                  element={<CategoryDetails />}
                />
                <Route
                  path="service-details/:id"
                  element={<ServiceDetails />}
                />
                <Route path="blog" element={<Blog />} />
                <Route path="login" element={<Login />} />
                {/* <Route path="cart" element={<Cart />} /> */}
                {/* <Route path="profile" element={<Profile />} /> */}
                <Route path="blog-details/:id" element={<BlogDetail />} />
                <Route path="otp" element={<OTP />} />
                <Route path="service-booked" element={<ServiceBooked />} />
                <Route path="product-booked" element={<ProductBooked />} />
                <Route path="register" element={<Register />} />
                <Route path="explore-all/:type/:id" element={<ExploreAll />} />
                <Route
                  path="payment-status/:type/:id"
                  element={<PaymentStatus />}
                />
                <Route path="privacy" element={<Privacy />} />
                <Route path="terms" element={<Terms />} />
                <Route path="refund" element={<Refund />} />
                <Route path="saved_address" element={<SavedAddress />} />
                <Route path="*" element={<Home />} />

                <Route index element={<Home />} />
                <Route path="about" element={<About />} />
                <Route path="contact" element={<Contact />} />
                <Route path="cancel" element={<Cart />} />

                <Route
                  path="category-details/:id/:name"
                  element={<CategoryDetails />}
                />

                <Route path="slider-details/:id" element={<Slider />} />

                <Route
                  path="service-details/:id"
                  element={<ServiceDetails />}
                />
                <Route path="blog" element={<Blog />} />
                <Route path="login" element={<Login />} />
                {/* <Route path="profile" element={<Profile />} /> */}
                <Route path="profile" element={<ProfileMain />} />

                <Route path="blog-details/:id" element={<BlogDetail />} />
                <Route path="otp" element={<OTP />} />
                <Route path="service-booked" element={<ServiceBooked />} />
                <Route path="product-booked" element={<ProductBooked />} />
                <Route path="register" element={<Register />} />
                <Route path="explore-all/:type/:id" element={<ExploreAll />} />
                <Route
                  path="payment-status/:type/:id"
                  element={<PaymentStatus />}
                />
                <Route path="privacy" element={<Privacy />} />
                <Route path="terms" element={<Terms />} />
                <Route path="refund" element={<Refund />} />
                <Route path="saved_address" element={<SavedAddress />} />
                <Route path="*" element={<Home />} />
                <Route path="Career" element={<Career />} />
                <Route
                  path="request-delete-account"
                  element={<AccountRemove />}
                />
                <Route
                  path="PopularCategories"
                  element={<PopularCategories />}
                />

                <Route path="Ordersummary" element={<Ordersummary />} />
                <Route path="timeSchedule" element={<Timeschedule />} />
                <Route path="view/:id" element={<ViewPage />} />
                <Route path="near-me" element={<CategoryDetailsAC />} />
              </Route>
            </Routes>
          </SmoothScroll>
        </BrowserRouter>
      </LoadScript>
    </>
  );
}
