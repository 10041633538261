import React, { useContext } from "react";
import { UrlHistoryContext } from "../context/UrlHistoryProvider";
import LoginModals from "./components/loginSignUp/login";

function ProfileBeforeLogin() {
  const { modalLogin, setmodalLogin } = useContext(UrlHistoryContext);

  const loginOpenModal = () => {
    setmodalLogin(true);
  };
  return (
    <>
      <LoginModals props={modalLogin} />

      <div className="row mt-lg-5 pt-lg-5">
        <div className="col-lg-1"></div>
        <div className="mx-5 col-lg-4 col-sm-6 col-8 inResponsiveMargin ">
          <div
            className="d-flex justify-content-center p-0 m-0 fw-bold"
            style={{ color: "gray" }}
          >
            Sorry
          </div>
          <button
            type="button"
            //   onClick={() => handleLogOut()}
            class="btn btn-outline-primary w-100 m-2 text-primary"
            onClick={() => loginOpenModal()}
            style={{ backgroundColor: "transparent", cursor: "pointer" }}
            onMouseOver={(e) =>
              (e.currentTarget.style.backgroundColor = "transparent")
            }
            onMouseOut={(e) =>
              (e.currentTarget.style.backgroundColor = "transparent")
            }
          >
            Please Log In
          </button>
          {/* <button
            type="button"
          
            class="btn btn-outline-primary w-100 m-2"
          >
            Register
          </button> */}
        </div>
        <div className="col-lg-1"></div>

        <div className="col-6 col-lg-5  d-none d-md-block d-sm-block">
          <div>
            <img
              style={{ width: "33rem", height: "23rem" }}
              src="./assets/frontend/img/about.jpg"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileBeforeLogin;
