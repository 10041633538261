import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { UrlHistoryContext } from "../../context/UrlHistoryProvider";
import {
  URLDateTime,
  URLReScheduleBookingSlot,
  URLRevisit,
  URLRevisitBooking,
} from "../../util/UrlConst";
import axios from "axios";
import { fas } from "@fortawesome/free-solid-svg-icons";

export default function Timeschedule(props) {
  const { setTimeDate, timeDate } = useContext(UrlHistoryContext);

  const [isloadin, setLoading] = useState(true);
  const [dateTime, setDateTime] = useState();
  const [reasonDescription, setReasonDescription] = useState();

  //   const handleSubmit=(selectedTime,fullDate)=>{
  // console.log(selectedTime,fullDate,'selectedTime,fullDate')
  //   }

  let token = sessionStorage.getItem("token");

  const getDateTime = async () => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    //   URLCategoryDetails

    await axios.post(URLDateTime + props?.id).then((response) => {
      // console.log(response.data.data);

      setDateTime(response?.data?.data);

      var data = response?.data?.data?.filter(
        (item) =>
          extractDayWithZero(props?.selectedDateTime?.date) == item?.date
      );
      setGroups(data[0]?.groups);

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  };
  // == revisit
  const getRevisitData = async () => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    //   URLCategoryDetails

    await axios.post(URLRevisit + props?.complaint_id).then((response) => {
      // console.log(response.data.data);

      setDateTime(response?.data?.data);

      var data = response?.data?.data?.filter(
        (item) =>
          extractDayWithZero(props?.selectedDateTime?.date) == item?.date
      );
      setGroups(data[0]?.groups);

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  };
  const handleClose = () => {
    setReasonDescription("");
    props.setClose(false);
    // setShow(false);
  };

  const [selectedDate, setSelectedDate] = useState(null);
  const [groups, setGroups] = useState();

  // if(props?.selectedDateTime){
  //   setSelectedDate(props?.selectedDateTime?.date)
  // }

  const getButtonStyle = (date) => ({
    backgroundImage:
      selectedDate === date
        ? "linear-gradient(to bottom right, white, rgb(50,147,252))"
        : "",
    margin: "20px",
    height: "70px",
    width: "52px",
    fontFamily: "Poppines",
    border: selectedDate === date ? "2px solid #4C92CC" : "1px solid gray",

    borderRadius: "12px",
    // border: "1px solid gray",
    backgroundColor:
      selectedDate === date ? "linear-gradient(red, yellow)" : "#fff",
  });

  const [selectedTime, setSelectedTime] = useState(null);
  const [fullDate, setFullDate] = useState(null);

  const handleTimeClick = (time, fullDate) => {
    setSelectedTime(time === selectedTime ? null : time);
    setFullDate(fullDate);
  };
  const handleButtonClick = (date, group) => {
    setSelectedDate(date === selectedDate ? null : date);
    setGroups(group);
    setSelectedTime(null);
  };

  const getTimeStyle = (time) => ({
    cursor: "pointer",
    background:
      selectedTime === time
        ? "linear-gradient(to bottom right, white, rgb(134,190,252))"
        : "",
    padding: "5px",
    border: selectedTime === time ? "2px solid #4C92CC" : "1px solid gray",
    borderRadius: "11px",
    margin: "5px",
    color: "black",
    // border: "1px solid gray",

    backgroundColor:
      selectedTime === time ? "linear-gradient(red, yellow" : "#fff",
    fontFamily: "Poppines",
  });
  function extractDayWithZero(dateString) {
    const dateObject = new Date(dateString);
    const dayWithZero = ("0" + dateObject.getDate()).slice(-2);
    return dayWithZero;
  }

  useEffect(() => {
    if (props.props == true) {
      if (props?.Booking == "Revisit") {
        getRevisitData();
        // setSelectedDate(extractDayWithZero(props?.selectedDateTime?.date));
        // setSelectedTime(props?.selectedDateTime?.time);
      } else {
        getDateTime();
        // console.log(props?.selectedDateTime?.time,'props?.selectedDateTime?.date')
        setSelectedDate(extractDayWithZero(props?.selectedDateTime?.date));
        setSelectedTime(props?.selectedDateTime?.time);
      }
    }
  }, [props.props]);

  // useEffect(() => {

  // });

  // function test() {}
  // test();

  const { changeLocalState, setChangeLocalState, salePrice, mrp } =
    useContext(UrlHistoryContext);

  const handleSubmit = (selectedTime, fullDate) => {
    // Retrieve existing cart from local storage or initialize as an empty array
    const existingCart = JSON.parse(sessionStorage.getItem("cart")) || [];

    // Find the index of the item in the existing cart
    const existingProductIndex = existingCart.findIndex(
      (item) => item && item.id === (props && props.id)
    );

    if (existingProductIndex !== -1) {
      if (existingCart[existingProductIndex].problem_exist == true) {
        if (
          existingCart[existingProductIndex] &&
          existingCart[existingProductIndex].problems
        ) {
          const problemIndex = existingCart[
            existingProductIndex
          ].problems.findIndex(
            (item) => item && item.id === (props && props.item && props.item.id)
          );
          if (problemIndex !== -1) {
            const updatedProblem = {
              date: fullDate,
              time: selectedTime,
            };

            if (
              existingProductIndex >= 0 &&
              existingProductIndex < existingCart.length &&
              existingCart[existingProductIndex]?.problems
            ) {
              existingCart[existingProductIndex].problems.forEach(
                (problem, index) => {
                  // Update date and time for each problem
                  existingCart[existingProductIndex].problems[index] = {
                    ...problem,
                    ...updatedProblem,
                  };
                }
              );
            }
          }
        }
      } else {
        existingCart[existingProductIndex] = {
          ...existingCart[existingProductIndex],
          date: fullDate,
          time: selectedTime,
        };
      }
      // If the item is found in the cart, update the item with the additional key-value pairs
    } else {
      // If the item is not found in the cart, add a new item with the extra key-value pairs
    }

    // Update the cart in local storage
    sessionStorage.setItem("cart", JSON.stringify(existingCart));
    setChangeLocalState(!changeLocalState);
  };

  const reScheduleBookingSlot = async () => {
    let token = sessionStorage.getItem("token");

    try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      var reschedule = `${URLReScheduleBookingSlot}${props?.complaint_id}&visit_date=${fullDate}&visit_time=${selectedTime}`;
      // Make a POST request using Axios
      const res = await axios.post(reschedule);
      if (res?.data.error) {
        toast(res?.data?.message, {
          type: "error",
          theme: "dark",
        });
      } else {
        toast(res?.data?.message, {
          type: "success",
          theme: "dark",
        });
      }

      // console.log(data,'hellll')
      // var data =
    } catch (error) {
      // Handle errors
    }
  };
  const reRevisitSlot = async () => {
    let token = sessionStorage.getItem("token");

    try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      var reschedule = `${URLRevisitBooking}${props?.complaint_id}&date=${fullDate}&time=${selectedTime}&description=${reasonDescription}`;
      // Make a POST request using Axios
      const res = await axios.post(reschedule);
      if (res?.data.error) {
        toast(res?.data?.message, {
          type: "error",
          theme: "dark",
        });
      } else {
        toast(res?.data?.message, {
          type: "success",
          theme: "dark",
        });
      }

      // console.log(data,'hellll')
      // var data =
    } catch (error) {
      // Handle errors
    }
  };

  return (
    <>
      <ToastContainer />

      <Modal
        Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="category-modal"
        show={props?.props}
        onHide={handleClose}
        className="main_model categoryModel"
        dialogClassName="main_model"
      >
        <Modal.Header
          className="modelHeder"
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            padding: "15px",
            border: "none",
            bottom: "50px",
            // background:"black"
          }}
        >
          <div className="modalCloseButton cursor-pointer d-none d-md-block d-lg-block">
            <span
              // className="ms-4"
              style={{ margin: "3px 8px" }}
              onClick={() => handleClose()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-x"
                style={{ margin: "3px -3px" }}
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </span>
          </div>
        </Modal.Header>

        {/* <Modal.Title>{problemData?.name}</Modal.Title> */}
        <Modal.Body
          className="m-0 model_body "
          style={{ position: "relative", top: "-40px" }}
          // style={{ background: "#eeeeeea3" }}
        >
          {/* <div>
            <h6 className="fw-bolder">When should the professional arrive?</h6>
           
          </div> */}
          <div className="service-grid">
            <div className="row mb-3">
              <div className="col ">
                <div
                  className="time-schedule-heading fs-5 fw-bolder"
                  style={{
                    textAlign: "center",
                  }}
                >
                  {props?.Booking == "Revisit" ? (
                    <div class="mb-3 ">
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        placeholder="Reason For Revisit"
                        onChange={(e) => setReasonDescription(e.target.value)}
                      ></textarea>
                    </div>
                  ) : (
                    ""
                  )}
                  <h5
                    style={{ fontFamily: "Serif, Sans-serif" }}
                    className="fw-bold ml-3 d-flex justify-content-around 
                  "
                  >
                    Schedule Time
                  </h5>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col">
                <div className="time-select-box text-center">
                  {dateTime?.map((date) => (
                    <button
                      className="dayOfWeeks "
                      style={getButtonStyle(date?.date)}
                      onClick={() =>
                        handleButtonClick(date?.date, date?.groups)
                      }
                    >
                      <div className="date fw-bold">
                        <span
                          style={{
                            // fontFamily: "Poppine",
                            fontWeight: "bolder",
                            fontSize: "25px",
                          }}
                          className="maxWidthFont"
                        >
                          {" "}
                          {date?.date}
                        </span>
                        <br />
                        <span
                          className="fw-normal"
                          // style={{ fontFamily: "sans-serif,Poppine" }}
                        >
                          {date?.dayName}
                        </span>
                      </div>
                      {/* <div className="day text-break">{date?.dayName}{date?.date}</div> */}
                    </button>
                  ))}
                </div>
              </div>
            </div>

            <div className="row mb-3 ">
              <div className="col ml-3">
                <h5
                  className="fw-bold "
                  style={{
                    paddingTop: "5px",
                    fontFamily: "Serif, Sans-serif",
                  }}
                >
                  {/* Select start time service */}
                </h5>
                {/* <div> */}
                <div>
                  {groups ? (
                    groups.map((group) => (
                      <div key={group.id}>
                        <div className="select-day fw-bold text-black maxWidthFont">
                          {group?.group_name}
                        </div>
                        <div
                          className="service-time"
                          style={{ display: "flex", gap: "50px" }}
                        >
                          {group?.times.map((time) => (
                            <div
                              className="select-day "
                              style={getTimeStyle(time?.startTime)}
                              onClick={() =>
                                handleTimeClick(
                                  time?.startTime,
                                  time?.full_date
                                )
                              }
                            >
                              {time?.startTime}
                            </div>
                          ))}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>No groups available</div>
                  )}
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12 d-flex">
                <button
                  type="button"
                  // onClick={() => {
                  //   handleSubmit(selectedTime, fullDate);
                  //   handleClose();
                  // }}
                  onClick={() => {
                    if (props?.Booking == "ReSchedule") {
                      reScheduleBookingSlot();
                      handleClose();
                    } else if (props?.Booking == "Revisit") {
                      reRevisitSlot(selectedTime, fullDate);

                      handleClose();
                    } else {
                      handleSubmit(selectedTime, fullDate);
                      handleClose();
                    }
                  }}
                  disabled={selectedTime ? false : true}
                  className={` mx-4 btn btn-lg btn-block w-100 rounded-4`}
                  style={{
                    backgroundColor: selectedTime ? "#007AFF" : "#007AFF",
                    color: "#ffffff", // Set text color
                    fontFamily: "Poppines",
                  }}
                >
                  Submit
                </button>
              </div>
            </div>

            {/* "date":"2023-12-07","time":"08:00 AM" */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
