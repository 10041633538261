/* eslint-disable no-unused-vars */

import React, { useContext, useEffect, useState } from "react";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { URLSearchProduct } from "../util/UrlConst";
import LoginModals from "../pages/components/loginSignUp/login";
import { UrlHistoryContext } from "../context/UrlHistoryProvider";
import ModalRegister from "../pages/components/registerModal";
import { Modal } from "react-bootstrap";
import "./index.css";
import LocationModal from "../pages/components/locationModal";
import MapContainer from "./Mapcondation";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

// import ModalRegister from "../pages/components/loginSignUp/registerModal";
// import RegisterModal from "../p";
// import RegisterModal from "../pages/components/loginSignUp/Registers";
// import Registers from "../pages/components/loginSignUp/registers";
// import { RegisterNew } from "../pages/components/loginSignUp/Registers";

export default function Nabvar() {
  const [placeholder, setPlaceholder] = useState("");
  const placeholderWords = [
    "kitchen cleaning",
    "AC Service",
    "Inverter Refrigerator",
  ];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const currentWord = placeholderWords[currentWordIndex];
  const [currentWordTyped, setCurrentWordTyped] = useState("");
  useEffect(() => {
    let currentIndex = 0;
    let interval;

    const typeNextCharacter = () => {
      if (currentIndex === placeholderWords[currentWordIndex].length) {
        clearInterval(interval);
        setTimeout(() => {
          const nextIndex = (currentWordIndex + 1) % placeholderWords.length;
          setCurrentWordIndex(nextIndex);
          setCurrentWordTyped("");
          startTyping(placeholderWords[nextIndex]);
        }, 1000); // Delay before typing the next word
      } else {
        setCurrentWordTyped(
          (prevTyped) =>
            prevTyped + placeholderWords[currentWordIndex][currentIndex]
        );
        currentIndex++;
      }
    };

    interval = setInterval(typeNextCharacter, 100); // Typing speed

    return () => clearInterval(interval);
  }, [currentWordIndex]); // Rerun effect whenever currentWordIndex changes

  useEffect(() => {
    startTyping(placeholderWords[currentWordIndex]);
  }, []);

  const startTyping = (word) => {
    setCurrentWordTyped("");
  };

  const headers = {
    Authorization: "Bearer YourAccessToken",
    "Content-Type": "application/json",
  };
  const [token, setToken] = useState();

  useEffect(() => {
    const tokens = sessionStorage.getItem("token");

    setToken(tokens);
  });
  const user = JSON.parse(sessionStorage.getItem("user"));

  const navigate = useNavigate();

  const logout = (e) => {
    toast("Succesfully Logout.", { type: "success", theme: "dark" });
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("cart");
    sessionStorage.clear();
    navigate("/", { replace: true });
  };

  const [searchkey, setSearchkey] = useState("");
  const [mapKey, setmapKey] = useState("");
  const [mapResultdata, setMapresultData] = useState([]);
  const [mapPinCode, setPinCode] = useState("");
  // const [modalLocation, setmodalLocation] = useState(false);
  // const [mapData, setMapData] = useState("");
  const {
    modalRegister,
    setmodalRegister,
    mapData,
    setMapData,
    postPinCode,
    setPostPinCode,
    setUpdateData,
    setModal,
    updateData,
    locationModakOpen,
    setLocationModalOpen,
  } = useContext(UrlHistoryContext);

  // const searchWithText = async (event) => {
  //   setmapKey(event);
  //   console.log(event, "hello");
  //   if (event) {
  //     fetch(
  //       `https://maps.googleapis.com/maps/api/geocode/json?address=${event}&key=AIzaSyC85uqpr_XiUY8O_Usjg3XV92w44vPpx4w`
  //     )
  //       .then((response) => {
  //         console.log(response.data, "response");
  //         if (!response.ok) {
  //           throw new Error("Network response was not ok");
  //         }
  //         return response.json();
  //       })
  //       .then((data) => {
  //         setMapresultData(data.results);
  //         console.log(mapResultdata, "setMapresultData");
  //       })
  //       .catch((error) => {
  //         console.error("API call error:", error);
  //       });
  //   }
  //   if (event.key === "Backspace") {
  //     setMapresultData([]);
  //   }
  // };
  const pinCode = (item) => {
    const myPinCode = item.address_components.find((component) =>
      component.types.includes("postal_code")
    );
    return myPinCode ? myPinCode.long_name : "000000";
  };
  // const getPinCode = async (data) => {debugger
  //   const pin = pinCode(data);

  //   console.log(pin);
  // };

  // const getPinCode = (index) => {debugger
  //   // Assuming index corresponds to the clicked location in mapResultData
  //   const clickedLocation = mapResultdata[index];

  // if (clickedLocation) {
  //   // Extract postal code directly
  //   const postalCode = clickedLocation.address_components.find(
  //     (component) => component.types.includes("postal_code")
  //   )?.short_name;

  //   console.log("Clicked Location Postal Code:", postalCode);

  //   // Use the postal code as needed
  // }
  // };

  // const getPinCode = (index,i) => {
  //   const clickedLocation = mapResultdata[index];

  //   if (clickedLocation && clickedLocation.geometry && clickedLocation.geometry.location) {
  //     const latitude = clickedLocation.geometry.location.lat;
  //     const longitude = clickedLocation.geometry.location.lng;

  //     fetch(
  //       `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
  //       )
  //       .then((response) => {
  //         if (!response.ok) {
  //           throw new Error("Network response was not ok");
  //         }
  //         return response.json();
  //       })
  //       .then((data) => {
  //         console.log(data,'111111111111110000')

  //         setPostPinCode({
  //           address_name:data?.address?.amenity ? data?.address?.amenity : data?.address?.neighbourhood            ,
  //           pinCode:data?.address?.postcode            ,
  //           longitude:longitude,
  //           latitude:latitude,
  //           mapData:mapData,
  //         })
  //       })
  //       .catch((error) => {
  //         console.error("Reverse geocoding error:", error);
  //       });
  //   }
  // };

  const serachservice = (e) => {
    navigate("/explore-all/search/" + searchkey);
  };

  const openNav = () => {
    $(".navbar-collapse").addclassName("d-flex");
  };

  useEffect(() => {
    $(document).on("click", ".back-to-top", function () {
      $("html,body").animate({ scrollTop: 0 }, 1500);
    });

    $(window).on("scroll", function () {
      var ScrollTop = $(".back-to-top");
      if ($(window).scrollTop() > 100) {
        ScrollTop.fadeIn(300);
        $(".header-sticky").fadeIn(300);
      } else {
        ScrollTop.fadeOut(300);
        $(".header-sticky").fadeOut(300);
      }
    });
  });

  const [selectedActivity, setSelectedActivity] = useState("");
  const [results, setResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [Repair, setRepair] = useState();
  const [search_data, setSearch_data] = useState("");
  const [errorOnSearch, setErrorOnSearch] = useState(false);
  const { modalLogin, setmodalLogin, currentPathname } =
    useContext(UrlHistoryContext);
  const { modalLocation, setmodalLocation } = useContext(UrlHistoryContext);

  const handleActivityChange = async (e) => {
    setSearch_data(e);

    try {
      // e.preventDefault();

      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      const response = await axios.post(URLSearchProduct, {
        search_key: e,
      });

      const responseData = response.data;

      if (!responseData.error) {
        setResults(responseData.data);
      } else {
        setErrorOnSearch(true);
      }
    } catch (error) {
      setErrorOnSearch(true);
      console.error("Error:", error);
    }
  };
  const loginOpenModal = () => {
    setmodalLogin(true);
  };
  const RegisterOpenModel = () => {
    setmodalRegister(true);
  };
  const handleLocation = () => {
    setmodalLocation(true);
  };
  const handleClose = () => {
    setmodalLocation(false);
    setMapresultData([]);
    setmapKey("");
  };

  const [hideBooking, setHideBooking] = useState();

  const checkLog = () => {
    // debugger;
    if (token == null || token == undefined) {
      setHideBooking(true);
    } else {
      setHideBooking(false);
    }
  };
  useEffect(() => {
    checkLog();
  });

  return (
    <>
      <div>
        <LoginModals props={modalLogin} />
        <ModalRegister props={modalRegister} />
        {/* <LocationModal props={modalLocation}/> */}
        {currentPathname == "/cart" ? null : (
          <header
            id="large-screen"
            className="d-none d-lg-block  d-md-block header-style-01 fixed-top m-0 p-0"
          >
            <nav class="navbar navbar-expand-lg navbar-light bg-white d-block">
              {/* <div class="row "> */}
              {/* <div class="col"></div> */}
              <div className="row">
                <div className="col-lg-2 col-md-2">
                  <Link
                    onClick={() => setModal(false)}
                    class="navbar-brand w-75"
                    id="logo"
                    to="/"
                    title="Running Blog"
                    rel="home"
                  >
                    <img
                      src="/assets/uploads/media-uploader/logo.png"
                      alt="Running Blog"
                    />
                  </Link>
                </div>
                <div className="col-lg-3 col-md-3">
                  <div className=" search-container mx-md-0  placeholderData">
                    <div
                      className="ms-3 "
                      style={{
                        position: "absolute",
                        left: "0px",
                        top: "45%",
                        transform: "translateY(-50%)",
                      }}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="#757575"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.426 22.569L12 21.75l.573.82a1 1 0 01-1.147-.001z"
                          fill="#757575"
                        ></path>
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12 5.75a4 4 0 100 8 4 4 0 000-8zm-2 4a2 2 0 114 0 2 2 0 01-4 0z"
                          fill="#757575"
                        ></path>
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11.426 22.569L12 21.75c.573.82.575.818.575.818l.002-.001.006-.004.02-.015.07-.05.257-.192a25.395 25.395 0 003.575-3.368c1.932-2.223 3.995-5.453 3.995-9.188a8.5 8.5 0 10-17 0c0 3.735 2.063 6.965 3.995 9.187a25.4 25.4 0 003.575 3.369 14.361 14.361 0 00.327.242l.02.015.006.004.003.002zM7.404 5.154A6.5 6.5 0 0118.5 9.75c0 3.015-1.687 5.785-3.505 7.875A23.403 23.403 0 0112 20.495a23.4 23.4 0 01-2.995-2.869C7.187 15.534 5.5 12.764 5.5 9.75a6.5 6.5 0 011.904-4.596z"
                          fill="#757575"
                        ></path>
                      </svg>
                    </div>
                    <input
                      className=""
                      style={{
                        height: "100%",
                        width: "100%",
                        borderRadius: "8px",
                        border: "1px solid #dcdcdc",
                        cursor: "pointer",
                        fontSize: "15px",
                        padding: "18px 2px 18px 45px",
                      }}
                      type="text"
                      placeholder={mapData ? mapData : "Search for Location"}
                      readOnly
                      onClick={() => {
                        handleLocation(true);
                        setLocationModalOpen(false);
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "8px",
                        transform: "translateY(-50%)",
                      }}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="#757575"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8 9.94L3.53 5.47 2.47 6.53l5 5a.75.75 0 001.06 0l5-5-1.06-1.06L8 9.94z"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-3">
                  <div className=" search-container  mx-md-0">
                    <div
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "40%",
                        transform: "translateY(-50%)",
                      }}
                    >
                      <svg
                        class="svg-icon search-icon mt-2"
                        aria-labelledby="title desc"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 19.9 19.7"
                      >
                        <title id="title">Search Icon</title>
                        <desc id="desc">A magnifying glass icon.</desc>
                        <g class="search-path" fill="none" stroke="#848F91">
                          <path
                            stroke-linecap="square"
                            d="M18.5 18.3l-5.4-5.4"
                          />
                          <circle cx="8" cy="8" r="7" />
                        </g>
                      </svg>
                    </div>

                    <input
                      className="py-3"
                      style={{
                        // height: "100%",
                        width: "100%",
                        borderRadius: "8px",
                        border: "1px solid #dcdcdc",
                        paddingLeft: "40px",
                      }}
                      type="text"
                      placeholder={`Search in ${currentWordTyped}`}
                      value={search_data}
                      onChange={(e) => handleActivityChange(e.target.value)}
                    />
                    {search_data == "" ? (
                      ""
                    ) : (
                      <ul className="suggestions mt-4">
                        {results.length > 0 ? (
                          results.map((result, index) => (
                            <>
                              <Link
                                className="listLine text-dark"
                                to={`/category-details/${
                                  result?.id
                                }/${encodeURIComponent(result?.name)}`}
                              >
                                <ul className="d-flex m-0 align-items-center ">
                                  <li key={index}>
                                    <img
                                      src={result.image}
                                      alt="Avatar"
                                      class="avatar"
                                    />
                                  </li>
                                  <li key={index}>{result.name}</li>
                                </ul>
                              </Link>
                            </>
                          ))
                        ) : (
                          <>
                            {errorOnSearch == true ? (
                              <ul className="suggestions">
                                <li>no data</li>
                              </ul>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </ul>
                    )}
                  </div>
                </div>

                <div className="col-lg-4 col-md-4">
                  <div className="row ms-lg-3 ms-md-0">
                    <div className=" col-lg-4 col-md-6 p-0 m-0">
                      <div className="info-bar-item"></div>
                      <div
                        className="login-account"
                        // style={{ marginLeft: "100px" }}
                      >
                        <div
                          className="accounts border px-lg-4 px-md-2 py-3 d-flex justify-content-between "
                          style={{ borderRadius: "8px" }}
                          id=""
                        >
                          <span className="account">Account</span>
                          {/* <i className="las la-user"></i> */}
                          {/* <i class="fa-solid fa-user p-1 ps-2"></i> */}
                          <i class=" ms-2 mt-1 fa-regular fa-user"></i>
                        </div>

                        <ul className="account-list-item mt-1 pl-1">
                          {token == null && token == undefined ? (
                            <>
                              <li
                                className="list py-2 "
                                onClick={() => loginOpenModal()}
                                style={{ cursor: "pointer" }}
                              >
                                {/* <Link to="login"> */}
                                <div className="d-flex justify-content-around m-0 p-0">
                                  <p className="p-0 m-0">&nbsp;&nbsp;Log In</p>
                                  <p className="p-0 m-0">
                                    <i
                                      class=" ms-2 fa fa-sign-in fs-5 "
                                      aria-hidden="true"
                                    ></i>
                                  </p>
                                </div>
                                {/* <span className="mx-2">  </span> */}
                                {/* </Link> */}
                              </li>
                              {/* <li
                              className="list p-2"
                              onClick={() => RegisterOpenModel()}
                              style={{ cursor: "pointer" }}
                            >
                              <span>
                                <i class="fa-solid fa-user"></i>
                              </span>{" "}
                              <span className="mx-2"> Register </span>
                            </li> */}
                            </>
                          ) : (
                            <>
                              {/* <li className="list">
                            <Link to="profile">
                              <span>
                                <i class="fa-solid fa-user"></i>
                              </span>{" "}
                              My Profile{" "}
                            </Link> 
                          </li>*/}
                              {/* <li className="list" onClick={logout}>
                            <a href="/"> Log Out </a>
                          </li> */}
                              <li
                                className="list p-2"
                                onClick={() => logout()}
                                style={{ cursor: "pointer" }}
                              >
                                <div className="d-flex justify-content-between mx-3 m-0 p-0">
                                  <p className="p-0 m-0"> logout </p>
                                  <p className="p-0 m-0">
                                    <i class="fa-solid fa-right-to-bracket"></i>
                                  </p>{" "}
                                </div>
                              </li>
                              <Link
                                className="listLine text-black"
                                to="/request-delete-account"
                              >
                                <li
                                  className="list p-2"
                                  style={{ cursor: "pointer" }}
                                >
                                  {/* <Link to="register"> */}
                                  <div className="d-flex justify-content-between mx-3 m-0 p-0">
                                    <p className="m-0 p-0"> Remove</p>
                                    <p className="m-0 p-0">
                                      {/* <i class=" ms-2 mt-1 fa-regular fa-user"></i> */}
                                      <i class="fa-solid fa-trash-can-arrow-up"></i>
                                    </p>{" "}
                                  </div>
                                  {/* </Link> */}
                                </li>
                              </Link>
                            </>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 p-0 m-0">
                      <div className="booking   cursor-pointer">
                        <Link className="listLine text-black" to="/Booking">
                          <div
                            // className="accounts border  px-3 py-3 "
                            className={
                              hideBooking
                                ? "d-none"
                                : "accounts border  px-lg-3 px-md-1 py-3 d-block d-flex justify-content-between "
                            }
                            style={{ borderRadius: "8px" }}
                            id=""
                          >
                            <span className="account ms-1">Booking</span>
                            {/* <i className="las la-user"></i> */}

                            <img
                              width="20"
                              height="20"
                              className="ms-2 mt-1"
                              // src="https://img.icons8.com/material-rounded/24/000000/event-accepted.png"
                              src="/assets/frontend/img/banner/booking.png"
                              alt="event-accepted"
                            />
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </nav>
          </header>
        )}
        {currentPathname == "/cart" ? null : (
          <header
            id="small-screen"
            className="col d-lg-none d-md-none d-block  header-style-01 fixed-top col-sm p-2"
            style={{ backgroundColor: "black" }}
          >
            <span className="text-dark fw-bold" style={{ fontSize: "20px" }}>
              <div className="row">
                <div className="col-md-6">
                  <div className=" search-container">
                    <div
                      className="ms-3"
                      style={{
                        position: "absolute",
                        left: "2px",
                        top: "45%",
                        transform: "translateY(-50%)",
                      }}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="#757575"
                        xmlns="http://www.w3.org/2000/svg"
                        color="white"
                      >
                        <path
                          d="M11.426 22.569L12 21.75l.573.82a1 1 0 01-1.147-.001z"
                          fill="#757575"
                        ></path>
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12 5.75a4 4 0 100 8 4 4 0 000-8zm-2 4a2 2 0 114 0 2 2 0 01-4 0z"
                          fill="#757575"
                        ></path>
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11.426 22.569L12 21.75c.573.82.575.818.575.818l.002-.001.006-.004.02-.015.07-.05.257-.192a25.395 25.395 0 003.575-3.368c1.932-2.223 3.995-5.453 3.995-9.188a8.5 8.5 0 10-17 0c0 3.735 2.063 6.965 3.995 9.187a25.4 25.4 0 003.575 3.369 14.361 14.361 0 00.327.242l.02.015.006.004.003.002zM7.404 5.154A6.5 6.5 0 0118.5 9.75c0 3.015-1.687 5.785-3.505 7.875A23.403 23.403 0 0112 20.495a23.4 23.4 0 01-2.995-2.869C7.187 15.534 5.5 12.764 5.5 9.75a6.5 6.5 0 011.904-4.596z"
                          fill="#757575"
                        ></path>
                      </svg>
                    </div>
                    <input
                      className="area-locations   no-border  "
                      style={{
                        fontFamily: "",
                        fontSize: "16px",
                        background: "black",
                        height: "100%",
                        width: "83%",
                        borderRadius: "8px",
                        cursor: "pointer",
                        paddingLeft: "45px",
                        border: "none !important",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                      type="text"
                      placeholder={mapData ? mapData : "Search for Location"}
                      readOnly
                      onClick={() => handleLocation(true)}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "8%",
                        transform: "translateY(-50%)",
                        color: "white",
                      }}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="#757575"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8 9.94L3.53 5.47 2.47 6.53l5 5a.75.75 0 001.06 0l5-5-1.06-1.06L8 9.94z"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </span>

            <div className="mt-3 ">
              <div className=" search-container px-4">
                <div
                  style={{
                    position: "absolute",
                    left: "8%",
                    top: "40%",
                    transform: "translateY(-50%)",
                  }}
                >
                  {/* Search icon */}
                  <svg
                    class="svg-icon search-icon mt-2"
                    aria-labelledby="title desc"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 19.9 19.7"
                  >
                    <title id="title">Search Icon</title>
                    <desc id="desc">A magnifying glass icon.</desc>
                    <g class="search-path" fill="none" stroke="#848F91">
                      <path stroke-linecap="square" d="M18.5 18.3l-5.4-5.4" />
                      <circle cx="8" cy="8" r="7" />
                    </g>
                  </svg>
                </div>

                <input
                  className="py-3"
                  style={{
                    // height: "100%",
                    width: "100%",
                    borderRadius: "8px",
                    border: "1px solid #dcdcdc",
                    paddingLeft: "40px",
                  }}
                  type="text"
                  // placeholder="Search in Disinfection Services"
                  // value={search_data}
                  // onChange={(e) => handleActivityChange(e.target.value)}
                  placeholder={`Search in ${currentWordTyped}`}
                  value={search_data}
                  onChange={(e) => handleActivityChange(e.target.value)}
                />
                {search_data !== "" && (
                  <ul className="suggestions  ms-4 ms-lg-0 ms-md-0 mt-lg-4 mt-2">
                    {results.length > 0 ? (
                      results.map((result, index) => (
                        <li key={index} className="d-flex align-items-center">
                          <img
                            src={result.image}
                            alt="Avatar"
                            className="avatar"
                          />
                          <span className="text-black ms-2">{result.name}</span>
                        </li>
                      ))
                    ) : (
                      <>
                        {errorOnSearch ? (
                          <li className="suggestions">no data</li>
                        ) : null}
                      </>
                    )}
                  </ul>
                )}
              </div>
            </div>
          </header>
        )}

        <div
          class="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content"></div>
          </div>
        </div>
      </div>
      {/* == location modal */}
      {modalLocation ? (
        <Modal
          // style={{ maxWidth: "900px", width: "80%" }}
          Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          id="category-modal"
          show={modalLocation}
          onHide={handleClose}
          className="main_model mapModelWidth"
          dialogClassName="main_model"
        >
          <Modal.Header
            className="modelHeder"
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              border: "none",
              bottom: "50px",
            }}
          >
            <div
              className="modalCloseButton cursor-pointer"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span style={{ margin: "3px 9px" }} onClick={() => handleClose()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-x"
                  style={{ margin: "3px -3px", color: "gray" }}
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </span>
            </div>
          </Modal.Header>
          <Modal.Body
            className=" model_body"
            style={{ padding: "0px 0px 11px 0px", marginTop: "-40px" }}
          >
            <MapContainer openLocation={locationModakOpen} />
          </Modal.Body>
          {/* <div>
            {mapResultdata.length ? (
              <>
                {mapResultdata?.map((item,index) => (
                  <>
                    {item?.address_components?.map((data,i) => (
                      <>
                      <div className="row">
                        <div className="col-3">
                          <div
                            className="ms-3"
                            style={{
                              position: "absolute",
                              left: "52px",
                              top: "15%",
                              transform: "translateY(-50%)",
                            }}
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="#757575"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.426 22.569L12 21.75l.573.82a1 1 0 01-1.147-.001z"
                                fill="#757575"
                              ></path>
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M12 5.75a4 4 0 100 8 4 4 0 000-8zm-2 4a2 2 0 114 0 2 2 0 01-4 0z"
                                fill="#757575"
                              ></path>
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M11.426 22.569L12 21.75c.573.82.575.818.575.818l.002-.001.006-.004.02-.015.07-.05.257-.192a25.395 25.395 0 003.575-3.368c1.932-2.223 3.995-5.453 3.995-9.188a8.5 8.5 0 10-17 0c0 3.735 2.063 6.965 3.995 9.187a25.4 25.4 0 003.575 3.369 14.361 14.361 0 00.327.242l.02.015.006.004.003.002zM7.404 5.154A6.5 6.5 0 0118.5 9.75c0 3.015-1.687 5.785-3.505 7.875A23.403 23.403 0 0112 20.495a23.4 23.4 0 01-2.995-2.869C7.187 15.534 5.5 12.764 5.5 9.75a6.5 6.5 0 011.904-4.596z"
                                fill="#757575"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div
                          className="col-9 mb-2 cursor-pointer"
                          onClick={() => {
                            handleClose();
                            setMapData(item?.formatted_address);
                            getPinCode(index,i);
                            setUpdateData(!updateData)
                          }}
                        >
                          <h6 className="m-0 p-0">{data?.long_name}</h6>
                          <p className="m-0 p-0">{item?.formatted_address}</p>
                        </div>
                        </div>
                      </>
                    ))}
                  </>
                ))}
              </>
            ) : (
              ""
            )}
          </div> */}
        </Modal>
      ) : (
        ""
      )}
    </>
  );
}
