import axios from "axios";
import React, { useEffect, useState } from "react";
import { URLDeleteaddress, URLGetAddress } from "../util/UrlConst";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";

export default function SavedAddress() {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [alladdress, setaddaddress] = useState([]);
  const [isloadin, setLoading] = useState(true);
  async function getdata() {
    await axios.post(URLGetAddress, { valetuserid: user.id }).then((res) => {
      setaddaddress(res.data.address);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  }

  useEffect(() => {
    getdata();
  }, []);

  const getData = () => getdata();

  const deleteaddress = (id) => async (e) => {
    await axios
      .post(URLDeleteaddress, { valetuserid: user.id, id: id })
      .then((res) => {
        toast(res.data.address, { type: "success", theme: "dark" });
        getdata();
      });
  };

  return (
    <>
      {/* {{-- ===========================================
                                BREADCRUMB
            =========================================== --}} */}
      <div className="banner-inner-area section-bg-3 padding-top-100 padding-bottom-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner-inner-contents">
                <h2 className="banner-inner-title mb-0"> Saved Addresses </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {{-- ===========================================
                    ADDRESS AREA
            =========================================== --}} */}
      <div className="address-area section-bg-2 padding-top-100 padding-bottom-40">
        <div className="container">
          {alladdress.length > 0 ? (
            <div className="address-list">
              <div className="row">
                {alladdress &&
                  alladdress.map((data) => (
                    <div key={data.id} className="col-lg-6">
                      <div className="address-box">
                        <div className="top-bar">
                          <div className="name">
                            {data.name} ( {data.mobile}{" "}
                            {", " + data.alter_number})
                          </div>
                          <div className="icons">
                            <Button onClick={deleteaddress(data.id)}>
                              <i className="las la-trash"></i>
                            </Button>
                          </div>
                        </div>
                        <div className="inner">
                          <table className="table table-borderless mb-0">
                            <tbody>
                              <tr>
                                <td>Zip Code</td>
                                <td className="text-end">{data.zipcode}</td>
                              </tr>
                              <tr>
                                <td>State</td>
                                <td className="text-end">{data.state}</td>
                              </tr>
                              <tr>
                                <td>District</td>
                                <td className="text-end">{data.district}</td>
                              </tr>
                              <tr>
                                <td>Landmarks</td>
                                <td className="text-end">{data.landmark}</td>
                              </tr>
                              <tr>
                                <td>Building Name/House No.</td>
                                <td className="text-end">{data.building}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            <>
              {" "}
              {isloadin ? (
                <center>
                  <h4>Loading....</h4>
                </center>
              ) : (
                <center>
                  {" "}
                  <h5> No address found</h5>
                </center>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
