import React, { useEffect } from "react";
// eslint-disable-next-line no-unused-vars
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

export default function About() {
  useEffect(() => {});

  // ========================= CLIENTS SLIDER
  // eslint-disable-next-line no-unused-vars
  const forclients = {
    margin: 30,
    responsiveclassName: true,
    nav: true,
    dots: false,
    autoplay: true,
    navText: [
      '<i className="las la-angle-left"></i>',
      '<i className="las la-angle-right"></i>',
    ],
    smartSpeed: 500,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      576: {
        items: 2,
      },
      768: {
        items: 3,
      },
      991: {
        items: 4,
      },
      1200: {
        items: 4,
      },
    },
  };

  return (
    <>
      <div className="banner-inner-area section-bg-3 padding-top-100 padding-bottom-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner-inner-contents">
                <h2 className="banner-inner-title mb-0"> About </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section
        className="About-area"
        data-padding-top="40"
        data-padding-bottom="10"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 margin-top-30">
              <div className="about-thumb-content">
                <div className="about-thumb">
                  <img src="assets/frontend/img/about.jpg" alt="" />
                  <div className="about-experience">
                    <h2 className="years-tiitle">8 Years </h2>
                    <h4 className="experience-tiitle"> Experience </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 margin-top-30">
              <div className="single-about">
                <h2 className="about-title">Know About Us</h2>
                <div className="about-contents">
                  <p className="about-para text-black">
                    Valet Groups is one stop solution to your home, office,
                    society and corporate services. We provide you with a
                    variety of services that simplify your everyday living. You
                    can book a wide range of services, delivered by well
                    qualified & background checked professionals from anywhere,
                    anytime by booking services. Here's a brief overview of some
                    of our key help services-Cleaning service, Appliance repair,
                    AC Service, electrician, plumber & carpenter service and
                    more. We are expanding all over India.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="margketplace-area"
        data-padding-top="10"
        data-padding-bottom="10"
      >
        <div className="container pt-0 pt-md-4 pt-lg-4">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-7 col-md-10">
              <div className="section-title">
                <h2 className="title py-2">
                  <span className="bg-main-one">Services</span> We Offer{" "}
                </h2>
                {/* <span className="section-para">It is a long established fact that a reader will be distracted by the
                                    readable content of a page when looking at its layout.</span> */}
              </div>
            </div>
          </div>
          <div className="row margin-top-20 justify-content-center">
            <div className="col-lg-4 mb-2 col-md-6 margin-top-30 marketplace-child">
              <div
                className="single-marketplace wow fadeInUp"
                data-wow-delay=".2s"
              >
                <div className="icon">
                  <i className="las la-home"></i>
                </div>
                <div className="marketplace-contents">
                  <h5 className="common-title">Home Cleaning </h5>
                  <p className="common-para">
                    Specialized in cleaning services. You can order for
                    bathroom, bedroom, kitchen deep cleaning, floor scrubbing
                    and polishing services as well as an all-inclusive Full Home
                    Deep Cleaning and move-in cleaning with modern machines
                    ensuring clean, healthy and hygienic service.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-2 col-md-6 margin-top-30 marketplace-child">
              <div
                className="single-marketplace wow fadeInUp"
                data-wow-delay=".2s"
              >
                <div className="icon">
                  <i className="las la-couch"></i>
                </div>
                <div className="marketplace-contents">
                  <h5 className="common-title">Sofa Cleaning </h5>
                  <p className="common-para">
                    Specialized sofa cleaning services namely sofa vacuuming &
                    shampooing & sanitisation ensuring removal of dust,
                    preserving its colour and increasing its life span.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-2 col-md-6 margin-top-30 marketplace-child">
              <div
                className="single-marketplace wow fadeInUp"
                data-wow-delay=".2s"
              >
                <div className="icon">
                  <i className="las la-shower"></i>
                </div>
                <div className="marketplace-contents">
                  <h5 className="common-title">Carpet Cleaning</h5>
                  <p className="common-para">
                    Services involve shampooing & sanitisation to flush out dust
                    particles, termites and germs, ensure your carpet is
                    spotlessly clean by using modern techniques & equipment and
                    will look like a brand new one all over again.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-2 col-md-6 margin-top-30 marketplace-child">
              <div
                className="single-marketplace wow fadeInUp"
                data-wow-delay=".2s"
              >
                <div className="icon">
                  <i className="las la-spider"></i>
                </div>
                <div className="marketplace-contents">
                  <h5 className="common-title">Pest Control</h5>
                  <p className="common-para">
                    Choose from a set of pest control services like Cockroach
                    control, Rat Control, Ant Control, Termite Control, General
                    pest control etc. our professionals use only certified
                    chemicals, because your safety matters.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-2 col-md-6 margin-top-30 marketplace-child">
              <div
                className="single-marketplace wow fadeInUp"
                data-wow-delay=".2s"
              >
                <div className="icon">
                  <i className="las la-fan"></i>
                </div>
                <div className="marketplace-contents">
                  <h5 className="common-title">AC Services</h5>
                  <p className="common-para">
                    AC installation, repair, maintenance, fixing major and minor
                    AC problems and AMCs- You name it, we offer it.
                    Refrigerator/Microwave/Washing Machine/Water Purifier:- When
                    it comes to appliances, we are the best. We provide end to
                    end solutions for your Appliance Repair.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-2 col-md-6 margin-top-30 marketplace-child">
              <div
                className="single-marketplace wow fadeInUp"
                data-wow-delay=".2s"
              >
                <div className="icon">
                  <i className="las la-broadcast-tower"></i>
                </div>
                <div className="marketplace-contents">
                  <h5 className="common-title">Electrical</h5>
                  <p className="common-para">
                    We offer electrical services at your place for fitting and
                    removing fans, switchboard installation and replacement,
                    circuit fault finding, all kinds of wiring works, lights
                    fitting and removal and various electrical installation and
                    repair services. We make sure that they are professional,
                    and follow all safety measures.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-2 col-md-6 margin-top-30 marketplace-child">
              <div
                className="single-marketplace wow fadeInUp"
                data-wow-delay=".2s"
              >
                <div className="icon">
                  <i className="las la-toilet"></i>
                </div>
                <div className="marketplace-contents">
                  <h5 className="common-title">Plumber Services</h5>
                  <p className="common-para">
                    Skilled plumbers for all your plumbing issue fixing of leaky
                    pipes, unclogging of choked drains, dripping faucets,
                    bathroom fittings, toilet and sanitary work etc.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="clientlogo-area" data-padding-top="40" data-padding-bottom="40">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="clientlogo-wrapper">
                                <div className="clientlogo-slider nav-style-one">
                                    <OwlCarousel loop

                                        className="owl-theme"
                                        {...forclients}
                                        margin={8} >
                                        <div className="clientlogo-item">
                                            <div className="single-clientlogo">
                                                <div className="thumb">
                                                    <a target="_blank" href="assets/uploads/media-uploader/cl11641478287.png">
                                                        <img src="assets/uploads/media-uploader/cl11641478287.png" alt="" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="clientlogo-item">
                                            <div className="single-clientlogo">
                                                <div className="thumb">
                                                    <a target="_blank" href="assets/uploads/media-uploader/cl21641480573.png">
                                                        <img src="assets/uploads/media-uploader/cl21641480573.png" alt="" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="clientlogo-item">
                                            <div className="single-clientlogo">
                                                <div className="thumb">
                                                    <a target="_blank" href="assets/uploads/media-uploader/cl31641615538.png">
                                                        <img src="assets/uploads/media-uploader/cl31641615538.png" alt="" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="clientlogo-item">
                                            <div className="single-clientlogo">
                                                <div className="thumb">
                                                    <a target="_blank" href="assets/uploads/media-uploader/cl41641615570.png">
                                                        <img src="assets/uploads/media-uploader/cl41641615570.png" alt="" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="clientlogo-item">
                                            <div className="single-clientlogo">
                                                <div className="thumb">
                                                    <a target="_blank" href="assets/uploads/media-uploader/cl21641480573.png">
                                                        <img src="assets/uploads/media-uploader/cl21641480573.png" alt="" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
    </>
  );
}
