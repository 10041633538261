import React from 'react'

export default function Refund() {
    return (
        <>

            <div className="banner-inner-area section-bg-3 padding-top-100 padding-bottom-30">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="banner-inner-contents">
                                <h2 className="banner-inner-title mb-0"> Refund Policy </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="policies" data-padding-top="40" data-padding-bottom="40">
                <div className="container">
                    <div className="data">
                        Since our Website offers non-tangible, irrevocable goods we do not provide
                        refunds after the product is purchased, which you acknowledge prior to
                        purchasing any product on the Website. Please make sure that you've carefully
                        read service description before making a purchase.
                    </div>
                    <div className="head">
                        Contacting Us
                    </div>
                    <div className="data">
                        If you would like to contact us concerning any matter relating to this Refund
                        Policy, you may do so via the contact form, send an email to support@theclassy.in
                        or write a letter to the classy yashwant plaza, Indore, Madhya Pradesh 452001
                    </div>
                    <div className="last">
                        This document was last updated on November, 2022
                    </div>
                </div>
            </section>

        </>
    )
}
